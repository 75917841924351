
<h5 class="heading1">{{'my_Support'|translate}}</h5>   
<div class="row">
    <div class="col-md-12">
        <div class="heading">    
            <p>{{'please_create'|translate}}</p>
        </div>
        <div class="float-top">
            <button class="main-button" (click)="viewOrAddSupport()">{{'submit_new_issue'|translate}}</button>    
        </div>   
    </div>
    <div class="cardd margin-top20 margin-bottom20">
        <div class="table-resposive">
            <table class="table table-striped table-shadow pricing-table">
                <thead class="text-center">
                <tr>
                    <th class="heading-priceing">
                        {{'issue_id'|translate}}
                    </th>
                    <th class="heading-priceing">
                        {{'date'|translate}}
                    </th>
                    <th class="heading-priceing">
                        {{'Subject'|translate}}
                    </th>
                    <th class="heading-priceing">
                        {{'status'|translate}}
                    </th>
                </tr>
                </thead>
                <tfoot>
                <tr *ngFor="let support of supportTicketList">
                    <td class="bot-button">
                        <div class="tfoot-info-cont">
                            <div class="tfoot-info">
                                {{support.supportIssueId}}
                            </div>
                        </div>
                    </td>
                    <td class="bot-button">
                        <div class="tfoot-info-cont">
                            <div class="tfoot-info">
                                {{support.createdDate | date:"dd MMM yyyy"}}
                            </div>
                        </div>
                    </td>
                    <td class="bot-button">
                        <div class="tfoot-info-cont">
                            <div class="tfoot-info">
                                {{support.subject}}
                            </div>
                        </div>
                    </td>
                    <td class="bot-button">
                        <div class="tfoot-info-cont">
                            <div class="tfoot-info">
                               <span class="open" *ngIf="support.status=='Open'">{{support.status}}</span> 
                               <span class="closed" *ngIf="support.status=='Closed'">{{support.status}}</span> 
                            </div>
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>    
    
