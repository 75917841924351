export  class AppAdminRouteConstants {
    public static adminRoute: string = 'admin';
    public static DASHBOARD_ROUTE = 'dashboard';
    public static SALES_DASHBOARD_ROUTE = 'sales-dashboard';
    public static VENDORS_DASHBOARD_ROUTE = 'vendors-dashboard';
    public static REPAIR_DASHBOARD_ROUTE = 'repair-dashboard';
    public static AUTH_ROUTE = 'login';
    public static REPAIR_ROUTE = 'repair';
    public static TRANSIT_ROUTE = 'transit';
    public static DEVICE_ISSUE_LISTING_ROUTE = 'device-issue';
    public static SERVICE_AREA_ROUTE = 'sa';
    public static SERVICE_CENTER_ROUTE = 'sc';
    public static ADMIN_USER_LISTING_ROUTE = 'users';
    public static DEVICE_CONTRACT_LISTING = 'dc-list';
    public static APP_ACTION_TRACKER = 'action-tracker';
    public static VENDORS_APPROVAL = 'vendors-approval';
    public static SUPPORT_TICKET = 'support-ticket';
    public static TEST_COMPONENT = 'test-component';
    public static CREATE_ORDER = 'create-order';

    /**
     * 
     * @param route 
     */
    public static getRoute(route): string {
        return "/admin/" + route;
    }
} 