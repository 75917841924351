import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-loader-dialog',
  templateUrl: './loader-dialog.component.html',
  styleUrls: ['./loader-dialog.component.css']
})
export class LoaderDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<LoaderDialogComponent>) { }

  ngOnInit(): void {
  }
}
