import { DatePipe, DOCUMENT } from "@angular/common";
import { Component, ElementRef, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { BaseComponent } from "src/app/abstract-components/base.component";
import { AppConstants, OrderType } from "src/app/constants/app.constants";
import { ACTypesModel, BrandModel, RefrigeratorTypesModel, WashingMachineTypesModel } from "src/app/data/brand-model/brand.model";
import { DeviceModel } from "src/app/data/device-model/device.model";
import { MobiOrderModel } from "src/app/data/mobi-order-model/mobi-order.model";
import { OrderRepairIssueModel } from "src/app/data/order-repair-issue-model/order-repair-issue.model";
import { PaymentModel, PaymentModelAfterPromo } from "src/app/data/payment-model/payment.model";
import { PaytmPaymentModel, RazorPaymentModel } from "src/app/data/paytm-payment-model/paytm-payment-model";
import { PaytmResponseModel } from "src/app/data/paytm-response-model/paytm-response-model";
import { ProductCategory } from "src/app/data/product-category-model/product-category-model";
import { RepairChargesModel } from "src/app/data/repair-charges-model/repair-charges.model";
import { UserAddressModel } from "src/app/data/user-address-model/user-address.model";
import { UserDetailsModel } from "src/app/data/user-details/user-details.model";
import { NewHomeService } from "src/app/services/home.service";
import { OrderDetailsService } from "src/app/services/order-details.service";
import { environment } from "src/environments/environment";
import { LoginSendOTPDialogDialog } from "../dialogs/login-sendOTP-dialog/login-sendotp-dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "src/app/services/user.service";
import { State } from "src/app/data/state-model/state-model";
import { City } from "src/app/data/city-model/city-model";
import { PurchaseModel } from "src/app/data/purchase-model/purchase-model";
import { DeviceContractModel } from "src/app/data/device-contract-model/device-contract.model";
import { DevicePlanModel } from "src/app/data/device-plans-model/device-plans.model";
import * as util from "../../utility-functions";
import { Title } from "@angular/platform-browser";
import { AppRouteConstants } from "src/app/constants/app-route.constants";
import { trigger, transition, style, animate } from "@angular/animations";

declare var Razorpay: any;

@Component({
  selector: "app-order-details",
  templateUrl: "./order-details.component.html",
  styleUrls: ["./order-details.component.scss"],
  animations: [
    trigger("openClose", [
      transition("void => *", [
        style({
          height: 0,
          opacity: 0,
          transform: "scale(0.85)",
          "margin-bottom": 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
        }),
        animate(
          "50ms",
          style({
            height: "*",
            "margin-bottom": "*",
            paddingTop: "*",
            paddingBottom: "*",
            paddingRight: "*",
            paddingLeft: "*",
          })
        ),
        animate(70),
      ]),
    ]),
  ],
})
export class OrderDetailsComponent extends BaseComponent implements OnInit {
  productCategory: ProductCategory[] = [];
  brandList: BrandModel[] = [];
  deviceList: DeviceModel[] = [];
  repairChargeList: RepairChargesModel[] = [];
  selectedProblems: RepairChargesModel[] = [];
  selectedProblemsPromoCodeRepair: RepairChargesModel[] = [];
  temperdGlassModel: RepairChargesModel = null!;
  user_data: any;
  product: any = null;
  brand: any = null;
  model: any = null;
  repair: any = null;
  acType: any = null;
  refrigeratorTypes: any = null;
  washingMachineTypes: any = null;
  btnType: string = "submit";
  stateList: State[] = [];
  cityList: City[] = [];
  acTypesList: ACTypesModel[] = [];
  refrigeratorTypesList: RefrigeratorTypesModel[] = [];
  washingMachineTypesList: WashingMachineTypesModel[] = [];
  minDate: Date = new Date();
  getPaymentDTOUrl: string;
  userDto: UserDetailsModel = new UserDetailsModel();
  fetchedUserDto: UserDetailsModel = new UserDetailsModel();
  mobiOrder: MobiOrderModel = new MobiOrderModel();
  createdMobiOrder: MobiOrderModel = new MobiOrderModel();
  addressOrderDto: UserAddressModel = new UserAddressModel();
  updateAddressOrderDto: UserAddressModel = new UserAddressModel();
  paymentDTO: PaymentModel;
  paymentDTO1: PaymentModelAfterPromo;
  deviceContractModel: DeviceContractModel;
  createPaymentDto: PaymentModel;
  user_data1: any;
  calculatorForm!: FormGroup;
  totalAmount: number = 0;
  goldMembershipDiscount: number = 0;
  newHomeService: NewHomeService;
  getProductCategoryDataListUrl: string =
    AppConstants.orderService + AppConstants.getAllProducts;
  createMMOrderUrl: string =
    AppConstants.orderService + AppConstants.createMMOrder;
  updateUserAddressUrl: string =
    AppConstants.userService + AppConstants.addAddressByUserId;
  getPaytmPaymentDataUrl: string =
    AppConstants.paymentService + AppConstants.getPaytmPaymentData;
  createPaymentUrl: string =
    AppConstants.paymentService + AppConstants.createPayment;
  paymentResponseUrl: string =
    AppConstants.paymentService + AppConstants.paymentResponse;
  getUserByIdUrl: string;
  datePipe: DatePipe;
  paymentResponse: PaytmResponseModel = null!;
  alreadyPaymentFailed: boolean = false;
  isErrorOnAnyStep: boolean = false;
  afterErrorCallFunction: string = "";
  getAllStatesUrl: string;
  getAllCitiesUrl: string;
  userID: any = localStorage.getItem("userId");
  planName: string = "";
  temperedGlass: string = "";
  utility: any = util;
  paymentOnlineModeType:string = "";
  isTemperedAdded: boolean = false;
  private stringToken: any;
  goldMember: boolean = false;
  updateadressurl: string;
  selectedProductForSwitch: any;
  constructor(
    private titleService: Title,
    dialog: MatDialog,
    private userService: UserService,
    snackBar: MatSnackBar,
    newHomeService: NewHomeService,
    router: Router,
    private orderDetailsService: OrderDetailsService,
    datePipe: DatePipe,
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document,
    private ngxService: NgxUiLoaderService
  ) {
    super();
    this.dialog = dialog;
    this.snackBar = snackBar;
    this.newHomeService = newHomeService;
    this.router = router;
    this.orderDetailsService = orderDetailsService;
    this.datePipe = datePipe;
    this.titleService.setTitle("Order Details");

    if (this.router.getCurrentNavigation()?.extras?.state?.["planName"]) {

      this.planName = this.router.getCurrentNavigation()?.extras?.state?.["planName"];
      this.totalAmount = this.router.getCurrentNavigation()?.extras?.state?.["orderAmount"];

    }

    if (this.router.getCurrentNavigation()?.extras?.state?.["temperedGlass"]) {

      this.temperedGlass = this.router.getCurrentNavigation()?.extras?.state?.["temperedGlass"];

    }
    if (this.router.getCurrentNavigation()?.extras?.state?.["productCategory"]) {

      this.productCategory = this.router.getCurrentNavigation()?.extras?.state?.["productCategory"];
      this.repairChargeList = this.router.getCurrentNavigation()?.extras?.state?.["repairChargeList"];
      this.product = this.router.getCurrentNavigation()?.extras?.state?.["product"];

      if (this.product == "MOBILE") {

        this.brandList = this.router.getCurrentNavigation()?.extras?.state?.["brandList"];
        this.deviceList = this.router.getCurrentNavigation()?.extras?.state?.["deviceList"];
        this.brand = this.router.getCurrentNavigation()?.extras?.state?.["brand"];
        this.model = this.router.getCurrentNavigation()?.extras?.state?.["model"];

      } else if (this.product == "AIR_CONDITIONER") {

        this.acTypesList = this.router.getCurrentNavigation()?.extras?.state?.["acTypesList"];
        this.acType = this.router.getCurrentNavigation()?.extras?.state?.["acTypes"];

      } else if (this.product == "REFRIGERATOR") {

        this.refrigeratorTypesList = this.router.getCurrentNavigation()?.extras?.state?.["refrigeratorTypesList"];
        this.refrigeratorTypes = this.router.getCurrentNavigation()?.extras?.state?.["refrigeratorTypes"];

      }
      else if (this.product == "WASHING_MACHINE") {

        this.washingMachineTypesList = this.router.getCurrentNavigation()?.extras?.state?.["washingMachineTypesList"];
        this.washingMachineTypes = this.router.getCurrentNavigation()?.extras?.state?.["washingMachineTypes"];

      }

      this.repair = this.router.getCurrentNavigation()?.extras?.state?.["repair"];
      this.totalAmount = this.router.getCurrentNavigation()?.extras?.state?.["totalAmount"];
    }
    this.userService.listen().subscribe((m: any) => {
      if (m != null) {
        this.userID = m[0];
        this.getUserDetails(this.userID);
      }
    });
    this.mobiOrder.quality_tested = "No";
    //this.LoadACTypes();
  }
  changeAcType(allProducts: any) {
    let getRepairChargeDataListUrl;
    if (
      this.calculatorForm.controls["product"].getRawValue() == "AIR_CONDITIONER"
    ) {
      getRepairChargeDataListUrl =
        AppConstants.orderService +
        AppConstants.getRepairChargesFromMobiMech +
        "?acTypeId=" +
        allProducts.acTypeId;
    } else if (this.calculatorForm.controls["product"].getRawValue() == "REFRIGERATOR") {
      getRepairChargeDataListUrl =
        AppConstants.orderService +
        AppConstants.getRepairChargesFromMobiMech +
        "?refrigeratorTypeId=" +
        allProducts.refrigeratorTypeId;
    }
    else if (this.calculatorForm.controls["product"].getRawValue() == "WASHING_MACHINE") {
      getRepairChargeDataListUrl =
        AppConstants.orderService +
        AppConstants.getRepairChargesFromMobiMech +
        "?washingMachineTypeId=" +
        allProducts.washingMachineTypeId;
    }
    this.newHomeService
      .getRepairChargeDataListApi(getRepairChargeDataListUrl)
      .subscribe({
        next: (res) => {
          this.repairChargeList = res;
          this.repairChargeList = this.repairChargeList.filter(
            (e: any) => e.repairAmount > 0
          );
        },
        error: (error) => {
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });

    this.calculatorForm.controls["repair"].reset();
    this.totalAmount = 0;
    this.repairChargeList = [];
    this.selectedProblems = [];
  }
  allStatesApi() {
    this.getAllStatesUrl = AppConstants.userService + AppConstants.getAllStates;
    this.orderDetailsService.getAllStates(this.getAllStatesUrl).subscribe({
      next: (res: State[]) => {
        this.stateList = [];
        res.forEach((e) => {
          if (
            e.name == "UTTAR PRADESH" ||
            e.name == "DELHI" ||
            e.name == "HARYANA"
          ) {
            this.stateList.push(e);
          }
        });
      },
      error: (error) => {
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
      },
    });
  }

  allCitiesApi(state: string) {
    this.getAllCitiesUrl =
      AppConstants.userService +
      AppConstants.getAllCitiesByStateName +
      "?stateName=" +
      state;
    this.orderDetailsService.getAllCities(this.getAllCitiesUrl).subscribe({
      next: (res: City[]) => {
        this.cityList = res;
      },
      error: (error) => {
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
      },
    });
  }

  initiateClientModule() {
    if (this.alreadyPaymentFailed) {
      this.alreadyPaymentFailed = false;
    }
    var s = this.document.createElement("script");
    s.type = "application/javascript";
    s.src =
      environment.paytmUrl +
      "/merchantpgpui/checkoutjs/merchants/" +
      this.paytmPaymentData.mid +
      ".js";
    s.crossOrigin = "anonymous";
    s.id = "paytmScript";
    const __this = this;
    s.onload = () => {
      var config = {
        root: "",
        flow: "DEFAULT",
        data: {
          orderId: this.paytmPaymentData.orderId,
          token: this.paytmPaymentData.TOKEN,
          tokenType: "TXN_TOKEN",
          amount: this.paytmPaymentData.TXN_AMOUNT,
        },
        merchant: {
          mid: this.paytmPaymentData.mid,
          // name: "Mobimech technplogies limited",
          redirect: false,
        },
        handler: {
          notifyMerchant: function (eventName, data) {
            console.log("notifyMerchant handler function called");
            console.log("eventName => ", eventName);
            console.log("data => ", data);
          },
          transactionStatus: (data: PaytmResponseModel) => {
            this.paymentResponse = data;
            window["Paytm"].CheckoutJS.close();
          },
        },
      };

      if (window["Paytm"] && window["Paytm"].CheckoutJS) {
        window["Paytm"].CheckoutJS.onLoad(function excecuteAfterCompleteLoad() {
          window["Paytm"].CheckoutJS.init(config)
            .then(() => {
              window["Paytm"].CheckoutJS.invoke();
            })
            .catch(function onError(error) {
              console.log("error => ", error);
            });
        });
      }
    };
    this.elementRef.nativeElement.appendChild(s);
  }

  radioChange(event){
    // console.log(event.value);
    this.paymentOnlineModeType = event.value
  }

  ngOnInit(): void {
    this.setAllUrls();
    setInterval(() => {
      if (this.paymentResponse != null) {
        if (this.paymentResponse.STATUS == "TXN_SUCCESS") {
          this.orderDetailsService
            .paymentResponse("/payment/payment-response", this.paymentResponse)
            .subscribe({
              next: (res) => {
                this.ngxService.stop();
                this.alreadyPaymentFailed = false;
                this.router.navigateByUrl(res["redirect"]);
              },
              error: (error) => {
                this.alreadyPaymentFailed = true;
                this.ngxService.stop();
              },
            });
        } else {
          this.alreadyPaymentFailed = true;
          this.ngxService.stop();
          this.disableOrderNowBtn = false;
          this.errorDisplayDialogPatym(
            AppConstants.paytmErrorMsg,
            AppConstants.paytmErrorMsg1
          );
        }
        this.paymentResponse = null!;
      }

      if (localStorage.getItem("razorpay_payment_id")!=undefined && localStorage.getItem("razorpay_payment_id")!=null){

        let payment_id = localStorage.getItem("razorpay_payment_id")
        let order_id = localStorage.getItem("razorpay_order_id")
        let signature = localStorage.getItem("razorpay_signature")
        let payment_id_main = localStorage.getItem("paymentId")
        let url = AppConstants.paymentService + AppConstants.saveRazorPaySuccessInfo + "?payment_id=" +
        payment_id_main + "&razorpay_payment_id=" + payment_id + "&razorpay_order_id=" +
        order_id + "&razorpay_signature=" + signature;

        if(localStorage.getItem("paymentId")!=undefined && localStorage.getItem("paymentId")!=null){
          localStorage.removeItem("paymentId")
          this.orderDetailsService
            .getAllStates(url,)
            .subscribe({
              next: (res) => {
                this.ngxService.stop();
                this.alreadyPaymentFailed = false;
                this.router.navigateByUrl(res["redirect"]);
                localStorage.removeItem("razorpay_payment_id")
                localStorage.removeItem("razorpay_order_id")
                localStorage.removeItem("razorpay_signature")
                
              },
              error: (error) => {
                this.alreadyPaymentFailed = true;
                this.ngxService.stop();
              },
            });
        }
        
      }
    }, 500);
    this.goldOrSilverUser();
    this.goldMember = localStorage.getItem("membership") != undefined && localStorage.getItem("membership") != null ?
      localStorage.getItem("membership") == 'Gold Membership' ? true : false : false;

    this.calculatorForm = new FormGroup({
      product: new FormControl(this.product, Validators.required),
      brand: new FormControl(this.brand, null),
      model: new FormControl(this.model, null),
      acTypes: new FormControl(this.acType, null),
      refrigeratorTypes: new FormControl(this.refrigeratorTypes, null),
      washingMachineTypes: new FormControl(this.washingMachineTypes, null),
      repair: new FormControl(this.repair, null),
      repairDate: new FormControl(new Date()),
      addressId: new FormControl(null),
      address1: new FormControl(null, Validators.required),
      address2: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      pincode: new FormControl(null, Validators.required),
      phonePurchaseDate: new FormControl(new Date()),
      promocode: new FormControl(null),
      PaymentMode: new FormControl(null),
      temperedGlass: new FormControl(null),
      PaymentOnlineMode: new FormControl(null)
    });
    this.calculatorForm.controls["PaymentMode"].setValue("Razor");
    this.calculatorForm.controls["PaymentOnlineMode"].setValue("Paytm");
    this.paymentOnlineModeType = this.calculatorForm.controls["PaymentMode"].value
    this.stringToken = localStorage.getItem("access_token");
    if (this.stringToken != undefined) {
      this.getUserDetails(this.userID);
    }
    if (this.user_data != undefined && this.stringToken != undefined) {
      if (
        this.user_data?.addresses.length > 0 &&
        !this.calculatorForm.controls["address1"].dirty &&
        !this.calculatorForm.controls["address2"].dirty &&
        !this.calculatorForm.controls["pincode"].dirty &&
        !this.calculatorForm.controls["state"].dirty &&
        !this.calculatorForm.controls["city"].dirty
      ) {
        this.setUserAddressIfAvailable();
      }
    }
    this.userService.listen().subscribe((m: any) => {
      this.stringToken = localStorage.getItem("access_token");
      this.userID = localStorage.getItem("userId");
      if (this.stringToken != undefined) {
        this.getUserDetails(this.userID);
      }
    });

    this.getProductCategoryDataListUrl =
      AppConstants.orderService + AppConstants.getAllProducts;
    this.callGetProductCategoryDataListApi();
    this.allStatesApi();
    if (this.planName == "") {
      this.calculatorForm.controls["repair"].setValidators(Validators.required);
    }

    if (this.temperedGlass != "") {
      if (localStorage.getItem("access_token") == undefined) {
        this.disableOrderNowBtn = false;
        this.openDialogTemperedGlass();
        return;
      } else if (localStorage.getItem("access_token") != undefined) {
        this.stringToken = localStorage.getItem("access_token");
      }
      this.getTemperdGlassRepairCharge();
    }

    if (this.repair != null) {
      if (localStorage.getItem("access_token") == undefined) {
        this.disableOrderNowBtn = false;
        this.openDialogSummary();
        return;
      } else if (localStorage.getItem("access_token") != undefined) {
        this.stringToken = localStorage.getItem("access_token");
      }
      this.getUserDetailsSummary(localStorage.getItem("userId"));
    }
  }

  getTemperdGlassRepairCharge() {
    let url =
      AppConstants.orderService +
      AppConstants.getRepairChargesByIssueName +
      "?issueName=Temperd Glass";
    this.newHomeService.getRepairChargeDataListApi(url).subscribe({
      next: (res) => {
        this.temperdGlassModel = res;
        this.calculatorForm.controls["temperedGlass"].setValue(
          this.temperdGlassModel.issueDto?.issueDescription
        );
      },
      error: (error) => {
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
      },
    });
  }

  getDeviceContractsByNameAndAmount() {
    let plan = "";
    if (this.planName == "shield_plan") {
      plan = "Shield Plan";
    } else if (this.planName == "extended_Warranty") {
      plan = "RC Extended Warranty";
    } else if (this.planName == "assistance_Plan") {
      plan = "Assistance Plan";
    } else if (this.planName == "all_in_one") {
      plan = "ALL IN ONE"
    }

    this.ngxService.start();
    let getDeviceContractsByNameAndAmountUrl =
      AppConstants.orderService +
      AppConstants.getDeviceContractsByNameAndAmount +
      "?name=" +
      plan +
      "&amount=" +
      this.totalAmount;
    this.orderDetailsService
      .getDeviceContractsByNameAndAmount(getDeviceContractsByNameAndAmountUrl)
      .subscribe({
        next: (res) => {
          this.deviceContractModel = res;
          this.ngxService.stop();
          this.makeOrder();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });
  }

  getDeviceContractsByNameAndAmountForValidation() {
    let plan = "";
    if (this.planName == "shield_plan") {
      plan = "Shield Plan";
    } else if (this.planName == "extended_Warranty") {
      plan = "RC Extended Warranty";
    } else if (this.planName == "assistance_Plan") {
      plan = "Assistance Plan";
    } else if (this.planName == "all_in_one") {
      plan = "ALL IN ONE"
    }

    this.ngxService.start();
    let getDeviceContractsByNameAndAmountUrl =
      AppConstants.orderService +
      AppConstants.getDeviceContractsByNameAndAmount +
      "?name=" +
      plan +
      "&amount=" +
      this.totalAmount;
    this.orderDetailsService
      .getDeviceContractsByNameAndAmount(getDeviceContractsByNameAndAmountUrl)
      .subscribe({
        next: (res) => {
          this.deviceContractModel = res;
          this.ngxService.stop();
          this.validatePromoCode();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });
  }

  getDeviceContractsSelectModel() {
    let plan = "";
    if (this.planName == "shield_plan") {
      plan = "Shield Plan";
    } else if (this.planName == "extended_Warranty") {
      plan = "RC Extended Warranty";
    } else if (this.planName == "assistance_Plan") {
      plan = "Assistance Plan";
    } else if (this.planName == "all_in_one") {
      plan = "ALL IN ONE"
    }

    this.ngxService.start();
    let getDeviceContractsByNameAndAmountUrl =
      AppConstants.orderService +
      AppConstants.getDeviceContractsByNameAndAmount +
      "?name=" +
      plan +
      "&amount=" +
      this.totalAmount;
    this.orderDetailsService
      .getDeviceContractsByNameAndAmount(getDeviceContractsByNameAndAmountUrl)
      .subscribe({
        next: (res) => {
          this.deviceContractModel = res;
          this.ngxService.stop();
          this.calculatePlanGst();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });
  }

  calculatePlanGst() {
    if (this.planName != "" && this.deviceContractModel == undefined) {
      this.disableOrderNowBtn = false;
      this.getDeviceContractsSelectModel();
      return;
    }
    if (this.planName != "" && this.plan.modelContractId == undefined) {
      this.saveModelContractPlanCalculation();
      return;
    }
    this.getOrderDetailsAfterPromoCode();
  }

  getUserDetails(userID) {
    this.ngxService.start();
    this.userService.getUserDetailById(userID).subscribe({
      next: (data: any) => {
        this.ngxService.stop();
        this.user_data = data;
        this.user_data1 = data;

        this.stringToken = localStorage.getItem("access_token");
        if (this.user_data != undefined && this.stringToken != undefined) {
          if (
            this.user_data?.addresses.length > 0 &&
            !this.calculatorForm.controls["address1"].dirty &&
            !this.calculatorForm.controls["address2"].dirty &&
            !this.calculatorForm.controls["pincode"].dirty &&
            !this.calculatorForm.controls["state"].dirty &&
            !this.calculatorForm.controls["city"].dirty
          ) {
            this.setUserAddressIfAvailable();
          }
        }
      },
      error: (error: any) => {
        this.ngxService.stop();
      },
    });
  }

  getUserDetailsSummary(userID) {
    this.ngxService.start();
    this.userService.getUserDetailById(userID).subscribe({
      next: (data: any) => {
        this.ngxService.stop();
        this.user_data = data;
        this.user_data1 = data;

        this.stringToken = localStorage.getItem("access_token");
        if (this.user_data != undefined && this.stringToken != undefined) {
          if (
            this.user_data?.addresses.length > 0 &&
            !this.calculatorForm.controls["address1"].dirty &&
            !this.calculatorForm.controls["address2"].dirty &&
            !this.calculatorForm.controls["pincode"].dirty &&
            !this.calculatorForm.controls["state"].dirty &&
            !this.calculatorForm.controls["city"].dirty
          ) {
            this.setUserAddressIfAvailable();
          }
        }
        this.getOrderDetailsAfterPromoCode();
      },
      error: (error: any) => {
        this.ngxService.stop();
      },
    });
  }

  goldOrSilverUser() {
    this.ngxService.start();
    this.userService.goldOrSilverUser().subscribe({
      next: (res) => {
        this.ngxService.stop();
        if (res == true) {
          this.goldMember = true;
          localStorage.setItem("membership", "Gold Membership")
        } else {
          this.goldMember = false;
          localStorage.setItem("membership", "Silver Membership")
        }
      },
      error: (error) => {
        this.ngxService.stop();
      },
    });
  }

  setUserAddressIfAvailable() {
    this.user_data?.addresses!.forEach((e) => {
      if (e.primaryAddress! == true) {
        this.calculatorForm.controls["addressId"].setValue(e.addressId);
        this.calculatorForm.controls["address1"].setValue(e.addressLine1);
        this.calculatorForm.controls["address2"].setValue(e.addressLine2);
        this.calculatorForm.controls["pincode"].setValue(e.pincode);
        this.calculatorForm.controls["state"].setValue(e.state);
        if (this.calculatorForm.controls["state"].value) {
          this.allCitiesApi(this.calculatorForm.controls["state"].value);
        }
        this.calculatorForm.controls["city"].setValue(e.city);
      }
    });
  }

  callGetProductCategoryDataListApi() {
    this.newHomeService
      .getProductCategoryDataListApi(this.getProductCategoryDataListUrl)
      .subscribe({
        next: (res) => {
          this.productCategory = res;

          if (this.product == null) {
            this.calculatorForm.controls["product"].setValue(
              res[0].productCategoryDescription
            );
            this.changeProduct(res[0].productCategoryDescription);
          }
        },
        error: (error) => {
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });
  }

  selectProblems() {
    if (localStorage.getItem("access_token") == undefined) {
      this.disableOrderNowBtn = false;
      this.openDialogSummary();
      return;
    } else if (localStorage.getItem("access_token") != undefined) {
      this.stringToken = localStorage.getItem("access_token");
    }

    this.disablePromocodeFieldAndBtn = false;
    this.PromoCodeOffAmount = 0;
    this.promoCodeTotalAmount = 0;
    this.calculatorForm.controls["promocode"].setValue(null);
    if (this.planName == "") {
      this.totalAmount = 0;
    }
    this.selectedProblems = this.calculatorForm.controls["repair"].value;

    if (this.temperedGlass != "") {
      if (this.selectedProblems != null) {
        this.selectedProblems.push(this.temperdGlassModel);
      }
    }

    if (this.selectedProblems != null) {
      this.totalAmount = 0;
      this.selectedProblems.forEach((e: any) => {
        if (e == null) {
          return;
        }
        this.totalAmount = this.totalAmount + e.repairAmount;
      });
    }

    this.getOrderDetailsAfterPromoCode();
  }

  changeProduct(selectedProduct: any) {

    this.product = selectedProduct
    this.disablePromocodeFieldAndBtn = false;
    this.PromoCodeOffAmount = 0;
    this.promoCodeTotalAmount = 0;
    this.calculatorForm.controls["promocode"].setValue(null);
    let getBrandDataListUrl =
      AppConstants.orderService +
      AppConstants.getAllBrandsClientByCategory +
      "?category=" +
      selectedProduct;
    this.newHomeService.getBrandDataListApi(getBrandDataListUrl).subscribe({
      next: (res) => {
        if (this.calculatorForm.controls["product"].getRawValue() == "AIR_CONDITIONER" || this.calculatorForm.controls["product"].getRawValue() == "REFRIGERATOR" || this.calculatorForm.controls["product"].getRawValue() == "WASHING_MACHINE") {
          this.LoadALlProductTypes();
          this.calculatorForm.controls["brand"].setValidators(null);
          this.calculatorForm.controls["model"].setValidators(null);
          this.calculatorForm.controls["repair"].setValidators(null);
          this.repair =
            this.router.getCurrentNavigation()?.extras?.state?.["repair"];
        }
        if (this.calculatorForm.controls["product"].getRawValue() == "MOBILE") {
          this.calculatorForm.controls["model"].setValidators(null);
          this.calculatorForm.controls["repair"].setValidators(null);
        }
        if (localStorage.getItem("access_token") != undefined) {
          if (util.token() != undefined && util.token().includes("APPLE")) {
            const result = res.filter((obj) => {
              return obj.brandName === "Apple";
            });

            this.brandList = result;
          } else {
            this.brandList = res;
          }
        } else {
          this.brandList = res;
        }
      },
      error: (error) => {
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
      },
    });

    this.calculatorForm.controls["brand"].reset();
    this.calculatorForm.controls["model"].reset();
    this.calculatorForm.controls["repair"].reset();
    if (this.planName == "") {
      this.totalAmount = 0;
    }
    this.brandList = [];
    this.deviceList = [];
    this.repairChargeList = [];
    this.selectedProblems = [];
  }

  LoadALlProductTypes() {
    let getProductTypesUrl;
    if (
      this.calculatorForm.controls["product"].getRawValue() == "AIR_CONDITIONER"
    ) {
      getProductTypesUrl = AppConstants.orderService + AppConstants.getACtypes;
    } else if (
      this.calculatorForm.controls["product"].getRawValue() == "REFRIGERATOR"
    ) {
      getProductTypesUrl =
        AppConstants.orderService + AppConstants.getRefrigeratorTypes;
    }
    else if (
      this.calculatorForm.controls["product"].getRawValue() == "WASHING_MACHINE"
    ) {
      getProductTypesUrl =
        AppConstants.orderService + AppConstants.getWashingMachineTypes;
    }
    this.newHomeService.getProductsTypesApi(getProductTypesUrl).subscribe({
      next: (res) => {
        if (
          this.calculatorForm.controls["product"].getRawValue() ==
          "AIR_CONDITIONER"
        ) {
          this.acTypesList = res;
        } else if (
          this.calculatorForm.controls["product"].getRawValue() ==
          "REFRIGERATOR"
        ) {
          this.refrigeratorTypesList = res;
        }
        else if (
          this.calculatorForm.controls["product"].getRawValue() ==
          "WASHING_MACHINE"
        ) {
          this.washingMachineTypesList = res;
        }
      },
      error: (error) => {
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
      },
    });
  }

  changeBrand(brand: BrandModel) {
    this.disablePromocodeFieldAndBtn = false;
    this.PromoCodeOffAmount = 0;
    this.promoCodeTotalAmount = 0;
    this.calculatorForm.controls["promocode"].setValue(null);
    let getModelDataListUrl =
      AppConstants.orderService +
      AppConstants.getModels +
      "?brandId=" +
      brand.brandId;
    this.newHomeService.getModelDataListApi(getModelDataListUrl).subscribe({
      next: (res) => {
        this.deviceList = res;
      },
      error: (error) => {
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
      },
    });

    this.calculatorForm.controls["model"].reset();
    this.calculatorForm.controls["repair"].reset();
    if (this.planName == "") {
      this.totalAmount = 0;
    }
    this.deviceList = [];
    this.repairChargeList = [];
    this.selectedProblems = [];
  }

  changeModel(model: DeviceModel) {
    this.disablePromocodeFieldAndBtn = false;
    this.PromoCodeOffAmount = 0;
    this.promoCodeTotalAmount = 0;
    this.calculatorForm.controls["promocode"].setValue(null);
    let getRepairChargeDataListUrl =
      AppConstants.orderService +
      AppConstants.getRepairChargesFromMobiMech +
      "?modelId=" +
      model.modelId;
    this.newHomeService
      .getRepairChargeDataListApi(getRepairChargeDataListUrl)
      .subscribe({
        next: (res) => {
          this.repairChargeList = res;
          this.repairChargeList = this.repairChargeList.filter(
            (e: any) => e.repairAmount > 0
          );
        },
        error: (error) => {
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });

    this.calculatorForm.controls["repair"].reset();
    if (this.planName == "") {
      this.totalAmount = 0;
    }

    if (this.planName != "") {
      if (localStorage.getItem("access_token") == undefined) {
        this.disableOrderNowBtn = false;
        this.openDialogSummary();
        return;
      } else if (localStorage.getItem("access_token") != undefined) {
        this.stringToken = localStorage.getItem("access_token");
        this.goldMember = localStorage.getItem("membership") != undefined && localStorage.getItem("membership") != null ?
          localStorage.getItem("membership") == 'Gold Membership' ? true : false : false;
      }
      this.calculatePlanGst();
    }
    this.repairChargeList = [];
    this.selectedProblems = [];
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginSendOTPDialogDialog, {
      width: "350px",
      height: "590px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.stringToken = localStorage.getItem("access_token");
      this.goldMember = localStorage.getItem("membership") != undefined && localStorage.getItem("membership") != null ?
        localStorage.getItem("membership") == 'Gold Membership' ? true : false : false;
      this.userID = result[0];
      this.getUserDetails(result[0]);
      this.userService.getusernameonlogin(result);

      if (this.user_data != undefined) {
        if (
          this.user_data?.addresses.length > 0 &&
          !this.calculatorForm.controls["address1"].dirty &&
          !this.calculatorForm.controls["address2"].dirty &&
          !this.calculatorForm.controls["pincode"].dirty &&
          !this.calculatorForm.controls["state"].dirty &&
          !this.calculatorForm.controls["city"].dirty
        ) {
          this.setUserAddressIfAvailable();
        }
      }
      if (result != undefined) {
        this.makeOrder();
      }
    });
  }

  openDialogTemperedGlass(): void {
    const dialogRef = this.dialog.open(LoginSendOTPDialogDialog, {
      width: "350px",
      height: "590px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.stringToken = localStorage.getItem("access_token");
      this.goldMember = localStorage.getItem("membership") != undefined && localStorage.getItem("membership") != null ?
        localStorage.getItem("membership") == 'Gold Membership' ? true : false : false;
      if (this.stringToken == null) {
        this.removeTemperedGlass();
        return;
      }
      this.userID = result[0];
      this.getUserDetails(result[0]);
      this.userService.getusernameonlogin(result);

      if (this.user_data != undefined) {
        if (
          this.user_data?.addresses.length > 0 &&
          !this.calculatorForm.controls["address1"].dirty &&
          !this.calculatorForm.controls["address2"].dirty &&
          !this.calculatorForm.controls["pincode"].dirty &&
          !this.calculatorForm.controls["state"].dirty &&
          !this.calculatorForm.controls["city"].dirty
        ) {
          this.setUserAddressIfAvailable();
        }
      }
      if (result != undefined) {
        this.getTemperdGlassRepairCharge();
      }
    });
  }

  openDialogSummary(): void {
    const dialogRef = this.dialog.open(LoginSendOTPDialogDialog, {
      width: "350px",
      height: "590px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.stringToken = localStorage.getItem("access_token");
      this.goldMember = localStorage.getItem("membership") != undefined && localStorage.getItem("membership") != null ?
        localStorage.getItem("membership") == 'Gold Membership' ? true : false : false;
      if (this.stringToken == null) {
        return;
      }
      this.userID = result[0];
      this.getUserDetailsSummary(result[0]);
      this.userService.getusernameonlogin(result);

      if (this.user_data != undefined) {
        if (
          this.user_data?.addresses.length > 0 &&
          !this.calculatorForm.controls["address1"].dirty &&
          !this.calculatorForm.controls["address2"].dirty &&
          !this.calculatorForm.controls["pincode"].dirty &&
          !this.calculatorForm.controls["state"].dirty &&
          !this.calculatorForm.controls["city"].dirty
        ) {
          this.setUserAddressIfAvailable();
        }
      }
    });
  }

  selectedCustomer: any;
  disableOrderNowBtn = false;

  makeOrder() {
    this.disableOrderNowBtn = true;
    if (localStorage.getItem("access_token") == undefined) {
      this.disableOrderNowBtn = false;
      this.openDialog();
      return;
    } else if (localStorage.getItem("access_token") != undefined) {
      this.stringToken = localStorage.getItem("access_token");
    }

    if (
      this.calculatorForm.status == "INVALID" &&
      localStorage.getItem("access_token") != undefined
    ) {
      this.snackBar.open("All fields are required!!", "", {
        duration: 5000,
        panelClass: ["blue-snackbar"],
      });
      this.disableOrderNowBtn = false;
      return;
    }
    if (this.alreadyPaymentFailed) {
      this.disableOrderNowBtn = false;
      this.redirectingToPaytm();
      return;
    }
    if (this.planName != "" && this.deviceContractModel == undefined) {
      this.disableOrderNowBtn = false;
      this.getDeviceContractsByNameAndAmount();
      return;
    }
    if (this.isErrorOnAnyStep) {
      this.totalAmount = this.totalAmountBeforePromoCode;
      this.disableOrderNowBtn = false;
      if (this.afterErrorCallFunction == AppConstants.updateAddressError) {
        this.isErrorOnAnyStep = false;
        this.updateAddress();
        return;
      } else if (
        this.afterErrorCallFunction == AppConstants.prepairingOrderError
      ) {
        this.isErrorOnAnyStep = false;
        this.preparingOrder();
        return;
      } else if (this.afterErrorCallFunction == AppConstants.getUserByIdError) {
        this.isErrorOnAnyStep = false;
        this.getUserById();
        return;
      } else if (
        this.afterErrorCallFunction == AppConstants.createMMOrderError
      ) {
        this.isErrorOnAnyStep = false;
        this.createMMOrder();
        return;
      } else if (
        this.afterErrorCallFunction == AppConstants.createPaymentError
      ) {
        this.isErrorOnAnyStep = false;
        this.createPayment();
        return;
      } else if (
        this.afterErrorCallFunction == AppConstants.redirectingToPaytmError
      ) {
        this.isErrorOnAnyStep = false;
        this.redirectingToPaytm();
        return;
      } else if(
        this.afterErrorCallFunction == AppConstants.MakeRazoryPayOrderError
      ){
        this.isErrorOnAnyStep = false;
        this.MakeRazoryPayOrder();
        return;
      }
    }
    if (
      this.user_data?.addresses.length == 0 ||
      !(
        this.user_data?.addresses[0]?.addressId ==
        this.calculatorForm.controls["addressId"].value &&
        this.user_data?.addresses[0]?.addressLine1 ==
        this.calculatorForm.controls["address1"].value &&
        this.user_data?.addresses[0]?.addressLine2 ==
        this.calculatorForm.controls["address2"].value &&
        this.user_data?.addresses[0]?.pincode ==
        this.calculatorForm.controls["pincode"].value &&
        this.user_data?.addresses[0]?.state ==
        this.calculatorForm.controls["state"].value &&
        this.user_data?.addresses[0]?.city ==
        this.calculatorForm.controls["city"].value
      )
    ) {
      this.calculatorForm.controls["addressId"].setValue(null);
      this.updateAddress();
    } else {
      this.preparingOrder();
    }
  }

  updateAddress() {
    this.userDto = this.user_data;
    this.addressOrderDto.addressId =
      this.calculatorForm.controls["addressId"].value;
    this.addressOrderDto.addressLine1 =
      this.calculatorForm.controls["address1"].value;
    this.addressOrderDto.addressLine2 =
      this.calculatorForm.controls["address2"].value;
    this.addressOrderDto.pincode =
      this.calculatorForm.controls["pincode"].value;
    this.addressOrderDto.state = this.calculatorForm.controls["state"].value;
    this.addressOrderDto.city = this.calculatorForm.controls["city"].value;
    // this.userDto.addresses?.push(this.addressOrderDto);

    this.ngxService.start();
    this.orderDetailsService
      .updateUserAddress(this.updateUserAddressUrl, this.addressOrderDto)
      .subscribe({
        next: (addressDto) => {
          this.updateAddressOrderDto = addressDto;

          this.calculatorForm.controls["addressId"].setValue(
            this.updateAddressOrderDto.addressId
          );
          this.calculatorForm.controls["address1"].setValue(
            this.updateAddressOrderDto.addressLine1
          );
          this.calculatorForm.controls["address2"].setValue(
            this.updateAddressOrderDto.addressLine2
          );
          this.calculatorForm.controls["pincode"].setValue(
            this.updateAddressOrderDto.pincode
          );
          this.calculatorForm.controls["state"].setValue(
            this.updateAddressOrderDto.state
          );
          this.calculatorForm.controls["city"].setValue(
            this.updateAddressOrderDto.city
          );
          this.disableOrderNowBtn = true;
          this.updateToPrimary(this.updateAddressOrderDto.addressId);
          this.ngxService.stop();
          this.getUserById();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
          this.isErrorOnAnyStep = true;
          this.disableOrderNowBtn = false;
          this.afterErrorCallFunction = AppConstants.updateAddressError;
        },
      });
  }

  updateToPrimary(addressId) {
    this.updateadressurl =
      AppConstants.userService +
      AppConstants.updateAddressToPrimary +
      "?userId=" +
      this.userID +
      "&addressId=" +
      addressId +
      "&primaryAddress=" +
      true;
    this.userService
      .UpdateAddress(this.updateadressurl, this.updateAddressOrderDto)
      .subscribe({
        next: (data) => { },
        error: (error) => { },
      });
  }
  getUserById() {
    this.getUserByIdUrl =
      AppConstants.userService +
      AppConstants.getUserById +
      "?id=" +
      this.userID;
    this.ngxService.start();
    this.orderDetailsService.getUserById(this.getUserByIdUrl).subscribe({
      next: (userDto) => {
        this.disableOrderNowBtn = true;
        this.fetchedUserDto = userDto;
        this.ngxService.stop();
        this.preparingOrder();
      },
      error: (error) => {
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
        this.isErrorOnAnyStep = true;
        this.disableOrderNowBtn = false;
        this.afterErrorCallFunction = AppConstants.getUserByIdError;
      },
    });
  }
  plan: DevicePlanModel = new DevicePlanModel();
  saveModelContract() {
    let planUrl =
      AppConstants.orderService + AppConstants.CREATE_MODEL_CONTRACT;
    let plan = new DevicePlanModel();
    plan.model = this.calculatorForm.controls["model"].value;
    plan.deviceContract = this.deviceContractModel;

    this.orderDetailsService.createModelContract(planUrl, plan).subscribe({
      next: (res) => {
        this.disableOrderNowBtn = true;
        this.plan = res;
        this.ngxService.stop();
        this.preparingOrder();
      },
      error: (error) => {
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
        this.isErrorOnAnyStep = true;
        this.disableOrderNowBtn = false;
        this.afterErrorCallFunction = AppConstants.prepairingOrderError;
      },
    });
  }

  saveModelContractPlanCalculation() {
    let planUrl =
      AppConstants.orderService + AppConstants.CREATE_MODEL_CONTRACT;
    let plan = new DevicePlanModel();
    plan.model = this.calculatorForm.controls["model"].value;
    plan.deviceContract = this.deviceContractModel;

    this.orderDetailsService.createModelContract(planUrl, plan).subscribe({
      next: (res) => {
        this.plan = res;
        this.ngxService.stop();
        this.calculatePlanGst();
      },
      error: (error) => {
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
        this.isErrorOnAnyStep = true;
        this.disableOrderNowBtn = false;
        this.afterErrorCallFunction = AppConstants.prepairingOrderError;
      },
    });
  }

  saveModelContractForValidation() {
    let planUrl =
      AppConstants.orderService + AppConstants.CREATE_MODEL_CONTRACT;
    let plan = new DevicePlanModel();
    plan.model = this.calculatorForm.controls["model"].value;
    plan.deviceContract = this.deviceContractModel;

    this.orderDetailsService.createModelContract(planUrl, plan).subscribe({
      next: (res) => {
        this.plan = res;
        this.ngxService.stop();
        this.validatePromoCode();
      },
      error: (error) => {
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
        this.isErrorOnAnyStep = true;
        this.disableOrderNowBtn = false;
        this.afterErrorCallFunction = AppConstants.prepairingOrderError;
      },
    });
  }

  setMobiMechOrderData() {
    let repairIssues: OrderRepairIssueModel[] = [];
    let purchaseDtoList: PurchaseModel[] = [];
    if (this.planName == "") {
      this.calculatorForm.controls["repair"].value.forEach((e) => {
        if (e != null) {
          let repairIssue: OrderRepairIssueModel = new OrderRepairIssueModel();
          repairIssue.brandDto = this.calculatorForm.controls["brand"].value;
          repairIssue.repairChargeDto = e;
          repairIssue.modelDto = this.calculatorForm.controls["model"].value;
          if (
            this.calculatorForm.controls["product"].getRawValue() == "AIR_CONDITIONER") {
            repairIssue.acTypeDto = this.calculatorForm.controls["acTypes"].value;
          }
          if (this.calculatorForm.controls["product"].getRawValue() == "REFRIGERATOR") {
            repairIssue.refrigeratorTypeDto = this.calculatorForm.controls["refrigeratorTypes"].value;
          }
          if (this.calculatorForm.controls["product"].getRawValue() == "WASHING_MACHINE") {
            repairIssue.washingMachineTypeDto = this.calculatorForm.controls["washingMachineTypes"].value;
          }

          repairIssue.repairedAmount = e.repairAmount;
          repairIssue.createdDate = new Date();
          repairIssue.updatedDate = new Date();
          repairIssue.repairRequestDate = new Date(
            this.calculatorForm.controls["repairDate"].value
          );
          repairIssue.repairModeDto = e.repairModeDto;
          repairIssues.push(repairIssue);
        }
      });
    } else {
      let purchase = new PurchaseModel();
      purchase.brandDto = this.calculatorForm.controls["brand"].value;
      purchase.modelDto = this.calculatorForm.controls["model"].value;
      purchase.purchaseInspectionDate = new Date(
        this.calculatorForm.controls["phonePurchaseDate"].value
      );
      purchase.plans?.push(this.plan);
      purchaseDtoList.push(purchase);
    }
    if (this.planName == "") {
      this.mobiOrder.repairIssues = repairIssues;
      this.mobiOrder.deliveryDate = new Date(
        this.datePipe
          .transform(
            this.calculatorForm.controls["repairDate"].value,
            "yyyy-MM-dd"
          )!
          .toString()
      );
    } else {
      this.mobiOrder.purchaseDtoList = purchaseDtoList;
    }

    if (localStorage.getItem("access_token") != undefined) {
      this.mobiOrder.userId = this.user_data1?.userId;
      if (this.user_data1?.addresses?.length <= 0) {
        this.mobiOrder.userDto = this.fetchedUserDto;
      } else {
        this.mobiOrder.userDto = this.user_data1;
      }
      this.mobiOrder.mobileNumber = this.mobiOrder.userDto?.mobileNumber;
    }

    if (this.mobiOrder.userDto!.createdDate != undefined) {
      if (Array.isArray(this.mobiOrder.userDto?.createdDate)) {
        this.mobiOrder.userDto!.createdDate = new Date(
          this.mobiOrder.userDto!.createdDate![0] +
          "-" +
          this.mobiOrder.userDto!.createdDate![1] +
          "-" +
          this.mobiOrder.userDto!.createdDate![2]
        );
        if (this.mobiOrder.userDto?.modifiedDate != null) {
          this.mobiOrder.userDto!.modifiedDate = new Date(
            this.mobiOrder.userDto?.modifiedDate[0] +
            "-" +
            this.mobiOrder.userDto?.modifiedDate[1] +
            "-" +
            this.mobiOrder.userDto?.modifiedDate[2]
          );
        }
      } else {
        this.mobiOrder.userDto!.createdDate = new Date(
          this.datePipe
            .transform(this.mobiOrder.userDto!.createdDate, "yyyy-MM-dd")!
            .toString()
        );
        if (this.mobiOrder.userDto?.modifiedDate != null) {
          this.mobiOrder.userDto!.modifiedDate = new Date(
            this.datePipe
              .transform(this.mobiOrder.userDto?.modifiedDate, "yyyy-MM-dd")!
              .toString()
          );
        }
      }
    }
    this.mobiOrder.vendorOrder = false;

    // preparing order
    if (this.planName == "") {
      if (
        this.calculatorForm.controls["product"].getRawValue() == "AIR_CONDITIONER" ||
        this.calculatorForm.controls["product"].getRawValue() == "REFRIGERATOR" ||
        this.calculatorForm.controls["product"].getRawValue() == "WASHING_MACHINE"
      ) {
        this.mobiOrder.orderType = OrderType.SERVICE;
      } else if (
        this.calculatorForm.controls["product"].getRawValue() == "MOBILE"
      ) {
        this.mobiOrder.orderType = OrderType.REPAIR;
      }
    } else {
      this.mobiOrder.orderType = OrderType.PURCHASE;
    }
    this.mobiOrder.orderAmount = this.totalAmount;

    if (this.calculatorForm.controls["addressId"].value != null) {
      this.addressOrderDto.addressId =
        this.calculatorForm.controls["addressId"].value;
      this.addressOrderDto.addressLine1 =
        this.calculatorForm.controls["address1"].value;
      this.addressOrderDto.addressLine2 =
        this.calculatorForm.controls["address2"].value;
      this.addressOrderDto.pincode =
        this.calculatorForm.controls["pincode"].value;
      this.addressOrderDto.state = this.calculatorForm.controls["state"].value;
      this.addressOrderDto.city = this.calculatorForm.controls["city"].value;
      this.mobiOrder.addressDto = this.addressOrderDto;
      this.mobiOrder.screenProtector = this.isToggled;
    } else {
      this.mobiOrder.addressDto = this.updateAddressOrderDto;
    }
    this.mobiOrder.addressId = this.mobiOrder.addressDto?.addressId;

    this.mobiOrder.orderDepartment =
      this.calculatorForm.controls["product"].value;

    if (this.PromoCodeOffAmount > 0) {
      this.mobiOrder.promoCode =
        this.calculatorForm.controls["promocode"].value;
      this.mobiOrder.promoCodeAmtOff = this.PromoCodeOffAmount;
    } else {
      this.mobiOrder.promoCode = null!;
      this.mobiOrder.promoCodeAmtOff = 0;
    }
  }

  preparingOrder() {
    if (this.planName != "" && this.plan.modelContractId == undefined) {
      this.saveModelContract();
      return;
    }
    this.setMobiMechOrderData();

    this.getPaymentDTOUrl =
      AppConstants.orderService +
      AppConstants.calculatePaymentBasedOnAllConditions +
      "?userId=" +
      this.mobiOrder.userId;

    this.ngxService.start();
    this.orderDetailsService
      .getPaymentDTOApi(this.getPaymentDTOUrl, this.mobiOrder)
      .subscribe({
        next: (res) => {
          this.paymentDTO = res;
          this.mobiOrder.paymentDto = this.paymentDTO;
          this.disableOrderNowBtn = true;
          this.ngxService.stop();
          this.createMMOrder();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
          this.isErrorOnAnyStep = true;
          this.disableOrderNowBtn = false;
          this.afterErrorCallFunction = AppConstants.prepairingOrderError;
        },
      });
  }
  codPaymentOrderUrl: string;
  createMMOrder() {
    this.ngxService.start();

    this.orderDetailsService
      .createMMOrderApi(this.createMMOrderUrl, this.mobiOrder)
      .subscribe({
        next: (res) => {
          this.disableOrderNowBtn = true;
          this.createdMobiOrder = res;
          this.ngxService.stop();
          if (this.calculatorForm.controls["PaymentMode"].value == "COD") {
            this.makeCODPaymentAgainstOrderDataApi();
          } else {
            this.createPayment();
          }
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
          this.isErrorOnAnyStep = true;
          this.disableOrderNowBtn = false;
          this.afterErrorCallFunction = AppConstants.createMMOrderError;
        },
      });
  }
  makeCODPaymentAgainstOrderDataApi() {
    this.ngxService.start();
    this.codPaymentOrderUrl =
      AppConstants.paymentService +
      AppConstants.createPayment +
      "?orderId=" +
      this.createdMobiOrder.orderId +
      "&paymentModeType=COD";

    this.orderDetailsService.createPayment(this.codPaymentOrderUrl).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        const paymentdetails = {
          id: this.createdMobiOrder.orderId,
          status: "TXN_SUCCESS",
        };
        this.router.navigate(
          [AppRouteConstants.getRoute(AppRouteConstants.paymentStatusRoute)],
          { state: paymentdetails }
        );
      },
      error: (error: any) => {
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  createPayment() {
    this.ngxService.start();
    this.createPaymentUrl =
      AppConstants.paymentService +
      AppConstants.createPayment +
      "?orderId=" +
      this.createdMobiOrder.orderId; 
      if(this.calculatorForm.controls["PaymentMode"].value == "Razor"){
        this.createPaymentUrl = this.createPaymentUrl + "&OnlineMode="+ this.calculatorForm.controls["PaymentMode"].value;
      }
      
    this.orderDetailsService.createPayment(this.createPaymentUrl).subscribe({
      next: (res) => {
        this.disableOrderNowBtn = true;
        this.createPaymentDto = res;
        localStorage.setItem("paymentId",res.paymentId)
        this.ngxService.stop();
        if(this.calculatorForm.controls["PaymentMode"].value == "Razor"){
          this.MakeRazoryPayOrder()
        }else {
          this.redirectingToPaytm();
        }
      },
      error: (error) => {
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if (error?.error?.message == undefined) {
            this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          } else {
            this.errorDisplayDialog(error.error.message);
          }
        }
        this.isErrorOnAnyStep = true;
        this.disableOrderNowBtn = false;
        this.afterErrorCallFunction = AppConstants.createPaymentError;
      },
    });
  }

  MakeRazoryPayOrder(){
    this.ngxService.start();
    let url =
      AppConstants.paymentService +
      AppConstants.getRazorPayPaymentData +
      "?payment_id=" +
      this.createPaymentDto.paymentId;
    this.orderDetailsService
      .getPaytmPaymentData(url)
      .subscribe({
        next: (res) => {
          this.disableOrderNowBtn = true;
          this.razorPaymentData = res;
          // console.log(res);
          this.ngxService.stop();
          this.initiateClientRazorPayModule();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
          this.isErrorOnAnyStep = true;
          this.disableOrderNowBtn = false;
          this.afterErrorCallFunction = AppConstants.MakeRazoryPayOrderError;
        },
      });
  }

  razorPayDataResponse:any;
  initiateClientRazorPayModule() {
    if (this.alreadyPaymentFailed) {
      this.alreadyPaymentFailed = false;
    }
    var s = this.document.createElement("script");
    s.type = "application/javascript";
    s.src =
      environment.razorPayUrl
    s.crossOrigin = "anonymous";
    s.id = "razorRayScript";
    const __this = this;
    s.onload = () => {
      var config = {
        "key": environment.razorPayKeyId, // Enter the Key ID generated from the Dashboard
        "amount": parseInt(this.razorPaymentData.amount) , // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": "INR",
        "name": "Repairing Company",
        "description": "Repairing Company",
        "image": "https://example.com/your_logo",
        "order_id": this.razorPaymentData.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){          
          // console.log(response.razorpay_payment_id);
          localStorage.setItem("razorpay_payment_id",response.razorpay_payment_id)
          localStorage.setItem("razorpay_order_id",response.razorpay_order_id)
          localStorage.setItem("razorpay_signature",response.razorpay_signature)
          // console.log(response.razorpay_order_id);
          // console.log(response.razorpay_signature)
        },
        "prefill": {
            "name": "",
            "email": "",
            "contact": ""
        },
        "notes": {
            "address": "Repairing Company"
        },
        "theme": {
            "color": "#3399cc"
        }
      };
      let rzp = new Razorpay(config);

      rzp.on('payment.failed', function (response){    
        // Todo - store this information in the server
        console.log(response.error.code);    
        console.log(response.error.description);    
        console.log(response.error.source);    
        console.log(response.error.step);    
        console.log(response.error.reason);    
        console.log(response.error.metadata.order_id);    
        console.log(response.error.metadata.payment_id);
      });
      
      rzp.open();

    };
    this.elementRef.nativeElement.appendChild(s);
  }

  paytmPaymentData: PaytmPaymentModel;
  razorPaymentData: RazorPaymentModel;

  redirectingToPaytm() {
    // get paytmPaymentData for paytm.
    this.ngxService.start();
    this.getPaytmPaymentDataUrl =
      AppConstants.paymentService +
      AppConstants.getPaytmPaymentData +
      "?payment_id=" +
      this.createPaymentDto.paymentId +
      "&order_id=" +
      this.createPaymentDto.orderId +
      "&channel_Id=WEB";
    this.orderDetailsService
      .getPaytmPaymentData(this.getPaytmPaymentDataUrl)
      .subscribe({
        next: (res) => {
          this.disableOrderNowBtn = true;
          this.paytmPaymentData = res;
          this.initiateClientModule();
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
          this.isErrorOnAnyStep = true;
          this.disableOrderNowBtn = false;
          this.afterErrorCallFunction = AppConstants.redirectingToPaytmError;
        },
      });
  }

  setAllUrls() {
    this.getProductCategoryDataListUrl =
      AppConstants.orderService + AppConstants.getAllProducts;
    this.createMMOrderUrl =
      AppConstants.orderService + AppConstants.createMMOrder;
    this.updateUserAddressUrl =
      AppConstants.userService + AppConstants.addAddressByUserId;
    this.getPaytmPaymentDataUrl =
      AppConstants.paymentService + AppConstants.getPaytmPaymentData;
    this.createPaymentUrl =
      AppConstants.paymentService + AppConstants.createPayment;
    this.paymentResponseUrl =
      AppConstants.paymentService + AppConstants.paymentResponse;
  }
  navigate() {
    this.router.navigate(["/"]);
  }

  totalAmountBeforePromoCode: number = 0;
  PromoCodeOffAmount: number = 0;
  disablePromocodeFieldAndBtn = false;
  promoCodeTotalAmount: number = 0;

  validatePromoCode() {
    if (localStorage.getItem("access_token") == undefined) {
      this.openDialog();
      return;
    } else if (localStorage.getItem("access_token") != undefined) {
      this.stringToken = localStorage.getItem("access_token");
    }
    if (
      this.calculatorForm.status == "INVALID" &&
      localStorage.getItem("access_token") != undefined
    ) {
      this.snackBar.open("All fields are required!!", "", {
        duration: 5000,
        panelClass: ["blue-snackbar"],
      });
      return;
    }
    if (
      this.calculatorForm.controls["promocode"].value == null ||
      this.calculatorForm.controls["promocode"].value == undefined
    ) {
      this.snackBar.open("Enter Promocode Value!!", "", {
        duration: 5000,
        panelClass: ["blue-snackbar"],
      });
      return;
    }
    if (this.planName != "" && this.deviceContractModel == undefined) {
      this.disableOrderNowBtn = false;
      this.getDeviceContractsByNameAndAmountForValidation();
      return;
    }
    if (this.planName != "" && this.plan.modelContractId == undefined) {
      this.saveModelContractForValidation();
      return;
    }
    this.setMobiMechOrderData();

    let validatePromoCodeUrl =
      AppConstants.orderService +
      AppConstants.validatePromoCode +
      "/" +
      this.calculatorForm.controls["promocode"].value;

    this.ngxService.start();
    this.totalAmountBeforePromoCode = this.totalAmount;
    this.orderDetailsService
      .validatePromocode(validatePromoCodeUrl, this.mobiOrder)
      .subscribe({
        next: (res) => {
          this.PromoCodeOffAmount = res;
          this.promoCodeTotalAmount =
            this.totalAmount - this.PromoCodeOffAmount;
          this.disablePromocodeFieldAndBtn = true;
          this.snackBar.open("Promo code Applied.", "", {
            duration: 5000,
            panelClass: ["green-snackbar"],
          });
          this.ngxService.stop();
          this.getOrderDetailsAfterPromoCode();
        },
        error: (error) => {
          this.PromoCodeOffAmount = 0;
          this.promoCodeTotalAmount = 0;
          this.disablePromocodeFieldAndBtn = false;
          this.ngxService.stop();
          this.errorDisplayDialog(AppConstants.couponErrorMsg);
        },
      });
  }

  getOrderDetailsAfterPromoCode() {
    this.setMobiMechOrderData();
    this.getPaymentDTOUrl =
      AppConstants.orderService +
      AppConstants.calculatePaymentBasedOnAllConditions +
      "?userId=" +
      this.mobiOrder.userId;
    this.ngxService.start();
    this.orderDetailsService
      .getPaymentDTOApi(this.getPaymentDTOUrl, this.mobiOrder)
      .subscribe({
        next: (res) => {
          this.paymentDTO1 = res;
          console.log(res)
          this.ngxService.stop();
          if (
            (this.selectedProblems != null &&
              this.selectedProblems.length > 0) || this.calculatorForm.controls["repair"].value != null
          ) {
            if ((this.selectedProblems != null &&
              this.selectedProblems.length > 0)) {
              this.totalAmount = 0;
              this.selectedProblems.forEach((e: any) => {
                if (e == null) {
                  return;
                }
                this.totalAmount = this.totalAmount + e.repairAmount;
              });
            } else {
              this.totalAmount = 0;
              this.calculatorForm.controls["repair"].value.forEach((e: any) => {
                if (e == null) {
                  return;
                }
                this.totalAmount = this.totalAmount + e.repairAmount;
              });
            }
          } else {
            this.totalAmount = this.paymentDTO1.paymentTotalAmount!;
          }
          if (this.paymentDTO1 != null && this.paymentDTO1.goldMember) {
            this.goldMembershipDiscount = this.totalAmount;
          }
        },
        error: (error) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            if (error?.error?.message == undefined) {
              this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            } else {
              this.errorDisplayDialog(error.error.message);
            }
          }
        },
      });
  }

  removePromoCode() {
    this.PromoCodeOffAmount = 0;
    this.promoCodeTotalAmount = 0;
    this.disablePromocodeFieldAndBtn = false;
    this.calculatorForm.controls["promocode"].setValue(null);
    this.getOrderDetailsAfterPromoCode();
  }

  removeTemperedGlass() {
    this.temperedGlass = "";
    this.calculatorForm.controls["temperedGlass"].setValue(null);
    this.PromoCodeOffAmount = 0;
    this.promoCodeTotalAmount = 0;
    this.disablePromocodeFieldAndBtn = false;
    this.calculatorForm.controls["promocode"].setValue(null);
    this.selectedProblems = this.selectedProblems.filter(
      (e) => e.repairChargeId != this.temperdGlassModel.repairChargeId
    );
    this.calculatorForm.controls["repair"].setValue(this.selectedProblems);
    this.temperdGlassModel = null!;
    if (this.selectedProblems != null) {
      this.totalAmount = 0;
      this.selectedProblems.forEach((e: any) => {
        this.totalAmount = this.totalAmount + e.repairAmount;
      });
    }
    this.getOrderDetailsAfterPromoCode();
  }
  isToggled: string = 'no';

  toggle(){
    this.isToggled = this.isToggled === 'no' ? 'yes' : 'no';
    console.log(this.isToggled)
  }
  
}
