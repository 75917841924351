<div class="container-fluid">
    <div class="col-md-2"></div>
    <div class="col-md-9">
        <mat-card>
            <mat-card-title>
                <span>Transit({{caseData.caseStatus}})</span>
            </mat-card-title>
            <mat-card-content>
                <mat-card *ngIf="orderData">
                    <mat-card-title><span>Contact Info</span></mat-card-title>
                    <mat-card-content>
                        <table>
                            <tr>
                                <th>Contact Person: </th>
                                <td><span>{{orderData.addressDto.personName ? orderData.addressDto.personName! :
                                        '--'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Contact Number: </th>
                                <td><span>{{orderData.addressDto.mobileNumber ? orderData.addressDto.mobileNumber :
                                        '--'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Adress : </th>
                                <td>{{orderData.addressDto.addressLine1 ? orderData.addressDto.addressLine1 : '--'}}
                                </td>
                            </tr>
                            <tr>
                                <th>Pincode : </th>
                                <td>{{orderData.addressDto.pincode ? orderData.addressDto.pincode : '--'}}
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card *ngIf="orderData">
                    <mat-card-title><span>Device Info</span></mat-card-title>
                    <mat-card-content>
                        <table>
                            <tr>
                                <th>Name: </th>
                                <td><span>{{orderData.repairIssues[0].modelDto.modelName ?
                                        orderData.repairIssues[0].modelDto.modelName : '--'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Manufacture Year: </th>
                                <td><span>{{orderData.repairIssues[0].modelDto.modelYear ?
                                        orderData.repairIssues[0].modelDto.modelYear : '--'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Brand : </th>
                                <td>{{orderData.repairIssues[0].brandDto.brandName ?
                                    orderData.repairIssues[0].brandDto.brandName : '--'}}
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
                <ng-template [ngIf]=caseData && (caseData.caseStatus === OrderStatus.PICKUP_PENDING || caseData.caseStatus === OrderStatus.PICKUP_COMPLETED)>
                    <mat-card *ngIf="serviceAreaAddress">
                        <mat-card-title><span>Service Center Address</span></mat-card-title>
                        <mat-card-content>
                            <table>
                                <tr>
                                    <th>Name: </th>
                                    <td><span>{{serviceAreaAddress.serviceAreaName}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Description: </th>
                                    <td><span>{{serviceAreaAddress.serviceAreaDescription ?
                                        serviceAreaAddress!.serviceAreaDescription : '--'}}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <th>Pin code: </th>
                                    <td>{{serviceAreaAddress!.pinCode}}
                                    </td>
                                </tr>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
              <button
                  *ngIf="caseData && caseData.nextCaseStatus"
                    mat-raised-button (click)="statusChange()">{{caseData.nextCaseStatus}}</button>
                <button mat-raised-button (click)="onNoClick()">Cancel</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>
