import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserDetailsModel } from "src/app/data/user-details/user-details.model";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "src/app/services/user.service";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "get-started-dialog",
  templateUrl: "./get-started-dialog.html",
  styleUrls: ["./get-started-dialog.scss"],
})

export class GetStartedDialog implements OnInit {
  getStartedForm: FormGroup;
  userDetailsModel = new UserDetailsModel();
  firstName: any;
  lastName: any;
  email: any;
  userDetails: any;
  isMobileNumberNull:boolean = false;
  countryCode:number = 91
  constructor(
    private ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRefGetStarted: MatDialogRef<GetStartedDialog>,
    private userservice: UserService
  ) {
    this.getUserDetails();
  }
  ngOnInit(): void {
    this.getStartedForm = new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      email: new FormControl(),
      mobileNumber: new FormControl()
    });

  }
  onNoClick(): void {
    this.dialogRefGetStarted.close();
  }

  getUserDetails() {
    this.userservice.getUserDetail().subscribe({
      next: (data: any) => {
        this.userDetails = data;
        console.log(this.userDetails.mobileNumber)
        if(this.userDetails.mobileNumber == undefined){
          this.isMobileNumberNull = true;
        }
      },
      error: (error: any) => {},
    });
  }

  submit() {
    this.ngxService.start();
    this.userDetailsModel = this.userDetails;
    this.userDetailsModel.firstName = this.getStartedForm.controls["firstName"].value;
    this.userDetailsModel.lastName = this.getStartedForm.controls["lastName"].value;
    if(this.userDetails.mobileNumber == null){
      this.userDetailsModel.mobileNumber = this.countryCode + this.getStartedForm.controls["mobileNumber"].value;  
    }
    else{
      this.userDetailsModel.email = this.getStartedForm.controls["email"].value;
    }

    this.userservice.updateUserDetails(this.userDetailsModel).subscribe({
      next: (data: any) => {
           this.getUserByUserID(this.data);
          this.ngxService.stop();
       },
      error: (error: any) => {
        console.log(error);
      },
    });
  }

  getUserByUserID(UserId:any)
  {
    this.userservice.getUserDetailById(UserId).subscribe({
      next:(data)=>{
            this.dialogRefGetStarted.close([data.userId,data.firstName,data.email])
            localStorage.setItem("name",data.firstName);
            localStorage.setItem('emailId', data.email);
      },
      error:(error)=>{

      }
    })
  }
}
