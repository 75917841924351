import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit {

  @Input() valKey: string;
  @Input() dispKey: string;
  @Input() class: string;
  @Input() List:any = [];
  selectedList:any = [];
  @Input() dispList:any = [];
  @Output() listEmitter: EventEmitter<any>;
  @Output() addElementEmitter: EventEmitter<any>;
  @Output() removeElementEmitter: EventEmitter<any>;

  constructor() {
    this.listEmitter = new EventEmitter();
    this.addElementEmitter = new EventEmitter();
    this.removeElementEmitter = new EventEmitter();
  }

  ngOnInit() {

  }

  emmitList() {
    this.listEmitter.emit(this.dispList);
  }

  removeItem(event:any, item:any, element:any) {
    console.log(item, this.selectedList.find(x => x === item));
    element.hidden = true;
    event.stopPropagation();
    this.selectedList.splice(this.selectedList.indexOf(this.List.find(x => x[this.dispKey] === item[this.dispKey])), 1);
    this.dispList.splice(this.dispList.indexOf(item), 1);
    this.removeElementEmitter.emit(item);
    this.emmitList();
  }

  toggle(element, val?: string) {
    element.hidden = val ? true : !element.hidden;
  }
  selectClick(event:any, item:any, ele:any) {
    ele.hidden = true;
    console.log(event.target);
    if (this.dispList.indexOf(event.target.value) >= 0) {
      this.dispList.splice(this.dispList.indexOf(event.target.value), 1);
      this.removeElementEmitter.emit(item);
      //this.dispList.splice(this.dispList.indexOf(this.List.find(x => x[this.valKey] === event.target.value)[this.dispKey]), 1);
    } else {
      this.addElementEmitter.emit(item);
      this.dispList.push(item);
      //this.dispList.push(this.List.find(x => x[this.valKey] === event.target.value)[this.dispKey]);
    }
    this.emmitList();
  }
}
