import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  httpErrorResponse:HttpErrorResponse;
  private stringToken:any;
  constructor( private injector:Injector, private authService:AuthService) {}

  intercept(request, next) {
    let authservice:any = this.injector.get(AuthService)
    let clonedRequest:any;
    this.stringToken = authservice.getJWTToken();
 
    if(localStorage.getItem('access_token') != undefined){
      clonedRequest = request.clone({
      setHeaders:{
      'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }});   
    }else {
      clonedRequest = request.clone({
      headers:request.headers.
        append("Access-Control-Allow-Origin", "*")
        .append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS")
        .append("Access-Control-Allow-Headers", "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers")
        .append("Access-Control-Max-Age", "3600")
      });
  }
 
  return next.handle(clonedRequest).pipe((res)=>{
      return res;
      },catchError(error => {
        if(error instanceof HttpErrorResponse && this.httpErrorResponse.error !== undefined){
        if(error.status === 401){
          this.authService.logOut();
          clonedRequest = request.clone({
            headers:request.headers.
              append("Access-Control-Allow-Origin", "*")
              .append("Access-Control-Allow-Methods", "GET, POST, PUT, DELETE, OPTIONS")
              .append("Access-Control-Allow-Headers", "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers")                .append("Access-Control-Max-Age", "3600")
            });
          return next.handle(clonedRequest);
        }
      }})
    )
  }

  addToken(request:HttpRequest<any>,token:string){
    let authservice:any = this.injector.get(AuthService)
    return request.clone({
      setHeaders:{
        'Authorization': `Bearer ${JSON.parse(authservice.getJWTToken())?.access_token}`
      }
    });
  }

}

