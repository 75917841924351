<h1 mat-dialog-title>{{'vendor_details' | translate}}</h1>
<div mat-dialog-content>
    <h2>{{'vendor_personal_details' | translate}}</h2>
    <div class="row">
        <mat-label>{{'first_name'|translate}}: {{selectedVendor.firstName}}</mat-label>
    </div>
    <div class="row">
        <mat-label *ngIf='selectedVendor.middleName'>{{'middle_name'|translate}}: {{selectedVendor.middleName}}</mat-label>
    </div>
    <div class="row">
        <mat-label>{{'last_name'|translate}}: {{selectedVendor.lastName}}</mat-label>
    </div>
    <div class="row">
        <mat-label>{{'email'|translate}}: {{selectedVendor.email}}</mat-label>
    </div>
    <div class="row">
        <mat-label>{{'mobile_number'|translate}}: {{selectedVendor.mobileNumber}}</mat-label>
    </div>
    <h2>{{'address_details'|translate}}</h2>
    <div class="row">
        <mat-label>{{address.addressLine1}}</mat-label>
    </div>
    <div class="row">
        <mat-label *ngIf='address.addressLine2'>{{address.addressLine1}}
        </mat-label>
    </div>
    <div class="row">
        <mat-label *ngIf='address.addressLine2'>{{address.addressLine2}}
        </mat-label>
    </div>
    <div class="row">
        <mat-label *ngIf='address.landMark'>{{address.landMark}}</mat-label>
    </div>
    <div class="row">
        <mat-label>{{address.city}}, {{address.state}}</mat-label>
    </div>
    <div class="row">
        <mat-label>{{address.pincode}}</mat-label>
    </div>
    <h2>{{'other_details'|translate}}</h2>
    <div class="row">
        <mat-label>{{'aadhar_number'|translate}}: {{aadhaarId}}</mat-label>
    </div>
    <div class="row">
        <mat-label>{{'pan_number'|translate}}: {{panNumber}}</mat-label>
    </div>

    <div class="row">
        <div class="col">
            {{'aadhar_card_front'|translate}}
        </div>
      <div class="col">
        {{'aadhar_card_back'|translate}}
      </div>
        <div class="col">
            {{'pan_card'|translate}}
        </div>
        <div class="col">
            {{'profile_pic'|translate}}
        </div>
    </div>

    <div class="row">
        <div class="col">
            <img class="image-block" [src]="selectedVendor.aadharCardFrontUrl">
        </div>
      <div class="col">
        <img class="image-block" [src]="selectedVendor.aadharCardEndUrl">
      </div>
        <div class="col">
            <img class="image-block" [src]="selectedVendor.panCardUrl">
        </div>
        <div class="col">
            <img class="image-block" [src]="selectedVendor.profilePicUrl">
        </div>
    </div>

    <div class="row margin-top10">
        <mat-form-field appearance="outline">
            <textarea matInput [(ngModel)]="selectedVendor.comment" placeholder="Comment..."></textarea>
        </mat-form-field>
    </div>

    <div class="row">
        <div class="col">
            <button mat-button class="button" (click)="approveVendorByAdmin(selectedVendor.userId!, 'ACTIVE')">{{'Approve'|translate}}</button>
        </div>
        <div class="col">
            <button mat-button class="buttonPause" (click)="approveVendorByAdmin(selectedVendor.userId!, 'INACTIVE')">{{'put_on_hold'|translate}}</button>
        </div>
        <div class="col">
            <button mat-button class="buttonCancel" (click)="onNoClick()">{{'Cancel'|translate}}</button>
        </div>
    </div>

</div>
