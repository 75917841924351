import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-display-dailog',
  templateUrl: './message-display-dailog.component.html',
  styleUrls: ['./message-display-dailog.component.css']
})
export class MessageDisplayDailogComponent implements OnInit {

  message: string;
  error: boolean = false;

  constructor(public dialogRef: MatDialogRef<MessageDisplayDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.message = data['message'];
      this.error = data['error'];
    }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
