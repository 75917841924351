<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">


<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 class="repair-heading-body uppercase">{{'order_details' | translate}}</h1>
    <div class="cursor-pointer padding0" (click)="onNoClick()"><mat-icon>close</mat-icon></div>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" 
fxLayoutGap="20" >
    <table class="margin-left20">
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'order_ID' | translate}} </li></td>
            <td><b>{{this.mobiOrder.orderId}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'order_Date' | translate}} </li></td>
            <td><b>{{this.mobiOrder.createdDate | date: 'MMM dd, yyyy'}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'order_Status' | translate}} </li></td>
            <td><b>{{this.mobiOrder.orderStatus?.toString()?.includes("_") ? 
                this.mobiOrder.orderStatus?.toString()?.split("_")![0] + " " +
                this.mobiOrder.orderStatus?.toString()?.split("_")![1] 
                : this.mobiOrder.orderStatus}}</b></td>
        </tr>
    </table>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center " class="margin-top20" >
    <h1 class="repair-heading-body">{{'customer_details' | translate}}</h1>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" 
fxLayoutGap="20" >
    <table class="margin-left20">
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'customer_Name' | translate}}</li></td>
            <td><b>{{this.user_data.firstName}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td>
                <li class="width200">{{'contact_Number' | translate}} </li></td>
            <td><b>{{custmobileNumber | slice:0:2}}-{{custmobileNumber | slice:2}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td>
                <li class="width200">{{'email_Address' | translate}} </li></td>
            <td><b>{{this.user_data.email}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'customer_Address' | translate}} </li></td>
            <td><b>{{this.mobiOrder.addressDto?.addressLine1}}
                 {{this.mobiOrder.addressDto?.addressLine2}}
                 {{this.mobiOrder.addressDto?.city}}
                 {{this.mobiOrder.addressDto?.state}}
                 {{this.mobiOrder.addressDto?.pincode  }}</b></td>
        </tr>
    </table>
</div>

<div fxLayout="row" *ngIf="isIssue" fxLayoutAlign="space-between center" class="margin-top20" >
    <h1 class="repair-heading-body">{{'issue_details' | translate}}</h1>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" 
fxLayoutGap="20" *ngIf="isIssue">
    <table class="margin-left20">
        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].modelDto?.modelName != undefined">
            <td >
                <li class="width200">{{'device_Name' | translate}} </li></td>
            <td><b>{{this.mobiOrder.repairIssues![0].modelDto?.modelName}}</b></td>
        </tr>

        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].acTypeDto?.acTypes != undefined">
            <td >
                <li class="width200">{{'product' | translate}} :</li></td>
            <td><b>{{'AIR_CONDITIONER' | translate}}</b></td>
        </tr>
        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].refrigeratorTypeDto?.refrigeratorType != undefined">
            <td >
                <li class="width200">{{'product' | translate}} :</li></td>
            <td><b>{{'REFRIGERATOR' | translate}}</b></td>
        </tr>
        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].washingMachineTypeDto?.washingMachineType != undefined">
            <td >
                <li class="width200">{{'product' | translate}} :</li></td>
            <td><b>{{'WASHING_MACHINE' | translate}}</b></td>
        </tr>
        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].acTypeDto?.acTypes != undefined">
            <td >
                <li class="width200">{{'model_type' | translate}} :</li></td>
            <td><b>{{this.model}}</b></td>
        </tr>
        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].refrigeratorTypeDto?.refrigeratorType != undefined">
            <td >
                <li class="width200">{{'model_type' | translate}} :</li></td>
            <td><b>{{this.model}}</b></td>
        </tr>
        <tr class="word-wrap-break-word" *ngIf="this.mobiOrder.repairIssues![0].washingMachineTypeDto?.washingMachineType != undefined">
            <td >
                <li class="width200">{{'model_type' | translate}} :</li></td>
            <td><b>{{this.model}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td>
                <li class="width200">{{'reported_Issue' | translate}}</li></td>
            <td><b>{{repairsText}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'selected_repair_date' | translate}} </li></td>
            <td><b>{{this.mobiOrder.deliveryDate | date: 'MMM dd, yyyy'}}</b></td>
        </tr>
    </table>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="margin-top20" >
    <h1 class="repair-heading-body">{{'payment_details' | translate}}</h1>
</div>

<div fxLayout="row" fxLayoutAlign="space-between center" class="margin-top0" 
fxLayoutGap="20" >
    <table class="margin-left20">
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'mode_of_Payment' | translate}}</li></td>
            <td><b>{{this.mobiOrder.paymentDto?.paymentTypeDto?.paymentModeType == "PAYTM_GATEWAY" ? 'Online' : "Cod" }}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'payment_Status' | translate}}</li></td>
            <td><b>{{this.mobiOrder.paymentDto?.status == "PAYMENT_COMPLETED" ? "Paid" : "Unpaid" }}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td>
                <li class="width200">{{'repair_amount' | translate}}</li></td> 
            <td><b>₹ {{this.mobiOrder.paymentDto?.paymentAmount | number : '1.2-2'}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'taxes' | translate}}</li></td>
            <td><b>₹ {{totalTax | number : '1.2-2'}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td>
                <li class="width200">{{'net_amount' | translate}}</li></td>
            <td><b>₹ {{this.mobiOrder.orderAmount! | number : '1.2-2'}}</b></td>
        </tr>
        <tr class="word-wrap-break-word" >
            <td><li class="width200">{{'promo_code_amt_off' | translate}} :</li></td>
            <td *ngIf="this.mobiOrder.promoCode != null"><b>₹ {{this.mobiOrder.promoCodeAmtOff | number : '1.2-2'}}</b></td>
            <td *ngIf="this.mobiOrder.promoCode == null"><b>₹ {{0 | number : '1.2-2'}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td>
                <li class="width200">{{'total_amount' | translate}}</li></td>
            <td><b>₹ {{this.mobiOrder.paymentDto?.paymentTotalAmount! | number : '1.2-2'}}</b></td>
        </tr>
        <tr class="word-wrap-break-word">
            <td >
                <li class="width200">{{'amount_Due' | translate}}</li></td>
            <td><b>₹ {{this.mobiOrder.paymentDto?.paymentTypeDto?.paymentModeType == "PAYTM_GATEWAY" ? 0 : this.mobiOrder.paymentDto?.paymentTotalAmount! | number : '1.2-2'}}</b></td>
        </tr>
        
    </table>
</div>

<div class="order-now-btn buy-btn-below margin-top40" fxLayoutAlign="start start">
    <button class="order-btn" (click)="onNoClick()">{{'close' | translate}}</button>
</div>
