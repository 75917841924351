import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error-display-dialog-paytm',
  templateUrl: './error-display-dialog-paytm.component.html',
  styleUrls: ['./error-display-dialog-paytm.component.scss']
})
export class ErrorDisplayDialogPaytmComponent implements OnInit {
  errorMessage: string;
  errorMessage1: string
  router:Router;

  constructor(public dialogRef: MatDialogRef<ErrorDisplayDialogPaytmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,router: Router) { 
      this.router=router;
      this.errorMessage = data['errorMessage']; this.errorMessage1 = data['errorMessage1'];  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  redirectHome():void{
    this.router.navigate(['']);
    this.onNoClick();
  }

  

}
