import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MobiOrderModel } from 'src/app/data/mobi-order-model/mobi-order.model';
import { UserDetailsModel } from 'src/app/data/user-details/user-details.model';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-conformation-details',
  templateUrl: './order-conformation-details.component.html',
  styleUrls: ['./order-conformation-details.component.scss']
})
export class OrderConformationDetailsComponent implements OnInit {
  mobiOrder: MobiOrderModel = new MobiOrderModel();
  repairsText: string = "";
  user_data: UserDetailsModel = new UserDetailsModel();
  custmobileNumber: any;
  totalTax: number = 0;
  totalBeforTax: number = 0;
  totalAmount: number = 0;
  goldMember: boolean = false;
  isIssue: boolean = true;
  modeltype: any;
  model: any;
  constructor(private titleService: Title, public dialogRef: MatDialogRef<OrderConformationDetailsComponent>, private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { orderID: string, mobiOrder: MobiOrderModel, userData: UserDetailsModel }, private ngxService: NgxUiLoaderService,) {
    this.titleService.setTitle('Order Confirmation')
    this.mobiOrder = this.data.mobiOrder;
    this.user_data = this.data.userData;
    this.custmobileNumber = this.user_data.mobileNumber
    console.log(this.mobiOrder)
    this.custmobileNumber = this.user_data.mobileNumber
    let repairTxt = "";
    this.goldMember = localStorage.getItem("membership") == "Gold Membership" ? true : false;
    if (this.mobiOrder.repairIssues?.length == 0) {
      this.isIssue = false;
      let plan = this.mobiOrder.purchaseDtoList?.at(0)
      plan?.plans?.forEach(e => {
        this.totalTax = this.totalTax +
          e.cgst! + e.sgst! + e.igst!
      });
    } else {
      for (let repair of this.mobiOrder.repairIssues!) {
        repairTxt = repairTxt + repair.repairChargeDto?.issueDto?.issueDescription! + ", ";
      }
      this.repairsText = repairTxt.substring(0, repairTxt.length - 2);
      this.mobiOrder.repairIssues?.forEach(e => {
        this.totalTax = this.totalTax +
          e.cgst! + e.sgst! + e.igst!
        this.totalBeforTax = this.totalBeforTax + (e.repairedAmount! - (e.cgst! + e.sgst! + e.igst!));
        this.totalAmount = this.totalAmount + e.repairedAmount!;
      })
    }
   // debugger
    if (this.mobiOrder.orderDepartment != "MOBILE" && this.mobiOrder.orderDepartment != "REFRIGERATOR" && this.mobiOrder.orderDepartment != "WASHING_MACHINE") {
      if (this.mobiOrder.repairIssues![0].acTypeDto?.acTypes != undefined) {
        this.modeltype = this.mobiOrder.repairIssues![0].acTypeDto?.acTypes
        this.translate.get(this.modeltype).subscribe((res: string) => {
          this.model = res;
        });
      }
    }
    if (this.mobiOrder.orderDepartment != "MOBILE" && this.mobiOrder.orderDepartment != "AIR_CONDITIONER" && this.mobiOrder.orderDepartment != "WASHING_MACHINE") {
      if (this.mobiOrder.repairIssues![0].refrigeratorTypeDto?.refrigeratorType != undefined) {
        this.modeltype = this.mobiOrder.repairIssues![0].refrigeratorTypeDto?.refrigeratorType
        this.translate.get(this.modeltype).subscribe((res: string) => {
          this.model = res;
        });
      }
    }
    if (this.mobiOrder.orderDepartment != "MOBILE" && this.mobiOrder.orderDepartment != "REFRIGERATOR" && this.mobiOrder.orderDepartment != "AIR_CONDITIONER") {
      if (this.mobiOrder.repairIssues![0].washingMachineTypeDto?.washingMachineType != undefined) {
        this.modeltype = this.mobiOrder.repairIssues![0].washingMachineTypeDto?.washingMachineType
        this.translate.get(this.modeltype).subscribe((res: string) => {
          this.model = res;
        });
      }
    }
  }

  ngOnInit(): void { }

  onNoClick() {
    this.dialogRef.close();
  }
}
