<div class="del-popup">
    <div style="text-align: center;" class="img-delete">
        <img src="../../../../assets/images/delete-account-successfully.png" alt="delete-account.jpg">
    </div>
    <h4>{{'delete_msg' | translate}}</h4>
    <div style="text-align: center;">
        <button class="yes-btn" (click)="onClick()">{{'OK' | translate}}</button>
    </div>
    <div style="text-align: center;">
    </div>
</div>