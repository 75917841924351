import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConstants } from 'src/app/constants/app.constants';
import { UserDetailsModel } from 'src/app/data/user-details/user-details.model';
import { UserAddressModel } from '../data/user-address-model/user-address.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  httpHeaders: any;
  isSocialUser: boolean = false;
  userEmail: any;
  private listners = new Subject<any>();
  private language = new Subject<any>();

  constructor(protected httpClient: HttpClient) { }
  /**
   * func to set access token in
   * http api calls
   * @param accessToken
   */
  setBearerToken(token_type: string, accessToken: string) {
    this.httpHeaders = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json')
      .append('X-Requested-With', 'XMLHttpRequest')
      .append('Authorization', token_type + " " + accessToken);
  }

  getUserDetail(): Observable<any> {
    return this.httpClient.get(AppConstants.userService + AppConstants.getUserDetailsAction, { headers: this.httpHeaders });
  }

  getUserDetailById(id: any): Observable<any> {
    return this.httpClient.get(AppConstants.userService + AppConstants.getUserByIdUrl + '?id=' + id, { headers: this.httpHeaders });
  }

  updateUserDetails(UserDetailsModel: UserDetailsModel) {
    return this.httpClient.post(AppConstants.userService + AppConstants.updateUserDetails, UserDetailsModel);
  }

  goldOrSilverUser(): Observable<any> {
    return this.httpClient.get(AppConstants.orderService + AppConstants.getGoldOrSilverMember, { headers: this.httpHeaders });
  }

  generateOTP(mobileNumber: string): Observable<any> {
    return this.httpClient.get(AppConstants.userService + AppConstants.generateOTP + '/' + mobileNumber, { headers: this.httpHeaders });
  }

  retryOTP(mobileNumber: string): Observable<any> {
    return this.httpClient.get(AppConstants.userService + AppConstants.retryOtp + '/' + mobileNumber, { headers: this.httpHeaders });
  }

  listen(): Observable<any> {
    return this.listners.asObservable();
  }
  lang(): Observable<any> {
    return this.language.asObservable();
  }
  getLang(status: any) {
    this.language.next(status);
  }
  getusernameonlogin(status: any) {
    this.listners.next(status);
  }
  getuserUpdatedname(status: any) {
    this.listners.next(status);
  }
  getUsers(getUsersUrl: string): Observable<any> {
    return this.httpClient.get(getUsersUrl);
  }

  addOrUpdateUser(addOrUpdateUserUrl: string, userDetails: UserDetailsModel): Observable<any> {
    return this.httpClient.post(addOrUpdateUserUrl, userDetails);
  }

  addOrUpdateRole(addOrUpdateUserUrl: string, permissionDto: string[]): Observable<any> {
    return this.httpClient.post(addOrUpdateUserUrl, permissionDto);
  }

  getAllRoles(getAllRolesUrl: string): Observable<any> {
    return this.httpClient.get(getAllRolesUrl);
  }

  deleteAddress(UpdateAddressUrl: string): Observable<any> {
    return this.httpClient.put(UpdateAddressUrl, null);
  }

  UpdateAddress(UpdateAddressUrl: string, UpdateAddressDto: UserAddressModel): Observable<any> {
    return this.httpClient.put(UpdateAddressUrl, UpdateAddressDto);
  }

  addAddress(UpdateAddressUrl: string, UpdateAddressDto: UserAddressModel): Observable<any> {
    return this.httpClient.post(UpdateAddressUrl, UpdateAddressDto);
  }

  updateAccount(deleteAccountUrl: string): Observable<any> {
    return this.httpClient.put(deleteAccountUrl, null);
  }
  updateChatbot(url: string, formData: FormData) {
    const headers = new HttpHeaders();
    return this.httpClient.post(url, formData, { headers });
  }
  deleteFaq(url: any) {
    return this.httpClient.delete(url);
  }
  getFaq(url: string) {
    return this.httpClient.get(url);
  }

  getChatData(url: string) {
    return this.httpClient.get(url);
  }
  postChatData(url: string,feedbackData:any) {
    const headers = new HttpHeaders();
    return this.httpClient.post(url,feedbackData, { headers });
  }
}
