import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

export interface Element {
    iPhone14: string;
    iPhone_14Pro: string;
  }
  
  const ELEMENT_DATA: Element[] = [
    {iPhone14: 'Aerospace-grade aluminum design with glass back', iPhone_14Pro: 'Surgical-grade stainless steel design with frosted glass back'},
    {iPhone14: '-', iPhone_14Pro: 'ProMotion with adaptive refresh rates up to 120Hz'},
    {iPhone14: '-', iPhone_14Pro: 'Always-on display'},
    {iPhone14: '800 nits max brightness (typical)', iPhone_14Pro: '1,000 nits max brightness (typical)'},
    {iPhone14: '1,200 nits peak brightness (HDR)', iPhone_14Pro: '1,600 nits peak brightness (HDR) and 2,000 nits peak brightness (outdoor)'},
    {iPhone14: '"Notch" TrueDepth camera array', iPhone_14Pro: 'TrueDepth camera array with Dynamic Island'},
    {iPhone14: 'A15 Bionic chip', iPhone_14Pro: 'A16 Bionic chip'},
    {iPhone14: '"Advanced" dual-camera system', iPhone_14Pro: '"Pro" camera system'},
    {iPhone14: '2x optical zoom range (2x optical zoom out, digital zoom up to 5x)', iPhone_14Pro: '6x optical zoom range (3x optical zoom in, 2x optical zoom out, digital zoom up to 15x)'},
    {iPhone14: '12-megapixel Main camera with ƒ/1.5 aperture', iPhone_14Pro: '48-megapixel Main camera with ƒ/1.78 aperture'},
    {iPhone14: '12-megapixel Ultra Wide camera with ƒ/2.4 aperture', iPhone_14Pro: '12-megapixel Ultra Wide camera with ƒ/2.2 aperture'},
    {iPhone14: '-', iPhone_14Pro: '12-megapixel Telephoto camera with ƒ/2.8 aperture'},
    {iPhone14: 'Sensor-shift optical image stabilization', iPhone_14Pro: 'Second-generation sensor-shift optical image stabilization'},
    {iPhone14: 'True Tone flash', iPhone_14Pro: 'Adaptive True Tone flash'},
    {iPhone14: '-', iPhone_14Pro: 'LiDAR scanner'},
    {iPhone14: '-', iPhone_14Pro: 'Night mode portraits'},
    {iPhone14: '-', iPhone_14Pro: 'Macro photography'},
    {iPhone14: '-', iPhone_14Pro: 'Apple ProRAW'},
    {iPhone14: '-', iPhone_14Pro: 'ProRes video recording up to 4K at 30 fps (1080p at 30 fps for 128GB capacity)'},
    {iPhone14: '-', iPhone_14Pro: 'Macro video recording, including slo‑mo and time‑lapse'},
    {iPhone14: '146.7mm height', iPhone_14Pro: '147.5mm height'},
    {iPhone14: '7.80mm thickness', iPhone_14Pro: '7.85mm thickness'},
    {iPhone14: 'Weight of 172 grams', iPhone_14Pro: 'Weight of 206 grams'},
    {iPhone14: '20-hour battery life (during video playback)', iPhone_14Pro: '23-hour battery life (during video playback)'},
    {iPhone14: '128GB, 256GB, and 512GB storage options', iPhone_14Pro: '128GB, 256GB, 512GB, and 1TB storage options'},
    {iPhone14: 'Available in Blue, Purple, Midnight, Starlight, and PRODUCT(RED)', iPhone_14Pro: 'Available in Deep Purple, Space Black, Silver, and Gold'},
    {iPhone14: 'Starts at $799', iPhone_14Pro: 'Starts at $999'},
];
  
@Component({
    selector: 'iphone14vsiphone14pro',
    templateUrl: './iphone14vsiphone14pro.html'
})

export class Iphone14vsIphone14ProComponent{
    displayedColumns: string[] = ['iPhone14', 'iPhone_14Pro'];
    dataSource = ELEMENT_DATA;
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"The iPhone 14 and iPhone 14 Pro are both highly anticipated apple releases, but which one is right for you? Read on to compare their features and specs."})
        this.metaService.updateTag({name:'keywords',content:'iPhone 14, iPhone 14 Pro, Featured, Buyers Guide, Repairing Company, Specifications'})
        this.canonicalServide.setCanonicalURL();
       
        this.translate.get('iPhone_14_vs_iPhone_14_Pro').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('iPhone_14_vs_iPhone_14_Pro').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        });  
        
    
    }   
}
