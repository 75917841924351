<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">


<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 class="repair-heading-body uppercase">{{updateOrAdd | translate}}</h1>
    <div class="cursor-pointer padding0" (click)="onNoClick()"><mat-icon>close</mat-icon></div>
</div>

<form [formGroup]="addUserFormGroup">
    <mat-card >
        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="50">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'Email' | translate}}</mat-label>
                            <input matInput #input formControlName="email"
                                placeholder="{{'Email' | translate}}">
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'First_Name' | translate}}</mat-label>
                            <input matInput #input formControlName="firstName"
                                placeholder="{{'First_Name' | translate}}">
                        </mat-form-field>
                    </div>                    
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'Last_Name' | translate}}</mat-label>
                            <input matInput #input formControlName="lastName" placeholder="{{'Last_Name' | translate}}">
                        </mat-form-field>
                    </div>
            </div>

             <div fxLayout="column" fxFlex="50">
                
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'Mobile_Number' | translate}}</mat-label>
                        <input matInput #input formControlName="mobileNumber"
                            placeholder="{{'Mobile_Number' | translate}}">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'Role' | translate}}</mat-label>
                        <mat-select formControlName="role">
                            <mat-option value={{role.roleName}} *ngFor="let role of roleList">{{role.roleName | translate}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'status' | translate}}</mat-label>
                        <mat-select formControlName="status" [disabled]="updateOrAdd == 'add_user'">
                            <mat-option value={{status}} *ngFor="let status of statusList">{{status | translate}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>
                </div>        
            </div> 
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <div style="margin:10px;">
                <button class="button" type="submit" mat-raised-button (click)="addOrUpdateUserModel()">{{updateOrAdd | translate}}</button>
            </div>
        </div>
    </mat-card>
</form>
