import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetStartedDialog } from "../get-started-dialog/get-started-dialog";
import { AuthService } from "src/app/services/auth.service";
import { AuthModel } from "src/app/data/auth-model/auth-model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { animate, style, transition, trigger } from "@angular/animations";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { SocialAuthService as auth } from '@abacritt/angularx-social-login';
import { SocialUser } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';

@Component({
  selector: "login-sendotp-dialog",
  templateUrl: "./login-sendotp-dialog.html",
  styleUrls: ["./login-sendotp-dialog.scss"],
  animations: [
    trigger("openClose", [
      transition("void => *", [
        style({
          height: 0,
          opacity: 0,
          transform: "scale(0.85)",
          "margin-bottom": 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
        }),
        animate(
          "50ms",
          style({
            height: "*",
            "margin-bottom": "*",
            paddingTop: "*",
            paddingBottom: "*",
            paddingRight: "*",
            paddingLeft: "*",
          })
        ),
        animate(70),
      ]),
    ]),
  ],
})
export class LoginSendOTPDialogDialog implements OnInit, OnDestroy {
  authModel: any = new AuthModel();
  animal: string;
  mobileNumber: string = "";
  OTP: string = "";
  name: string;
  hide: boolean = false;
  disabled: boolean = false;
  counter: any;
  hidetimer: boolean = false;
  success: any;
  counter1: any;
  user_data: any;
  apiReturned = false;
  loginForm!: FormGroup;
  indiacode: string = "91";
  user: SocialUser;
  userIdToUpdate: number;
  public showPassword: boolean = false;
  subscription: any;
  updateAccounttUrl: string;
  private readonly JWT_TOKEN = 'access_token';
  dialogRefWelcome:any;
  constructor(
    private router: Router,
    private ngxService: NgxUiLoaderService,
    private snackBar: MatSnackBar,
    public dialogReflogged: MatDialogRef<LoginSendOTPDialogDialog>,
    public loginDialog: MatDialog,
    private datamangerService: UserService,
    private auth: AuthService,
    private authService: auth
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      mobileNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
      otp: new FormControl('')
    });
    this.subscription = this.authService.authState.subscribe((user) => {
      this.user = user;
      if (this.user != null) {
        this.LoginWithGoogle(this.user.idToken);
      }
    });
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => console.log(x));
  }
  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => console.log(x));
  }
  signOut(): void {
    this.authService.signOut();
  }

  LoginWithGoogle(email: string) {
    this.ngxService.start();
    this.auth.loginByGoogle(email).subscribe({
      next: (data: AuthModel) => {
        this.authModel = data;
        console.log(data)

        if (this.authModel != null) {
          localStorage.setItem(this.JWT_TOKEN, this.authModel.access_token!);
          localStorage.setItem('userId', this.authModel.userId);
          localStorage.setItem('role', this.authModel.role);
          if (this.authModel!.fullName != undefined) {
            var str = this.authModel.fullName;
            var lastIndex = str.lastIndexOf(" ");
            str = str.substring(0, lastIndex);
            localStorage.setItem('name', str);
            localStorage.setItem('emailId', this.authModel.emailId);
          }
          this.ngxService.stop();

          if (this.user != null) {
            this.signOut();
          }

          if (this.authModel!.fullName == undefined) {
            let diaRef = this.loginDialog.open(GetStartedDialog, {
              width: "800px",
              height: "550px",
              data: data.userId,
              disableClose: true,
            });

            diaRef.afterClosed().subscribe(result => {
              this.dialogReflogged.close(result);
            });
          }
          else {
            this.dialogReflogged.close([this.authModel.userId, str, this.authModel.emailId]);
          }
        }
      },
      error: (error: any) => {
        console.log(error);
        this.snackBar.open("Cannot login. Please try again", "", {
          duration: 5000,
          panelClass: ["blue-snackbar"],
        });
        this.ngxService.stop();
      },
    });


  }

  onNoClick(): void {
    this.dialogReflogged.close();
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  Login(): void {
    this.mobileNumber = this.indiacode + this.loginForm.controls["mobileNumber"].value;
    this.OTP = this.loginForm.controls["otp"].value;
    this.ngxService.start();
    this.auth.loginbyOTP(this.mobileNumber, this.OTP).subscribe({
      next: (data: AuthModel) => {
        this.authModel = data;

        localStorage.setItem(this.JWT_TOKEN, this.authModel.access_token!);
        localStorage.setItem('userId', this.authModel.userId);
        localStorage.setItem('role', this.authModel.role);
        if (this.authModel != null) {
          if (this.authModel!.fullName != undefined) {
            var str = this.authModel.fullName;
            var lastIndex = str.lastIndexOf(" ");
            str = str.substring(0, lastIndex);
            localStorage.setItem('name', str);
            localStorage.setItem('emailId', this.authModel.emailId);
          }
          this.ngxService.stop();
          if (this.authModel!.fullName == undefined) {
            if (this.authModel.emailId == null) {
              let diaRef = this.loginDialog.open(GetStartedDialog, {
                width: "800px",
                height: "550px",
                data: data.userId,
                disableClose: true,
              });
              diaRef.afterClosed().subscribe(result => {
                this.dialogReflogged.close(result);
              });
            }
          }
          else {
            this.dialogReflogged.close([this.authModel.userId, str, this.authModel.emailId]);
          }
        }

      },
      error: (error: any) => {
        console.log(error);
        this.snackBar.open("Invalid OTP. Please try again", "", {
          duration: 5000,
          panelClass: ["blue-snackbar"],
        });
        this.ngxService.stop();
      },
    });
  }

  click() {
    this.mobileNumber = this.indiacode + this.loginForm.controls["mobileNumber"].value;
    this.ngxService.start();
    this.datamangerService.generateOTP(this.mobileNumber).subscribe({
      next: (data: any) => {
        this.success = true;
        this.counter1 = 5;
        const interval1 = setInterval(() => {
          this.counter1--;
          if (this.counter1 < 1) {
            clearInterval(interval1);
            this.success = false;
          }
        }, 500);

        if (data == true) {
          this.hide = true;
          this.counter = 30;
          const interval = setInterval(() => {
            this.counter--;
            if (this.counter < 1) {
              clearInterval(interval);
              this.hidetimer = true;
              this.apiReturned = true;
            }
          }, 1000);
        }
        this.ngxService.stop();
        this.loginForm.controls["mobileNumber"].disable();
        this.loginForm.controls["otp"].setValidators([Validators.required, Validators.minLength(6)]);
      },
      error: (error: any) => {
        console.log(error);
        this.ngxService.stop();
      },
    });
  }

  retryotp() {
    this.mobileNumber = this.indiacode + this.loginForm.controls["mobileNumber"].value;
    this.ngxService.start();
    this.datamangerService.retryOTP(this.mobileNumber).subscribe({
      next: (data: any) => {
        console.log(data);
        this.success = true;
        this.counter1 = 5;
        const interval1 = setInterval(() => {
          this.counter1--;
          if (this.counter1 < 1) {
            clearInterval(interval1);
            this.success = false;
          }
        }, 500);

        if (data == true) {
          this.hide = true;
          this.counter = 30;
          const interval = setInterval(() => {
            this.counter--;
            if (this.counter < 1) {
              clearInterval(interval);
              this.hidetimer = true;
              this.apiReturned = true;
            }
          }, 1000);
        }
        this.ngxService.stop();
        this.loginForm.controls["mobileNumber"].disable();
        this.loginForm.controls["otp"].setValidators([Validators.required, Validators.minLength(6)]);
      },
      error: (error: any) => {
        console.log(error);
        this.ngxService.stop();
      },
    });
  }

  ChangeNumber() {
    this.loginForm.reset();
    this.loginForm.controls["mobileNumber"].enable();
    this.hide = false;
  }
  oncloseSnackbar() {
    this.success = false;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
