import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'after-delete-dialog',
    templateUrl: './after-delete-dialog.html',
    styleUrls: ['./yes-or-no-dialog.css']
})
export class AfterDeleteDialog implements OnInit {

    constructor(public dialogRef: MatDialogRef<AfterDeleteDialog>) { }
    
    ngOnInit(): void {
    }

    onClick(): void {
       this.dialogRef.close();
    }
}
