import { Component,Inject,OnDestroy,OnInit } from "@angular/core";
import { MatDialog,MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/app/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserService } from "src/app/services/user.service";
import { Router } from "@angular/router";
import { MobiOrderModel } from "src/app/data/mobi-order-model/mobi-order.model";
import { UserDetailsModel } from "src/app/data/user-details/user-details.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "order-details-dialog",
  templateUrl: "./order-details-dialog.html",
  styleUrls: ["./order-details-dialog.scss"]
})

export class OrderDetailsDialog implements OnInit{

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, private translate:TranslateService,
    public dialogReflogged: MatDialogRef<OrderDetailsDialog>,
    public loginDialog: MatDialog,
    private userservice: UserService
  ) {}
  mobiOrder: MobiOrderModel = new MobiOrderModel();
  user_data: UserDetailsModel = new UserDetailsModel();
  totalTax:number = 0;
  isIssue:boolean =true;
  repairsText : string = "" ;
  custmobileNumber:any;
  modeltype:any;
  model:any;
  
  ngOnInit(): void {
 
    this.mobiOrder = this.data.orderDetail
    
   
    let repairTxt = "";
    if(this.mobiOrder.repairIssues?.length==0){
      this.isIssue = false;
      let plan = this.mobiOrder.purchaseDtoList?.at(0)
      plan?.plans?.forEach(e=>{
        this.totalTax = this.totalTax + 
        e.cgst! + e.sgst! + e.igst!
      });
    }else{
      for(let repair of this.mobiOrder.repairIssues!){
        repairTxt = repairTxt + repair.repairChargeDto?.issueDto?.issueDescription! + ", ";
      }
      this.repairsText = repairTxt.substring(0,repairTxt.length - 2);
      this.mobiOrder.repairIssues?.forEach(e=>{
        this.totalTax = this.totalTax + 
        e.cgst! + e.sgst! + e.igst!
      })
    }
    if(this.mobiOrder.repairIssues![0].acTypeDto?.acTypes != undefined)
      {
      this.modeltype = this.mobiOrder.repairIssues![0].acTypeDto?.acTypes
      this.translate.get(this.modeltype).subscribe((res: string) => {
        this.model= res;
      });
      }
      if(this.mobiOrder.repairIssues![0].refrigeratorTypeDto?.refrigeratorType != undefined)
      {
      this.modeltype = this.mobiOrder.repairIssues![0].refrigeratorTypeDto?.refrigeratorType
      this.translate.get(this.modeltype).subscribe((res: string) => {
        this.model= res;
      });
      }
      if(this.mobiOrder.repairIssues![0].washingMachineTypeDto?.washingMachineType != undefined)
      {
      this.modeltype = this.mobiOrder.repairIssues![0].washingMachineTypeDto?.washingMachineType
      this.translate.get(this.modeltype).subscribe((res: string) => {
        this.model= res;
      });
      }
    
    this.getUserDetails();

  };

  getUserDetails(){
   
    this.userservice.getUserDetail().subscribe({
      next:(data:any)=>{
     
        this.user_data = data; 
        if (this.user_data != undefined) {
          this.user_data = data; 
          this.custmobileNumber = this.user_data.mobileNumber
        }
      },
      error:(error:any)=>{
        
        
        }
    });
   }

  onNoClick(){
    this.dialogReflogged.close();
  }
}
