import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-trackyourlostmobile',
    templateUrl: './trackyourlostmobile.component.html'
})

export class trackyourlostmobileComponent{
  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {
    this.metaService.updateTag({name:'description',content:"If you ever lose your phone, this blog will guide you how to track it down using its IMEI number. Don't worry - it's easy and doesn't require any technical knowledge."})
    this.metaService.updateTag({name:'keywords',content:'imei tracker, track your lost phone, track your lost mobile, track your lost phone using imei number'})
    this.canonicalServide.setCanonicalURL();

    this.translate.get('how_To_Track_Your_Lost_Mobile').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('how_To_Track_Your_Lost_Mobile').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    });
  }     
}