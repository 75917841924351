<div fxLayout="column" fxLayoutAlign="space-between " fxLayoutGap="40"
    class="every-repair-section every-repair-section1 margin-top10 bg-color-annual-section">
<div fxLayout="row" class="padding-top20" fxLayoutAlign="space-between center">
    <h1 class="every-repair-heading absoulte-position">{{'ALL_IN_ONE' | translate}}</h1>
</div>
<div fxLayout="row" fxLayoutAlign="space-between center">
    <h2 class="every-repair-heading-small absoulte-position">{{'live_without_worrying_about_repairs' | translate}}
    </h2>
</div>

<div class="container">
<div class="plan-section different-sep">
<div class="row">
<div class="col-md-3">
<div class="first">    
<h3>ALL IN ONE</h3>
<h1>Rs. 1999.00</h1>
</div> 
</div>
<div class="col-md-7">
<div class="details">

<ul>
<li>
<div class="icon">
<mat-icon role="img" class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
</div>
<div class="text">
100% wear and tear repairs    
</div>
</li> 

<li>
<div class="icon">
<mat-icon role="img" class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
</div>
<div class="text">
100% money back guarantee check 
</div>
</li> 

<li>
<div class="icon">
<mat-icon role="img" class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
</div>
<div class="text">
Free pickup from home
</div>
</li> 

<li>
<div class="icon">
<mat-icon role="img" class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
</div>
<div class="text">
Scheduled pickups and drops check
</div>
</li> 

<li>
<div class="icon">
<mat-icon role="img" class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
</div>
<div class="text">
Real time case status
</div>
</li> 

<li>
<div class="icon">
<mat-icon role="img" class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
</div>
<div class="text">
24x7 support
</div>
</li> 
</ul>    
</div>
</div>  

<div class="col-md-2">
<div class="text-center">    
<button class="order-btn" (click)="NavigateToPlansPage('all_in_one')">Buy</button>
</div>
</div>  

</div>    
</div>
</div>
</div>