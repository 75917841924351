import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-view-details-plans',
  templateUrl: './view-details-plans.component.html',
  styleUrls: ['./view-details-plans.component.scss']
})
export class ViewDetailsPlansComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ViewDetailsPlansComponent>,public router:Router) { }

  ngOnInit(): void {
  }
  
  onNoClick(){
    this.dialogRef.close();
  }

}
