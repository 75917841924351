<!-- -->
<app-calculator-form></app-calculator-form>

<section class="three-boxes">
    <div class="container">
        <div class="row">
            <div class="col-md-12 heading">
                <h1>{{'Repair_Your_Gadget' | translate}}</h1>
                <p>{{'book_a_free_pickup' | translate}}</p>
            </div>
        </div>
        <div class="row justify-content-center">

<div class="col-md-4">
<div class="step-img">
<div class="circlestep">
<div class="box">
<img src="../assets/images/check-price.png" alt="">
</div>
</div>    
<div class="margin-top10">
<h4>{{'Check_Price' | translate}}</h4>
<p>{{'select_your_device' | translate}}</p>
</div>
</div>
</div>

<div class="col-md-4">
    <div class="step-img">
    <div class="circlestep">
    <div class="box">
    <img src="../assets/images/schedule-pickup.png" alt="">
    </div>
    </div>    
    <div class="margin-top10">
    <h4>{{'Schedule_Pickup' | translate}}</h4>
    <p>{{'book_a_free_pickup' | translate}}</p>
    </div>
    </div>
</div>

<div class="col-md-4">
    <div class="step-img">
    <div class="circlestep">
    <div class="box">
        <img src="../assets/images/repair.png" alt="">
    </div>
    </div>    
    <div class="margin-top10">
        <h4>{{'Get_repaired' | translate}}</h4>
        <p>{{'Our_executive' | translate}}</p>
    </div>
    </div>
</div>



            <div class="col-md-12">
                <div class="line-step"></div>
            </div>
        </div>
    </div>
</section>
<section class="second-home">
<div fxLayout="column" fxLayoutAlign="space-between " fxLayoutGap="90" class="every-repair-section margin-top10px whyrepair">
<div fxLayout="row" class="padding-top20" fxLayoutAlign="space-between center">
<h1 class="every-repair-heading absoulte-position">{{'why_Repairing_Company' | translate}}</h1>
</div>
<div class="container">
<div class="row justify-content-center">
<div class="col-md-6">
    <div class="side-img">
        <div class="slidercircle">
            <div class="circle-one">
                <div class="circle-arround-one"></div>
            </div>
            <div class="circle-two">
                <a href="#">
                    <div class="circle-arround-two-1 anima">
                        <img src="../assets/images/phone-circle.png">
                        <span class="circle-text">Mobile<br>Repairs</span>
        
                    </div>
                </a>
                <a href="#">
                    <div class="circle-arround-two-2 anima">
                        <img src="../assets/images/refrigerator-circle.png">
                        <span class="circle-text">Refrigerator<br>Repairs</span>
                    </div>
                </a>
                <a href="https://www.instagram.com/ogzozbn/" target="_blank">
                    <div class="circle-arround-two-3 anima">
                        <img src="../assets/images/ac-circle.png">
                        <span class="circle-text">AC<br>Repairs</span>
                    </div>
                </a>
                <a href="#">
                    <div class="circle-arround-two-4 anima">
                        <img src="../assets/images/washing-circle.png">
                        <span class="circle-text">Washing Machine<br>Repairs</span>
                    </div>
                </a>
            </div>
          
        </div>
        <div class="main main-display">
            <div class="circle">
              <div class="icon-block">
                <img src="../assets/images/phone-circle.png">
            </div>
              <div class="icon-block">
                <img src="../assets/images/refrigerator-circle.png">
                </div>
              <div class="icon-block">
                <img src="../assets/images/ac-circle.png">
                </div>
              <div class="icon-block">
                <img src="../assets/images/washing-circle.png">
            </div>
            </div>
           
          </div>
        <img src="../assets/images/rc-left.png">
        </div>


      </div>
<div class="col-md-6">
<div class="right-text">    
<div class="border-topbot"></div>    
<div class="whyrep-box">
<div class="icon">
<img src="../assets/images/genuine-parts.png">
</div>
<div class="text">
<h3>{{'100_gen_parts' | translate}}</h3>
<div class="row">
<div class="col-md-6">
<div class="order-now-class" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
<div class="col-md-6">
<div class="order-now-class" (click)="navigate()">{{'order_Now' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
</div>
</div>    
</div>



<div class="whyrep-box">
<div class="icon">
<img src="../assets/images/six-months-warranty.png">
</div>
<div class="text">
<h3>{{'3_Months_Warranty' | translate}}</h3>
<div class="row">
<div class="col-md-6">
<div class="order-now-class" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
<div class="col-md-6">
<div class="order-now-class" (click)="navigate()">{{'order_Now' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
</div>
</div>    
</div>    



<div class="whyrep-box">
<div class="icon">
<img src="../assets/images/unbeatable-price.png">
</div>
<div class="text">
<h3>{{'unbeatable_Price' | translate}}</h3>
<div class="row">
<div class="col-md-6">
<div class="order-now-class" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
<div class="col-md-6">
<div class="order-now-class" (click)="navigate()">{{'order_Now' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
</div>
</div>    
</div>    


<div class="whyrep-box">
<div class="icon">
<img src="../assets/images/scheduled-pickups.png">
</div>
<div class="text">
<h3>{{'scheduled_pickups_and_drops' | translate}}</h3>
<div class="row">
<div class="col-md-6">
<div class="order-now-class" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
<div class="col-md-6">
<div class="order-now-class" (click)="navigate()">{{'order_Now' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
</div>
</div>    
</div>    


<div class="whyrep-box">
<div class="icon">
<img src="../assets/images/support.png">
</div>
<div class="text">
<h3>{{'24x7_support' | translate}}</h3>
<div class="row">
<div class="col-md-6">
<div class="order-now-class" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
<div class="col-md-6">
<div class="order-now-class" (click)="navigate()">{{'order_Now' | translate}}
<mat-icon>keyboard_arrow_right</mat-icon></div>
</div>
</div>
</div>    
</div>    


</div>
</div>

</div>
</div>
</div>
</section>





<div fxLayout="column" fxLayoutAlign="space-between " fxLayoutGap="40"
    class="every-repair-section every-repair-section1 margin-top10 bg-color-annual-section">
    <div fxLayout="row" class="padding-top20" fxLayoutAlign="space-between center">
        <h1 class="every-repair-heading absoulte-position">{{'annual_Protection_Plans' | translate}}</h1>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="every-repair-heading-small absoulte-position">{{'live_without_worrying_about_repairs' | translate}}
        </h2>
    </div>
    <div class="container">
        <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayout.xs="column" fxLayout.md="column"
            fxLayout.sm="column" class="padding40">
            <div fxLayout="column" class="padding10" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutAlign="space-evenly start" class="card-annual-protection">
                    <img class="img2" src="../../../assets/images/peace-of-mind.png">
                    <div class="card-font-1">{{'peace_of_mind' | translate}}</div>
                    <div class="text">{{'when_youre_off_the_grid' | translate}}</div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigate()">{{'order_Now' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                    </div>
                </div>

            </div>

            <div fxLayout="column" class="padding10" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutAlign="space-evenly start" class="card-annual-protection">
                    <img class="img2" src="../../../assets/images/doorstep.png">
                    <div class="text"> <span class="card-font-1">{{'doorsteps' | translate}}</span> <br>{{'repairs' |
                        translate}}</div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigate()">{{'order_Now' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="padding10" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutAlign="space-evenly start" class="card-annual-protection">
                    <img class="img2" src="../../../assets/images/water-damage.png">
                    <div class="text"><span class="card-font-1">{{'water_damage' | translate}}</span> <br>{{'covered' |
                        translate}}* </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigate()">{{'order_Now' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="padding10" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutAlign="space-evenly start" class="card-annual-protection">
                    <img class="img2" src="../../../assets/images/physical-damage.png">
                    <div class="text"> <span class="card-font-1">{{'physical_damage' | translate}}</span>
                        <br>{{'covered' | translate}}* </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigate()">{{'order_Now' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                    </div>
                </div>
            </div>

            <div fxLayout="column" class="padding10" fxLayoutAlign="space-evenly center">
                <div fxLayout="column" fxLayoutAlign="space-evenly start" class="card-annual-protection">
                    <img class="img2" src="../../../assets/images/validity.png">
                    <div class="text"> <span class="card-font-1">{{'valid_for' | translate}}</span> <br>{{'year' |
                        translate}}* </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigateToSchedulePickups()">{{'learn_More' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                        <div class="col-md-6">
                            <div class="order-now-class1" (click)="navigate()">{{'order_Now' | translate}}
                                <mat-icon>keyboard_arrow_right</mat-icon></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20"
    class="every-repair-section bg-color-annual-section " style="padding-bottom: 0;">

    <!-- <div fxLayout="row" class="padding-top30" fxLayoutAlign="space-between center">
        <h1 class="every-repair-heading absoulte-position" style="margin-bottom: 40px;">
            {{'choose_a_plan_that_suits_your_needs' | translate}}</h1>
    </div> -->

    <app-plans class="plan-space"></app-plans>




    <section class="middle-sec1">
        <div class="container">
            <div class="row">
                <div class="col-md-12 heading">
                    <h1>{{'Why_People_Trust_Us' | translate}}</h1>
                </div>

                <div class="col-md-6">
                    <div class="trustmain">
                        <div class="img">
                            <img src="../assets/images/why-star.png">
                        </div>
                        <div class="text">
                            <h4>{{'One_stop_Solution' | translate}}</h4>
                            <p>{{'accessorize_your_smartphone' | translate}}</p>
                        </div>
                    </div>

                    <div class="trustmain">
                        <div class="img">
                            <img src="../assets/images/why-star.png">
                        </div>
                        <div class="text">
                            <h4>{{'Trained_Professionals' | translate}}</h4>
                            <p>{{'Trusted_experts' | translate}}</p>
                        </div>
                    </div>

                    <div class="trustmain">
                        <div class="img">
                            <img src="../assets/images/why-star.png">
                        </div>
                        <div class="text">
                            <h4>{{'Amazing_Prices' | translate}}</h4>
                            <p>{{'love_our_prices' | translate}}</p>
                        </div>
                    </div>

                    <div class="trustmain">
                        <div class="img">
                            <img src="../assets/images/why-star.png">
                        </div>
                        <div class="text">
                            <h4>{{'quick_Hassle_free' | translate}}</h4>
                            <p>{{'get_mobile_care' | translate}}</p>
                        </div>
                    </div>

                    <div class="trustmain">
                        <div class="img">
                            <img src="../assets/images/why-star.png">
                        </div>
                        <div class="text">
                            <h4>{{'Premium_Products' | translate}}</h4>
                            <p>{{'high_quality_products' | translate}}</p>
                        </div>
                    </div>

                </div>

                <div class="col-md-5">
                    <div class="why-trust">
                        <img src="../assets/images/why-trust-us.png" style="width: 100%;">
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="three-boxes middle-sec">
        <div class="container">
            <div class="row">
                <div class="col-md-12 heading">
                    <h1>{{'Testimonials' | translate}}</h1>
                </div>
            </div>
            <div id="slider">
                <input type="radio" name="slider" id="slide1" checked>
                <input type="radio" name="slider" id="slide2">
                <input type="radio" name="slider" id="slide3">
                <div id="slides">
                    <div id="overflow">
                        <div class="inner">
                            <div class="slide slide_1">
                                <div class="slide-content">
                                    <p>{{'testimonial_slide_1' | translate}}</p>
                                    <div class="test-bot">
                                        <div class="img"><img src="../assets/images/rj-aman.jpg"></div>
                                        <div class="text">
                                            <h4>{{'Rj_Aman' | translate}}</h4>
                                            <p>{{'Radio_Jockey' | translate}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="slide slide_2">
                                <div class="slide-content">
                                    <p>{{'testimonial_slide_2' | translate}}</p>
                                    <div class="test-bot">
                                        <div class="img"><img src="../assets/images/vishal-jain.jpg"></div>
                                        <div class="text">
                                            <h4>{{'Vishal_Jain' | translate}}</h4>
                                            <p>{{'Youtube_Influencer' | translate}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="slide slide_3">
                                <div class="slide-content">
                                    <p>{{'testimonial_slide_3' | translate}}</p>
                                    <div class="test-bot">
                                        <div class="img"><img src="../assets/images/gaurav-jain.jpg"></div>
                                        <div class="text">
                                            <h4>{{'Gaurav_Singh' | translate}}</h4>
                                            <p>{{'Professor' | translate}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="controls">
                    <label for="slide1"></label>
                    <label for="slide2"></label>
                    <label for="slide3"></label>
                </div>
                <div id="bullets">
                    <label for="slide1"></label>
                    <label for="slide2"></label>
                    <label for="slide3"></label>
                </div>
            </div>
        </div>
    </section>


    <section class="middle-sec1">
        <div class="container">
            <div class="row">
                <div class="col-md-12 heading">
                    <h1>{{'faq' | translate}}</h1>
                </div>

                <div class="col-md-12">
                    <div class="faq">

                        <div class="acc-kontainer">
                            <div>
                                <input type="radio" name="acc" id="acc1" [ngClass]="{ 'active': isActive('One') }"
                                    (click)="setActive('One')">
                                <label for="acc1">{{'how_is_Repairing_company' | translate}}</label>
                                <div class="acc-body" *ngIf="isActive('One')">
                                    {{'repairing_company_is_different' | translate}}
                                    <ul>
                                        <li>{{'genuine_products' | translate}} </li>
                                        <li>{{'Our_Guaranteed_period' | translate}}</li>
                                        <li>{{'unbeatable_price' | translate}}</li>
                                        <li>{{'Scheduled_pickups' | translate}}</li>
                                        <li>{{'support_for_queries' | translate}}</li>
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <input type="radio" name="acc" id="acc2" [ngClass]="{ 'active': isActive('Two') }"
                                    (click)="setActive('Two')">
                                <label for="acc2">{{'Set_up_An_Account' | translate}}</label>
                                <div class="acc-body" *ngIf="isActive('Two')">
                                    {{'set_up_your_Repairing_company' | translate}}
                                    <ul>
                                        <li>{{'click_on_it' | translate}}</li>
                                        <li>{{'You_will_receive_an_otp' | translate}} </li>
                                        <li>{{'After_verifying_the_otp' | translate}}</li>
                                    </ul>
                                    <p>{{'After_that_you_are_all_set' | translate}}</p>
                                    <p>{{'From_the_screen' | translate}}</p>
                                </div>
                            </div>
                            <div>
                                <input type="radio" name="acc" id="acc3" [ngClass]="{ 'active': isActive('Three') }"
                                    (click)="setActive('Three')">
                                <label for="acc3">{{'What_are_the_payment_modes' | translate}}</label>
                                <div class="acc-body" *ngIf="isActive('Three')">
                                    {{'You_can_pay' | translate}}
                                </div>
                            </div>

                            <div>
                                <input type="radio" name="acc" id="acc4" [ngClass]="{ 'active': isActive('Four') }"
                                    (click)="setActive('Four')">
                                <label for="acc4">{{'What_is_the_warranty' | translate}}</label>
                                <div class="acc-body" *ngIf="isActive('Four')">
                                    {{'We_offer' | translate}}
                                    {{'To_claim_your' | translate}}
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </div>
    </section>
    
</div>


