<section class="header-banner">
    <div class="container">
       <div class="col-md-11">
          <h1>Repairing Company Refund Policy</h1>
          <h4 class="font">Repairing Company Repair offers you a 6-month warranty along with a refund. Here is how to request a refund for the Screen to be replaced for you:</h4>
         
       </div>
    </div>
 </section>
<section class="middle-sec">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
           
           <b>Under the following circumstances, we offer a 6-month warranty with a refund for each repair or replacement :</b>
            <ul class="margin-top20">
               <li class="margin-top10">The defect that malfunctioned, or does not work as intended, or was designed and was repaired by repairing company technical staff.</li>
               <li class="margin-top10">Any paid repair performed by the technical staff of the repairing company does not function properly.</li>
               <li class="margin-top10">Any display issues applicable in mobile phones & Laptops might develop automatically and are associated with the quality of the screen, particularly dead pixels and touch problems for repairs carried out by repair company technical staff.</li>
            </ul>
        </div>
      </div>
   </div>
</section>
<section class="middle-sec1">
   <div class="container">
      <div class="row">
         <div class="col-md-11">
            <b>You may request a brand-new screen with a 6-month extended warranty if the screen we replaced causes any of the problems identified, or return our screen for a refund of your screen within 15 days of the service date. All that is required is:</b>
            <ul>
                <li class="margin-top10">In case of screen repairs, Send the video of the phone with the prevailing display issue to “support@repairingcompany.com”</li>
                <li class="margin-top10">In case of other services, Register a ticket by going to support in the mobile app or on the website.</li>
                <li class="margin-top10">Send us your phone number/order number/IMEI Number. Just about any of it to support@repairingcompany.com</li>
                <li class="margin-top10">Our Customer Service Associate will respond to you within 48 to 72 hours and schedule our technician visit to replace your place for the issue found!</li>
            </ul>
            <p>The parts and/or services that were purchased are the only things covered by the warranty and refund. The warranty and refund are only good for the replacement of the parts if only parts were purchased. If parts and repair services were purchased, the warranty also covers any additional repairs that are specifically caused by the initial repair, including labour costs for replacing any damaged parts.</p>
            <b>** Please be aware that slot charges only apply if an order is fulfilled. Refunds are only available on Service prices.</b>
        </div>
      </div>
   </div>
</section>
<section class="middle-sec">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <h2>Cancellations:</h2>
            <ul>
                <li class="margin-top10">All the orders can be canceled via the Website or mobile app until the device is not serviced.</li>
                <li class="margin-top10">Every successful cancellation results in a 100% refund in 48 to 72 hours.</li>
                <li class="margin-top10">In order to cancel the order, Follow the below steps:
                    <ol>
                        <li>Login to the Website or mobile app</li>
                        <li>Go to My Account> My orders</li>
                        <li>Select the order you want to cancel</li> 
                        <li>Cancel the order</li>                   
                    </ol>
                </li>
                <li class="margin-top10">In case of any issues, Register a support ticket via the Mobile app or website or  send an email to “support@repairingcompany.com”</li>
            </ul>
         </div>
      </div>
   </div>
</section>