export function token(): any
{
    let token:any = localStorage.getItem('access_token')?.split('.')[1];
    let parsed_token = JSON.parse(window.atob(token));
    if(parsed_token.authorities != undefined)
    {
        return parsed_token.authorities;
    }
}

export function hasPermission(Permission:any):boolean
{
  if(localStorage.getItem('access_token') != undefined &&  token() != undefined)
  {
    if(token().includes(Permission))
    {
      return true;
    }
  }
  return false;
}


export function hasAnyPermission(Permission:any)
{ 
  if(localStorage.getItem('access_token') != undefined &&  token() != undefined)
  {
   return token().some( ai => Permission.includes(ai) );
  }
  return false;
}
