import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

export interface Element {
    specification:string;
    iPhone_14_Pro: string;
    iPhone_4_Pro_Plus: string;
  }
  
  const ELEMENT_DATA: Element[] = [
    {specification:'Starting price', iPhone_14_Pro: '$799', iPhone_4_Pro_Plus: '$899'},
    {specification:'Screen size', iPhone_14_Pro: '6.1 inches', iPhone_4_Pro_Plus: '6.7 inches'},
    {specification:'Refresh rate', iPhone_14_Pro: '60Hz', iPhone_4_Pro_Plus: '60Hz'},
    {specification:'CPU', iPhone_14_Pro: 'Apple A15 Bionic', iPhone_4_Pro_Plus: 'Apple A15 Bionic'},
    {specification:'Storage', iPhone_14_Pro: '128GB, 256GB, 512GB', iPhone_4_Pro_Plus: '128GB, 256GB, 512GB'},
    {specification:'Cameras', iPhone_14_Pro: 'Dual 12MP (Wide, ultrawide)', iPhone_4_Pro_Plus: 'Dual 12MP (Wide, ultrawide)'},
    {specification:'Size', iPhone_14_Pro: '5.78 x 2.82 x 0.31 inches', iPhone_4_Pro_Plus: '6.33 x 3.07 x 0.31 inches'},
    {specification:'Weight', iPhone_14_Pro: '6.07 ounces)', iPhone_4_Pro_Plus: '7.16 ounces'},
    {specification:'Colors', iPhone_14_Pro: 'Purple, blue, midnight, starlight, Product RED', iPhone_4_Pro_Plus: 'Purple, blue, midnight, starlight, Product RED'}
];
  
@Component({
    selector: 'iphone14vsiphone14plus',
    templateUrl: './iphone14vsiphone14plus.html'
})

export class Iphone14vsIphone14PlusComponent{
    displayedColumns: string[] = ['specification', 'iPhone_14_Pro', 'iPhone_4_Pro_Plus'];
    dataSource = ELEMENT_DATA;
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Get a sneak peek at the upcoming iPhone 14 and iPhone 14 Plus with this comparison guide. Discover the features and specs that set these two models apart."})
        this.metaService.updateTag({name:'keywords',content:'iPhone 14, iPhone 14 Plus, Repairing Company, Specifications, Features'})
        this.canonicalServide.setCanonicalURL();
       
        this.translate.get('iPhone_14_vs_iPhone_14_Plus').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('iPhone_14_vs_iPhone_14_Plus').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        });  
        
    }   
}
