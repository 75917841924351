<section class="middle-sec contact-section ">
    <div class="container">
        <div class="row">
            <div class="col-lg-5 col-md-12 col-sm-12 content-column">
                <div class="content_block_5">
                    <div class="content-box">
                        <div class="sec-title">
                            <p>Contact Us</p>
                            <h2>GET IN TOUCH</h2>
                        </div>
                        <ul class="social-links clearfix" style="padding-left: 0;padding-top: 15px;">
                            <li><a (click)="navigateTofacebook()"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li><a (click)="navigateToinstagram()"><i class="fa fa-instagram"></i></a></li>
                            <li><a (click)="navigateTolinkedin()"><i class="fa fa-linkedin"></i></a></li>
                            <li><a (click)="navigateToyouTube()" style="background: #e80303;"><i class="fa fa-play"
                                        style="padding-left: 5px;"></i></a></li>
                            <li><a (click)="navigateTotwitter()"><i class="fa fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-inner">
                    <form class="example-form" [formGroup]="contactForm">
                        <div class="row">

                            <div class="col-md-12">
                                <mat-form-field
                                    class="example-full-width select-name mat-form-fileld mat-form-field-appearance-fill">
                                    <input matInput placeholder="Full Name" formControlName="fullName">
                                </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                <mat-form-field
                                    class="example-full-width select-name mat-form-fileld mat-form-field-appearance-fill">
                                    <input matInput placeholder="Email" formControlName="email">
                                </mat-form-field>
                            </div>

                            <div class="col-md-6">
                                <mat-form-field
                                    class="example-full-width select-name mat-form-fileld mat-form-field-appearance-fill">
                                    <input matInput placeholder="Phone Number" formControlName="phoneNumber">
                                </mat-form-field>
                            </div>

                            <div class="col-md-12">
                                <mat-form-field
                                    class="example-full-width select-name mat-form-fileld mat-form-field-appearance-fill">
                                    <input matInput placeholder="Address" formControlName="address">
                                </mat-form-field>
                            </div>

                            <div class="col-md-12">
                                <mat-form-field
                                    class="example-full-width select-name mat-form-fileld mat-form-field-appearance-fill">
                                    <textarea matInput placeholder="Message" class="textareaheight"
                                        formControlName="message"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <div class="order-now-btn">
                                    <div class="col-lg-12 col-md-12 col-sm-12 form-group message-btn">

                                        <button class="main-button" id="save_contact" type="submit" name="submit-form"
                                            (click)="saveContactDetails()" fdprocessedid="jxts7p">Submit Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sec-contact">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="single-info-box">
                    <div class="inner-box app-icon">
                        <div class="icon-box"><i class="fa fa-map-marker" aria-hidden="true"></i></div>
                        <h3>Download Our App</h3>
                        <div fxLayout="row" fxLayoutGap="10" class="bold-footer-heading">
                            <a class="cursor-a" (click)="navigateToplayStore()">
                                <div><img class="icon-footer-playstore" src="../../../assets/images/android-app.png">
                                </div>
                            </a>
                            <a class="cursor-a" (click)="navigateToAppStore()">
                                <div><img class="icon-footer-apple" src="../../../assets/images/app-store.png"></div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="single-info-box">
                    <div class="inner-box">
                        <div class="icon-box"><i class="fa fa-phone" aria-hidden="true"></i></div>
                        <h3>Phone</h3>
                        <p><a href="tel: 01143015881  ">011-43015881</a><br></p>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <div class="single-info-box">
                    <div class="inner-box">
                        <div class="icon-box"><i class="fa fa-envelope" aria-hidden="true"></i></div>
                        <h3>Email</h3>
                        <p><a href="mailto:support@repairingcompany.com"> Support@RepairingCompany.com </a><br></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>