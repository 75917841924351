import { BrandModel } from '../brand-model/brand.model'
import { DevicePlanModel } from '../device-plans-model/device-plans.model';

export class DeviceModel {
    modelId?: number;
    modelName?: string;
    modelYear?: number;
    brandDto?: BrandModel = {};
    enabled?: boolean;
    isSelected?: boolean = false;
    planList?: DevicePlanModel[];
    modelImageUrl?: string;
}