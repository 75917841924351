
import { Component, HostBinding, Inject, OnInit, PLATFORM_ID, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from './abstract-components/base.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BotModel, LanguageModel } from './data/language-model/language-model';
import { LoginSendOTPDialogDialog } from './ui/dialogs/login-sendOTP-dialog/login-sendotp-dialog';
import { trigger, transition, style, animate } from '@angular/animations';
import { AppRouteConstants } from './constants/app-route.constants';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import * as util from './utility-functions'
import { SafeResourceUrl, Title } from '@angular/platform-browser';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import * as html2pdf from 'html2pdf.js';
declare var $: any; // Declare the global $ function
import { environment } from 'src/environments/environment';
import { io } from 'socket.io-client';
declare var intlTelInput: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('openClose', [
      transition('void => *', [
        style({
          height: 0,
          opacity: 0,
          transform: 'scale(0.85)',
          'margin-bottom': 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0
        }),
        animate('50ms', style({
          height: '*',
          'margin-bottom': '*',
          paddingTop: '*',
          paddingBottom: '*',
          paddingRight: '*',
          paddingLeft: '*',
        })),
        animate(70)
      ]),
    ]),

  ]
})
export class AppComponent extends BaseComponent implements OnInit, AfterContentChecked {
  chatBotEnabled = environment.chatBotEnabled
  chatBotUrl = environment.chatBotUrl
  safeImageUrl: SafeResourceUrl;
  count = 0;
  state = false;
  messages = [new BotModel()];
  question = [];
  public sanitizedScript: SafeHtml;
  showlogoutbtn: any = false;
  toggleControl = new FormControl(false);
  @HostBinding('class') className = '';
  name: any
  role: any;
  isLoggedIn: boolean = false;
  selectedLang: string = 'en';
  selectedIcon: string = "Dark_Mode";
  name1: any
  hideloginbtn: boolean = false
  hidelogoutbtn: boolean = false;
  emailId: any
  userId: any;
  token: any;
  parsed_token: any;
  dialogReflogged: any;
  isPreviousLoggedIn = false;
  utility: any = util;
  questionPatternStr = "Click to see details."
  hiPatternStr = "Check your Order"
  yourPhoneNumberStr = "What is your Phone Number?"
  getrefund = "Get refund."
  editPatternStr = "Edit your Order."
  raiseAnIssuePatternStr = "Raise an issue."
  ourCustomerServiceStr = "Our customer service department is looking"
  liveAgentStr = " has entered the conversation."
  modelarrstr = "Please select a model"
  mobilearrstr = "Please select your mobile"
  tableissue = "Issues found:"
  textContent: any;


  counterPhoneId: any = 0
   imagesCountid: any = 0
  sub_option_for_order_list: any = []
  support_options: any = []
  selectedValuesmain: any = [];
  selectedValuesmainforUpdate: any = [];


  languageList: LanguageModel[] = [
    { "languageCode": "en", "languageName": "English", "languageFlagClass": "fi fi-us" },
    { "languageCode": "hi", "languageName": "हिन्दी", "languageFlagClass": "fi fi-in" },
  ];

  constructor(private userService: UserService, private sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: any, @Inject(DOCUMENT) private document,
    private overlay: OverlayContainer,
    public loginDialog: MatDialog,
    snackBar: MatSnackBar,
    router: Router,
    private translation: TranslateService, private changeDetector: ChangeDetectorRef, public authService: AuthService, private titleService: Title) {
    super();
    this.snackBar = snackBar;
    this.router = router;
    this.safeImageUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.chatBotUrl);

    if (localStorage.getItem('lang') == undefined) {
      this.translation.setDefaultLang('en');
      localStorage.setItem('lang', 'en');
      this.selectedLang = 'en';
      this.translation.use(localStorage.getItem('lang') || 'en');
    } else {
      this.translation.use(localStorage.getItem('lang') || 'en');
      this.selectedLang = localStorage.getItem('lang') || 'en'!.toString();
    }
    this.token = localStorage.getItem('access_token')?.split('.')[1];
    // this.translation.get('repairing_Company').subscribe((res: string) => {
    //   this.titleService.setTitle(res);
    // });
    // this.translation.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.translation.get('repairing_Company').subscribe((res: string) => {
    //     this.titleService.setTitle(res);
    //   });
    // });
  }

  changeLang(value: any) {
    localStorage.setItem('lang', value);
    this.translation.use(value);
    this.selectedLang = localStorage.getItem('lang')!.toString();
    this.userService.getLang(this.selectedLang);
  }
  hidedarkicon: boolean = false;
  changeTheme() {
    let darkMode: boolean = false;
    if (this.selectedIcon === 'Dark_Mode') {
      this.selectedIcon = 'Light_Mode'
      this.hidedarkicon = true
      darkMode = true;
    } else {
      this.selectedIcon = 'Dark_Mode'
      this.hidedarkicon = false
    }
    const darkClassName = 'darkMode';
    this.className = darkMode ? darkClassName : '';
    if (darkMode) {

      this.overlay.getContainerElement().classList.add(darkClassName);
    } else {

      this.overlay.getContainerElement().classList.remove(darkClassName);
    }
  }





  openDialog(): void {
    this.dialogReflogged = this.loginDialog.open(LoginSendOTPDialogDialog, {
      width: '350px',
      height: '590px',
      data: {},
      disableClose: true
    });

    this.dialogReflogged.afterClosed().subscribe((result: any) => {
      if (result != null && localStorage.getItem('access_token') != null) {
        this.userService.getusernameonlogin(result);
        this.hideloginbtn = true;
        this.showlogoutbtn = true;
        if (result[2] != null) {
          this.name1 = result[1];
          this.emailId = result[2];
        }

        this.isPreviousLoggedIn = true;
        this.goldOrSilverUser();
      }

    });

  }

  goldOrSilverUser() {
    this.userService.goldOrSilverUser().subscribe({
      next: (res) => {
        if (res == true) {
          localStorage.setItem("membership", "Gold Membership");
        } else {
          localStorage.setItem("membership", "Silver Membership");
        }
      },
      error: (error) => { },
    });
  }
  storedmsg: any
  socket: any
  ngOnInit() {
    this.chatBotUrl = environment.chatBotUrl
    this.userService.listen().subscribe((m1: any) => {
      if (m1 != null) {
        this.name1 = m1
      }
    });
    this.userService.listen().subscribe((m: any) => {
      if (m != null) {
        this.name1 = m[1];
        this.emailId = m[2];
        this.hideloginbtn = true
        this.showlogoutbtn = true;
        this.token = localStorage.getItem('access_token')?.split('.')[1];
        this.parsed_token = JSON.parse(window.atob(this.token));

      }
    });

    setInterval(() => {
      if (localStorage.getItem('access_token') != null) {
        if (this.authService.isTokenExpired(localStorage.getItem('access_token'))) {
          this.authService.logOut();
          this.hideloginbtn = false
          this.showlogoutbtn = false;
          this.isPreviousLoggedIn = false;
        }
      }
    }, 100);

    if (localStorage.getItem('access_token') != null) {
      this.hideloginbtn = true;
      this.name1 = localStorage.getItem('name');
      this.emailId = localStorage.getItem('emailId');
      this.token = localStorage.getItem('access_token')?.split('.')[1];
      this.parsed_token = JSON.parse(window.atob(this.token));
      if (this.parsed_token.authorities != undefined) {

      }

      this.hidelogoutbtn = false;
      this.showlogoutbtn = true;

    } else {
      this.showlogoutbtn = false;
      this.hideloginbtn = false;
      this.hidelogoutbtn = true;
      this.isPreviousLoggedIn = false;
    }

  }

  logout() {
    this.authService.loggedUser = ''
    this.authService.removeTokens();
    this.router.navigate(['']);
    this.snackBar.open('Logged Out Successfully', '', { duration: 2000, panelClass: ['blue-snackbar'] });
    this.hideloginbtn = false
    this.showlogoutbtn = false;
    this.isPreviousLoggedIn = false;
  }

  Home() {
    this.router.navigate([''])
  }
  navigateToAbout() {
    this.router.navigate([AppRouteConstants.aboutUs]);
  }
  navigateToContact() {
    this.router.navigate([AppRouteConstants.contact]);
  }
  navigateToBlogs() {
    this.router.navigate([AppRouteConstants.blogs]);
  }
  navigateToManageUsers() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.userManagement]);
  }
  navigateToManageRoles() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.roleManagement]);
  }
  navigateToManageSupportTicket() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.supportTicketManagement]);
  }
  navigateToManageApproveVendors() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.approveVendors]);
  }
  navigateToManageProductCommission() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.manageProductCommissions]);
  }
  navigateToCreateOrder() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.createorder]);
  }
  navigateToServiceCenter() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.serviceCenterRoute]);
  }
  navigateToServiceArea() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.service_area_route]);
  }
  navigateToDeviceIssue() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.DEVICE_ISSUE_LISTING_ROUTE]);
  }
  navigateToActionTracker() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.APP_ACTION_TRACKER]);
  }
  navigateToDashboard() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.DASHBOARD_ROUTE]);
  }
  navigateToOpenCases() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.open_case]);
  }
  navigateToMyAccount() {
    this.router.navigate([AppRouteConstants.my_account]);
  }
  navigateToVendorsDashboard() {
    this.router.navigate([AppRouteConstants.admin + '/' + AppRouteConstants.vendorsDashboard]);
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}


