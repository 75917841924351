export class UserAddressModel {

    addressId?: number;
    personName?: string;
    mobileNumber?: number;
    addressLine1?: string;
    addressLine2?: string;
    landMark?: string;
    pincode?: number;
    primaryAddress?:boolean;
    pincodeServiceable?: boolean;
    state?: string;
    city?: string;
    isSelected?: boolean = false;
    timeSlot?: TimeSlotModel;
    userId?:any
}

export class TimeSlotModel {
    date?: Date;
    slot?: number;
}