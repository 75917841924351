<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h2 class="repair-heading-body uppercase">{{'compare_plans' | translate}}</h2>
    <div class="cursor-pointer padding0" (click)="onNoClick()"><mat-icon>close</mat-icon></div>
</div>
<div fxLayout="row" id="recommended" fxLayoutAlign="space-evenly center" fxLayout.xs="column" fxLayout.md="column" fxLayout.sm="column" >

    <div fxLayout="column" class="padding20" fxLayoutAlign="space-evenly center">
        <div class="hide-recommended"></div>
        <div fxLayout="column" fxLayoutAlign="space-between start" class="plan-card firstplan">
            <div class="red-upper">
                {{'extended_Warranty' | translate}}
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between center" class="plan-card-body-heading">
            <h2>199</h2>    
                <div>{{'rs' | translate}} 199</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" class="plan-card-body">
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'100_wear_and_tear_repairs' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'100_money_back_guarantee' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'scheduled_pickups_and_drops' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'real_time_case_status' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'24x7_support' | translate}}</span></div>
            </div>
            <div class="order-now-btn buy-btn-below">
                <button class="order-btn" (click)="NavigateToPlansPage('extended_Warranty')">{{'buy' | translate}}</button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="padding20" fxLayoutAlign="space-evenly center">
        <div class="recommended">{{'recommended' | translate}}</div>
        <div fxLayout="column" fxLayoutAlign="space-between start" class="plan-card">
            <div class="red-upper">
                {{'shield_plan' | translate}}
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between center" class="plan-card-body-heading">
                <h2>1199</h2>      
                <div>{{'rs' | translate}} 1199</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" class="plan-card-body">
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'covers_physical_damage' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'covers_Water_damage' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon> <span class="margin-left5">{{'doorstep_Service' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'loaner_phone_during_repair' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'100_money_back_guarantee' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'real_time_case_status' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'24x7_support' | translate}}</span></div>
            </div>
            <div class="order-now-btn buy-btn-below">
                <button class="order-btn" (click)="NavigateToPlansPage('shield_plan')">{{'buy' | translate}}</button>
            </div>
        </div>
    </div>

    <div fxLayout="column" class="padding20" fxLayoutAlign="space-evenly center">
        <div class="hide-recommended"></div>
        <div fxLayout="column" fxLayoutAlign="space-between start" class="plan-card">
            <div class="red-upper">
                {{'assistance_Plan' | translate}}
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between center" class="plan-card-body-heading">
                <h2>999</h2>      
                <div>{{'rs' | translate}} 999</div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-between start" class="plan-card-body">
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'free_pickup_from_home' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'drops_to_Service_Centers' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'100_money_back_guarantee' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'scheduled_pickups_and_drops' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'real_time_case_status' | translate}}</span></div>
                <div><mat-icon class="padding-top0imp">check</mat-icon><span class="margin-left5">{{'24x7_support' | translate}}</span></div>
            </div>
            <div class="order-now-btn buy-btn-below">
                <button class="order-btn" (click)="NavigateToPlansPage('assistance_Plan')">{{'buy' | translate}}</button>
            </div>
        </div>
    </div>
</div>
