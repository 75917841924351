import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppConstants } from '../constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private readonly JWT_TOKEN = 'access_token';
  private readonly MERCHANT_STATIC_CONFIG = 'MERCHANT_STATIC_CONFIG';
  private readonly name = 'name';
  private readonly emailId = 'emailId';
  private readonly userId = 'userId';
  private readonly role = 'role';
  private readonly user_name = 'user_name';

  public loggedUser !: string;
  httpHeaders: any;
  protected dialog!: MatDialog;
  constructor(private http: HttpClient,dialog: MatDialog,public router:Router,public snackBar: MatSnackBar) {
    this.dialog=dialog;
   }

  
  // to make login call on mobimech with username and pasword, authorization basic auth call.
  // login(user:{username:string,password:string}):Observable<boolean>{
  //   const url = AppConstants.BASE_URL + AppConstants.authServiceUrl + AppConstants.login;
  //   const headers = new HttpHeaders({
  //   'Content-Type':  'application/json',
  //   'Authorization': 'Basic ' + btoa(user.username + ':' + user.password),
  //   'X-Requested-With': 'XMLHttpRequest'})

  //   return this.http.post<any>(url,null,{
  //     headers: headers
  //   }).pipe(tap((tokens) => this.doLoginUser( user.username,tokens)),
  //   mapTo(true),
  //   catchError((error)=>{
  //     console.log(error.error);
  //     return of(false);
  //   }));
  // }
  loginbyOTP(mobileNumber:string,OTP:string):Observable<any>{
    return this.http.get(AppConstants.userService +  AppConstants.verifyOtp + '/' + OTP + '/'+ mobileNumber, 
    { headers: this.httpHeaders });
 }

  // to store the data
  private doLoginUser(username:string,tokens:any){

    this.loggedUser = username;
    this.storeTokens(tokens);
    
  }

  loginByGoogle(email:string):Observable<any>{
    return this.http.get(AppConstants.userService +  AppConstants.loginWithGoogle + '/' + email,
    { headers: this.httpHeaders });
  }
  
  // to store tokens
  private storeTokens(tokens:any){
    localStorage.setItem(this.JWT_TOKEN,tokens.access_token);
  }

  // to remove tokens
  public removeTokens(){
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.MERCHANT_STATIC_CONFIG);
    localStorage.removeItem(this.name);
    localStorage.removeItem(this.emailId);
    localStorage.removeItem(this.userId);
    localStorage.removeItem(this.role);  
    localStorage.removeItem(this.user_name);
  }
  // just to logout
    logOut(){
    this.loggedUser = '';
    this.removeTokens();
    this.router.navigate(['']);
    this.snackBar.open('Your session has Timed out, Please Login Again!!','',{duration:2000,panelClass: ['blue-snackbar']});
  }

  // to get jwt for localstorage
  getJWTToken(){
    return localStorage.getItem(this.JWT_TOKEN);
  }

  // to check if user is logged in or not
  isLoggedIn(){
    return !!this.getJWTToken();
  }
  
  isTokenExpired(token: any) {
    var expiry:any = null;
    if(token != null)
    {
      expiry = (JSON.parse(window.atob(token.split('.')[1])))?.exp;
    }
   return expiry*1000 < Date.now();
  }

}

