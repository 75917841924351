import { Component, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppRouteConstants } from '../constants/app-route.constants';
import { OrderDetailsService } from '../services/order-details.service';
import { ErrorDisplayDialogPaytmComponent } from '../ui/dialogs/error-display-dialog-paytm/error-display-dialog-paytm.component';
import { ErrorDisplayDialogComponent } from '../ui/dialogs/error-display-dialog/error-display-dialog.component';
import { LoaderDialogComponent } from '../ui/dialogs/loader-dialog/loader-dialog.component';
import { MessageDisplayDailogComponent } from '../ui/dialogs/message-display-dailog/message-display-dailog.component';

/**
 * class to hold common functionlaity for components 
 * throughout the customer app.
 */
@Component({
    template: '<div></div>'
})
export abstract class  BaseComponent implements OnDestroy{
    protected dataManager: OrderDetailsService
    protected router!: Router;
    protected snackBar!: MatSnackBar;
    protected route!: ActivatedRoute;
    protected id!: String;
    protected dialog!: MatDialog;

    protected dialogRef!: MatDialogRef<any>;
    requiredField = new FormControl('', [Validators.required]);
    numberOnlyField = new FormControl('', [Validators.pattern('^[0-9]{4}$')]);
    /**
     * var to attach event bus to
     * component lifecycle
     */
    protected eventBusSubcription!: Subscription;

    createAppStateObject(status:any, metaData:any, sessionId:any, order_id = null) { 
        return {
                "metaData": JSON.stringify(metaData),
                "status": status,
                "sessionId": sessionId,
                "orderId": order_id
            };
    }

    openLoaderDialog() {
        this.dialogRef = this.dialog.open(LoaderDialogComponent, {
            disableClose: true
          });
    }
    closeLoaderDialog() {
        this.dialogRef.close()
    }
    /**
     * func to fetch and set id from url
     */
    protected fetchIdFromQueryParams() {
        this.route.queryParams.subscribe(params => {
            this.id = params['id'];
          });
          
        return this.id ? true: false;
    }
    /**
     * func to route components
     * @param route 
     * @param options 
     */
    protected routeTo(route:string, options: NavigationExtras) {
        if (options != null && options != undefined) 
            this.router.navigate([route], options)
        else
            this.router.navigate([route])
        
    }

    /**
     * function to clear all generic calls
     * when component is destroyed
     */
    ngOnDestroy(): void {
        if (this.eventBusSubcription)
            this.eventBusSubcription.unsubscribe();
    }

    /**
     * will return true if
     * error casewill be handled by this function
     * will check 403, 401, etc auth and other
     * errors not related to api call data
     * @param error 
     */
    protected errorHandler(error: any) {
        if(typeof error === 'string') {
            console.log('instance of error in string', error);
            error = JSON.parse(error);
            return true;
        }
        if (error.status === 401 || error.status === 403) {
            // create session expire flow
            error.error.message = "Unauthorized";
            return true;
        } else if (error.status === 404) {
            // api not found handler
            error.error.message = "Something Went Wrong";
            return true;
        } else if (error.status > 401 && error.status < 500) {
            // bad request
            error.error.message = "Something Went Wrong";
            return true;
        } else if (error.status >= 500 && error.status < 600) {
            //server error.
            if (error.error.status >= 500 && error.error.status < 600) {
                error.error.message = "Something Went Wrong";
            }
            return true;
        }
        return true;
    }

    protected errorHandler1(error: any, route:Router) {
        if(typeof error === 'string') {
            console.log('instance of error in string', error);
            error = JSON.parse(error);
            return true;
        }
        if (error.status === 401 || error.status === 403) {
            // create session expire flow
            error.error.message = "Session Is Expired";
           // this.dataManager.logout();
            this.router = route;
            this.routeTo(AppRouteConstants.getRoute(AppRouteConstants.authRoute), null!);
            return true;
        } else if (error.status === 404) {
            // api not found handler
            error.error.message = "Something Went Wrong";
            return true;
        } else if (error.status > 401 && error.status < 500) {
            // bad request
            error.error.message = "Something Went Wrong";
            return true;
        } else if (error.status >= 500 && error.status < 600) {
            //server error.
            if (error.error.status >= 500 && error.error.status < 600) {
                error.error.message = "Something Went Wrong";
            }
            return true;
        }
        return true;
    }

    /**
     * function to logout from application instance
     * as registered customer
     */
    logOut() {
       // this.dataManager.logout();
        this.routeTo(AppRouteConstants.getRoute(AppRouteConstants.authRoute), null!);
    }

    showSnackBar(message:any, actionText:any, duration:any, css:any) {
        this.snackBar.open(message, actionText, {
            duration: duration,
            panelClass: [css]
        });
    }

    messageDisplayDialog(message: string, error: boolean ){
    const dialogRef = this.dialog.open(MessageDisplayDailogComponent, {
        width: 'auto',
        height: 'auto',
        disableClose: true,
        data: {
          message : message,
          error : error,
        }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
      });
    }

    /**
     * function to clone object.
     * @param data 
     */
    cloneObject(data:any) {
        console.log(JSON.stringify(data));
        console.log(JSON.parse(JSON.stringify(data)))
        return JSON.parse(JSON.stringify(data));
    }

    errorDisplayDialog(errorMessage: string) {
        const dialogRef = this.dialog.open(ErrorDisplayDialogComponent, {
            width: 'auto',
            height: 'auto',
            disableClose: true,
            data: {
                errorMessage: errorMessage
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    errorDisplayDialogPatym(errorMessage: string,errorMessage1: string,) {
        const dialogRef = this.dialog.open(ErrorDisplayDialogPaytmComponent, {
            width: 'auto',
            height: 'auto',
            disableClose: true,
            data: {
                errorMessage: errorMessage,
                errorMessage1: errorMessage1
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    

} 