    export class RoleModel {
        roleId: number;
        roleName: string;
        roleNameHi?: any;
        permissionsDto?: PermissionDto[];
        systemRole: boolean;
        roleDescription?: string;
    }

    export class PermissionDto {
        permissionName: string;
        permissionDescription: string;
        permissionDescriptionHi?: any;
        checked: boolean;
    }