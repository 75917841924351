import { Component, Inject, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { Router } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';
import { DOCUMENT } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent extends BaseComponent implements OnInit {
  
  constructor(private app:AppComponent,router:Router,@Inject(DOCUMENT) private document,public titleService: Title ) {
    super();
    this.router = router;
    if(localStorage.getItem('access_token') == null)
    {
      this.app.hideloginbtn = false
      this.app.showlogoutbtn = false;
      this.app.isPreviousLoggedIn = false;
    }
    this.titleService.setTitle('Repairing Company');
  }

  ngOnInit(): void {}
  
  navigate(){
    this.router.navigateByUrl(AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute));
  }

  NavigateToRecommended(){
    this.document.getElementById('recommended').scrollIntoView({
      behavior: 'smooth'
    });
  }

  NavigateToPlansPage(PlanName){
    const plan = {
      planName:PlanName
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.plansRoute)],{state:plan});
  }
  
}
