<div class="container">
<h3 class="margin-top15"><b>Terms and Conditions for Repairing Company services:</b></h3>
<p>By using it, you are agreeing to these Terms & Conditions (defined below). Please read them carefully. Mobimech Technologies Pvt Ltd.,, doing business as “Repairing Company”<br/>
    “Repairing Company” owns and operates the website, www.Repairing Company.com. The mobile touch versions, App on Play store and Apple App store and/or any site(s) we have now or in the future that reference these Terms & Conditions (collectively, “Repairing Company”), provides a marketplace and platform for consumers and organizations to sell or repair their used, old and other articles, and conduct varied transactions and activities, with third-party companies and other entities and persons (collectively, “Third Parties”). The Repairing Company marketplace, platform, and related functionality, whether provided through the Site or through other means, are collectively referred to as the services (the “Services”).<br/>
    If you do not agree with any part of these Terms & Conditions, you must not use the Site or Services. Your continued use of the Site or Services will constitute your acceptance of these Terms & Conditions, as they may be modified by us from time to time, with or without notice to you. Please check this page regularly for updates to the Repairing Company Terms & Conditions.
</p>
<ol>
    <li><b>Repairing Company is a marketplace venue</b></li>
    <p>Repairing Company is a platform to allow users, subject to compliance with Repairing Company’s policies, to sell/repair certain goods. Repairing Company may not be directly involved in the transaction between user(s) and third party professional(s), ensuing no control by reasons whatsoever in any aspect of your transactions with Third Parties, and the Third Parties are solely responsible to you for all aspects of your transactions with them.</p>
    <li><b>Permitted Use and Compliance with Laws.</b></li>
    <p>Repairing Company authorizes you to access, view and use the Site content and software (collectively, the “Mobimech Technologies Pvt Ltd Property”) solely to the extent necessary for you to use the Services. You may not remove any copyright, trademark or other proprietary notices that have been placed on the Repairing Company Property. You may not engage in systematic retrieval of data or other content from the Repairing Company Property. Except as expressly permitted by these Terms & Conditions, any modification, reproduction, redistribution, republication, uploading, posting, transmitting, distributing or otherwise exploiting in any way the Repairing Company Property, or any portion of the Repairing Company Property, is strictly prohibited without the prior written permission of Mobimech Technologies Pvt Ltd.<br/>
        You agree that you will comply with all applicable laws, regulations and ordinances relating to the Site and Services, the Repairing Company Property or your use of them, and that in using the Site and Services you will not engage in any conduct that restricts or inhibits any other person from using or enjoying the Services.</p>
        <li><b>Copyright and trademarks.</b></li>
        <p>Except as otherwise indicated, all materials in the Site, including, but not limited to text, software, photos, video, graphics, music, sound, the Repairing Company Logo, ScreenPro logo, ReGlobe Logo and other Repairing Company ,ScreenPro and ReGlobe trademarks and service marks and the entire contents of the Site are the property of Mobimech Technologies Pvt Ltd and/or its affiliates or licensors, including the Third Parties, and are protected by international/Indian copyright and trademark laws, all rights reserved. Any violation of these restrictions may result in a copyright, trademark, or other intellectual property right infringement that may subject you to civil and/or criminal penalties.<br/>
          Other marks on the site not owned by Repairing Company may be under license from the trademark owner thereof, in which case such license is for the exclusive benefit and use of Repairing Company unless otherwise stated, or may be the property of their respective owners. You may not use any of the trademark name, logos, trademarks or brands, or trademarks or brands of others on the Site without Repairing Company(Mobimech Technologies Pvt Ltd).</p>
            <li><b>Repairing Company Services and Third Party Services and Sites</b></li>
            <p>The information and materials provided in the Site and through the Services are intended for general reference only, and may not describe all of the terms, conditions, and exceptions applicable to Repairing Company’s Services. Repairing Company presents information from Third Parties through the Repairing Company Site and Services, including prices offered for your items, item descriptions, certain Third Party terms of service, and other information (“Third Party Information”). Repairing Company cannot control, and is not responsible for the accuracy of any Third Party Information.<br/>
                You conduct your actual sales and other transactions directly with the Third Parties and, unless otherwise specifically and clearly indicated, not with Repairing Company. Repairing Company cannot control any aspect of your sales and transactions with Third Parties, and the Third Parties are solely responsible to you for all aspects of your sales and transactions with them.<br/>
                At times, the Repairing Company Site may have links to websites hosted by the Third Parties and other entities (the “Additional Sites”), or such Additional Sites may have links to the Repairing Company Site. These links are offered as a convenience and for informational purposes only, not as referrals or endorsements by Repairing Company of the Additional Sites. The Additional Sites are maintained by their respective organizations, and those organizations are solely responsible for the content of their own websites. Repairing Company does not verify nor make any warranty or representation about the content, accuracy, opinions expressed, warranties, products or services, intellectual property compliance, or links of such Additional Sites. You should read the privacy policies and Terms & Conditions agreements of all Additional Sites.</p>
                <li><b>Privacy and Passwords</b></li>
                <p>Repairing Company values and protects the privacy of your information. Please review the Repairing Company privacy policy, as it contains important information relating to your use of the Site and Services. Some portions of the Site are protected and require a user identification code (“User ID”) and password for access. Unauthorized access or use of such portions of the Site is prohibited. You agree that you will notify Repairing Company immediately if you believe that a third party has obtained your User ID or password, or if you believe that any unauthorized access or use may occur or has occurred. For your protection, if Repairing Company believes that any unauthorized access may occur or has occurred, Repairing Company may terminate access without prior notice to you. You also agree that Repairing Company is permitted to act upon any instructions received such instructions as authorized by you.</p>
                <li><b>Users and Vendors.</b></li>
                <ol>
                    <li>Registration and Basic User Terms: Users are visitors to the site and or those using the Services that choose to register with Repairing Company (“Vendors”). Once registered, an account is created for each User (“Account”). If you choose to register as a Vendor, you represent and warrant to us that: </li>
                    <ul>
                        <li>you are of legal age to form a binding contract</li>
                        <li>you will provide us with accurate, current and complete registration and contact information, and keep your information updated </li>
                        <li>your registration and your use of the Services are not prohibited by law. We reserve the right to suspend or terminate your membership, or your access to the Site and or Services, in the event that you breach any of the Terms & Conditions or other applicable Repairing Company policies.</li>
                    </ul>
                    <li>Password and Account Security: In connection with your Account, you will create a password and a user id. You are responsible for keeping your password and secure, and you are responsible for all actions taken using your password.</li>
                    <li>Age: To create an Account or to otherwise use this Website, you must be at least eighteen (18) years old or the age of majority in your state or province of residence, whichever is greater.</li>
                </ol>
<li><b>Inactive Accounts</b></li>
<p>Inactive Status: A Users account may be set to inactive if there is no activity associated with that account for 180 days.</p>
<li><b>Warranty Exclusions and Limitations of Liability.</b></li>
<p>We warrant that the Services and Repairing Company Property will conform substantially to the descriptions set forth on the Site. In the event of any breach of this warranty, the Customer’s sole and exclusive remedy, and Repairing Company’s sole and exclusive liability, shall be (at Repairing Company’s option) to remedy the failure or to refund the monetary amount paid for the services by the Customer, if any. Except as set forth in the foregoing sentence, we make no representations or warranties of any kind regarding the Services or the Repairing Company Property. We expressly disclaim any and all warranties, whether express or implied, including: all warranties of merchantability, fitness for a particular purpose, title, non-infringement, and any and all warranties arising from course of dealing and usage of trade; that the services and Repairing Company property will meet your requirements, will always be available, accessible, uninterrupted, timely, secure or operate without error, as to the results that may be obtained from the operation, use or other exploitation of the services or Repairing Company property, and as to the accuracy or reliability of any information obtained from the services or the Repairing Company property. No advice or information, whether oral or written, obtained not expressly stated herein.<br/>
    Under no circumstances will you be entitled to recover from us any incidental, consequential, indirect, punitive or special damages (including damages for loss of business, loss of profits or loss of use), whether based on contract, tort (including negligence), or otherwise arising from or relating to the services or Repairing Company property, even if Repairing Company has been informed or should have known of the possibility of such damages. in any event, Repairing Company’s maximum aggregate liability for any and all damages arising from the services or the Repairing Company property shall be a refund of the amount paid by you to us, if any.<br/>
    Some jurisdictions do not allow the limitation or exclusion of warranties or of liability, so some of the above limitations or exclusions may not apply to you.</p>
    <li><b>Indemnity</b></li>
    <p>Users and Vendors agrees to indemnify and hold Repairing Company, our affiliates, suppliers, licensors and partners, and the officers, directors, employees, agents and representatives of each of them harmless, including costs, liabilities and legal fees, from any claim or demand made by any third party due to or arising out of (i) your access to or use of Services, (ii) your violation of these Terms & Conditions, or (iii) the infringement by you, or any third party using your account, of any intellectual property or other right of any person or entity. Repairing Company reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of Repairing Company. We will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
    <li><b>. Applicable Law, Jurisdiction, Compliance</b></li>
    <p>You and Repairing Company agree that all matters arising from or relating to the use and operation of the Site and/or the Services will be governed by the substantive laws of Republic of India, without regard to its conflicts of laws principles. You agree that all claims you may have arising from or relating to the operation, use or other exploitation of the Site and/or the Services will be heard and resolved in the courts located in New Delhi. You consent to the personal jurisdiction of such courts over you, stipulate to the fairness and convenience of proceeding in such courts, and covenant not to assert any objection to proceeding in such courts.</p>
    <li><b>Miscellaneous Provisions</b></li>
    <p>No delay or omission by us in exercising any of our rights occurring upon any noncompliance or default by you with respect to any of these Terms & Conditions will impair any such right or be construed to be a waiver thereof, and a waiver by us of any of the covenants, conditions or agreements to be performed by you will not be construed to be a waiver of any succeeding breach thereof or of any other covenant, condition or agreement hereof contained. As used in these Terms & Conditions, “including” means “including but not limited to.” If any provision of these Terms & Conditions is found by a court of competent jurisdiction to be invalid or unenforceable, then these Terms & Conditions will remain in full force and effect and will be reformed to be valid and enforceable while reflecting the intent of the parties to the greatest extent permitted by law. Except as otherwise expressly provided herein, these Terms & conditions set forth the entire agreement between you and Repairing Company regarding its subject matter, and supersedes all prior promises, agreements or representations, whether written or oral, regarding such subject matter. You agree that the electronic text of these Terms & Conditions constitutes writing and your assent to the terms and conditions hereof constitutes a “signing” for all purposes.</p>

    <li><b>Information Collection, Use, and Sharing</b></li>
    <p>By submitting any Repairing Company contact form, you understand that you may be contacted by a Repairing Company representative or a representative from the partner you’ve chosen. You may be contacted via telephone, email, text, or prerecorded message regarding Repairing Company programs, offers, events, announcements, and offers from Repairing Company’s third party marketing partners. If you respond to any such offer, your information will be subject to that third party’s terms and conditions. By providing us your phone number, you grant Repairing Company permission for the use of text messages, prerecorded voice or and/or automatic telephone dialing systems to contact you and/or deliver Repairing Company related information, offers or announcements.<br/>
        You also agree that Repairing Company may attempt to contact you via any telephone number you provide, even if it is a mobile telephone number which may result in charges to you. You can opt out from Repairing Company email messaging at any time.<br/>
        You understand that agreeing to the run diagnostics on Repairing Company app program will allow Repairing Company access to your device, solely in relation to conducting the assessment of the quality parameters of your device. In no matter will your private information and data on the device will be accessed for any other purpose and that such information shall not be downloaded by the Company or used in any other manner.</p>

</ol>
<h3>Grievance officer</h3>
<p>In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of the Grievance Officer are provided below:
    <br/>
    Mr. Ajay Aggarwal <br/>
Mobimech Technologies Pvt Ltd. <br/>
77 Gagan Vihar Extension,  <br/>
Delhi-110051 <br/>
India <br/>
<br/>
Contact us <br/>
Email: ajay@repairingcompany.com <br/>
Time: Monday to Friday (10:00 AM – 20:00 PM) <br/>

</p>
