export class BrandModel {
    brandId?: number;
    brandName?: string;
    category?: string;
    enabled?: boolean = false;
    isSelected?: boolean = false;
    brandImageUrl?: string;
}
export class ModelDetailModel
{
    brand:BrandModel;
    modelId:number;
    modelImagePath: string;
    modelImageUrl: string;
    modelName: string;
    modelYear: string;
}
export class ACTypesModel {
    acTypeId!: number;
    acTypes!: string;
    enabled?: boolean = false;
}
export class RefrigeratorTypesModel {
    refrigeratorTypeId!: number;
    refrigeratorType!: string;
    enabled?: boolean = false;
}
export class WashingMachineTypesModel {
    washingMachineTypeId!: number;
    washingMachineType!: string;
    enabled?: boolean = false;
}
