import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeviceIssueService {
  httpHeaders: any;

  constructor(private httpClient: HttpClient) {
    this.httpHeaders = new HttpHeaders()
}

  getRequest(getAllExpenseTypeListUrl:string) : Observable<any>{
    return this.httpClient.get(getAllExpenseTypeListUrl);
  }

  updateRequest(updatedeviceIssueUrl:string,support:any):Observable<any>{
    return this.httpClient.put(updatedeviceIssueUrl,support);
  }

  addRequest(updatedeviceIssueUrl:string,support:any):Observable<any>{
    return this.httpClient.post(updatedeviceIssueUrl,support);
  }

  uploadFile(url, formData): Observable<any> {
    const req = new HttpRequest('POST', url, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: new HttpHeaders()
      .append("Authorization", this.httpHeaders.get("Authorization"))
      .append('X-Requested-With', 'XMLHttpRequest')
    });
    return this.httpClient.request(req);
  }

}
