import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouteConstants } from './constants/app-route.constants';
import { AuthGuard } from './guards/auth.guard';
import { AboutComponent } from './ui/about/about.component';
import { ContactComponent } from './ui/contact/contact.component';
import { FaqComponent } from './ui/faqs/faq.component';
import { HomeComponent } from './ui/home/home.component';
import { MyAccountComponent } from './ui/my-account/my-account.component';
import { OrderDetailsComponent } from './ui/order-details/order-details.component';
import { PaymentStatusComponent } from './ui/payment-status/payment-status.component';
import { PrivacyComponent } from './ui/privacy/privacy.component';
import { ProtectionPlansComponent } from './ui/protection-plans/protection-plans.component';
import { RefundPolicyComponent } from './ui/refund-policy/refund-policy.component';
import { ScheduledpickupsComponent } from './ui/scheduledpickups/scheduledpickups.component';
import { TermsConditionsComponent } from './ui/terms-conditions/terms-conditions.component';
import { WarrantyPolicyComponent } from './ui/warranty-policy/warranty-policy.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component: HomeComponent },
  { path: AppRouteConstants.orderDetailsRoute, component: OrderDetailsComponent },
  { path: AppRouteConstants.paymentStatusRoute, component: PaymentStatusComponent, canActivate: [AuthGuard] },
  { path: AppRouteConstants.plansRoute, component: ProtectionPlansComponent },
  { path: AppRouteConstants.my_account, component:MyAccountComponent},
  { path: AppRouteConstants.warrantyPolicy, component:WarrantyPolicyComponent},
  { path: AppRouteConstants.termsAndConditions, component:TermsConditionsComponent},
  { path: AppRouteConstants.privacy, component:PrivacyComponent},
  { path: AppRouteConstants.refundPolicy, component:RefundPolicyComponent},
  { path: AppRouteConstants.aboutUs, component:AboutComponent},
  { path: AppRouteConstants.contact, component:ContactComponent},
  { path: AppRouteConstants.faq, component:FaqComponent},
  { path: AppRouteConstants.schedulepickups, component:ScheduledpickupsComponent},
  { path: AppRouteConstants.admin,loadChildren: () => import('../app/ui/admin/admin.module').then(m => m.AdminModule)},
  { path: AppRouteConstants.blogs,loadChildren: () => import('../app/ui/blogs/blogs.module').then(m => m.BlogsModule)},
  { path: AppRouteConstants.careers,loadChildren: () => import('../app/ui/careers/careers.module').then(m => m.CareersModule)}, 
  { path: '**', redirectTo: '' },
 ];
 
@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
