import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MobiOrderModel } from '../data/mobi-order-model/mobi-order.model';
import { PaytmPaymentModel } from '../data/paytm-payment-model/paytm-payment-model';
import { PaytmResponseModel } from '../data/paytm-response-model/paytm-response-model';
import { UserAddressModel } from '../data/user-address-model/user-address.model';
import { DevicePlanModel } from '../data/device-plans-model/device-plans.model';
import { SupportTicketModel } from '../data/support-ticket-model/support-ticket.model';
import { ProductCategory } from '../data/product-category-model/product-category-model';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {

  httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
  }

  getPaymentDTOApi(getPaymentDTOUrl : string,MobiOrderModel:MobiOrderModel) : Observable<any>{
     return this.http.post(getPaymentDTOUrl,MobiOrderModel);
  }

  createMMOrderApi(createMMOrderUrl : string,MobiOrderModel:MobiOrderModel) : Observable<any>{
    return this.http.post(createMMOrderUrl,MobiOrderModel);
  }

  updateMMOrderApi(updateOrderUrl : string,MobiOrderModel:MobiOrderModel) : Observable<any>{
    return this.http.put(updateOrderUrl,MobiOrderModel);
  }
  updateOrderStatus(updateOrderUrl : string) : Observable<any>{
    return this.http.put(updateOrderUrl,MobiOrderModel);
  }
  updateUserAddress(updateUserAddress: string, userAddressModel:UserAddressModel): Observable<any>{
    return this.http.post(updateUserAddress,userAddressModel,{headers : new HttpHeaders({ 'Content-Type': 'application/json' })});
  }

  getUserById(userUrl: string):Observable<any>{
    return this.http.get(userUrl);
  }

  createPayment(createPaymentUrl:string):Observable<any>{
    return this.http.post(createPaymentUrl,null);
  }

  getPaytmPaymentData(getPaytmPaymentDataUrl:string):Observable<any>{
    return this.http.get(getPaytmPaymentDataUrl);
  }

  redirectToPaytm(paytmRedirectUrl:string,PaytmPaymentModel:PaytmPaymentModel){
    return this.http.post(paytmRedirectUrl,PaytmPaymentModel);
  }

  paymentResponse(url:string,data:PaytmResponseModel):Observable<any>{
    const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
    return this.http.post(url,data,{ headers: reqHeader })
  }

  getAllStates(getAllStatesUrl:string):Observable<any>{
    return this.http.get(getAllStatesUrl);
  }

  getAllCities(getAllCitiesUrl:string):Observable<any>{
    return this.http.get(getAllCitiesUrl);
  }
  getDeviceContractsByNameAndAmount(getDeviceContractsByNameAndAmountUrl:string):Observable<any>{
    return this.http.get(getDeviceContractsByNameAndAmountUrl);
  }

  createModelContract(createModelContractUrl:string,DevicePlanModel:DevicePlanModel){
    return this.http.post(createModelContractUrl,DevicePlanModel);
  }

  createSupportIssue(createModelContractUrl:string,supportTicket:SupportTicketModel){
    return this.http.post(createModelContractUrl,supportTicket);
  }

  getAllOrderByUserId(getAllOrderByUserIdUrl:string):Observable<any>{
    return this.http.get(getAllOrderByUserIdUrl);
  }
  getTotalExpense(totalExpenseUrl:string):Observable<any>{
    return this.http.get(totalExpenseUrl);
  }
  getTotalPaymentAmount(getTotalPaymentAmountUrl):Observable<any>{
    return this.http.get(getTotalPaymentAmountUrl);
  }
  getTotalPaymentCount(getTotalPaymentCountUrl):Observable<any>{
    return this.http.get(getTotalPaymentCountUrl);
  }
  getsupportCount(supportCountUrl:any){
    return this.http.get(supportCountUrl);
  }
  
   getRequest(url: string): Observable<any> {
    return this.http.get(url, { headers: this.httpHeaders });
  }
  
   postRequest(url: string, postBody: any): Observable<any> {
    return this.http.post(url, postBody, { headers: this.httpHeaders });
  }

  getOrderById(getOrderByIdUrl:string): Observable<any> 
  {
    return this.http.get(getOrderByIdUrl, { headers: this.httpHeaders });
  }
  
  getHeaders(): HttpHeaders {
    return this.httpHeaders;
  }
  
  validatePromocode(validatePromoCodeUrl:string,MobiOrderModel:MobiOrderModel):Observable<any>{
    return this.http.post(validatePromoCodeUrl,MobiOrderModel);
  }

  saveOrUpdateProductCategory(saveOrUpdateProductCategoryUrl:string,productCategory:ProductCategory):Observable<any>{
    return this.http.post(saveOrUpdateProductCategoryUrl,productCategory);
  }
}
