
import { BankAccountModel } from '../bank-account-model/bank-account.model';
import { DepartmentModel } from '../department-list-model/department.model';
import { RepairChargesModel } from '../repair-charges-model/repair-charges.model';
import { UserAddressModel } from '../user-address-model/user-address.model';

export class UserDetailsModel {
    userId?: string;
    vendorName?: string;
    firstName?: string;
    middleName?: null;
    lastName?: string;
    email?: string;
    emailVerified?: boolean;
    mobileNumber?: number;
    mobileNumberVerified?: true;
    dateOfBirth?: Date;
    gender?: string;
    active?: boolean;
    language?: string;
    status?: string;
    addresses?: UserAddressModel[] = [];
    role?: string;
    roleId?: number;
    locked?: boolean;
    lockedTill?: string;
    failedLoginAttempt?: number;
    pwdExpiryDt?: Date;
    expiryNotifyCount?: number;
    forgetPwdToken?: string;
    activatePwdToken?: string;
    userRoleId?: number;
    permissions?: [] = [];
    lastSuccessfulLogin?: Date;
    rating?: number;
    createdBy?: string;
    createdDate?: Date;
    modifiedBy?: string;
    modifiedDate?: Date;
    socialId?: string;
    socialProvider?: string;
    panNumber?: string;
    aadhaarId?: string;
    gstNumber?: String;
    password?: string;
    confirmPassword?: string;
    bankAccounts?: BankAccountModel[] = [];
    departments?: DepartmentModel[] = [];
    panCardFile?: any;
    panCardFileName?: string;
    aadharCardFile?: any;
    aadharCardFileName?: string;
    profilePicFile?: any;
    profilePicFileName?: string;
    profilePicUrl?: string;
    aadharCardFrontUrl?: string;
    aadharCardEndUrl?: string;
    panCardUrl?: string;
    repairCharges?: RepairChargesModel[] =[];
    totalAmount?: number;
    totalSales?: number;
    totalRefunds?: number;
    amountBeforeTax?: number;
    tax?: number;
    discount?: number;
}
