import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../data/contact-us-model/contact-us-model';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  constructor(protected httpClient: HttpClient) { }

  getSupportTicketList(getSupportTicketListUrl: string): Observable<any> {
    return this.httpClient.get(getSupportTicketListUrl);
  }

  updateSupportTicket(updateSupportTicketUrl: string, support: any): Observable<any> {
    return this.httpClient.put(updateSupportTicketUrl, support);
  }

  saveContactDetails(saveContactDetailsUrl: string, contactModel: Contact): Observable<Contact> {
    return this.httpClient.post<Contact>(saveContactDetailsUrl, contactModel);
  }

}
