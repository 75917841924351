import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-bestlaptop',
    templateUrl: './bestlaptop.component.html'
})

export class BestlaptopComponent{
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Looking for the ultimate laptop to help you ace college? Find the best laptops for students in India 2023 with this comprehensive guide. It's all here!"})
        this.metaService.updateTag({name:'keywords',content:'best laptops for students'})
        this.canonicalServide.setCanonicalURL();
      
        this.translate.get('best_laptops').subscribe((res: string) => {
          this.titleService.setTitle(res);
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('best_laptops').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        }); 
    }      
}