import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';
import { AppConstants } from 'src/app/constants/app.constants';
import { MobiOrderModel } from 'src/app/data/mobi-order-model/mobi-order.model';
import { OrderConfirmationService } from 'src/app/services/order-confirmation.service';
import { OrderConformationDetailsComponent } from '../order-conformation-details/order-conformation-details.component';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserDetailsModel } from 'src/app/data/user-details/user-details.model';
import { UserService } from 'src/app/services/user.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss']
})
export class PaymentStatusComponent extends BaseComponent implements OnInit {
  rou: ActivatedRoute
  orderId: string;
  //TXN_SUCCESS for success or failure
  status: string;
  orderFailedOrSuccess: string;
  orderConfirmationSercvice : OrderConfirmationService;
  getOrderDetailsByIDUrl : string;
  mobiOrder: MobiOrderModel = new MobiOrderModel();
  user_data: UserDetailsModel = new UserDetailsModel();
  
  constructor(private titleService:Title, rout: ActivatedRoute,private routerNavigator:Router,
  public loginDialog: MatDialog,private ngxService: NgxUiLoaderService,
  orderConfirmationSercvice : OrderConfirmationService,router:Router,
  dialog: MatDialog, private userservice:UserService,snackBar: MatSnackBar) 
  {
    super();
    this.rou = rout
    this.orderConfirmationSercvice = orderConfirmationSercvice;
    this.dialog = dialog;
    this.snackBar = snackBar;
    this.router = router;
    this.titleService.setTitle('Order Confirmation');
    this.orderId = this.router.getCurrentNavigation()?.extras?.state?.['id'];
    this.rou.queryParams.subscribe(params => {
      if(params['id'] == undefined)
      {
        this.status = this.router.getCurrentNavigation()?.extras?.state?.['id'];
        this.status = this.router.getCurrentNavigation()?.extras?.state?.['status'];
      }
      else
      {
        this.orderId = params['id'];
        this.status = params['status'];
      }
    });
  }
   
   NavigateToPlansPage(PlanName){
    const plan = {
      planName:PlanName
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.plansRoute)],{state:plan});
  }

  ngOnInit(): void {
    
     

    if(this.status == "TXN_SUCCESS"){
      this.orderFailedOrSuccess = "order_Successful";
    }else{
      this.orderFailedOrSuccess = "order_Failed";
    }
    this.getOrderDetailsByIDUrl = AppConstants.orderService + AppConstants.getOrderRequest + "?id=" + this.orderId;
    this.getOrderDetails();
    this.getUserDetails();   
  }

  getOrderDetails(){
    this.ngxService.start();
    this.orderConfirmationSercvice.getOrderDetailsByID(this.getOrderDetailsByIDUrl).subscribe({
      next:(res)=>{
        this.mobiOrder = res;
        this.ngxService.stop();
      },
      error:(error)=>{
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
      }
    })
  }

  getUserDetails(){
    this.ngxService.start();
    this.userservice.getUserDetail().subscribe({
      next:(data:any)=>{
        this.ngxService.stop();
        this.user_data = data; 
        if (this.user_data != undefined) {
          this.user_data = data; 
        }
      },
      error:(error:any)=>{
        this.ngxService.stop();
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
        }
    });
   }

  home(){
    this.routerNavigator.navigateByUrl(AppRouteConstants.getRoute(""))
  }

  showOrderConfirmation(): void {
    const dialogRef = this.loginDialog.open(OrderConformationDetailsComponent, {
      width: '650px',
      height:'650px',
      disableClose: true,
      panelClass: "mat-dialog-container-details" ,
      data : {orderID: this.orderId,mobiOrder:this.mobiOrder,userData:this.user_data}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
    });
  }
  navigateToMyAcc(){
    this.router.navigate([AppRouteConstants.my_account]);
  }
}
