<form>
    <h1 mat-dialog-title *ngIf="serviceCenter!.serviceCenterId != null">
        {{'edit_service_center' | translate}}
    </h1>
    <h1 mat-dialog-title *ngIf="serviceCenter!.serviceCenterId == null">
        {{'create_service_center' | translate}}
    </h1>
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-6">
                   <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'center_name' | translate}}</mat-label>
                    <input matInput  type="text" [(ngModel)]="serviceCenter.serviceCenterName" [formControl]="centerName" required>
                    <mat-error *ngIf="centerName.invalid">{{'you_must_enter_a_name' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                    <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'contact_Number' | translate}}</mat-label>
                    <input matInput  type="number" [(ngModel)]="serviceCenter.serviceCenterContactNumber"
                        [formControl]="contactNumber" required>
                    <mat-error *ngIf="contactNumber.invalid">{{'please_provide_contact_number' | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                    <mat-label>{{'departments' | translate}}*</mat-label>
                    <!-- <ng-multiselect-dropdown 
                    [settings]="dropdownSettings"
                    [data]="departmentList"
                    [(ngModel)]="serviceCenter.serviceCenterDepartments"
                    [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown> -->
                    <app-multi-select  [List]="departmentList" [valKey]="'departmentId'"
                                      [class]="(serviceCenter.serviceCenterDepartments!.length <= 0) ? 'invalid' : ''"
                                      [dispList]="serviceCenter.serviceCenterDepartments" 
                                      (removeElementEmitter)="assignDepartments($event, false)"
                                      (addElementEmitter)="assignDepartments($event, true)"
                                      [dispKey]="'departmentName'"></app-multi-select>
            </div>
            <div class="col-sm-6">
                    <mat-label>{{'active_area' | translate}}*</mat-label>
                    <app-multi-select [List]="serviceAreaList" [valKey]="'serviceAreaId'"
                                      [class]="(serviceCenter.servieCenterAreas!.length <= 0) ? 'invalid' : ''"
                                      [dispList]="serviceCenter.servieCenterAreas" 
                                      (removeElementEmitter)="assignServiceAreas($event, false)"
                                      (addElementEmitter)="assignServiceAreas($event, true)"
                                      [dispKey]="'serviceAreaName'">
                                      </app-multi-select>
                                      <input type="hidden" ng-model="(selectarea==0?true:false))" ng-required="true">
            </div>
        </div>
        <div clas="row">
            <div class="col-sm-6">
                    <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'active_area' | translate}}</mat-label>
                    <mat-select [(ngModel)]="serviceCenter.enabled" [formControl]="requiredField" required>
                        <mat-option [value]="false">{{'services_Suspended' | translate}}</mat-option>
                        <mat-option [value]="true">{{'service_active' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>{{'Cancel' | translate}}</button>
        <button mat-button (click)="submitForm()" [disabled]="!(!requiredField.invalid && !centerName.invalid && !contactNumber.invalid && !(serviceCenter.servieCenterAreas!.length ==0?true:false) && !(selectDepartment==0?true:false))"
            cdkFocusInitial>
            <span *ngIf="serviceCenter!.serviceCenterId != null">  
                {{'edit_service_center' | translate}}
            </span>
            <span *ngIf="serviceCenter!.serviceCenterId == null">  
                {{'create_service_center' | translate}}
            </span>
        </button>
    </div>
</form>