import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-need-laptop-repair',
    templateUrl: './need-laptop-repair.html'
})

export class NeedLaptopRepairComponent{
  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {
    this.metaService.updateTag({name:'description',content:"Need laptop repair services? Not sure if your laptop needs to be fixed? Check out 10 ways to tell if you need a laptop repair from a reliable repairing company."})
    this.metaService.updateTag({name:'keywords',content:'repairing services, repairing company, laptop repairing, Laptop Repair Services, repairing services across the Delhi/NCR region'})
    this.canonicalServide.setCanonicalURL();
    
    this.translate.get('need_Laptop_Repair').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('need_Laptop_Repair').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
    });
  }      
}