import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';
import { AndroidRepairsComponent } from './android-repairs/android-repairs';
import { BestlaptopComponent } from './best-laptops-for-students/bestlaptop.component';
import { BestcameraphonesCcomponent } from './bestcameraphones/bestcameraphones.component';
import { BestmobilephonesintheworldComponent } from './bestmobilephonesintheworld/bestmobilephonesintheworld.component';
import { BlogsComponent } from './blogs.component';
import { EnhancingCustomerExperienceComponent } from './enhancing-customer-experience/enhancing-customer-experience';
import { Iphonepro14vsIphonepro14maxProComponent } from './iphone14provsiphone14promax/iphone14provsiphone14promax';
import { Iphone14vsIphone14PlusComponent } from './iphone14vsiphone14plus/iphone14vsiphone14plus';
import { Iphone14vsIphone14ProComponent } from './iphone14vsiphone14pro/iphone14vsiphone14pro';
import { Iphone14vsIphone14ProMaxComponent } from './iphone14vsiphone14ProMax/iphone14vsiphone14ProMax';
import { LockappsoniphoneComponent } from './lockappsoniphone/lockappsoniphone.component';
import { NeedLaptopRepairComponent } from './need-laptop-repair/need-laptop-repair';
import { takescreenshotskeyboardComponent } from './takescreenshotskeyboard/takescreenshotskeyboard.component';
import { TipsToBeSuccessfulInRepairBusinessComponent } from './tips-to-be-successful-in-repair-business/tips-to-be-successful-in-repair-business';
import { trackyourlostmobileComponent } from './trackyourlostmobile/trackyourlostmobile.component';
import { DealWithWaterDamageComponent } from './deal-with-water-damage/deal-with-water-damage';
import { ImpactOf5gComponent } from './impactof5g/impactof5gnow';
import { CrackedScreenComponent } from './cracked-screen/cracked-screen';

const routes: Routes = [
  { path:'',component:BlogsComponent },
  { path: AppRouteConstants.trackyourlostmobile, component:trackyourlostmobileComponent },
  { path: AppRouteConstants.takescreenshotskeyboard, component:takescreenshotskeyboardComponent },
  { path: AppRouteConstants.lockappsoniphone, component:LockappsoniphoneComponent },
  { path: AppRouteConstants.bestlaptop, component:BestlaptopComponent },
  { path: AppRouteConstants.bestcameraphones, component:BestcameraphonesCcomponent },
  { path: AppRouteConstants.bestmobilephonesintheworld, component:BestmobilephonesintheworldComponent },
  { path: AppRouteConstants.enhancingcustomerexperience, component:EnhancingCustomerExperienceComponent },
  { path: AppRouteConstants.needLaptopRepair, component:NeedLaptopRepairComponent },
  { path: AppRouteConstants.tipsToBeSuccessfulInRepairBusiness, component:TipsToBeSuccessfulInRepairBusinessComponent },
  { path: AppRouteConstants.androidRepairs, component:AndroidRepairsComponent },
  { path: AppRouteConstants.iphone14vsiphone14plus, component:Iphone14vsIphone14PlusComponent },
  { path: AppRouteConstants.iphone14vsiphone14pro, component:Iphone14vsIphone14ProComponent },
  { path: AppRouteConstants.iphone14vsiphone14proMax, component:Iphone14vsIphone14ProMaxComponent },
  { path: AppRouteConstants.iphone14provsiphone14proMax, component:Iphonepro14vsIphonepro14maxProComponent },
  { path: AppRouteConstants.dealWithWaterDamage, component:DealWithWaterDamageComponent },
  { path: AppRouteConstants.impactOf5g, component: ImpactOf5gComponent},
  { path: AppRouteConstants.crackedScreen, component:CrackedScreenComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BlogsRoutingModule { }
