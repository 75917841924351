<link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css?family=Sniglet&display=swap" rel="stylesheet" />
<div>
  <span class="rc-float">  <a (click)="onNoClick()" class="rc-a cursor-a">X &nbsp;</a> </span>
  <div>
    <div  class="rc-heading">RC</div>
  </div>
  <div *ngIf="success" [@openClose]>
  <span class="rc-custom-snackbar rc-snackbar-width" >
    <span class="rc-float">  <a (click)="oncloseSnackbar()" class="rc-a cursor-a">X &nbsp;</a> </span>
    <p class="rc-otp-heading"> 
      {{'sent_successfully' | translate}}
    </p>
  </span>
</div>
<h1 mat-dialog-title class="rc-title">
  {{'repairing_company' | translate}}
</h1>
<h1 mat-dialog-title class="rc-login-title">{{'log_in' | translate}}</h1>
<h5 class="rc-mobile-heading">{{'enter_phone_number' | translate}}</h5>
<form [formGroup]="loginForm">
  <div mat-dialog-content  class="rc-custom-margin1">
    <span class="rc-mobilenumber">{{'phone_number' | translate}}</span>
    <span class="rc-float"  *ngIf="hide">
    <button class="rc-changenumber2" 
      (click)="ChangeNumber()" >
    {{'change_number' | translate}}
    </button> 
    </span>
    <mat-form-field appearance="outline">
      <span matPrefix class="rc-91">+{{indiacode}} - &nbsp;</span>
      <input matInput (keypress)="numberOnly($event)" formControlName="mobileNumber" maxlength="10" name="phone" placeholder="{{'enter_mobile_number' | translate}}">  
    </mat-form-field>
  </div>
  <div mat-dialog-content *ngIf="hide" [@openClose]>
  <span class="rc-mobilenumber">{{'otp' | translate}}</span>
  <span class="rc-changenumber1">
  <button class="rc-changenumber2" (click)="retryotp()"  [disabled]="apiReturned ? null: true">
  {{'resend_otp' | translate}}</button> 
  </span>
  <span *ngIf="!hidetimer" class="rc-timer">{{counter | formatTime}}</span>
  <mat-form-field appearance="outline" >
    <input matInput (keypress)="numberOnly($event)" formControlName="otp" maxlength="6" placeholder="{{'enter_otp' | translate}}" [type]="showPassword ? 'text' : 'password'"> 
    <mat-icon matSuffix (click)="togglePasswordVisibility()">
      {{showPassword?'visibility_off':'visibility'}}
    </mat-icon>
  </mat-form-field>
  </div>
  <div mat-dialog-actions class="rc-custom-margin2 make-center">
    <button mat-button cdkFocusInitial class="rc-send-otp-btn" *ngIf="!hide" [disabled]="!loginForm.valid" [@openClose] (click)="click()">{{'send_otp' | translate}}</button>
    <button mat-button cdkFocusInitial class="rc-login-btn" *ngIf="hide" [disabled]="!loginForm.valid" [@openClose] (click)="Login()">{{'log_in' | translate}}</button>
    <div class="margin-top30 " fxLayout="row">
      <div class="rc-mobilenumber margin-right10 margin-top5">{{'sing_in_with'|translate}}</div>
      <asl-google-signin-button 
      type="standard" size="large" 
      shape="rectangular" text="signin_with" 
      width="400" theme="filled_blue"> </asl-google-signin-button>
    </div>
  </div>
</form>
</div>
