<div class="msgAlign">
    <ng-template [ngIf]="error">
        <mat-icon> error </mat-icon>
    </ng-template>
    <ng-template [ngIf]="!error">
        <mat-icon> done </mat-icon>
    </ng-template>

    <p [innerHTML]="message"></p>

    <button mat-button (click)="onNoClick()">OK</button>
</div>