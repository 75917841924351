export class SupportTicketModel {
    supportIssueId?: String;
    subject?: String
    details?: String
    resolution?: String;
    category?: String;
    status?: String;
    userId?: number;
    createdDate?: Date;
    updatedDate?: Date;
    orderId?:number;
}