import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewHomeService{

  constructor(private http: HttpClient) {
  }

  getProductCategoryDataListApi(getProductCategoryDataListUrl : string) : Observable<any>{
     return this.http.get(getProductCategoryDataListUrl);
  }

  deleteProductUrl(deleteProductUrl : string) : Observable<any>{
    return this.http.delete(deleteProductUrl);
 }

  getBrandDataListApi(getBrandDataListUrl:string) : Observable<any>{
    return this.http.get(getBrandDataListUrl);
  }

  getProductsTypesApi(getACTypesListUrl:string) : Observable<any>{
    return this.http.get(getACTypesListUrl);
  }

  getModelDataListApi(getModelDataListUrl:string) : Observable<any>{
    return this.http.get(getModelDataListUrl);
  }

  getRepairChargeDataListApi(getRepairChargeDataListUrl:string) : Observable<any>{
    return this.http.get(getRepairChargeDataListUrl);
  }

}
