import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AppRouteConstants } from "src/app/constants/app-route.constants";

@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['./careers.component.scss'],    

})

export class CareersComponent{

    constructor(private router:Router){}

    ApplyNow()
    {
        this.router.navigate([AppRouteConstants.applyNow]);
    }

    jdOfSalesManager()
    {
        this.router.navigate([AppRouteConstants.careers + '/' + AppRouteConstants.jdOfSalesManager]);
    }
    
}