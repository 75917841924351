import { ACTypesModel, RefrigeratorTypesModel, WashingMachineTypesModel } from "../brand-model/brand.model";
import { DeviceContractModel } from "../device-contract-model/device-contract.model";
import { DeviceModel } from "../device-model/device.model";

export class DevicePlanModel {
    modelContractId?: number;
    deviceContract?: DeviceContractModel = new DeviceContractModel();
    model?: DeviceModel = new DeviceModel();
    acTypeDto?: ACTypesModel = new ACTypesModel();
    refrigeratorTypeDto?: RefrigeratorTypesModel = new RefrigeratorTypesModel();
    washingMachineTypeDto?: WashingMachineTypesModel = new WashingMachineTypesModel();
    enabled?: boolean = true;
    cgst?: number;
    igst?: number;
    sgst?: number;
    gst?: number;

}