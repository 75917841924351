<div class="main">
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-3">
            <div class="sidebar">

               <div class="profileimg">
                  <img src="../../../assets/images/profile.png">
                  <div class="name-member">
                     <h4>{{firstname}} {{lastname}}</h4>
                     <div class="membership-icon">
                        <img [src]="url">
                     </div>
                  </div>
                  <a (click)="logout()" class="log-out cursor-a">{{'log_out' | translate}}</a>
               </div>
               <div class="menu">
                  <ul>
                     <li class="cursor-a" (click)="onClick(1)"><a [class]="{'active': tab==='tab1'}"><span
                              class="bi bi-house"></span>{{'my_details' | translate}}</a></li>
                     <li class="cursor-a" (click)="onClick(2)"><a [class]="{'active': tab==='tab2'}"><span
                              class="bi bi-bag-check"></span>{{'my_Orders' | translate}}</a></li>
                     <li class="cursor-a" (click)="onClick(3)"><a [class]="{'active': tab==='tab3'}"><span
                              class="bi bi-clipboard-data"></span>{{'Manage_Addresses' | translate}}</a></li>
                     <li class="cursor-a" (click)="onClick(4)"><a [class]="{'active': tab==='tab4'}"><span
                              class="bi bi-chat"></span>{{'Support' | translate}}</a></li>
                     <li class="cursor-a" (click)="openDialog()"><a [class]="{'active': tab==='tab5'}"><span
                              class="bi bi-trash"></span>{{'delete_acc' | translate}}</a></li>
                  </ul>
               </div>
            </div>
         </div>
         <div class="col-md-8">
            <my-details *ngIf="myacc"></my-details>
            <my-orders *ngIf="myorder"></my-orders>
            <my-addresses *ngIf="myaddress"></my-addresses>
            <my-support *ngIf="mysupport"></my-support>
         </div>
      </div>
   </div>
</div>