import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-error-display-dialog',
  templateUrl: './error-display-dialog.component.html',
  styleUrls: ['./error-display-dialog.component.css']
})
export class ErrorDisplayDialogComponent implements OnInit {

  errorMessage: string;

  constructor(public dialogRef: MatDialogRef<ErrorDisplayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.errorMessage = data['errorMessage']; }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
