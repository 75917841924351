import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';
import { DOCUMENT } from '@angular/common';
import { AppComponent } from 'src/app/app.component';
import { Meta, Title } from '@angular/platform-browser';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private translation: TranslateService, private app: AppComponent, private router: Router, @Inject(DOCUMENT) private document, private titleService: Title, private metaService: Meta, private canonicalServide: CanonicalService) {

    if (localStorage.getItem('access_token') == null) {
      this.app.hideloginbtn = false
      this.app.showlogoutbtn = false;
      this.app.isPreviousLoggedIn = false;
    }

    this.metaService.updateTag({ name: 'description', content: "Need a reliable mobile repair shop? Our professionals can provide you with genuine parts and repairs with doorstep service in areas like Noida, Delhi, Gurgaon, & more!" });
    this.metaService.updateTag({ name: 'keywords', content: 'mobile phone repair shop, mobile repairs, mobile repairing at home, mobile screen replacement, online mobile repair, doorstep mobile repair in noida, doorstep mobile repair in delhi, iphone repair doorstep, iphone screen repair gurgaon, laptop service, laptop repairing service, laptop servicing center, repairing company' })
    this.canonicalServide.setCanonicalURL();

    this.translation.get('best_Mobile_Repair_Shop').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });

    this.translation.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translation.get('best_Mobile_Repair_Shop').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
    });
  }


  ngOnInit(): void {
    var stopCircle = document.getElementsByClassName('anima');
    for (var i = 0; i < stopCircle.length; i++) {
      if (stopCircle[i].matches(':hover')) { }
      stopCircle[i].addEventListener("mouseover", function (event) {
        document.getElementsByClassName('circle-arround-two-1')[0].classList.add("stopanima");
        document.getElementsByClassName('circle-arround-two-2')[0].classList.add("stopanima");
        document.getElementsByClassName('circle-arround-two-3')[0].classList.add("stopanima");
        document.getElementsByClassName('circle-arround-two-4')[0].classList.add("stopanima");
      });
      stopCircle[i].addEventListener("mouseout", function (event) {
        document.getElementsByClassName('circle-arround-two-1')[0].classList.remove("stopanima");
        document.getElementsByClassName('circle-arround-two-2')[0].classList.remove("stopanima");
        document.getElementsByClassName('circle-arround-two-3')[0].classList.remove("stopanima");
        document.getElementsByClassName('circle-arround-two-4')[0].classList.remove("stopanima");
      });
    }
  }

  navigate() {
    this.router.navigateByUrl(AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute));
  }

  navigateToSchedulePickups() {
    this.router.navigateByUrl(AppRouteConstants.getRoute(AppRouteConstants.schedulepickups));
  }

  NavigateToRecommended() {
    this.document.getElementById('recommended').scrollIntoView({
      behavior: 'smooth'
    });
  }

  NavigateToPlansPage(PlanName) {
    const plan = {
      planName: PlanName
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.plansRoute)], { state: plan });
  }


  activeAccordion = 'One'

  isActive = (name: string) => this.activeAccordion === name

  setActive = (name: string) => this.activeAccordion = name
}
