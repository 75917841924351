import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-cracked-screen',
    templateUrl: './cracked-screen.html'
})

export class CrackedScreenComponent{
    
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
      this.metaService.updateTag({name:'description',content:"Discover the ultimate Screen Replacement Guide for your cracked screen. Get expert tips and DIY solutions. Your go-to resource for screen replacements."})
      this.metaService.updateTag({name:'keywords',content:'Screen Replacement Guide, mobile screen repair, mobile phone screen replacement, repairing company, repairing services'})
      this.canonicalServide.setCanonicalURL();
      
      this.translate.get('cracked_Screen').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate.get('cracked_Screen').subscribe((res: string) => {
              this.titleService.setTitle(res);
        });
        });
    }   
}
