import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";
import { AppRouteConstants } from "src/app/constants/app-route.constants";

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})

export class BlogsComponent {

  constructor(private router: Router, public titleService: Title, private translate: TranslateService, private metaService: Meta, private canonicalServide: CanonicalService) {
    this.metaService.updateTag({ name: 'description', content: "Blogs" });
    this.metaService.updateTag({ name: 'keywords', content: 'repairing company blogs' })
    this.canonicalServide.setCanonicalURL();
    this.translate.get('Blogs').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate.get('Blogs').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
    });
  }

  ngOnInit(): void { }

  navigateToImpactOf5g() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.impactOf5g])
    );
    window.open(url, '_blank');
  }

  navigateToWaterDamage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.dealWithWaterDamage])
    );
    window.open(url, '_blank');
  }
  navigateToCrackedScreen() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.crackedScreen])
    );
    window.open(url, '_blank');
  }

  navigateToTrackyourlostmobile() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.trackyourlostmobile])
    );
    window.open(url, '_blank');
  }
  navigateToTakescreenshotskeyboard() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.takescreenshotskeyboard])
    );
    window.open(url, '_blank');
  }
  navigateToLockappsoniphoneComponent() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.lockappsoniphone])
    );
    window.open(url, '_blank');
  }
  navigateToBestlaptopComponent() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.bestlaptop])
    );
    window.open(url, '_blank');
  }
  navigateToBestcameraphonesCcomponent() {

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.bestcameraphones])
    );
    window.open(url, '_blank');
  }
  
  navigateToBestmobilephonesintheworldComponent() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.bestmobilephonesintheworld])
    );
    window.open(url, '_blank');
  }

  navigateToneedLaptopRepair() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.needLaptopRepair])
    );
    window.open(url, '_blank');
  }

  navigateTipsToBeSuccessfulInRepairBusiness() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.tipsToBeSuccessfulInRepairBusiness])
    );
    window.open(url, '_blank');
  }

  navigateToAndroidRepairs() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.androidRepairs])
    );
    window.open(url, '_blank');
  }

  navigateToenhancingcustomerexperience() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.enhancingcustomerexperience])
    );
    window.open(url, '_blank');
  }

  navigateToiphone14vsiphone14plus() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.iphone14vsiphone14plus])
    );
    window.open(url, '_blank');
  }

  navigateToiphone14vsiphone14pro() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.iphone14vsiphone14pro])
    );
    window.open(url, '_blank');
  }

  navigateToiphone14vsiphone14proMax() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.iphone14vsiphone14proMax])
    );
    window.open(url, '_blank');
  }

  navigateToiphone14provsiphone14proMax() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['ui/' + AppRouteConstants.blogs + '/' + AppRouteConstants.iphone14provsiphone14proMax])
    );
    window.open(url, '_blank');
  }

}
