<head>

    <meta name="description" content="Hey watchers! We are here for entertainment. Discover where to watch and download free movies on the most popular movie streaming websites."/>
    <meta name="keywords" content="Download movies for free, download movies online, free hd movies direct download, free download sites movies, "/>
    <link rel="canonical" href="https://repairingcompany.com/ui/best-website-to-download-movies-for-free" />
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap" rel="stylesheet">
</head>

<section class="careers-banner">
<div class="container-fluid p-0">
<div class="row">
<div class="col-md-6">
<div class="left">
<img src="../assets/images/careers-left.png"> 
<div class="text">
<h6>Repairing Company</h6>  
<h1>Work at the <br>heart of change</h1>  
</div>
</div>    
</div>    
<div class="col-md-6">
<div class="right">    
<img src="../assets/images/career-bg.png" style="width:100%">    
</div>     
</div>    
</div>    
</div>    
</section>  

<section class="sec-sectin">
<div class="container">
<div class="row">
<div class="col-md-4">
<div class="text">    
<h5>Repairing Company</h5>
<h2>Start Your Dream Jobs</h2>    
</div> 
</div> 
<div class="col-md-8">
    <div class="text"> 
    <p>At the heart of every great change is a great human. Every day our People of 
        Change are doing incredible things by working together to pursue our shared 
        purpose– to deliver on the promise of technology and human ingenuity. Come 
        be part of our team–bring your ideas, ingenuity, and determination to make a 
        difference, and we’ll solve some of the world’s biggest challenges.</p> 
    <p>Around the globe, we work with exceptional people, the latest and greatest 
        tech, and leading companies across industries.
    </p>   
</div> 
</div>    
</div>    
</div>    
</section>

<section class="job-sectin">
<div class="container">
<div class="row">
<div class="col-md-12">
<div class="text">    
<h5>technology recruitment firm</h5>
<h2>Come Join Us</h2>    
</div> 
</div> 

<div class="col-md-12">
<div class="job-open">
<div class="row"> 
<div class="col-md-10">
<div class="text">     
<h5>Information Technology &amp; Services</h5>
<h2> Corporate Sales Manager</h2>
<p>Business development for B2B customers
Reaching out, connecting and closing sales with enterprises and corporate companies.
Able to support rapid sales growth.......
</p>
<a class="read-more cursor-a" (click)="jdOfSalesManager()" >Read More</a>
</div>
</div>
<div class="col-md-2 text-center apply">   
<a title="Explore" class="apply-now cursor-a" (click)="ApplyNow()" >Apply Now</a>
</div>
</div>
</div>
</div> 

<div class="col-md-12">
<div class="job-open">
<div class="row"> 
<div class="col-md-10">
<div class="text">     
<h5>Information Technology Services</h5>
<h2>Inside Sales Manager</h2>
<p>Business development for B2B customers
Reaching out, connecting and closing sales with enterprises and corporate companies.
Able to support rapid sales growth.......
</p>
<a class="read-more cursor-a" (click)="jdOfSalesManager()" >Read More</a>
</div>
</div>
<div class="col-md-2 text-center apply">   
<a title="Explore" class="apply-now cursor-a" (click)="ApplyNow()">Apply Now</a>
</div>
</div>
</div>
</div>
</div>    
</div>    
</section>

<section class="careers-bot">
<div class="container-fluid p-0">
<div class="row">
<div class="col-md-6">
<div class="left">
<div class="text">    
<h5>Repairing Company</h5>
<h2>Grow Your Professional Career</h2> 
<p>Repairing Company requires certified consultants with skills such as UI UX 
design, web development, digital marketing, and salesforce consulting and 
prepares them for our future projects. All applications are pre-screened based 
on academic credentials, certifications, and work experience. Depending 
upon the skill set and working experience, we offer the role from intern to 
senior positions. We provide better career and future options for professionals 
to learn and grow in the digital industry.</p>   
<p>On academic credentials, Certifications, and work experience. Depending 
upon the skill set and working experience, we offer the role from intern to 
senior positions. We provide better career and future options for professionals 
to learn and grow in the digital industry. All applications are pre-screened based 
on academic credentials, certifications, and work experience. Depending 
upon the skill set and working experience, we offer the role from intern to 
senior positions. </p>
</div> 
</div>    
</div>
<div class="col-md-1"></div>    
<div class="col-md-5">
<div class="right">    
<img src="../assets/images/careers-bot.png" style="width:100%">    
</div>     
</div>    
</div>    
</div>    
</section> 