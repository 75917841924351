import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcurementVendorModel } from '../data/procurement-vendor-model/procurement-vendor-model';


@Injectable({
  providedIn: 'root'
})

export class ProcurementVendorService {

  constructor(private http: HttpClient) {
  }

  getAllProcurementVendor(getAllProcurementVendorListUrl:string) : Observable<any>{
    return this.http.get(getAllProcurementVendorListUrl);
  }
    
  saveProcurementVendor(saveProcurementVendorUrl:string,ProcurementVendor:ProcurementVendorModel) : Observable<any>{
    return this.http.post(saveProcurementVendorUrl,ProcurementVendor);
  }
}
