import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-warranty-policy',
  templateUrl: './warranty-policy.component.html'
})
export class WarrantyPolicyComponent implements OnInit {
  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {this.metaService.updateTag({name:'description',content:"Every smartphone screen repair or replacement performed by the Repairing Company comes with a six-month breakage warranty starting from the date of invoicing. Moreover, we offer a 6-month warranty on all the additional replacement parts."})
    this.metaService.updateTag({name:'keywords',content:'Repairing Company Warranty Policy'})
    this.canonicalServide.setCanonicalURL();       
    
    this.translate.get('warranty_Policy').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('warranty_Policy').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    }); 
   }

  ngOnInit(): void {
    
  }
}
