import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { ExpenseModel } from 'src/app/data/expense-model/expense.model';
import { ExpenseTypeModel } from 'src/app/data/Expense-type-model/expense-type.model';
import { LedgerModel } from 'src/app/data/ledger-model/ledger-model';
import { AddExpensesService } from 'src/app/services/add-expenses.service';

@Component({
  selector: 'app-add-expenses',
  templateUrl: './add-expenses.component.html',
  styleUrls: ['./add-expenses.component.scss']
})
export class AddExpensesComponent implements OnInit {
  addExpenseFormGroup: FormGroup;
  expenseTypeList: ExpenseTypeModel[] = [];
  expenseModel: ExpenseModel = new ExpenseModel();
  expenseModelForUpdate: LedgerModel = new LedgerModel();
  getAllExpenseListUrl:string = AppConstants.orderService + AppConstants.getAllExpenseType;
  saveExpense:string = AppConstants.orderService + AppConstants.addExpense;
  updateOrAdd:string;
  updateOrAddedSuccessMsg:string = 'Expenses Added Successfully!!';

  constructor(public dialogRef: MatDialogRef<AddExpensesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public addExpService : AddExpensesService,
    private ngxService: NgxUiLoaderService,public snackBar: MatSnackBar) { 
    this.getAllExpenseList();
    
    if(data != null){
      if(data.operation == 'update'){
        this.updateOrAdd = 'update_expenses';
        this.expenseModelForUpdate = this.data.ledgerDto;
        this.updateOrAddedSuccessMsg = 'Expenses updated Successfully!!'
      }
    }else{
      this.updateOrAdd = 'add_expenses';
    }
    
  }

  getAllExpenseList(){
    this.ngxService.start();
    this.addExpService.getAllExpenseType(this.getAllExpenseListUrl).subscribe({
      next:(data:any)=>{
        this.expenseTypeList=data;
        this.ngxService.stop();    
      },
      error:(error:any)=>{     
        this.ngxService.stop();
      }
    });
  }

  ngOnInit(): void {
    this.addExpenseFormGroup = new FormGroup({
      orderId: new FormControl(null),
      expenseId: new FormControl(null),
      vendorName: new FormControl(null,Validators.required),
      expenseDetails: new FormControl(null,Validators.required),
      expenseAmount: new FormControl(null,Validators.required),
      expenseDate: new FormControl(new Date(),Validators.required),
      expenseType: new FormControl(null,Validators.required)
    });

    if(this.expenseModelForUpdate.expenseId != undefined){
      this.addExpenseFormGroup.controls['expenseId'].setValue(this.expenseModelForUpdate.expenseId);
      this.addExpenseFormGroup.controls['orderId'].setValue(this.expenseModelForUpdate.mapperOrderId);
      this.addExpenseFormGroup.controls['vendorName'].setValue(this.expenseModelForUpdate.vendorName);
      this.addExpenseFormGroup.controls['expenseDetails'].setValue(this.expenseModelForUpdate.expenseDetails);
      this.addExpenseFormGroup.controls['expenseAmount'].setValue(this.expenseModelForUpdate.transactionAmount);
      this.addExpenseFormGroup.controls['expenseDate'].setValue(this.expenseModelForUpdate.transactionDate); 
      this.addExpenseFormGroup.controls['expenseType'].setValue(this.expenseModelForUpdate.expenseType);
    }
  }

  addExpenseModel(){
    if(this.addExpenseFormGroup.status == 'INVALID' && localStorage.getItem("access_token") != undefined){
      this.snackBar.open('All fields are required!!','',{duration:5000,panelClass: ['blue-snackbar']})
      return;
    }
    this.expenseModel.expenseId =  this.addExpenseFormGroup.controls['expenseId'].value;
    this.expenseModel.orderId =  this.addExpenseFormGroup.controls['orderId'].value;
    this.expenseModel.vendorName =  this.addExpenseFormGroup.controls['vendorName'].value;
    this.expenseModel.expenseDetails =  this.addExpenseFormGroup.controls['expenseDetails'].value;
    this.expenseModel.expenseAmount =  this.addExpenseFormGroup.controls['expenseAmount'].value;
    if(this.updateOrAdd == 'update_expenses'){
      if(this.addExpenseFormGroup.controls['expenseDate'].touched == true){
        this.expenseModel.expenseDate = new Date(this.addExpenseFormGroup.controls['expenseDate'].value); 
        this.expenseModel.expenseDate.setHours(this.expenseModel.expenseDate.getHours() + 5);
        this.expenseModel.expenseDate.setMinutes(this.expenseModel.expenseDate.getMinutes() + 30);
      }else{
        this.expenseModel.expenseDate = this.expenseModelForUpdate.transactionDate;
      }
    }else{
      this.expenseModel.expenseDate = new Date(this.addExpenseFormGroup.controls['expenseDate'].value); 
      this.expenseModel.expenseDate.setHours(this.expenseModel.expenseDate.getHours() + 5);
      this.expenseModel.expenseDate.setMinutes(this.expenseModel.expenseDate.getMinutes() + 30);
    }
    this.expenseModel.expenseType =  this.addExpenseFormGroup.controls['expenseType'].value;
    this.save();
  }

  save(){
    this.ngxService.start();
    this.addExpService.saveExpense(this.saveExpense,this.expenseModel).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.addExpenseFormGroup.reset();
        this.onNoClick();
        this.snackBar.open(this.updateOrAddedSuccessMsg,'',{duration:5000,panelClass: ['green-snackbar']});
      },
      error:(error:any)=>{
        this.snackBar.open('Order Id Not found!!','',{duration:5000,panelClass: ['blue-snackbar']}) 
        this.ngxService.stop();
      }
    });
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
