<h1 mat-dialog-title>{{'issue_ticket' | translate}}</h1>
<div mat-dialog-content>
    <h2>{{'support_ticket_details' | translate}}</h2>
    <div class="row">
        <div>{{'support_ticket_id' | translate}}: {{supportTicket.supportIssueId}}</div>
    </div>
    <div class="row">
        <div>{{'Status'|translate}}: {{supportTicket.status}}</div>
    </div>
    <div class="row">
        <div>{{'Subject'|translate}}: {{supportTicket.subject}}</div>
    </div>
    <div class="row">
        <div>{{'Description'|translate}}: {{supportTicket.details}}</div>
    </div>
 
    <h2>User Details</h2>
    <div class="row">
        <div>{{'first_name'|translate}}: {{userDetail!.firstName}}</div>
    </div>
    <div class="row">
        <div>{{'last_name'|translate}}: {{userDetail!.lastName}}</div>
    </div>
    <div class="row">
        <div>{{'email'|translate}}: {{userDetail.email == null ? '' :userDetail.email}}</div>
    </div>
    <div class="row">
        <div>{{'mobile_number'| translate}}: {{userDetail.mobileNumber == null ? '' : userDetail.mobileNumber}}</div>
    </div>
   
    <div class="row margin-top10">
        <mat-form-field appearance="outline">
        <textarea matInput #resolution  placeholder="resolution..."></textarea>
        </mat-form-field>
    </div>

    <div class="row">
        <div class="col">
            <button mat-button class="button" (click)="updateSupportTicket(resolution.value)">{{'Resolved'|translate}}</button>
        </div>
        <div class="col">
            <button mat-button class="buttonCancel" (click)="onNoClick()">{{'Cancel'|translate}}</button>
        </div>
    </div>
</div>