import { RepairIssueModel } from "../repair-issue-model/repair-issue.model";

export class DeviceContractModel {
    deviceContractId?: number;
    description?: string;
    amount?: number;
    imageUrl?: string;
    imagePath?: string;
    tenure?: number;
    name?: string;
    issues?: RepairIssueModel[] = [];
}