<div class="close" ><a class="cursor-b" (click)="oncloseSnackbar()">X</a></div>
<div fxLayout="column" fxLayoutAlign="space-between "  fxLayoutGap="10" class="every-repair-section margin-top10px">
   <div fxLayout="row" fxLayoutAlign="center" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">
      <div fxLayout="row" fxLayoutAlign="space-evenly center" class="padding20" fxLayoutGap="10">
         <div class="bubbleBox2" >
            <div class="bubbleHeading">
               <span class="text15">
               {{'order_details' | translate}}
               </span>
            </div>
         </div>
      </div>
   </div>
</div>
<mat-card>
   <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20" fxLayout.xs="column">
      <div fxLayout="column" fxFlex="50">
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'order_Id' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'order_Id' | translate}}" disabled [(ngModel)]="mobiOrder.orderId">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'Created_Date' | translate}}</mat-label>
               <input matInput [matDatepicker]="picker" disabled [(ngModel)]="mobiOrder.createdDate" required>
               <mat-hint>{{'MM/DD/YYYY' | translate}}</mat-hint>
               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
               <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'order_Amount' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'order_Amount' | translate}}t" disabled
               [(ngModel)]="mobiOrder.orderAmount">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'department' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'department' | translate}}t" disabled
               [(ngModel)]="orderDepartment">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill" *ngIf="mobiOrder.acType! != null">
               <mat-label>{{'model_type' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'department' | translate}}t" disabled
               [(ngModel)]="actype">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill" *ngIf="mobiOrder.brand! != null">
               <mat-label>{{'brand' | translate}}</mat-label>
               <mat-select [(ngModel)]="mobiOrder.brand!.brandId" required disabled (selectionChange)="changeBrand($event.value)">
               <mat-option *ngFor="let brand of brandList" [value]="brand!.brandId">{{brand!.brandName}}
               </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill" *ngIf="mobiOrder.model! != null">
               <mat-label>{{'model' | translate}}</mat-label>
               <mat-select [(ngModel)]="mobiOrder.model!.modelId" required disabled (selectionChange)="changeModel($event.value)">
               <mat-option *ngFor="let model of deviceList" [value]="model!.modelId">{{model!.modelName}}
               </mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'order_status' | translate}}</mat-label>
               <mat-select [(ngModel)]="mobiOrder.orderStatus">
               <mat-option *ngFor="let orderStatus of OrderStatuskeys" [value]="orderStatus">{{orderStatus  | translate}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'quality_tested' | translate}}</mat-label>
               <mat-select [(ngModel)]="mobiOrder.quality_tested">
               <mat-option value="Yes">{{'Yes' | translate}}</mat-option>
               <mat-option value="No">{{'No' | translate}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>Payment Status</mat-label>
               <input matInput #input required placeholder="{{'customer_Mobile_number' | translate}}" disabled
               [(ngModel)]="paymentStatus">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>Mode Of Payment</mat-label>
               <input matInput #input required placeholder="{{'customer_Mobile_number' | translate}}" disabled
               [(ngModel)]="paymentMode">
            </mat-form-field>
         </div>
      </div>
      <div fxLayout="column" fxFlex="50">
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'customer_Mobile_number' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'customer_Mobile_number' | translate}}" disabled
               [(ngModel)]="customerMobile">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'customer_First_Name' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'customer_First_Name' | translate}}" disabled
               [(ngModel)]="custfirstname">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'customer_Last_Name' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'customer_Last_Name' | translate}}" disabled
               [(ngModel)]="custlastname">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'customer_Email_Address' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'customer_Email_Address' | translate}}" disabled
               [(ngModel)]="custemail">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'customer_Address_Line_1' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'customer_Address_Line_1' | translate}}" disabled
               [(ngModel)]="custaddress1">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'customer_Address_Line_2' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'customer_Address_Line_2' | translate}}" disabled
               [(ngModel)]="custaddress2">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'city' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'city' | translate}}" disabled
               [(ngModel)]="custcity">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'state' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'state' | translate}}" disabled
               [(ngModel)]="custstate">
            </mat-form-field>
         </div>
         <div>
            <mat-form-field appearance="fill">
               <mat-label>{{'pincode' | translate}}</mat-label>
               <input matInput #input required placeholder="{{'pincode' | translate}}" disabled
               [(ngModel)]="custpin">
            </mat-form-field>
         </div>
      </div>
   </div>
  
   <div class="margin">
      <div  *ngFor="let e of mobiOrder?.repairIssues;let index = index;" >
         <form [formGroup]="procurementdetails" class="example-form-fields"fxLayout.xs="column">               
         <mat-form-field appearance="fill">
            <mat-label>{{'problem' | translate}}</mat-label>
            <input matInput #input required placeholder="{{'problem' | translate}}"
            [(ngModel)]="e.repairChargeDto!.issueDto!.issueName"[ngModelOptions]="{standalone: true}">
         </mat-form-field>
         
            <mat-form-field appearance="fill">
               <mat-label>{{'procurement_vendor' | translate}}</mat-label>
               <mat-select formControlName="vendorName">
                  <mat-option value={{procurementVendorList.procurementVendorName}} *ngFor="let procurementVendorList of ProcurementVendorList">
                     {{procurementVendorList.procurementVendorName}}
                  </mat-option>
               </mat-select>
             </mat-form-field>
            
         
 
         
         <mat-form-field appearance="fill">
            <mat-label>{{'procurement_Amount' | translate}}</mat-label>
            <input matInput formControlName="expenseAmount" #input required placeholder="{{'procurement_Amount' | translate}}" >
         </mat-form-field>
         <button  (click)="AddExpense(e)"  mat-raised-button class="button1">+</button>
         </form>
      </div>
   </div>
   <span class="show-link" (click)="openProcurementVendors()">{{'add_New_Vendor' | translate}}</span>
   
      <table mat-table [dataSource]="totalExpense">
         <ng-container matColumnDef="Problem">
            <th mat-header-cell *matHeaderCellDef   class="mat-header-ref" >{{'Problems' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-def">
               <div>
                  {{element.repairChargeDto.issueDto.issueName}}
               </div>
            </td>
         </ng-container>
         <ng-container matColumnDef="Vendor">
            <th mat-header-cell *matHeaderCellDef   class="mat-header-ref" >{{'vendor_name' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-def">
               <div *ngIf="element.expense!= null">
                  {{element.expense.vendorName}}
               </div>
               <div *ngIf="element.expense == null">
                  -
               </div>
            </td>
         </ng-container>
         <ng-container matColumnDef="Amount">
            <th mat-header-cell *matHeaderCellDef   class="mat-header-ref" >{{'Amount' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-def">
               <div *ngIf="element.expense!= null">
                  {{element.expense.expenseAmount | currency:'INR'}}
               </div>
               <div *ngIf="element.expense == null">
                  -
               </div>
            </td>
         </ng-container>
         <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef   class="mat-header-ref" >{{'Action' | translate}}</th>
            <td mat-cell *matCellDef="let element" class="mat-cell-def">
               <div *ngIf="element.expense!= null">
                  <button mat-icon-button (click)="DeleteExpense(element)">
                  <span class="material-symbols-outlined edit cursor-a">delete</span>
                  </button>
               </div>
               <div *ngIf="element.expense == null">
                 -
               </div>
            </td>
         </ng-container>
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div  fxLayout="row" fxLayoutAlign="center">
         <button class="button" type="reset" mat-raised-button (click)="updateOrder()">{{'Update' | translate}}</button>
      </div>
</mat-card>