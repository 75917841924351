import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { SupportTicketModel } from 'src/app/data/support-ticket-model/support-ticket.model';
import { OrderDetailsService } from 'src/app/services/order-details.service';
import { ViewOraddSupportComponent } from '../view-oradd-support/view-oradd-support.component';

@Component({
  selector: 'my-support',
  templateUrl: './my-support.component.html',
  styleUrls: ['./my-support.component.scss']
})

export class MysupportComponent implements OnInit{
  supportTicketList:SupportTicketModel[] = [];
  UserId:any;
  pageNo:any;
  DataLength:any;
  pageSize:any;
  sortValue: any;

  constructor(private ngxService: NgxUiLoaderService,private orderDetailsService: OrderDetailsService,public dialogRef: MatDialog ){}
  
  ngOnInit(): void {
    this.pageNo = 0;
    this.sortValue = "ASC";
    this.UserId = localStorage.getItem('userId')?.toString();
    this.getAllSupportIssuesByUserId(this.pageNo);
    this.getAllSupportIssuesByUserIdCount();
  }

  sort(event) {
    this.pageNo = 0;
    if (event.direction == "asc") {
      this.sortValue = "ASC";
    } else if (event.direction == "desc") {
      this.sortValue = "DESC";
    } else {
      this.sortValue = event.direction;
    }
    this.getAllSupportIssuesByUserId(this.pageNo);
   
  }
  pageEvents(event: any) {
    this.pageSize = event.pageSize;
    if(event.pageIndex > this.pageNo){
      this.getAllSupportIssuesByUserId(event.pageIndex);
      this.pageNo = event.pageIndex;
    }else{
      this.getAllSupportIssuesByUserId(event.pageIndex);
      this.pageNo = event.pageIndex;
    }
  }

  getAllSupportIssuesByUserId(pageNo: any) {
    let supportIssueUrl = AppConstants.supportService + AppConstants.getAllSupportIssuesByUserId +"?userId=" + this.UserId
    + "&sortOrder=" + this.sortValue + '&offSet=' + this.pageNo ;
    this.ngxService.start();
    this.orderDetailsService
      .getAllOrderByUserId(supportIssueUrl)
      .subscribe({
        next: (data: any) => {
          this.supportTicketList = data;
          this.ngxService.stop();
        },
        error: (error: any) => {
          console.log(error);
          this.ngxService.stop();
        },
      });
  }

  getAllSupportIssuesByUserIdCount() {
    let supportIssueUrlCount = AppConstants.supportService + AppConstants.getAllSupportIssuesCountByUserId +"?userId=" + this.UserId;
    this.ngxService.start();
    this.orderDetailsService
      .getAllOrderByUserId(supportIssueUrlCount)
      .subscribe({
        next: (data: any) => {
          this.DataLength = data;
          this.ngxService.stop();
        },
        error: (error: any) => {
          console.log(error);
          this.ngxService.stop();
        },
      });
  }

  viewOrAddSupport(){
    let dialogRef = this.dialogRef.open(ViewOraddSupportComponent, {
      height: '100%',
       width: '100%',
      disableClose:true,
      
    });
    dialogRef.afterClosed().subscribe(info=>{
      this.getAllSupportIssuesByUserId(this.pageNo);
    })
  }

}
