<h2>{{'address' | translate}}<a class="cursor-b" (click)="onClose()">X</a></h2>
<div class="cardd">
   <form [formGroup]="editaddressForm">
      <div class="row">
         <div class="col-md-6">
            <mat-form-field appearance="outline">
               <mat-label>{{'address_first_line' | translate}}</mat-label>
               <input matInput formControlName="addressLine1" placeholder="{{'address_first_line' | translate}}">
            </mat-form-field>
         </div>
         <div class="col-md-6">
            <mat-form-field appearance="outline">
               <mat-label>{{'address_first_line2' | translate}}</mat-label>
               <input matInput formControlName="addressLine2" placeholder="{{'address_first_line2' | translate}}">
            </mat-form-field>
         </div>
         <div class="col-md-6">
            <mat-form-field appearance="outline">
               <mat-label>{{'state' | translate}}</mat-label>
               <mat-select formControlName="state" (selectionChange)="changeState($event.value)">
                  <mat-option *ngFor="let state of stateList" value={{state.name}}>{{state.name}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="col-md-6">
            <mat-form-field appearance="outline">
               <mat-label>{{'city' | translate}}</mat-label>
               <mat-select formControlName="city">
                  <mat-option *ngFor="let city of cityList" value={{city.name}}>{{city.name}}</mat-option>
               </mat-select>
            </mat-form-field>
         </div>
         <div class="col-md-6">
            <mat-form-field appearance="outline">
               <mat-label>{{'pincode' | translate}}</mat-label>
               <input matInput formControlName="pincode" placeholder="{{'pincode' | translate}}">
            </mat-form-field>
         </div>
      </div>
      <div  fxLayout="row" fxLayoutAlign="center">
         <button class="button" *ngIf="showUpdateButton" type="reset" mat-raised-button (click)="updateAddress()">{{'Update' | translate}}</button>
         <button class="button" *ngIf="!showUpdateButton" type="reset" mat-raised-button (click)="AddAddress()">{{'Add_Address' | translate}}</button>
      </div>
   </form>
</div>