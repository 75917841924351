import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-bestcameraphones',
    templateUrl: './bestcameraphones.component.html'
})

export class BestcameraphonesCcomponent{
    constructor(private translate:TranslateService,private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService)
    {
        this.metaService.updateTag({name:'description',content:"Discover the best camera phones under 20000 in India! With this comprehensive guide, you can find the perfect camera phone for your budget and needs."})
        this.metaService.updateTag({name:'keywords',content:'best camera phones under 20000, camera phone, best phone under 20000'})
        this.canonicalServide.setCanonicalURL();
        
        this.translate.get('best_Camera_Phones').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });

        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('best_Camera_Phones').subscribe((res: string) => {
                this.titleService.setTitle(res);
        });
        });   
    }      
}