import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppAdminRouteConstants } from 'src/app/constants/app-admin-route.constants';
import { AppConstants } from 'src/app/constants/app.constants';
import { CaseModel } from 'src/app/data/case-model/case.model';
import { MobiOrderModel } from 'src/app/data/mobi-order-model/mobi-order.model';
import { ServiceAreaModel } from 'src/app/data/serive-area-model/service-area.model';
import { ServiceCenterAreaModel } from 'src/app/data/service-center-area-model/service-center-area.model';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-transit',
  templateUrl: './transit.component.html',
  styleUrls: ['./transit.component.scss']
})
export class TransitComponent extends BaseComponent implements OnInit {
  getCaseDataUrl: string;
  getOrderDetailsUrl: string;
  updateClientCaseUrl: string;
  getServiceCenterByUserIdUrl: string;
  caseData: CaseModel;
  mmCaseId: number;
  orderData: any;
  transitSheetStatus: string;
  serviceAreaAddress: ServiceAreaModel;
  serviceCenter: ServiceCenterAreaModel;
  constructor(
    dialogRef: MatDialogRef<TransitComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dashboardService: DashboardService,
    router: Router,
    snackBar: MatSnackBar,
    route: ActivatedRoute,
    dialog: MatDialog) {
    super();
    this.dialogRef = dialogRef;
    this.router = router;
    this.snackBar = snackBar;
    this.route = route;
    this.dialog = dialog;
    this.mmCaseId = data['mmCaseId'];
    this.getCaseDataUrl = AppConstants.supportService + AppConstants.GET_CASE + '?id=' + this.mmCaseId;
    this.getCaseDataAPI();

  }

  ngOnInit(): void {
  }

  statusChange() {
    console.log('case status-->' + this.caseData.caseStatus);
    this.updateClientCaseUrl = AppConstants.supportService + AppConstants.UPDATE_CASE_STATUS
      + '?id=' + this.caseData.mmCaseId;
    this.updateCase();

  }

  getCaseDataAPI() {
    this.dashboardService.getRequest(this.getCaseDataUrl)
        .subscribe(
            res => {
                this.passCaseDataResponse(res);
            },
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

getOrderData() {
    this.dashboardService.getRequest(this.getOrderDetailsUrl)
        .subscribe(
            res => {
                this.passOrderData(res);
            },
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

updateCase() {
    this.dashboardService.getRequest(this.updateClientCaseUrl)
        .subscribe(
            res => {
                this.dialogRef.close(res);
                this.passUpdateClientCaseResponse(res);
            },
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

getServiceCenterAddress(){
    this.dashboardService.getRequest(this.getServiceCenterByUserIdUrl)
        .subscribe(
            res => {
              console.log(res)
                this.passServiceCenterAddress(res);
            },
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

  protected passCaseDataResponse(res: any) {
    this.caseData = res;
    let greatervalue = 0;
    let assignedUserId;
    this.caseData.caseAssignmentHistoryDtos?.forEach(x => {
      if (greatervalue < x.caseAssignmentHistoryId!) {
        greatervalue = x.caseAssignmentHistoryId!;
        assignedUserId = x.userId;
      }
    });
    this.caseData.assignedUserId = assignedUserId;
    console.log("case status", res);
    this.getOrderDetailsUrl = AppConstants.orderService + AppConstants.getOrderRequest + '?id=' + this.caseData.orderId;
    this.getOrderData();
    // this.getServiceCenterByUserIdUrl = AppConstants.userService + AppConstants.getUserById + '?id=' +  this.caseData.assignedUserId;
    // this.getServiceCenterAddress();
  }

  protected passOrderData(res: any) {
    this.orderData = res;
    console.log("order Data", this.orderData);
    console.log("order Data res", res);
  }

  protected passUpdateClientCaseResponse(res: any) {
    this.routeTo(AppAdminRouteConstants.getRoute(AppAdminRouteConstants.DASHBOARD_ROUTE),res);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  passServiceCenterAddress(res: any){
    this.serviceCenter = res;
    this.serviceAreaAddress =  this.serviceCenter.serviceAreaDto!;
  }
}
