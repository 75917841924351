<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
<link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap" rel="stylesheet">
    

<div fxLayout="column" fxLayoutAlign="space-between " fxLayoutGap="40" class="every-repair-section bg-color-annual-section"  >
<section class="header-top">
 <div class="container">
        <div class="row">
        <div class="col-md-8">
            <h2 class="every-repair-heading-small2 ">{{planName | translate}}</h2> 
            <h1 class="every-repair-heading" >{{'annual_Protection_Plans' | translate}}</h1> 
            <h2 class="every-repair-heading-small" >{{'live_without_worrying_about_repairs' | translate}}</h2>  
        </div>
        <div class="col-md-4">
            <div class="order-now-btn" >
                <button class="order-btn" (click)="NavigateToOrderPage()">{{'buy_Now' | translate}}</button>
                <!-- <div class="order-now-class" (click)="showComparePlans()">{{'compare_plans' | translate}}</div> -->
            </div>
        </div>
        
    </div>
    </div>
</section>   

<div class="container">
<div fxLayout="row" fxLayoutAlign="space-between center" class="">
<div class="col-md-12">
<div class="every-repair-heading-small2">{{'what_is' | translate}} <span class="color-red">{{'covered' | translate}}</span> {{'in' | translate}} <span class="">{{planName | translate}}</span></div>

  
<div fxLayout="column" *ngIf="planName=='all_in_one'" class="margin-bottom40" fxLayoutAlign="space-between stretch" fxLayout.xs="column" fxLayout.md="column" fxLayout.sm="column">
    <div fxLayout="row"  fxLayoutAlign="space-between center">
        
        <ul class="ulspace">
            <li *ngFor="let points of all_in_one" class="every-repair-heading-small4">{{points | translate}}</li>
        </ul>
    </div>
</div>

</div>    

</div>
</div>

   


</div>
<div class="container">
<div fxLayout="row" fxLayoutAlign="space-between bot-textplan center" class="">
<div class="col-md-12">
<div fxLayout="column" fxLayoutAlign="space-between" *ngIf="planName!='shield_plan'" fxLayoutGap="40" class="every-repair-section "  >
   
    <div fxLayout="row" fxLayoutAlign="space-between center"  *ngIf="planName=='assistance_Plan'" class="margin-top20">
        <h2 class="every-repair-heading-small2" style="margin-bottom: 0;">{{'what_is' | translate}} <span class="color-red">{{'not'|translate}} {{'covered' | translate}}</span> {{'in' | translate}} <span class="">{{planName | translate}}</span></h2>
    </div>

    <div fxLayout="column" *ngIf="planName=='assistance_Plan'" class="margin-bottom40" fxLayoutAlign="space-between stretch" fxLayout.xs="column" fxLayout.md="column" fxLayout.sm="column">
        <div fxLayout="row"  fxLayoutAlign="space-between center">
            <table class="margin-left20">
                <tr class="word-wrap-break-word margin-bottom40" *ngFor="let points of assitancePlanNotCovered">
                    <td>
                        <li class="every-repair-heading-small5">{{points | translate}}</li>
                    </td>
                    <td>
                        <b><div class="order-now-btn1 margin-left20">
                        <button class="order-btn1" (click)="NavigateToShield()">{{'covered' | translate}} {{'in' | translate}} <b>{{'shield_plan' | translate}}</b></button></div></b>
                    </td>
                </tr>
            </table>
        </div>            
    </div>

    <div fxLayout="column" *ngIf="planName=='extended_Warranty'" class="margin-bottom40" fxLayoutAlign="space-between stretch" fxLayout.xs="column" fxLayout.md="column" fxLayout.sm="column">
        <div fxLayout="row"  fxLayoutAlign="space-between center"  >
            <table class="margin-left20">
                <tr class="word-wrap-break-word margin-bottom40" *ngFor="let points of extendedWarrantyPlanNotCovered">
                    <td>
                        <li class="every-repair-heading-small5">{{points | translate}}</li>
                    </td>
                    <td>
                        <b><div class="order-now-btn1 margin-left20">
                        <button class="order-btn1" (click)="NavigateToShield()">{{'covered' | translate}} {{'in' | translate}} <b>{{'shield_plan' | translate}}</b></button></div></b>
                    </td>
                </tr>
            </table>
        </div>      
    </div>
</div>
</div>
</div>
</div>
