import { ExpenseTypeModel } from "../Expense-type-model/expense-type.model";
import { OrderRepairIssueModel } from "../order-repair-issue-model/order-repair-issue.model";

export class ExpenseModel {
    expenseId?: any;
    orderId?: number;
    expenseDetails?: string;
    vendorName?: string;
    expenseDate?: Date;
    expenseAmount?: number;
    expenseType?: string;
    repairIssueDto:OrderRepairIssueModel
}

    

    

    