import { environment } from "src/environments/environment";

export class AppConstants {
    public static BASE_URL = environment.baseUrl;
    /**service backend*/
    public static authServiceUrl = '/auth';
    public static userService = '/user';
    public static orderService = '/order';
    public static supportService = '/support';
    public static paymentService = '/payment';
    public static contactService = '/contact';
    public static notificationService = '/notification';
    /**Action API*/
    public static generateForgotPasswordLink = "/generateForgotPasswordLink";
    public static forgotPassword = "/forgotPassword";
    public static getAllExpenseType = "/getAllExpenseType";
    public static addExpense = "/addExpense";
    public static getUsers = "/getUsers"
    public static getRoles = "/getRoles"
    public static getAllRoles = "/getAllRoles";
    public static getUsersCount = "/getUsersCount";
    public static getAllSupportIssuesCount = "/getAllSupportIssuesCount";
    public static inReviewVendorsCount = "/inReviewVendorsCount";
    public static getAllServiceAreasCount = "/getAllServiceAreasCount";
    public static getAllServiceCentersCount = "/getAllServiceCentersCount";
    public static getRolesCount = "/getRolesCount";
    public static totalExpense = '/totalExpense';
    public static getPaymentAmount = "/getPaymentAmount";
    public static getAllExpenses = '/getAllExpenses';
    public static getPaymentCount = "/getPaymentCount";
    public static getAllBrands = "/getAllBrandsClient";
    public static getRepairIssues = "/getRepairIssues";
    public static getRepairCharges = "/getRepairChargesClient";
    public static getRepairChargesByAdmin = "/getRepairChargesClientByAdmin";
    public static getIssuesByDeviceContractsId = "/getIssuesByDeviceContractsId";
    public static updateOrder = "/updateOrder";
    public static updateOrderStatus = "/updateOrderStatus";
    public static addProduct = "/addProduct";
    public static deleteProduct = "/deleteProduct";
    public static getAddresses = "/getAddresses";
    public static addAddress = "/addAddress";
    public static updateUserAddress = "/updateUserAddress";
    public static getDeviceContractsByNameAndAmount = "/getDeviceContractsByNameAndAmount";
    public static addAddressByUserId = "/addAddressByUserId"
    public static getModels = "/getModelsClient";
    public static UNMAPPED_ISSUES = "/getUnmappedIssues";
    
    public static socialLoginAction = "/socialUserAuth";
    public static generateOTP = "/generateOtp";
    public static getGoldOrSilverMember = "/getGoldOrSilverMember";

    public static retryOtp = "/retryOtp";
    public static verifyOtpAndCreateUser = "/verifyOtpAndCreateUser";
    public static verifyOtp = "/verifyOtp";
    public static sendOrderConfirmationMail = "/sendOrderConfirmationMail";
    public static login = "/authenticate";
    public static register = "/registerUser";
    public static activateUser = "/activateAccount";
    public static createMMOrder = "/createMMOrder";
    public static getAllOrderByUserId = "/getAllOrderByUserId";
    public static getOrders = "/getOrders";
    public static getOrdersIds = "/getOrdersIds";
    public static getPayments = "/getPayments";
    public static getAllSupportIssueCount = "/getAllSupportIssueCount";
    public static getUserDetailsAction = "/getUserDetails";
    public static updateUserDetails = "/updateUser";
    public static getUserByIdUrl = "/getUserById";
    public static getOrderRequest = "/getOrderById";
    public static getPaytmPaymentData = "/getPaytmPaymentData";
    public static getRazorPayPaymentData = "/getRazorPayPaymentData";
    public static createPayment = "/createPayment";
    public static createCustomerAppState = "/createCustomerAppState";
    public static updateCusAppState = "/updateCusAppState";
    public static deleteRepairIssue = "/deleteRepairIssue";
    public static paymentResponse: "/payment-response";
    public static cancelOrder = "/cancelOrder";
    public static addVendor= "/addVendor";
    public static validateVendor = "/validateVendor";
    public static GET_ALL_DEPARTMENTS = "/getDepartmentList";
    public static getEmailByVendorId = "/getEmailByVendorId";
    public static validateIMEINumber = "/validateIMEINumber";
    public static geModelContractByModelId = "/geModelContractByModelId";
    public static getPurchasesByUserId = "/getPurchasesByUserId";
    public static getAllPurchasePlanByPurchaseId = "/getAllPurchasePlanByPurchaseId";
    public static getUser = "/getUser";
    public static fileUpload = "/uploadFile";
    public static ADD_BRNAD = "/createBrand";
    public static getAllStates = "/getAllStates"
    public static getAllCitiesByStateName = "/getAllCitiesByStateName";
    public static saveRazorPaySuccessInfo = "/saveRazorPaySuccessInfo";
    public static getOrdersCount = "/getOrdersCount";
    public static getUserDetailsByMobileNumber = "/getUserDetailsByMobileNumber";
    public static getAllProducts = "/getAllProducts";
    public static getRepairChargesByIssueName = '/getRepairChargesByIssueName';
    public static validatePromoCode = "/validatePromoCode";
    public static getAllBrandsClientByCategory = "/getAllBrandsClientByCategory";
    public static getACtypes = "/getACTypes";
    public static getRefrigeratorTypes = "/getRefrigeratorTypes";
    public static getWashingMachineTypes = "/getWashingMachineTypes";
    public static getRepairChargesFromMobiMech = "/getRepairChargesFromMobiMech";
    public static getGstCategory = "/getGstCategory";
    public static loginWithGoogle = "/loginWithGoogle";
    public static ADD_DEVICE = "/createModel";
    public static UPDATE_REPAIR_CHARGE = "/updateRepairCharge";
    public static UPDATE_MODEL_CONTRACT = "/updateModelContract";
    public static ADD_REPAIR_ISSUE = "/createIssue";
    public static MAPPED_REPAIR_CHARGES = "/getMappedRepairCharges";
    public static MAPPED_DEVICE_CONTRACTS = "/getModelContractByModelId";
    public static geModelContractByModelIdCount = "/geModelContractByModelIdCount";
    public static getMappedRepairChargesCount = "/getMappedRepairChargesCount";
    public static GET_All_REPAIR_MODE = "/getAllRepairMode";
    public static ADD_REPAIR_CHARGE = "/createRepairCharge";
    public static generatePaymentLinkByOrderId = "/generatePaymentLinkByOrderId";
    public static getGeoCoordinates="/getGeoCoordinates";
    public static getAddressByCoordinates="/getAddressByCoordinates";
    public static getSingleClosestVendorByCoordinates = "/getSingleClosestVendorByCoordinates";
    public static getAdminWithRepairCharges = "/getAdminWithRepairCharges";
    public static getUserById = "/getUserById";
    public static updateAddressForMyAccount = "/updateAddressForMyAccount";
    public static updateAddressToPrimary = "/updateAddressToPrimary";
    public static deleteAddress = "/deleteAddress";
    public static getAddressByAddressId = "/getAddressByAddressId";
    public static getSalesDashboard ="/getSalesDashboard";
    public static getVendorsDashboard ="/getVendorsDashboard";
    public static getAllVendorsCount = "/getAllVendorsCount";
    public static getRepairDashboard = "/getRepairDashboard";
    public static getMMCaseByOrderId = "/getMMCaseByOrderId";
    public static updateBrand = "/updateBrand";
    public static updateDevice = "/updateDevice";
    public static updateActualCostForOrderId = "/updateActualCostForOrderId";
    public static calculatePaymentBasedOnAllConditions = "/calculatePaymentBasedOnAllConditions";
    public static getAllSupportIssueByUserId = "/getAllSupportIssueByUserId";
    public static getAllSupportIssuesByUserId = "/getAllSupportIssuesByUserId";
    public static getAllSupportIssuesCountByUserId = "/getAllSupportIssuesCountByUserId";
    public static createSupportIssue = "/createSupportIssue";
    public static addUser = "/addUser";
    public static addRole = "/addRole";
    public static deleteRole = "/deleteRole";
    public static updateRole = "/updateRole";
    public static updateUser = "/updateUser";
    public static addProcurementVendor = "/addProcurementVendor";
    public static getAllProcurementVendor = "/getALlProcurementVendor";
    public static deleteExpense = "/deleteExpense";
    public static cancelCase = "/cancelCase";
    public static deleteAccount = "/deleteAccount";
    public static updateUserStatus = "/updateUserStatus";
    public static createContactIssue = "/createContactIssue";
    /** search apis */
    public static GET_MODEL_BY_NAME = "/getModelsByName";
    /**local storage keys*/
    public static userDataKey = "user_data";
    public static userAuthData = "user_auth_data";
    public static socialLoginFlag = "s_login_flag";
    public static userDetailsKey = "user_details";
    //regex patterns
    public static passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=])(?=\S+$).{8,}$/;
    public static mobileRegex = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/;
    public static emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    public static emailAddressRegex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    public static vendorIdRegex = /^[0-9]{5,}$/;
    public static containsAlphabet = /[a-zA-Z]/;
    /*event bus action */
    public static SEARCH_RESULT_BRAND_DEVICE = 'search_result_brand_device';
    public static PAYMENT_DONE = 'payment_completed';
    public static EDIT_ISSUES = 'edit_issues';
    public static EDIT_BRAND = 'editBrand';
    public static STEP_BRAND = 'step_brand';
    public static STEP_VENDOR = 'step_vendor';
    public static INITIATE_PAYMENT = 'initiate_payment';
    public static STEP_DEVICE = 'step_device';
    public static EDIT_DEVICE = 'edit_device';
    public static STEP_ADDRESS = 'step_address';
    public static EDIT_ADDRESS = 'edit_address';
    public static ENABLE_PAYMENT = 'enable_payment';

    /*snack bar css clasS names*/
    public static SNACK_BAR_SUCESS_CLASS = 'snackbar-success';
    public static SNACK_BAR_ERROR_CLASS = 'snackbar-error';
    public static SNACK_BAR_WARNING_CLASS = 'snackbar-warning';

    /** Event bus event actions */
    public static ET_USER_EMAIL = "USER_EMAIL_PUSH";
    public static ET_USER_LOG_OUT = "USER_LOGGED_OUT";
    public static ET_USER_LOG_IN = "USER_LOGGED_IN";

    /** Warning Messages */
    public static UPDATE_THE_VENDOR_MESSAGE = "You have changed the Address so vendors of this location has been changed<br>Please Decide whether you want to change the address or update the vendor";
    public static PLAN_BRAND_MISMATCH_MESSAGE = "This brand is not covered in your purchased plan so you have to pay the charges As per order";
    public static PLAN_Model_MISMATCH_MESSAGE = "This model is not covered in your purchased plan so you have to pay the charges As per order";

    public static updateAddressError = "updateAddress";
    public static prepairingOrderError = "prepairingOrder";
    public static getUserByIdError = "getUserById";
    public static createMMOrderError = "createMMOrder";
    public static createPaymentError = "createPayment";
    public static redirectingToPaytmError = "redirectingToPaytm";
    public static MakeRazoryPayOrderError = "MakeRazoryPayOrder";
    public static somethingWentWrongErrorMsg = "Something Went Wrong!! Please Try Again.";
    public static paytmErrorMsg = "Your payment has been declined by your bank.";
    public static paytmErrorMsg1 = "Please try again or use a different method to complete the payment.";
    public static couponErrorMsg = "Invalid promo code. Please try again for great savings!";



    /**Event Bus action keys*/
  public static EVN_BS_USER_ROLE = "user_role_evn_bus";

  /** Admin App Constants */
   /**API Endpoint*/
   public static GET_USER_DATA = "/getUser";
   public static GET_USER_BY_ID = "/getUserDetailsById";
   public static GET_CASE_ASSIGNMENT_BY_USERID = "/getCaseAssignmentHistoriesByUserId";
   public static GET_ROLES = "/manageRoles";
   public static ADD_ADMIN_USER = "/addAdminUser";
   public static UPDATE_ADMIN_USERS = "/updateUser";
   public static GET_DASHBOARD_DATA = "/getDashBoardList";
   public static GET_CASE = "/getMMCase";
   public static UPDATE_CASE_STATUS = "/updateMMCaseStatus";
   public static UPDATE_REPAIR_ISSUE = "/updateRepairIssue";
   public static GET_ALL_BRANDS = "/getAllBrands";
   public static GET_ALL_MODELS = "/getModels";
   public static GET_SERVICE_AREA_LIST = "/getAllServiceAreas";
   public static CREATE_SERVICE_AREA = "/createServiceArea";
   public static UPDATE_SERVICE_AREA = "/updateServiceArea";
   public static GET_SERVICE_CENTER_LIST = "/getAllServiceCenters";
   public static CREATE_SERVICE_CENETER = "/createServiceCenter";
   public static UPDATE_SERVICE_CENTER = "/updateServiceCenter";
   public static GET_ADMIN_USER_LIST = "/getAdminUsers";
   public static CREATE_DEVICE_CONTRACT = "/createDeviceContract";
   public static UN_MAPPED_DEVICE_CONTRACTS = "/getUnMappedDeviceContracts";
   public static CREATE_MODEL_CONTRACT = "/createModelContract";
   public static SEARCH_DEVICE_CONTRACT = "/searchDeviceContract";
   public static GET_REPAIR_ISSUES = "/getRepairIssues";
   public static getActionTrack = "/getActionTrack";
   public static getPermissions = "/getAllPermissions";
   public static getActionTrackCount = "/getActionTrackCount";
   public static inReviewVendors = "/inReviewVendors";
   public static approveVendor = "/approveVendor";
   public static updateSupportIssue = "/updateSupportIssue";
   public static getAllSupportIssue = "/getAllSupportIssue";

   /**Local storage keys */
   public static LS_USER_DATA_KEY = 'admin_user_data';
   public static LS_AUTH_DATA_KEY = 'admin_auth_data';
   public static LS_USER_PERMISSINO = 'admin_user_permissions';
   public static LS_USER_ROLE = 'admin_user_role';

    public static stateCityList = {
        'Andhra Pradesh (AP)': [
          'Adilabad',
          'Anantapur',
          'Chittoor',
          'Kakinada',
          'Guntur',
          'Hyderabad',
          'Karimnagar',
          'Khammam',
          'Krishna',
          'Kurnool',
          'Mahbubnagar',
          'Medak',
          'Nalgonda',
          'Nizamabad',
          'Ongole',
          'Hyderabad',
          'Srikakulam',
          'Nellore',
          'Visakhapatnam',
          'Vizianagaram',
          'Warangal',
          'Eluru',
          'Kadapa'
        ],
        'Arunachal Pradesh (AR)': [
          'Anjaw',
          'Changlang',
          'East Siang',
          'Kurung Kumey',
          'Lohit',
          'Lower Dibang Valley',
          'Lower Subansiri',
          'Papum Pare',
          'Tawang',
          'Tirap',
          'Dibang Valley',
          'Upper Siang',
          'Upper Subansiri',
          'West Kameng ',
          'West Siang',
        ],
        'Assam (AS)': [
          'Baksa',
          'Barpeta',
          'Bongaigaon',
          'Cachar',
          'Chirang',
          'Darrang',
          'Dhemaji',
          'Dima Hasao',
          'Dhubri',
          'Dibrugarh',
          'Goalpara',
          'Golaghat',
          'Hailakandi',
          'Jorhat',
          'Kamrup',
          'Kamrup Metropolitan',
          'Karbi Anglong',
          'Karimganj',
          'Kokrajhar',
          'Lakhimpur',
          'Marigaon',
          'Nagaon',
          'Nalbari',
          'Sibsagar',
          'Sonitpur',
          'Tinsukia',
          'Udalguri',
        ],
        'Bihar (BR)': [
          'Araria',
          'Arwal',
          'Aurangabad',
          'Banka',
          'Begusarai',
          'Bhagalpur',
          'Bhojpur',
          'Buxar',
          'Darbhanga',
          'East Champaran',
          'Gaya',
          'Gopalganj',
          'Jamui',
          'Jehanabad',
          'Kaimur',
          'Katihar',
          'Khagaria',
          'Kishanganj',
          'Lakhisarai',
          'Madhepura',
          'Madhubani',
          'Munger',
          'Muzaffarpur',
          'Nalanda',
          'Nawada',
          'Patna',
          'Purnia',
          'Rohtas',
          'Saharsa',
          'Samastipur',
          'Saran',
          'Sheikhpura',
          'Sheohar',
          'Sitamarhi',
          'Siwan',
          'Supaul',
          'Vaishali',
          'West Champaran'
        ],
        'Chandigarh': ['Chandigarh'],
        'Chhattisgarh (CG)': [
          'Bastar',
          'Bijapur',
          'Bilaspur',
          'Dantewada',
          'Dhamtari',
          'Durg',
          'Jashpur',
          'Janjgir-Champa',
          'Korba',
          'Koriya',
          'Kanker',
          'Kabirdham (Kawardha)',
          'Mahasamund',
          'Narayanpur',
          'Raigarh',
          'Rajnandgaon',
          'Raipur',
          'Surguja'
        ],
        'Dadra and Nagar Haveli (DN)': ['Dadra and Nagar Haveli'],
        'Daman and Diu (DD)': [
          'Daman ',
          'Diu'
        ],
        'Delhi (DL)': [
          'Central Delhi',
          'East Delhi',
          'New Delhi',
          'North Delhi',
          'North East Delhi',
          'North West Delhi',
          'South Delhi',
          'South West Delhi',
          'West Delhi'
        ],
        'Goa (GA)': [
          'North Goa',
          'South Goa',
        ],
        'Gujarat (GJ)': [
          'Ahmedabad',
          'Amreli district',
          'Anand',
          'Banaskantha',
          'Bharuch',
          'Bhavnagar',
          'Dahod',
          'The Dangs',
          'Gandhinagar',
          'Jamnagar',
          'Junagadh',
          'Kutch',
          'Kheda',
          'Mehsana',
          'Narmada',
          'Navsari',
          'Patan',
          'Panchmahal',
          'Porbandar',
          'Rajkot',
          'Sabarkantha',
          'Surendranagar',
          'Surat',
          'Vyara',
          'Vadodara',
          'Valsad'
        ],
        'Haryana (HR)': [
          'Ambala',
          'Bhiwani',
          'Faridabad',
          'Fatehabad',
          'Gurgaon',
          'Hissar',
          'Jhajjar',
          'Jind',
          'Karnal',
          'Kaithal',
          'Kurukshetra',
          'Mahendragarh',
          'Mewat',
          'Palwal',
          'Panchkula',
          'Panipat',
          'Rewari',
          'Rohtak',
          'Sirsa',
          'Sonipat',
          'Yamuna Nagar'
        ],
        'Himachal Pradesh (HP)': [
          'Bilaspur',
          'Chamba',
          'Hamirpur',
          'Kangra',
          'Kinnaur',
          'Kullu',
          'Lahaul and Spiti',
          'Mandi',
          'Shimla',
          'Sirmaur',
          'Solan',
          'Una'
        ],
        'Jammu and Kashmir (JK)': [
          'Anantnag',
          'Badgam',
          'Bandipora',
          'Baramulla',
          'Doda',
          'Ganderbal',
          'Jammu',
          'Kargil',
          'Kathua',
          'Kishtwar',
          'Kupwara',
          'Kulgam',
          'Leh',
          'Poonch',
          'Pulwama',
          'Rajauri',
          'Ramban',
          'Reasi',
          'Samba',
          'Shopian',
          'Srinagar',
          'Udhampur'
        ],
        'Jharkhand (JH)': [
          'Bokaro',
          'Chatra',
          'Deoghar',
          'Dhanbad',
          'Dumka',
          'East Singhbhum',
          'Garhwa',
          'Giridih',
          'Godda',
          'Gumla',
          'Hazaribag',
          'Jamtara',
          'Khunti',
          'Koderma',
          'Latehar',
          'Lohardaga',
          'Pakur',
          'Palamu',
          'Ramgarh',
          'Ranchi',
          'Sahibganj',
          'Seraikela Kharsawan',
          'Simdega',
          'West Singhbhum'
        ],
        'Karnataka (KA)': [
          'Bagalkot',
          'Bangalore Rural',
          'Bangalore Urban',
          'Belgaum',
          'Bellary',
          'Bidar',
          'Bijapur',
          'Chamarajnagar',
          'Chikkamagaluru',
          'Chikkaballapur',
          'Chitradurga',
          'Davanagere',
          'Dharwad',
          'Dakshina Kannada',
          'Gadag',
          'Gulbarga',
          'Hassan',
          'Haveri district',
          'Kodagu',
          'Kolar',
          'Koppal',
          'Mandya',
          'Mysore',
          'Raichur',
          'Shimoga',
          'Tumkur',
          'Udupi',
          'Uttara Kannada',
          'Ramanagara',
          'Yadgir'
        ],
        'Kerala (KL)': [
          'Alappuzha',
          'Ernakulam',
          'Idukki',
          'Kannur',
          'Kasaragod',
          'Kollam',
          'Kottayam',
          'Kozhikode',
          'Malappuram',
          'Palakkad',
          'Pathanamthitta',
          'Thrissur',
          'Thiruvananthapuram',
          'Wayanad'
        ],
        'Madhya Pradesh (MP)': [
          'Alirajpur',
          'Anuppur',
          'Ashok Nagar',
          'Balaghat',
          'Barwani',
          'Betul',
          'Bhind',
          'Bhopal',
          'Burhanpur',
          'Chhatarpur',
          'Chhindwara',
          'Damoh',
          'Datia',
          'Dewas',
          'Dhar',
          'Dindori',
          'Guna',
          'Gwalior',
          'Harda',
          'Hoshangabad',
          'Indore',
          'Jabalpur',
          'Jhabua',
          'Katni',
          'Khandwa (East Nimar)',
          'Khargone (West Nimar)',
          'Mandla',
          'Mandsaur',
          'Morena',
          'Narsinghpur',
          'Neemuch',
          'Panna',
          'Rewa',
          'Rajgarh',
          'Ratlam',
          'Raisen',
          'Sagar',
          'Satna',
          'Sehore',
          'Seoni',
          'Shahdol',
          'Shajapur',
          'Sheopur',
          'Shivpuri',
          'Sidhi',
          'Singrauli',
          'Tikamgarh',
          'Ujjain',
          'Umaria',
          'Vidisha'
        ],
        'Maharashtra (MH)': [
          'Ahmednagar',
          'Akola',
          'Amravati',
          'Aurangabad',
          'Bhandara',
          'Beed',
          'Buldhana',
          'Chandrapur',
          'Dhule',
          'Gadchiroli',
          'Gondia',
          'Hingoli',
          'Jalgaon',
          'Jalna',
          'Kolhapur',
          'Latur',
          'Mumbai City',
          'Mumbai suburban',
          'Nandurbar',
          'Nanded',
          'Nagpur',
          'Nashik',
          'Osmanabad',
          'Parbhani',
          'Pune',
          'Raigad',
          'Ratnagiri',
          'Sindhudurg',
          'Sangli',
          'Solapur',
          'Satara',
          'Thane',
          'Wardha',
          'Washim',
          'Yavatmal'
        ],
        'Manipur (MN)': [
          'Bishnupur',
          'Churachandpur',
          'Chandel',
          'Imphal East',
          'Senapati',
          'Tamenglong',
          'Thoubal',
          'Ukhrul',
          'Imphal West'
        ],
        'Meghalaya (ML)': [
          'East Garo Hills',
          'East Khasi Hills',
          'Jaintia Hills',
          'Ri Bhoi',
          'South Garo Hills',
          'West Garo Hills',
          'West Khasi Hills'
        ],
        'Mizoram (MZ)': [
          'Aizawl',
          'Champhai',
          'Kolasib',
          'Lawngtlai',
          'Lunglei',
          'Mamit',
          'Saiha',
          'Serchhip'
        ],
        'Nagaland (NL)': [
          'Dimapur',
          'Kohima',
          'Mokokchung',
          'Mon',
          'Phek',
          'Tuensang',
          'Wokha',
          'Zunheboto'
        ],
        'Orissa (OR)': [
          'Angul',
          'Boudh (Bauda)',
          'Bhadrak',
          'Balangir',
          'Bargarh (Baragarh)',
          'Balasore',
          'Cuttack',
          'Debagarh (Deogarh)',
          'Dhenkanal',
          'Ganjam',
          'Gajapati',
          'Jharsuguda',
          'Jajpur',
          'Jagatsinghpur',
          'Khordha',
          'Kendujhar (Keonjhar)',
          'Kalahandi',
          'Kandhamal',
          'Koraput',
          'Kendrapara',
          'Malkangiri',
          'Mayurbhanj',
          'Nabarangpur',
          'Nuapada',
          'Nayagarh',
          'Puri',
          'Rayagada',
          'Sambalpur',
          'Subarnapur (Sonepur)',
          'Sundergarh'
        ],
        'Pondicherry (Puducherry) (PY)': [
          'Karaikal',
          'Mahe',
          'Pondicherry',
          'Yanam'
        ],
        'Punjab (PB)': [
          'Amritsar',
          'Barnala',
          'Bathinda',
          'Firozpur',
          'Faridkot',
          'Fatehgarh Sahib',
          'Fazilka',
          'Gurdaspur',
          'Hoshiarpur',
          'Jalandhar',
          'Kapurthala',
          'Ludhiana',
          'Mansa',
          'Moga',
          'Sri Muktsar Sahib',
          'Pathankot',
          'Patiala',
          'Rupnagar',
          'Ajitgarh (Mohali)',
          'Sangrur',
          'Nawanshahr',
          'Tarn Taran'
        ],
        'Rajasthan (RJ)': [
          'Ajmer',
          'Alwar',
          'Bikaner',
          'Barmer',
          'Banswara',
          'Bharatpur',
          'Baran',
          'Bundi',
          'Bhilwara',
          'Churu',
          'Chittorgarh',
          'Dausa',
          'Dholpur',
          'Dungapur',
          'Ganganagar',
          'Hanumangarh',
          'Jhunjhunu',
          'Jalore',
          'Jodhpur',
          'Jaipur',
          'Jaisalmer',
          'Jhalawar',
          'Karauli',
          'Kota',
          'Nagaur',
          'Pali',
          'Pratapgarh',
          'Rajsamand',
          'Sikar',
          'Sawai Madhopur',
          'Sirohi',
          'Tonk',
          'Udaipur'
        ],
        'Sikkim (SK)': [
          'East Sikkim',
          'North Sikkim',
          'South Sikkim',
          'West Sikkim'
        ],
        'Tamil Nadu (TN)': [
          'Ariyalur',
          'Chennai',
          'Coimbatore',
          'Cuddalore',
          'Dharmapuri',
          'Dindigul',
          'Erode',
          'Kanchipuram',
          'Kanyakumari',
          'Karur',
          'Madurai',
          'Nagapattinam',
          'Nilgiris',
          'Namakkal',
          'Perambalur',
          'Pudukkottai',
          'Ramanathapuram',
          'Salem',
          'Sivaganga',
          'Tirupur',
          'Tiruchirappalli',
          'Theni',
          'Tirunelveli',
          'Thanjavur',
          'Thoothukudi',
          'Tiruvallur',
          'Tiruvarur',
          'Tiruvannamalai',
          'Vellore',
          'Viluppuram',
          'Virudhunagar'
        ],
        'Tripura (TR)': [
          'Dhalai',
          'North Tripura',
          'South Tripura',
          'Khowai',
          'West Tripura'
        ],
        'Uttar Pradesh (UP)': [
          'Agra',
          'Allahabad',
          'Aligarh',
          'Ambedkar Nagar',
          'Auraiya',
          'Azamgarh',
          'Barabanki',
          'Budaun',
          'Bagpat',
          'Bahraich',
          'Bijnor',
          'Ballia',
          'Banda',
          'Balrampur',
          'Bareilly',
          'Basti',
          'Bulandshahr',
          'Chandauli',
          'Chhatrapati Shahuji Maharaj Nagar',
          'Chitrakoot',
          'Deoria',
          'Etah',
          'Kanshi Ram Nagar',
          'Etawah',
          'Firozabad',
          'Farrukhabad',
          'Fatehpur',
          'Faizabad',
          'Gautam Buddh Nagar',
          'Gonda',
          'Ghazipur',
          'Gorakhpur',
          'Ghaziabad',
          'Hamirpur',
          'Hardoi',
          'Mahamaya Nagar',
          'Jhansi',
          'Jalaun',
          'Jyotiba Phule Nagar',
          'Jaunpur district',
          'Ramabai Nagar (Kanpur Dehat)',
          'Kannauj',
          'Kanpur',
          'Kaushambi',
          'Kushinagar',
          'Lalitpur',
          'Lakhimpur Kheri',
          'Lucknow',
          'Mau',
          'Meerut',
          'Maharajganj',
          'Mahoba',
          'Mirzapur',
          'Moradabad',
          'Mainpuri',
          'Mathura',
          'Muzaffarnagar',
          'Panchsheel Nagar district (Hapur)',
          'Pilibhit',
          'Shamli',
          'Pratapgarh',
          'Rampur',
          'Raebareli',
          'Saharanpur',
          'Sitapur',
          'Shahjahanpur',
          'Sant Kabir Nagar',
          'Siddharthnagar',
          'Sonbhadra',
          'Sant Ravidas Nagar',
          'Sultanpur',
          'Shravasti',
          'Unnao',
          'Varanasi'
        ],
        'Uttarakhand (UK)': [
          'Almora',
          'Bageshwar',
          'Chamoli',
          'Champawat',
          'Dehradun',
          'Haridwar',
          'Nainital',
          'Pauri Garhwal',
          'Pithoragarh',
          'Rudraprayag',
          'Tehri Garhwal',
          'Udham Singh Nagar',
          'Uttarkashi'
        ],
        'West Bengal (WB)': [
          'Birbhum',
          'Bankura',
          'Bardhaman',
          'Darjeeling',
          'Dakshin Dinajpur',
          'Hooghly',
          'Howrah',
          'Jalpaiguri',
          'Cooch Behar',
          'Kolkata',
          'Maldah',
          'Paschim Medinipur',
          'Purba Medinipur',
          'Murshidabad',
          'Nadia',
          'North 24 Parganas',
          'South 24 Parganas',
          'Purulia',
          'Uttar Dinajpur'
        ]
      };

      public static DEFAULT_IMAGE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAO' +
    'EAAADhCAMAAAAJbSJIAAAAY1BMVEXExMRbW13JyclbW1tVVVdZWVuKiopYWFjGxsbMzMxhYWJ8fH5' +
    'zc3OxsbFQUFCkpKS/v7+bm5tsbGyUlJSCgoK5ubmqqqpfX19vb2+Hh4eioqJnZ2etra14eHiPj49V' +
    'VVhGRkbXPaOJAAAI0ElEQVR4nO2di5aiOBBAIQkSEBB5iIrg/v9XblIBBAxqtyTM6VN3e7pHx5l4r' +
    'cqThHUcBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEEQBEGQN3D4Gj8cPcfh1/DnXE' +
    'I4kQQBIeLR5C//i6i32L1LPnlaivS/iPijNE1LQXw6HY95216KYt+cz+fyX1ccx0VKyd84nUt8O1yP' +
    'eX65FE0TRVldV5UvYeI/P2EMHsT/jCHvISOCgEsbEZerctk3TZbVYUUpY4lCiUioTwf631oz5KNMgx' +
    'RTcQkAERUukywtRVRkjuUix/ZRVNcuE9zlN2Eif3p3z/Xcz6H+zVYMISTCqasvcdzVl11RNBHEJaQ' +
    'qw3wpAvzIZcEwOdmK4VXWfZFj57OqL0NuyeoiH4xz62uxhyGzZehkIjC+54svERxlISqTCzLdDxee' +
    'fTakM9zhW6We8OTX8IHJdkZ+g3+GsiOxI8gb/3dB8Nxqoud3KiwZWhrhUlVVdo72+90uz4/X6+kWl' +
    '+XJs2pI9v7Pck+8Pa+DQUsjhCgNwzrLoqgpil2bH48HoSJcUocHpKvo0ENCQ3bzwTC3FcPiVQwfIn' +
    '0r4yqXZl8Ul510OSgXwaiH7HW6MtR32VLLnzFkqd/aMtxBDPs0Y1BfZK2EDKvrM8RFBCY/yBxLwSV1' +
    '+t6Rk6G7dMBo0nzw4dswoBMvKqEO+zs7hpzvmDT0RX3Jon1xgRw7QH1JVW/fDSWDh8yXJXaGRbCSw7s' +
    'CczCkqezgA9JXFpVSw5d66Xyw/csS01AZGo5h/05JnkAM4y6jHkLOs84KEwI5Eq/AMDIaw8cbJUdlaK' +
    '3/FYWHVgyHGB7AkN1s1HvVlhJleDadpV1t6wyTA3m0iOOfIzRP/RRVcg2GmcmsCZoqHICOj4ZWgOpOI' +
    'k8a1kYNI993Z7MEzySuKspLYpkJnWFqUFEYrjhN+ByaxEQOFJWhyZWaf8DQ9cI/G0NOLgwe2TBkRivf' +
    'GOaNYri7g6HJxbbecG+P6N4Zig6qhRgyC4Z1MFk/M0lwSB4xzMHwbsPQ0gzNgaHTw/CoYmhysa0zDLc1' +
    'NDkU3tjwCs0OOxgsfmPDg4qhyaWojQ1v3h835DdPKjL9UtQ6lXNjwxg6fNbqpsBfrgL1bGxYKsPdXze8' +
    'm1xs29gQFttcpjX8GzFUhl4T6JqVP2Ho1GBocrFtW0PHycAw+7O9heOcPVG8V5PnSrfWUPVnhmuUOjHk' +
    'jSzeM/kBbxxDZehS/UWeVcK4sSEpOkPtS60brlMzZoZwvYuZC+HPYpiu0QnrDBO+Vv/+zA8MSX5eo8SJ' +
    'YdAqQ/1nZzVLxYcc+0m7wic9jeFOXQ3SL0XZNJTX4CLP8w/k62Knhnln+P2/u8THWRrIhT9alV83ujND' +
    'laWx7pqy3ZZGbX2p/Prb7Qkzw6MyvGn+Vb7OWv+nWcoztdmGFd/m09TwqgxP5jYKf2IoSidqJwqsGq0a' +
    'w4PqD6/mRhwfxZDzW9JfoaJ+/N27mY7abr4y1OW+xXrI09p3B0V5te+LwmeGKoa5uUXvj7KUF8wd8Fj' +
    'Ev2kEpoaliqHBvXufGJLDdEsf25EvNkXNDFUMW2IsiB8YQo5OFU/jjXjTF799pzND2ETLLpvWw2A/38v' +
    't0aULfrwl797Z1DCtYB/WZcO5BSfXZCYo9/jog0WO/xXBmzemNWw2GXl3u13Lai7oVgvbCXlM32/4na7' +
    'TpDUY7nVJaj6GqgTN7m+5yTbXLQBGcgP8iTivPv5ZDJVhtFGWysHMken3RrP4qTUVAx9pX5Uv+8tZDM+' +
    'wipEZmwC/rYdlRZ+zVMVx3id2Ax+5TY2kyyVODXkEf6feqC0Vscj8hcMj1G9mL05DdTKDsv2rE3fTLO' +
    'URbNevtprjd7tBtHheO5lm8KIfnLtJ/qLPmBkWnaGxCxevY1guC8r2YbzBQMyDRvE9Lc+xpllK9r425' +
    'aH4r+0krw2zl6do/FHHz8vwMS6gNFxeC5jVw8t2htx5TAqXFMOh+vDzKNyUevVidzYzlO2vCLpukGR4' +
    'ji8ET29P3rGCq0aFtLMPw2uWxjazetgqQ3OHLF9kaVq/Exz2ifA4mUc7WRrbzGKY94b2V6L6vZ+voTd' +
    '1ofNplyq0Nu8NyVEd7tIeEDCbpeT0NODW4dcpkR/GU4WVYxvtpG96DZjDuJ4y7cdh0JDDpPCTzcPUj8' +
    'i4oxjhZdqmYhZDeOQmB8v1kEP//ZEhZW0aLgxdG10/PquHJ/XI3GLbQpbKVb6P9n/LM6PZQptLta3NL' +
    'IbxNoZEMyn8DboGZBbDzvBobKFGa8jJSjv4PRo/Dd+m/SGBgaHJg7Jaw+5qwveCsrWZlzjr8Uuow0lr' +
    'taXh5Qr3EeiAmdSyodMbXmzGkPNmNUHXvbfB1HFmCIcsXd+q4atJ4S9IZp351NBJoT74T6E2aEhuKx8' +
    'TotMx52DYPRnC4S7dYpsZQ9FHn1c+CeXD2Gaw7Ecxypv3B2XttTTBfB70NbMAPbJU3ZSgljGkZ3sxFP' +
    'MgumJDA4bJbrR/tDc8Ho75rtg36jj32dY1YDEP8ta9NwuQXIOZoehHkjscmIMHBo+RTg25WlRYnfEcv' +
    'jecvsCWITmZOW9J/cdMSmvoh8aOr00NP5wU/kKR7V8aUkuGQWEkR4Hh1Ig+hpUVQzlXX7cZnSh2EyR9' +
    'DKmxxbaRIU9Dc36P1mZLQ4M5KvGgveSdYX+nLNH7yntKJcaOkfanZHm6sJ60Imwv81QZVt0N+Ritanm' +
    '/n9Z8PeSl+XP5TM6RujENa6+HG9y8CO7qZ3w/Ta1uUWEaX7Q2/ci7XOdWTB8bBrmNeyt4bszns6d1bs' +
    'j03jBNVh+MagnTYDoDNk5vaOv2GCwi2xgamE8sKbanTQxtApvI/rTh6N4mf9TQRUM0REM0RMPVDA2Pu' +
    'AfDc+Lf2Qb8ZyuGJL/sNqGw9r8L4CSwdZev6S2/bAmqm+jbx5ae2bnnv1k' +
    'wgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIsjb/A4E4p2NlmhYsAAAAAElFTkSuQmCC';

}


export enum PaymentModeType { PAYTM_GATEWAY, COD }

export enum OrderStatus {
  ORDER_CREATED,
  PAYMENT_PENDING,
  PICKUP_PENDING,
  SERVICE_PENDING,
  PICKUP_COMPLETED,
  SERVICE_CENTER_DROPPED,
  SERVICE_STARTED,
  SERVICE_COMPLETED,
  DELIVERY_PENDING,
  PICKUP_DELIVERY,
  DELIVERY_IN_PROGRESS,
  PRODUCT_DELIVERED,
  PAYMENT_AWAITED,
  PAYMENT_RECEIVED,
  DOORSTEP,
  ONSITE_REPAIR_PENDING,
  ORDER_CANCELLED,
  PURCHASE_ORDER,
  ARRIVED_AT_LOCATION,
  PENDING_ON_SITE_REPAIR,
  ON_THE_WAY,
  PRODUCT_SERVICED
}


export enum OrderType { PURCHASE, REPAIR, SERVICE }

export enum PaymentStatus {
  PAYMENT_PENDING,
  PAYMENT_COMPLETED,
  PAYMENT_CANCELED,
  PAYMENT_FAILED,
  PAYMENT_AWAITED,
  REFUND_PENDING,
  REFUND_COMPLETED,
  REFUND_PARTIAL
}

export enum RepairStatus {
  PENDING,
  REPAIR_IN_PROGRESS,
  REPAIR_COMPLETED,
  REPAIR_ON_HOLD,
  CANCELLED
}

export enum RepairModeType { PICKUP_AND_DROP, ONSITE }
