<div fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="40" class="footer">

<div class="container">
<div class="row">

<div class="col-md-1"></div>    
<div class="col-md-4">
        <div class="footer-side">    
            <div fxLayout="column" fxLayoutAlign="start start" class="bold-footer-heading footer-card-header margin-bottom20">
            {{'Services' | translate}}
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20" class="bold-footer-heading footer-card-body">
                <div fxLayout="column" fxLayoutAlign="space-between start" class="footerlink bold-footer-heading-links">
                    <div><a class="cursor-a" (click)="navigateToRepairs('MOBILE')">{{'Mobile_Repairs' | translate}}</a></div>
                    <div><a class="cursor-a" (click)="navigateToRepairs('MOBILE')">{{'Laptop_Repairs' | translate}}</a></div>
                    <div><a class="cursor-a" (click)="navigateToRepairs('AIR_CONDITIONER')">{{'AC_Repairs' | translate}}</a></div>
                    <div><a class="cursor-a" (click)="NavigateToPlansPage()">{{'Annual_Protection_Plans' | translate}}</a></div>
                    <div><a class="cursor-a" >{{'Earn_With_Us' | translate}}</a></div>
            </div>
            </div>
        </div>
</div> 

<div class="col-md-3">
    <div class="footer-side">    
        <div fxLayout="column" fxLayoutAlign="start start" class="bold-footer-heading footer-card-header margin-bottom20">
        {{'Company' | translate}}
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20" class="bold-footer-heading footer-card-body">
            <div fxLayout="column" fxLayoutAlign="space-between start" class="footerlink bold-footer-heading-links">
                <div><a class="cursor-a" (click)="navigateToAboutUs()">{{'about_us' | translate}}</a></div>
                <div><a class="cursor-a" (click)="navigateToCareers()">{{'Careers' | translate}}</a></div>
                <div><a class="cursor-a" (click)="navigateToBlogs()">{{'Blogs' | translate}}</a></div>
                <div><a class="cursor-a" >{{'Investors' | translate}}</a></div>
        </div>
        </div>
    </div>    
</div>
<div class="col-md-1"></div> 
<div class="col-md-3">
    <div class="footer-side">    
        <div fxLayout="column" fxLayoutAlign="start start" class="bold-footer-heading footer-card-header margin-bottom20">
        {{'Help_Desk' | translate}}
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20" class="bold-footer-heading footer-card-body">
            <div fxLayout="column" fxLayoutAlign="space-between start" class="footerlink bold-footer-heading-links">
                <div><a class="cursor-a" (click)="NavigateToFAQ()">{{'faq' | translate}}</a></div>
                <div><a href="">{{'contact_us' | translate}}</a></div>
                <div><a class="cursor-a" (click)="navigateToRefundPolicy()">{{'refund_Policy' | translate}}</a></div>
                <div><a class="cursor-a" (click)="navigateToWarrantyPolicy()">{{'warranty_Policy' | translate}}</a></div>
        </div>
        </div>
    </div>    
</div>
<div class="col-md-1"></div>  
    <div class="col-md-4">
        <div class="footer-side">      
        <div class="rc-heading-a cursor-a" >
            <div fxLayout="column" fxLayoutAlign="space-between start" class="download-out-app">
            {{'More_Info' | translate}}
            </div>
            <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="20" class="bold-footer-heading footer-card-body">
                <div fxLayout="column" fxLayoutAlign="space-between start" class="footerlink bold-footer-heading-links">
                    <div><a (click)="navigateToPrivacyPolicy()">{{'privacy_Policy' | translate}}</a></div>
                    <div> <a (click)="navigateToTermsAndCondition()">{{'terms_And_Conditions' | translate}}</a></div>
            </div>
            </div>    
        </div> 
    </div> 
    </div>
    
    <div class="col-md-4">
        <div class="footer-side">    
            <div fxLayout="column" fxLayoutAlign="space-between start" class="download-out-app">
                {{'download_Our_App' | translate}}
            </div>
        
            <div fxLayout="row" fxLayoutGap="10" class="bold-footer-heading margin-top40">
                <a (click)="navigateToplayStore()" class="cursor-a"><div><img class="icon-footer-playstore" src="../../../assets/images/android-app.png"></div></a>
                <a (click)="navigateToAppStore()" class="cursor-a"><div><img class="icon-footer-apple" src="../../../assets/images/app-store.png"></div></a>
            </div>
        </div> 
    </div>
<div class="col-md-3">
    <div class="footer-side">
    <div class="rc-heading-a cursor-a" >
        <div fxLayout="column" fxLayoutAlign="space-between start" class="download-out-app">
            {{'Follow_Us' | translate}}
        </div>
    </div> 
    <div class="socialmedia">
        <div fxLayout="row" fxLayoutGap="10"  class="bold-footer-heading margin-bottom5 margin-top20" >
            <a (click)="navigateToyouTube()" class="cursor-a"><div><img class="icon-footer-twitter" src="../../../assets/images/repairing_company_youtube.png"></div></a>
            <a (click)="navigateTofacebook()" class="cursor-a"><div><img class="icon-footer-fb" src="../../../assets/images/repairing_company_facebook.png"></div></a>
            <a (click)="navigateToinstagram()" class="cursor-a"><div><img class="icon-footer-insta" src="../../../assets/images/repairing_company_insta.png"></div></a>
            <a (click)="navigateTotwitter()" class="cursor-a"><div><img class="icon-footer-twitter" src="../../../assets/images/repairing_company_twitter.png"></div></a>
            <a (click)="navigateTolinkedin()" class="cursor-a"><div><img class="icon-footer-linkedin" src="../../../assets/images/repairing_company_linkedin.png"></div></a>
        </div>    
    </div>
    </div> 
</div>  


</div>    
</div>
   
</div>

<div class="row last-footer">
<div class="col-md-12">
<div class="text-center">      
<p>© 2023 {{'repairing_Company' | translate}}. {{'All_rights_reserved' | translate}}.</p>
</div>
</div>

</div>    

