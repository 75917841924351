import { ACTypesModel, BrandModel, RefrigeratorTypesModel, WashingMachineTypesModel } from "../brand-model/brand.model";
import { DeviceModel } from "../device-model/device.model";
import { ExpenseModel } from "../expense-model/expense.model";
import { MobiOrderModel } from "../mobi-order-model/mobi-order.model";
import { PaymentRefundModel } from "../payment-refund-model/payment-refund.model";
import { RepairChargesModel } from "../repair-charges-model/repair-charges.model";
import { RepairModeModel } from "../repair-mode-model/repair-mode.model";

export class OrderRepairIssueModel {
    repairIssueId?: number;
    orderDto?: MobiOrderModel;
    orderId?: number;
    repairChargeDto?: RepairChargesModel;
    gst?:number;
    igst?:number;
    cgst?:number;
    sgst?:number;
    status?: string;
    createdDate?: Date;
    updatedDate?: Date;
    modelDto?: DeviceModel;
    brandDto?: BrandModel;
    acTypeDto?:ACTypesModel;
    refrigeratorTypeDto?:RefrigeratorTypesModel;
    washingMachineTypeDto?:WashingMachineTypesModel;
    repairModeDto?: RepairModeModel;
    repairedDate?: Date;// for when repair is completed.
    repairedAmount?: number;
    paymentId?: number;
    repairRequestDate?: Date;
    refundId?: number;
    refundPaymentDto?: PaymentRefundModel;
    expense:ExpenseModel;
}
