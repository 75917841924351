<div class="container-fluid">
    <div class="col-md-2"></div>
    <div class="col-md-9">
        <mat-card>
            <mat-card-title>
                <span>Repair
                    Sheet({{caseData.caseStatus}})</span>
            </mat-card-title>
            <mat-card-content>
                <mat-card *ngIf="orderData">
                    <mat-card-title><span>Device Info</span></mat-card-title>
                    <mat-card-content>
                        <table>
                            <tr>
                                <th>Name: </th>
                                <td><span>{{orderData.repairIssues[0].modelDto.modelName ?
                                        orderData.repairIssues[0].modelDto.modelName : '--'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Manufacture Year: </th>
                                <td><span>{{orderData.repairIssues[0].modelDto.modelYear ?
                                        orderData.repairIssues[0].modelDto.modelYear : '--'}}</span>
                                </td>
                            </tr>
                            <tr>
                                <th>Brand : </th>
                                <td>{{orderData.repairIssues[0].brandDto.brandName ?
                                    orderData.repairIssues[0].brandDto.brandName : '--'}}
                                </td>
                            </tr>
                        </table>
                    </mat-card-content>
                </mat-card>
                <mat-card>
                    <mat-card-title><span>RepairInfo</span></mat-card-title>
                    <mat-card-content>
                        <table *ngIf="orderData" mat-table [dataSource]="orderData.repairIssues"
                            class="mat-elevation-z8 repair_list">
                            <ng-container matColumnDef="issueName">
                                <th mat-header-cell *matHeaderCellDef> Issue </th>
                                <td mat-cell *matCellDef="let element"> {{element.repairChargeDto.issueDto.issueName ?
                                    element.repairChargeDto.issueDto.issueName : '--'}} </td>
                            </ng-container>

                            <ng-container matColumnDef="issueDescription">
                                <th mat-header-cell *matHeaderCellDef> Issue Description </th>
                                <td mat-cell *matCellDef="let element">
                                    {{element.repairChargeDto.issueDto.issueDescription ?
                                    element.repairChargeDto.issueDto.issueDescription : '--'}} <i
                                        class="fas fa-external-link-alt"></i></td>
                            </ng-container>
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef> Action </th>
                                <td mat-cell *matCellDef="let element">
                                    <button *ngIf="caseData.caseStatus! ==='In-Service' && element.status === 'PENDING'" mat-raised-button
                                        (click)="repairIssueStatusChanged(element)">Service Completed</button><i
                                        class="fas fa-external-link-alt"></i>
                                </td>

                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                        <button *ngIf="caseData.caseStatus! === 'Dropped To Service Center' || caseData.caseStatus ===
                        'Arrived_At_Location'" mat-raised-button (click)="caseStatusChanged()">Repair Service
                            Started</button>
                            <button *ngIf="onSIteOrderPickUp" mat-raised-button (click)="changeToServiceCenter()">Pick Up For Service Center</button>

                        <button *ngIf="caseData.caseStatus! === 'In-Service'" mat-raised-button (click)="closeSheet()">Service Completed</button>

                        <button mat-raised-button (click)="onNoClick()">Cancel</button>
                    </mat-card-content>
                </mat-card>
            </mat-card-content>
        </mat-card>
    </div>
</div>
