import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CaseModel } from 'src/app/data/case-model/case.model';
import { UserModel } from 'src/app/data/user-model/user.model';
import { CaseAssignmentModel } from 'src/app/data/case-assignment-model/case-assignment.model';
import { AppConstants } from 'src/app/constants/app.constants';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-user-record',
  templateUrl: './user-record-dialog.component.html',
  styleUrls: ['./user-record-dialog.component.scss']
})
export class UserRecordDialogComponent extends BaseComponent implements OnInit {
  protected getUserDetailsURL: string;
  protected getCaseAssignmentHistoriesByUserIdURL: string;

  protected assignedUserId: number;
  userDetail: UserModel;
  delayedPickup: CaseModel[] = [];
  delayedDropToCenter: CaseModel[] = [];
  delayedService: CaseModel[] = [];
  delayedDropToCustomer: CaseModel[] = [];
  onTimePickup: CaseModel[] = [];
  onTimeDropToCenter: CaseModel[] = [];
  onTimeService: CaseModel[] = [];
  onTimeDropToCustomer: CaseModel[] = [];
  allCaseAssigmentHistory: CaseAssignmentModel[] = [];
  totalRecord: number = 0;
  delayCases: number = 0;

  colorScheme = {
    domain: ['#C7B42C', '#AAAAAA']
  };
  single: any[];
  view: any[] = [300, 200];

  // options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;


  constructor(
    dialogRef: MatDialogRef<UserRecordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dashboardService: DashboardService,
    snackBar: MatSnackBar) {
    super();
    this.dialogRef = dialogRef;
    this.snackBar = snackBar;
    this.assignedUserId = data['assignedUserId'];
    this.delayedPickup = data['delayedPickup'];
    this.delayedDropToCenter = data['delayedDropToCenter'];
    this.delayedService = data['delayedService'];
    this.delayedDropToCustomer = data['delayedDropToCustomer'];
    this.onTimePickup = data['onTimePickup'];
    this.onTimeDropToCenter = data['onTimeDropToCenter'];
    this.onTimeService = data['onTimeService'];
    this.onTimeDropToCustomer = data['onTimeDropToCustomer'];
    this.getUserDetailsURL = AppConstants.userService + AppConstants.GET_USER_BY_ID + "?userId=" + this.assignedUserId;
    this.getCaseAssignmentHistoriesByUserIdURL = AppConstants.supportService + AppConstants.GET_CASE_ASSIGNMENT_BY_USERID + "?userId=" + this.assignedUserId;
    this.getUserDetails(this.assignedUserId);
    this.getFullRecord(this.assignedUserId);
    Object.assign(this,  this.single );
  }

  ngOnInit(): void {
  }


  getUserDetails(assignedUserId: number) {
    this.dataManager.getRequest(this.getUserDetailsURL)
        .subscribe(
            res => this.passGetUserDetailsResponse(res),
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );

}

getFullRecord(assignedUserId: number) {
    this.dataManager.getRequest(this.getCaseAssignmentHistoriesByUserIdURL)
        .subscribe(
            res => this.passGetUserRecordResponse(res),
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );

}

    passGetUserDetailsResponse(res) {
    this.userDetail = res;
    if(this.userDetail.role === 'SERVICE_ENGINEER'){
      this.delayedService = this.delayedService.filter(item => item.assignedUserId === this.userDetail.userId);
      this.onTimeService = this.onTimeService.filter(item => item.assignedUserId === this.userDetail.userId);
    } else{
      this.delayedDropToCustomer = this.delayedDropToCustomer.filter(item => item.assignedUserId === this.userDetail.userId);
      this.onTimeDropToCustomer = this.onTimeDropToCustomer.filter(item => item.assignedUserId === this.userDetail.userId);
      this.delayedPickup = this.delayedPickup.filter(item => item.assignedUserId === this.userDetail.userId);
      this.delayedDropToCenter = this.delayedDropToCenter.filter(item => item.assignedUserId === this.userDetail.userId);
      this.onTimePickup = this.onTimePickup.filter(item => item.assignedUserId === this.userDetail.userId);
      this.onTimeDropToCenter = this.onTimeDropToCenter.filter(item => item.assignedUserId === this.userDetail.userId);
    }
  }

  passGetUserRecordResponse(res) {
    this.allCaseAssigmentHistory = res;
    this.totalRecord = this.allCaseAssigmentHistory.length;
    this.allCaseAssigmentHistory = this.allCaseAssigmentHistory.filter(item => item.delay);
    this.delayCases = this.allCaseAssigmentHistory.length;
    this.single = [
      {
        "name": "Delay Cases",
        "value": this.delayCases
      },
      {
        "name": "On-Time",
        "value": this.totalRecord - this.delayCases
      },

    ];
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
