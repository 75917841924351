import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants, RepairModeType } from 'src/app/constants/app.constants';
import { ACTypesModel, BrandModel, RefrigeratorTypesModel, WashingMachineTypesModel } from 'src/app/data/brand-model/brand.model';
import { DeviceModel } from 'src/app/data/device-model/device.model';
import { RepairChargesModel } from 'src/app/data/repair-charges-model/repair-charges.model';
import { RepairIssueModel } from 'src/app/data/repair-issue-model/repair-issue.model';
import { RepairModeModel } from 'src/app/data/repair-mode-model/repair-mode.model';
import { DeviceIssueService } from 'src/app/services/device-issue.service';

@Component({
  selector: 'app-add-issue-charge-dialog',
  templateUrl: './add-issue-charge-dialog.component.html',
  styleUrls: ['./add-issue-charge-dialog.component.scss']
})
export class AddIssueChargeDialogComponent extends BaseComponent implements OnInit {

  selectedBrand: BrandModel = new BrandModel();
  updateRepairCharge: RepairChargesModel = new RepairChargesModel();
  isEditRepairChargeView: boolean = false;
  unmappedIssues: RepairIssueModel[] = [];
  emptyUnmappedIssueView: boolean = false;
  repairModes: RepairModeModel[] = [];
  unmappedIssuesUrl: string;
  defaultMode: RepairModeModel = new RepairModeModel();
  protected allRepairModesUrl: string;
  protected selectedDevice: any = new DeviceModel();
  protected selectedAcType: ACTypesModel = new ACTypesModel();
  protected selectedRefrigeratorType: RefrigeratorTypesModel = new RefrigeratorTypesModel();
  protected selectedWashingMachineType: WashingMachineTypesModel = new WashingMachineTypesModel();
  protected selectedIssue: any = new RepairIssueModel();
  protected newRepairCharge: RepairChargesModel = new RepairChargesModel();
  protected selectedMode: any;
  protected repairChargeId: any;
  protected addRepairChargeUrl: string;
  protected selectedpro:string;
  repairChargeForm: FormGroup;

  constructor(dialogRef: MatDialogRef<AddIssueChargeDialogComponent>,
    private ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private deviceIssueService:DeviceIssueService,
    snackBar: MatSnackBar,
    private fb: FormBuilder) {
    super();
    this.dialogRef = dialogRef
    this.snackBar = snackBar;
    this.selectedBrand = data['selectedBrand'];
    this.selectedDevice = data['selectedDevice'];
    this.selectedAcType = data['selectedAcType'];
    this.selectedRefrigeratorType = data['selectedRefrigeratorType'];
    this.selectedWashingMachineType = data['selectedWashingMachineType'];
    this.updateRepairCharge = data['updateRepairCharge'];
    this.isEditRepairChargeView = data['isEditRepairChargeView'];
    this.unmappedIssues = data['unmappedIssues'];
    this.selectedpro = data['selectedpro']

    this.allRepairModesUrl = AppConstants.orderService + AppConstants.GET_All_REPAIR_MODE;
    this.addRepairChargeUrl = AppConstants.orderService + AppConstants.ADD_REPAIR_CHARGE;
    this.getAllUnmappedIssuesByDevice();
    console.log(this.selectedpro)
  }

  ngOnInit(): void {
    this.repairChargeForm = this.fb.group({
      issueDD: ['', ],
      repairModeDD: ['this.updateRepairCharge.repairModeDto', [Validators.required]],
      amount: ['this.newRepairCharge.repairAmount', [Validators.required]],
      resolutionPeriod: ['this.newRepairCharge.resolutionPeriod', [Validators.max(40), Validators.min(0)]]
    });
  }

  getAllRepairModes() {
    this.deviceIssueService.getRequest(this.allRepairModesUrl)
      .subscribe(
        {
          next:(data)=>
          {
            console.log(data);
            this.passGetAllRepairModesResponse(data);
          },
          error:(error)=>
          {
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
            }
          }
        });
}

submitNewRepairCharge() {
    this.ngxService.start();
   
    if (this.selectedMode.repairMode == RepairModeType.ONSITE) {
        this.newRepairCharge.resolutionPeriod = 0;
    }
    
    if(this.selectedpro =='AIR_CONDITIONER')
    {
      this.newRepairCharge = {
        "repairChargeId": this.repairChargeId ? this.repairChargeId : null,
        "repairIssueId": this.selectedIssue.issueId,
        "acTypeDto": this.selectedAcType,
        "repairAmount": this.newRepairCharge.repairAmount,
        "resolutionPeriod": this.newRepairCharge.resolutionPeriod,
        "issueDto": this.selectedIssue,
        "repairModeDto": this.selectedMode
    };

    }

    else if(this.selectedpro =='REFRIGERATOR')
    {
      this.newRepairCharge = {
        "repairChargeId": this.repairChargeId ? this.repairChargeId : null,
        "repairIssueId": this.selectedIssue.issueId,
        "refrigeratorTypeDto": this.selectedRefrigeratorType,
        "repairAmount": this.newRepairCharge.repairAmount,
        "resolutionPeriod": this.newRepairCharge.resolutionPeriod,
        "issueDto": this.selectedIssue,
        "repairModeDto": this.selectedMode
    };
    
    }
    else if(this.selectedpro =='WASHING_MACHINE')
    {
      this.newRepairCharge = {
        "repairChargeId": this.repairChargeId ? this.repairChargeId : null,
        "repairIssueId": this.selectedIssue.issueId,
        "washingMachineTypeDto": this.selectedWashingMachineType,
        "repairAmount": this.newRepairCharge.repairAmount,
        "resolutionPeriod": this.newRepairCharge.resolutionPeriod,
        "issueDto": this.selectedIssue,
        "repairModeDto": this.selectedMode
    };
    
    }
    else if(this.selectedpro =='MOBILE'){
      this.selectedDevice.brandDto!.enabled = true;
      this.selectedDevice.enabled = true;
      this.newRepairCharge = {
        "repairChargeId": this.repairChargeId ? this.repairChargeId : null,
        "repairIssueId": this.selectedIssue.issueId,
        "modelDto": this.selectedDevice,
        "repairAmount": this.newRepairCharge.repairAmount,
        "resolutionPeriod": this.newRepairCharge.resolutionPeriod,
        "issueDto": this.selectedIssue,
        "repairModeDto": this.selectedMode
    };
    }
    
    this.deviceIssueService.addRequest(this.addRepairChargeUrl, this.newRepairCharge)
        .subscribe({
          next:(data)=>
          {
            this.dialogRef.close(data)
            this.ngxService.stop();
            this.snackBar.open('Repair Charge Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
          },
          error:(error)=>
          {
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
              this.ngxService.stop();
              this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
            }
          }
        });
}


  getAllUnmappedIssuesByDevice() {
    if (this.isEditRepairChargeView) {
      this.selectedIssue! = this.updateRepairCharge.issueDto;
      this.newRepairCharge.repairAmount = this.updateRepairCharge.repairAmount;
      this.newRepairCharge.repairChargeId = this.updateRepairCharge.repairChargeId;
      this.newRepairCharge.resolutionPeriod = this.updateRepairCharge.resolutionPeriod;
      this.selectedMode = this.updateRepairCharge.repairModeDto;
      this.selectedDevice = this.updateRepairCharge.modelDto;
      this.getAllRepairModes();
      this.repairChargeId = this.updateRepairCharge.repairChargeId;
    }
  }

  passGetAllUnmappedIssuesResponse(res: RepairIssueModel[]) {
    if (res.length == 0) {
      this.emptyUnmappedIssueView = true;
    }
    this.unmappedIssues = res;
  }

  passGetAllRepairModesResponse(res: RepairModeModel[]) {
    this.repairModes = res;
    if (this.isEditRepairChargeView) {
      
      this.repairChargeForm.controls["repairModeDD"].setValue(this.repairModes[this.repairModes.findIndex(r =>
        r.repairMode === this.updateRepairCharge.repairModeDto!.repairMode
      )]);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
