import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExpenseModel } from '../data/expense-model/expense.model';

@Injectable({
  providedIn: 'root'
})
export class AddExpensesService {

  constructor(private http: HttpClient) {
  }

  getAllExpenseType(getAllExpenseTypeListUrl:string) : Observable<any>{
    return this.http.get(getAllExpenseTypeListUrl);
  }

  saveExpense(saveExpenseUrl:string,Expense:ExpenseModel) : Observable<any>{
    return this.http.post(saveExpenseUrl,Expense);
  }

  deleteExpense(deleteExpenseUrl:string,Expense:ExpenseModel) : Observable<any>{
    return this.http.put(deleteExpenseUrl,Expense);
  }
}
