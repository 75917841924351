import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { UserAddressModel } from 'src/app/data/user-address-model/user-address.model';
import { UserModel } from 'src/app/data/user-model/user.model';
import { VendorService } from 'src/app/services/vendor.service';


@Component({
  selector: 'app-vendor-approval-dialog',
  templateUrl: './vendor-approval-dialog.component.html',
  styleUrls: ['./vendor-approval-dialog.component.scss']
})
export class VendorApprovalDialogComponent implements OnInit {

  selectedVendor: UserModel;
  aadhaarId: string;
  panNumber: string;
  userId: number;
  approveVendorUrl:string;
  address:UserAddressModel = new UserAddressModel();

  constructor(public dialogRef: MatDialogRef<VendorApprovalDialogComponent>,public vendorService: VendorService,
    @Inject(MAT_DIALOG_DATA) public data: {},private ngxService: NgxUiLoaderService,public snackBar: MatSnackBar,
    dialog: MatDialog) {
    this.selectedVendor = data['selectedvendor'];
    this.aadhaarId = atob(this.selectedVendor.aadhaarId!);
    this.panNumber = atob(this.selectedVendor.panNumber!);
    this.address = data['selectedvendor'].addresses[0]!
   }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  approveVendorByAdmin(userId:string, status: string){
    this.userId = Number.parseInt(userId);
    console.log(this.selectedVendor.comment);
    var comment = this.selectedVendor.comment?this.selectedVendor.comment:'';
    if(status == 'Inactive' && comment == null){
      this.dialogRef.close();
    }
    this.approveVendorUrl = AppConstants.userService + AppConstants.approveVendor + '?userId=' + userId + '&status='+ status +'&comment='+comment;
    this.ngxService.start();    
    this.vendorService.approveVendor(this.approveVendorUrl,null).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.snackBar.open('Vendor Status : ' + status,'',{duration:5000,panelClass: ['green-snackbar']});
        this.dialogRef.close(this.userId);
      },
      error:(error:any)=>{
        this.ngxService.stop();
        this.snackBar.open('Vendor Not Approved!!','',{duration:5000,panelClass: ['blue-snackbar']}) 
      }
    });
  }

}
