import { BrandModel } from "../brand-model/brand.model";
import { DeviceModel } from "../device-model/device.model";
import { DevicePlanModel } from "../device-plans-model/device-plans.model";
import { PurchasePlanModel } from "../purchase-plan-model/purchase-plan.model";
import { RepairOrderModel } from "../repair-order/repair-order.model";
import { TimeSlotModel } from "../user-address-model/user-address.model";

export class PurchaseModel {
    purchaseId?: number;
    createdDate?: Date;
    brandDto?: BrandModel;
    modelDto?: DeviceModel;
    order?: RepairOrderModel;
    imeiNumber?: String;
    purchaseAmount?: number;
    paymentId?: String;
    deviceList?: DeviceModel[] =[];
    allPlans?: DevicePlanModel[] = [];
    plans?: DevicePlanModel[] = [];
    purchasePlans?: PurchasePlanModel[] = [];
    showPlans?: boolean = false;
    formData?: FormData = new FormData();
    purchaseInspectionDate?: Date;
    uniqueIdentifierImageFile?: any;
}