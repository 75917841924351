import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { UserDetailsModel } from 'src/app/data/user-details/user-details.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-add-or-update-user',
  templateUrl: './add-or-update-user.component.html',
  styleUrls: ['./add-or-update-user.component.scss']
})
export class AddOrUpdateUserComponent implements OnInit {
  addUserFormGroup: FormGroup;
  userModelForUpdate: UserDetailsModel = new UserDetailsModel();
  addUserUrl:string = AppConstants.userService + AppConstants.addUser;
  roleList:any;
  updateOrAdd:string;
  updateOrAddedSuccessMsg:string = 'User Added Successfully!!';
  statusList = ['ACTIVE', 'INACTIVE', 'LOCKED', 'EXPIRED', 'DELETED']

  constructor(public dialogRef: MatDialogRef<AddOrUpdateUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public userService:UserService,
    private ngxService: NgxUiLoaderService,public snackBar: MatSnackBar) {
      this.getAllRoles();
      if(data != null){
        if(data.operation == 'update'){
          this.updateOrAdd = 'update_user';
          this.userModelForUpdate = this.data.userDto;
          this.updateOrAddedSuccessMsg = 'User updated Successfully!!'
        }
      }else{
        this.updateOrAdd = 'add_user';
      }
  }

  ngOnInit(): void {
    this.addUserFormGroup = new FormGroup({
      email: new FormControl(null,Validators.required),
      firstName: new FormControl(null,Validators.required),
      lastName: new FormControl(null,Validators.required),
      mobileNumber: new FormControl(null,Validators.required),
      role: new FormControl(null,Validators.required),
      status:new FormControl('INACTIVE',Validators.required),
    });
    
    if(this.userModelForUpdate.userId != undefined){
      this.addUserFormGroup.controls['email'].setValue(this.userModelForUpdate.email);
      this.addUserFormGroup.controls['firstName'].setValue(this.userModelForUpdate.firstName);
      this.addUserFormGroup.controls['lastName'].setValue(this.userModelForUpdate.lastName);
      this.addUserFormGroup.controls['mobileNumber'].setValue(this.userModelForUpdate.mobileNumber);
      this.addUserFormGroup.controls['status'].setValue(this.userModelForUpdate.status);
      if(this.updateOrAdd == 'add_user'){
        this.addUserFormGroup.controls['status'].setValue('INACTIVE');
      }
    }
  }

  addOrUpdateUserModel(){
    this.userModelForUpdate.email = this.addUserFormGroup.controls['email'].value;
    this.userModelForUpdate.firstName = this.addUserFormGroup.controls['firstName'].value;
    this.userModelForUpdate.lastName = this.addUserFormGroup.controls['lastName'].value;
    this.userModelForUpdate.mobileNumber = this.addUserFormGroup.controls['mobileNumber'].value;
    this.userModelForUpdate.status = this.addUserFormGroup.controls['status'].value;

    if(this.addUserFormGroup.controls['role'].value != null && this.addUserFormGroup.controls['role'].value != undefined){
      this.userModelForUpdate.roleId = this.roleList.filter(e=>e.roleName == this.addUserFormGroup.controls['role'].value)[0].roleId;
      this.userModelForUpdate.role = this.roleList.filter(e=>e.roleName == this.addUserFormGroup.controls['role'].value)[0].roleName;
    }
    this.save();
  }

  getAllRoles(){
    this.ngxService.start();
    let roleUrl = AppConstants.userService + AppConstants.getAllRoles
    this.userService.getAllRoles(roleUrl).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.roleList = data;
        if(this.userModelForUpdate.userId != undefined){
          this.addUserFormGroup.controls['role'].setValue(this.roleList.filter(e=>e.roleId == this.userModelForUpdate.roleId)[0].roleName);
        }
      },
      error:(error:any)=>{
        this.ngxService.stop();
      }
    });
  }

  save(){
    if(this.addUserFormGroup.invalid){
      this.snackBar.open('Please fill the Required Details!!' 
      ,'',{duration:5000,panelClass: ['blue-snackbar']}) 
      return;
    }
    this.ngxService.start();
    let url = "";
    if(this.updateOrAdd == 'add_user'){
      url = AppConstants.userService + AppConstants.addUser;
    }else{
      url = AppConstants.userService + AppConstants.updateUser;
    }
    this.userService.addOrUpdateUser(url,this.userModelForUpdate).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.addUserFormGroup.reset();
        this.onNoClick();
        this.snackBar.open(this.updateOrAddedSuccessMsg,'',{duration:5000,panelClass: ['green-snackbar']});
      },
      error:(error:any)=>{
        this.snackBar.open('User Not found!!','',{duration:5000,panelClass: ['blue-snackbar']}) 
        this.ngxService.stop();
      }
    });
  }

  onNoClick(){
    this.dialogRef.close();
  }

}
