import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppConstants } from "src/app/constants/app.constants";
import { City } from "src/app/data/city-model/city-model";
import { State } from "src/app/data/state-model/state-model";
import { UserAddressModel } from "src/app/data/user-address-model/user-address.model";
import { OrderDetailsService } from "src/app/services/order-details.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-update-address-dialog",
  templateUrl: "./update-address-dialog.html",
  styleUrls: ["./update-address-dialog.scss"],
})

export class UpdateAddressDilog implements OnInit {
  editaddressForm: any;
  updateadressurl: string;
  userAddressModel: UserAddressModel = new UserAddressModel();
  addAddressModel: UserAddressModel;
  stateList: State[] = [];
  cityList: City[] = [];
  getAllCityUrl: string;
  getAllStatesUrl: string;
  showUpdateButton: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<UpdateAddressDilog>,
    private dataManager: OrderDetailsService,
    private userService: UserService,
    private ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.editaddressForm = new FormGroup({
      addressLine1: new FormControl(),
      addressLine2: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      pincode: new FormControl(),
    });
    this.getAllStatesUrl = AppConstants.userService + AppConstants.getAllStates;
    this.allStatesApi();
  }

  ngOnInit(): void {
    this.userAddressModel = this.data?.addressDetail;
    console.log(this.userAddressModel);
    if (this.data?.addressDetail != undefined) {
      this.showUpdateButton = true;
      this.editaddressForm.controls["addressLine1"].setValue(
        this.data.addressDetail!.addressLine1
      );
      this.editaddressForm.controls["addressLine2"].setValue(
        this.data.addressDetail!.addressLine2
      );
      this.editaddressForm.controls["city"].setValue(
        this.data.addressDetail!.city
      );
      this.editaddressForm.controls["state"].setValue(
        this.data.addressDetail!.state
      );
      this.changeState(this.data.addressDetail!.state);
      this.editaddressForm.controls["pincode"].setValue(
        this.data.addressDetail!.pincode
      );
    } else {
      this.showUpdateButton = false;
      this.editaddressForm.controls["addressLine1"].setValue(null);
      this.editaddressForm.controls["addressLine2"].setValue(null);
      this.editaddressForm.controls["city"].setValue(null);
      this.editaddressForm.controls["state"].setValue(null);
      this.editaddressForm.controls["pincode"].setValue(null);
    }
  }

  updateAddress() {
    this.ngxService.start();
    this.userAddressModel = this.data.addressDetail;
    this.userAddressModel.addressId = this.data.addressDetail.addressId;
    this.userAddressModel.addressLine1 =
      this.editaddressForm.controls["addressLine1"].value;
    this.userAddressModel.addressLine2 =
      this.editaddressForm.controls["addressLine2"].value;
    this.userAddressModel.city = this.editaddressForm.controls["city"].value;
    this.userAddressModel.state = this.editaddressForm.controls["state"].value;
    this.userAddressModel.pincode =
      this.editaddressForm.controls["pincode"].value;

    this.updateadressurl =
      AppConstants.userService + AppConstants.updateAddressForMyAccount;
    this.userService
      .UpdateAddress(this.updateadressurl, this.userAddressModel)
      .subscribe({
        next: (data) => {
          this.dialogRef.close();
          this.ngxService.stop();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }
  AddAddress() {
    this.ngxService.start();

    this.addAddressModel = {
      userId: localStorage.getItem("userId"),
      addressLine1: this.editaddressForm.controls["addressLine1"].value,
      addressLine2: this.editaddressForm.controls["addressLine2"].value,
      pincode: this.editaddressForm.controls["pincode"].value,
      state: this.editaddressForm.controls["state"].value,
      city: this.editaddressForm.controls["city"].value,
    };
    this.updateadressurl =
      AppConstants.userService + AppConstants.addAddressByUserId;
    this.userService
      .addAddress(this.updateadressurl, this.addAddressModel)
      .subscribe({
        next: (data) => {
          console.log(data);
          this.dialogRef.close();
          this.ngxService.stop();
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  onClose() {
    this.dialogRef.close();
  }

  changeState(state: string) {
    this.getAllCityUrl =
      AppConstants.userService +
      AppConstants.getAllCitiesByStateName +
      "?stateName=" +
      state;
    this.cityApi();
  }
  allStatesApi() {
    this.ngxService.start();
    this.dataManager.getRequest(this.getAllStatesUrl).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        this.getAllStatesData(res);
      },
      error: (error: any) => {
        this.ngxService.stop();
      },
    });
  }

  cityApi() {
    this.ngxService.start();
    this.dataManager.getRequest(this.getAllCityUrl).subscribe({
      next: (res: any) => {
        this.ngxService.stop();
        this.getAllCityData(res);
      },
      error: (error: any) => {
        this.ngxService.stop();
      },
    });
  }

  protected getAllStatesData(res: any) {
    this.stateList = res;
    this.ngxService.stop();
  }

  protected getAllCityData(res: any) {
    this.cityList = res;
    this.ngxService.stop();
  }
}
