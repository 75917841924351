export class AppRouteConstants {
    public static uiUrl: string = 'ui'
    public static admin: string = 'admin'
    public static homeRoute: string = 'home';
    public static repairOrderRoute: string = 'ro';
    public static authRoute: string = 'login';
    public static userAccAtivatationRoute: string = 'userActivation';
    public static orderListingRoute: string = 'order-list';
    public static orderDetailsRoute: string = 'order-details';
    public static userProfileRoute: string = 'user-profile';
    public static paymentStatusRoute: string = 'paymentStatus';
    public static plansRoute: string = 'plans';
    public static vendorLogin: string = 'vendor-login';
    public static vendorRegister: string = 'vendor-register';
    public static vendorHome: string = 'vendor-home';
    public static customerRegistration: string = 'customer-registration';
    public static vendorRepairCharges: string = 'vendor-repairCharges';
    public static plans: string = 'plans';
    public static forgotPassword: string = 'forgot-pwd';
    public static resetPassword: string = 'reset-pwd';
    public static adminRoute: string = 'admin';
    public static userManagement: string = 'user-management';
    public static roleManagement: string = 'role-management';
    public static supportTicketManagement: string = 'support-ticket-management';
    public static approveVendors: string = 'approve-vendors';
    public static DASHBOARD_ROUTE = 'dashboard';
    public static AUTH_ROUTE = 'login';
    public static REPAIR_ROUTE = 'repair';
    public static TRANSIT_ROUTE = 'transit';
    public static DEVICE_ISSUE_LISTING_ROUTE = 'device-issue';
    public static service_area_route = 'service-area';
    public static serviceCenterRoute = 'service-center';
    public static ADMIN_USER_LISTING_ROUTE = 'users';
    public static DEVICE_CONTRACT_LISTING = 'dc-list';
    public static APP_ACTION_TRACKER = 'action-tracker';
    public static VENDORS_APPROVAL = 'vendors-approval';
    public static privacy = 'privacy';
    public static refundPolicy = 'refundPolicy';
    public static aboutUs = 'about-us';
    public static contactUs = 'contactUs';
    public static termsAndConditions = 'termsAndConditions';
    public static warrantyPolicy = 'warrantyPolicy';
    public static salesDashboard = 'sales-dashboard';
    public static vendorsDashboard = 'vendors-dashboard';
    public static manageProductCommissions = 'manage-product-commissions';
    public static repairDashboard = 'repair-dashboard';
    public static createorder = 'create-order';
    public static open_case = 'open-cases';
    public static my_account = 'my-account';
    public static contact = 'contact';
    public static careers = 'careers';
    public static applyNow = 'apply-now';
    public static jdOfSalesManager = 'jd-of-sales-manager';
    public static applyforSalesManager = 'apply-for-Sales-Manager'
    public static faq = 'faqs';
    public static schedulepickups = 'schedule-pickups';
    public static chatBotAdmin = 'chatbot-admin';
    public static blogs = 'blogs';
    //blogs route
    public static iphone14provsiphone14proMax = 'iphone-14-pro-vs-iphone-14-pro-max';
    public static iphone14vsiphone14proMax = 'iphone-14-plus-vs-iphone-14-pro-max';
    public static iphone14vsiphone14pro = 'iphone-14-vs-iphone-14-pro';
    public static iphone14vsiphone14plus = 'iphone-14-vs-iphone-14-plus';
    public static enhancingcustomerexperience = 'enhancing-customer-experience';
    public static androidRepairs = 'android-repairs';
    public static tipsToBeSuccessfulInRepairBusiness = 'tips-to-successful-repair-business';
    public static needLaptopRepair = 'need-Laptop-Repair';
    public static bestmobilephonesintheworld = 'best-mobile-phones-in-the-world-january';
    public static bestcameraphones = 'best-camera-phones-under-20000-in-india';
    public static crackedScreen = 'cracked-screen';
    public static trackyourlostmobile = 'how-to-track-your-lost-mobile-phone-with-imei-number';
    public static dealWithWaterDamage = 'deal-with-water-damage';
    public static takescreenshotskeyboard = 'how-to-take-screenshots-using-keyboard-shortcuts-on-laptop';
    public static impactOf5g = 'the-impact-of-5g';
    public static bestlaptop = 'best-laptops-for-students-in-india';
    public static lockappsoniphone = 'how-to-lock-apps-on-iphone-with-password';
    /**
     *
     * @param route
     */
    public static getRoute(route: string): string {
        return '/' + route;
    }

    public static getParentRoute(parent: string, route: string) {
        return parent + '/' + route;
    }

}
