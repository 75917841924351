import { ACTypesModel, RefrigeratorTypesModel, WashingMachineTypesModel } from '../brand-model/brand.model';
import { DeviceModel } from '../device-model/device.model';
import { RepairIssueModel } from '../repair-issue-model/repair-issue.model';
import { RepairModeModel } from '../repair-mode-model/repair-mode.model';

export class RepairChargesModel  {
    repairChargeId?: number;
    beforeTax?: number;
    repairIssueId?: number;
    modelDto?: DeviceModel = new DeviceModel();
    acTypeDto?: ACTypesModel = new ACTypesModel();
    refrigeratorTypeDto?: RefrigeratorTypesModel = new RefrigeratorTypesModel();
    washingMachineTypeDto?: WashingMachineTypesModel = new WashingMachineTypesModel();
    repairAmount?: number;
    isSelected?: boolean = false;
    issueDto?: RepairIssueModel = new RepairIssueModel();
    repairChargeDto?: RepairChargesModel;
    status?: String;
    repairModeDto?: RepairModeModel = new RepairModeModel();
    resolutionPeriod?: number = 0;
    enabled?: boolean;
    vendorId?: number;
}