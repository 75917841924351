import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { UserModel } from "src/app/data/user-model/user.model";
import { UserService } from "src/app/services/user.service";
import { CompareplansComponent } from "../../compareplans/compareplans.component";
import { ViewDetailsPlansComponent } from "../../view-details-plans/view-details-plans.component";
import { Router } from "@angular/router";
import { AppRouteConstants } from "src/app/constants/app-route.constants";

@Component({
  selector: "my-details",
  templateUrl: "./my-details.component.html",
  styleUrls: ["./my-details.component.scss"],
})

export class MydetailsComponent implements OnInit {
  UserId: any;
  userDetails: UserModel;
  address: string;
  isDisabled1 = true;
  isDisabled2 = true;
  isDisabled3 = true;
  isDisabled4 = true;
  editForm: any;
  editForm1:any;
  goldMember:boolean = false;
  addresses: any;
  constructor(
    private ngxService: NgxUiLoaderService,
    private userservice: UserService,
    public loginDialog: MatDialog,
    private router:Router
  ) {}

  ngOnInit(): void {
    this.goldOrSilverUser();
    this.UserId = localStorage.getItem("userId")?.toString();
    this.getUserByUserID();

    this.editForm = new FormGroup({
      firstName: new FormControl(),
      lastName: new FormControl(),
      mobileNumber: new FormControl(),
      email: new FormControl(),
      addressLine1: new FormControl(),
      addressLine2: new FormControl(),
      city: new FormControl(),
      state: new FormControl(),
      pincode: new FormControl(),
    });

    this.editForm1 = new FormGroup({
      membership: new FormControl(),
    });

    this.editForm.controls["firstName"]?.disable();
    this.editForm.controls["lastName"]?.disable();
    this.editForm.controls["mobileNumber"]?.disable();
    this.editForm.controls["email"]?.disable();
    this.editForm.controls["addressLine1"]?.disable();
    this.editForm.controls["addressLine2"]?.disable();
    this.editForm.controls["city"]?.disable();
    this.editForm.controls["state"]?.disable();
    this.editForm.controls["pincode"]?.disable();
    this.editForm1.controls["membership"]?.disable();
  }

  getUserByUserID() {
    this.ngxService.start();
    this.userservice.getUserDetailById(this.UserId).subscribe({
      next: (data: any) => {
        this.userDetails! = data;
        this.editForm.controls["firstName"]?.setValue(
          this.userDetails!.firstName!
        );
        this.editForm.controls["lastName"]?.setValue(
          this.userDetails!.lastName!
        );
        this.editForm.controls["mobileNumber"]?.setValue(
          this.userDetails!.mobileNumber!
        );
        this.editForm.controls["email"]?.setValue(this.userDetails!.email!);
        this.ngxService.stop();
        this.userDetails!.addresses!.forEach((e) => {
          if (e.primaryAddress! == true) {
            this.addresses = e;
            this.editForm.controls["addressLine1"]?.setValue(
              this.addresses!.addressLine1!
            );
            this.editForm.controls["addressLine2"]?.setValue(
              this.addresses!.addressLine2!
            );
            this.editForm.controls["city"]?.setValue(this.addresses!.city!);
            this.editForm.controls["state"]?.setValue(this.addresses!.state!);
            this.editForm.controls["pincode"]?.setValue(
              this.addresses!.pincode!
            );
          }
        });
      },
      error: (error) => {
        this.ngxService.stop();
      },
    });
  }
  planName='all_in_one'
  orderAmount = 1999
  NavigateToOrderPage(){
    this.planName='all_in_one'
    this.orderAmount = 1999
    
    const plan = {
      orderAmount:this.orderAmount,
      planName:this.planName
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:plan});
  }

  showComparePlans(): void {
    const dialogRef = this.loginDialog.open(ViewDetailsPlansComponent, {
      width: '84vw',
      maxWidth:'84vw',
      height:'80%',
      disableClose: false,
      panelClass: "mat-dialog-container-details" ,
    });

    dialogRef.afterClosed().subscribe(result => {});
  }

  goldOrSilverUser() {
    this.ngxService.start();
    this.userservice.goldOrSilverUser().subscribe({
      next: (res) => {
        this.ngxService.stop();
        if(res == true){
          this.goldMember= true;
          this.editForm1.controls['membership'].setValue( "Gold Membership");
        }else{
          this.goldMember= false;
          this.editForm1.controls['membership'].setValue("Silver Membership");
        }
      },
      error: (error) => {
        this.ngxService.stop();
      },
    });
  }

  editfName() {
    this.isDisabled1 = false;
    this.editForm.controls["firstName"]?.enable();
  }
  editlName() {
    this.isDisabled2 = false;
    this.editForm.controls["lastName"]?.enable();
  }
  editmobile() {
    this.isDisabled3 = false;
    this.editForm.controls["mobileNumber"]?.enable();
  }
  editemail() {
    this.isDisabled4 = false;
    this.editForm.controls["email"]?.enable();
  }

  submit() {
    this.ngxService.start();
    this.userDetails!.addresses = [];
    this.userDetails!.firstName = this.editForm.controls["firstName"].value;
    this.userDetails!.lastName = this.editForm.controls["lastName"].value;
    this.userDetails!.mobileNumber =
      this.editForm.controls["mobileNumber"].value;
    this.userDetails!.email = this.editForm.controls["email"].value;

    this.userservice.updateUserDetails(this.userDetails).subscribe({
      next: (data: any) => {
        this.isDisabled1 = true;
        this.isDisabled2 = true;
        this.isDisabled3 = true;
        this.isDisabled4 = true;
        this.userservice.getusernameonlogin([
          localStorage.getItem("userId"),
          this.editForm.controls["firstName"].value,
          this.editForm.controls["email"].value,
        ]);
        localStorage.setItem("name", this.editForm.controls["firstName"].value);

        this.editForm.controls["firstName"]?.disable();
        this.editForm.controls["lastName"]?.disable();
        this.editForm.controls["mobileNumber"]?.disable();
        this.editForm.controls["email"]?.disable();
        this.editForm.controls["address"]?.disable();
        this.ngxService.stop();
      },
      error: (error: any) => {
        console.log(error);
        this.ngxService.stop();
      },
    });
  }
}
