import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router:Router) { }
 
  ngOnInit(): void {
  }
  navigateToRefundPolicy(){
    this.router.navigate([AppRouteConstants.refundPolicy])
  }
  navigateToPrivacyPolicy(){
    this.router.navigate([AppRouteConstants.privacy])
  }
  navigateToWarrantyPolicy(){
    this.router.navigate([AppRouteConstants.warrantyPolicy])
  }
  navigateToTermsAndCondition(){
    this.router.navigate([AppRouteConstants.termsAndConditions])
  }
  navigateToAboutUs()
  {
    this.router.navigate([AppRouteConstants.aboutUs]);
  }
  navigateToBlogs()
  {
    this.router.navigate([AppRouteConstants.blogs]);
  }
  navigateToRepairs(product:string)
  {
    const orderDetails= 
    {
      product:product
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:orderDetails});
  }
  NavigateToPlansPage(){
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.plansRoute)]);
  }
  NavigateToFAQ(){
    this.router.navigate([AppRouteConstants.faq]);
  }

  navigateToyouTube()
  {
    window.open('https://www.youtube.com/@repairingcompany/', '_blank');
  }

  navigateTofacebook()
  {
    window.open('https://www.facebook.com/RepairingCompany', '_blank');
  }

  navigateToinstagram()
  {
    window.open('https://www.instagram.com/repairingcompany/', '_blank');
  }

  navigateTotwitter()
  {
    window.open('https://twitter.com/repairingcompny', '_blank');
  }

  navigateTolinkedin()
  {
    window.open('https://www.linkedin.com/company/repairing-company/', '_blank');
  }
  
  navigateToplayStore()
  {
    window.open('https://play.google.com/store/apps/details?id=com.mobi.repairing_company_app', '_blank')
  }

  navigateToAppStore()
  {
    window.open('https://apps.apple.com/in/app/repairing-company/id1586972099', '_blank')
  }
  navigateToCareers()
  {
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.careers)]);
  }

}
