import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { PermissionDto, RoleModel } from 'src/app/data/role-model/role-model';
import { UserService } from 'src/app/services/user.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-add-or-update-roles',
  templateUrl: './add-or-update-roles.component.html',
  styleUrls: ['./add-or-update-roles.component.scss']
})
export class AddOrUpdateRolesComponent implements OnInit {
  addRolesFormGroup: FormGroup;
  roleModelForUpdate: RoleModel = new RoleModel();
  addUserUrl:string = AppConstants.userService + AppConstants.addUser;
  roleList:any;
  updateOrAdd:string;
  updateOrAddedSuccessMsg:string = 'Role Added Successfully!!';
  permission:PermissionDto[]=[];
  selectedPermission:PermissionDto[]=[];

  constructor(public dialogRef: MatDialogRef<AddOrUpdateRolesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public userService:UserService,
    private ngxService: NgxUiLoaderService,public snackBar: MatSnackBar) {
      this.getAllRoles();
      this.getPermissions();
      if(data != null){
        if(data.operation == 'update'){
          this.updateOrAdd = 'update_role';
          this.roleModelForUpdate = this.data.roleDto;
          this.updateOrAddedSuccessMsg = 'Role updated Successfully!!'
        }else if(data.operation == 'view'){
          this.updateOrAdd = 'view_role';
          this.roleModelForUpdate = this.data.roleDto;
        }
      }else{
        this.updateOrAdd = 'add_role';
      }
  }
  dropdownSettings:IDropdownSettings = {};
  ngOnInit(): void {
    this.addRolesFormGroup = new FormGroup({
      roleName: new FormControl(null,Validators.required),
      
      permission: new FormControl(null),
      roleNameHi: new FormControl(null)
    });

    if(localStorage.getItem('lang') == 'en')
    {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'permissionName',
        textField: 'permissionDescription',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
        };
    }
    else if(localStorage.getItem('lang') == 'hi')
    {
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'permissionName',
        textField: 'permissionDescriptionHi',
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        itemsShowLimit: 10,
        allowSearchFilter: true
        };
    }
    

    
    
    if(this.roleModelForUpdate.roleId != undefined){
      this.addRolesFormGroup.controls['roleName'].setValue(this.roleModelForUpdate.roleName);
      //this.addRolesFormGroup.controls['roleDescription'].setValue(this.roleModelForUpdate.roleDescription);
      this.addRolesFormGroup.controls['roleNameHi'].setValue(this.roleModelForUpdate.roleNameHi);
    }
  }

  addOrUpdateRoleModel(){
    this.roleModelForUpdate.roleName = this.addRolesFormGroup.controls['roleName'].value;
   // this.roleModelForUpdate.roleDescription = this.addRolesFormGroup.controls['roleDescription'].value;
    this.roleModelForUpdate.permissionsDto = this.addRolesFormGroup.controls['permission'].value;
    this.roleModelForUpdate.roleNameHi = this.addRolesFormGroup.controls['roleNameHi'].value
    this.save();
  }

  getPermissions(){
    this.ngxService.start();
    let permissionUrl = AppConstants.userService + AppConstants.getPermissions
    this.userService.getAllRoles(permissionUrl).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.permission = data;
        if(this.roleModelForUpdate.roleId != undefined){
          this.addRolesFormGroup.controls['permission'].setValue(this.roleModelForUpdate.permissionsDto);
        }
      },
      error:(error:any)=>{
        this.ngxService.stop();
      }
    });
  }

  getAllRoles(){
    this.ngxService.start();
    let roleUrl = AppConstants.userService + AppConstants.getAllRoles
    this.userService.getAllRoles(roleUrl).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.roleList = data;
      },
      error:(error:any)=>{
        this.ngxService.stop();
      }
    });
  }

  save(){
    if(this.addRolesFormGroup.invalid){
      this.snackBar.open('Please fill the Required Details!!' 
      ,'',{duration:5000,panelClass: ['blue-snackbar']}) 
      return;
    }
    this.ngxService.start();
    let url = "";
    if(this.updateOrAdd == 'add_role'){
      url = AppConstants.userService + AppConstants.addRole 
      + "?role=" +this.roleModelForUpdate.roleName + "&roleHi=" +this.roleModelForUpdate.roleNameHi 
      + "&roleDescription=" + this.roleModelForUpdate.roleDescription;
    }else{
      url = AppConstants.userService + AppConstants.updateRole + "?roleId=" + this.roleModelForUpdate.roleId 
      + "&role=" +this.roleModelForUpdate.roleName + "&roleHi=" +this.roleModelForUpdate.roleNameHi 
      + "&roleDescription=" + this.roleModelForUpdate.roleDescription;
    }
    let perms:PermissionDto[] = this.addRolesFormGroup.controls['permission'].value;
    let permissonStringList: string[] = [];
    if(perms!=null){
      perms.forEach(e=>{permissonStringList.push(e.permissionName)});  
    }
    this.userService.addOrUpdateRole(url,permissonStringList).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.addRolesFormGroup.reset();
        this.onNoClick();
        this.snackBar.open(this.updateOrAddedSuccessMsg,'',{duration:5000,panelClass: ['green-snackbar']});
      },
      error:(error:any)=>{
        this.snackBar.open('Role Not found!!','',{duration:5000,panelClass: ['blue-snackbar']}) 
        this.ngxService.stop();
      }
    });
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
