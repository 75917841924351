<div fxLayout="row" fxLayoutAlign="space-between stretch" class=" genuine-class-color footer" 
   fxLayoutGap="20" fxLayout.md="column" fxLayoutAlign.md="center center" fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center" fxLayout.sm="column" fxLayout.xs="column">
   <div fxLayout="column" class="cursor-a" fxFlex="40" (click)="redirectToOrderNowPage()">
      <img class="display-no img-width"  src="../assets/images/banner-home.png" alt=""> 
   </div>
   <div fxLayout="column" fxFlex="60" fxLayout.xs="column" fxFlex.xs="100">
      <div class="container">
         <div class="row">
            <div class="col-md-8">
               <h1 class="repair-heading">{{'genuine_Repairs' | translate}}</h1>
               <h2 class="repair-subheading">{{'atyour_doorstep' | translate}}</h2>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-12">
               <div class="form-main">
                  <div class="maincircle">
                     <div class="circle pulse orange">
                        <div class="total-rupees">
                           <span class="total-rupees">₹</span> {{totalAmount | number : '1.2-2'}}
                        </div>
                     </div>
                  </div>
                  <form [formGroup]="calculatorForm">
                     <div class="margin-top20" [@openClose]>
                        <mat-form-field appearance="fill" class="select-product" >
                           <mat-label >{{'select_Product' | translate}}</mat-label>
                           <mat-select formControlName="product" (selectionChange)="changeProduct($event.value)">
                              <mat-option *ngFor="let product of productCategory" value={{product.productCategoryName}}>{{product.productCategoryDescription | translate}}</mat-option>
                           </mat-select>
                           <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                        </mat-form-field>
                     </div>
                     <div [ngSwitch]="selectedProductForSwitch">
                        <div *ngSwitchCase="'MOBILE'" [@openClose]>
                           <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap.lg="20" fxLayoutGap.md="20" fxLayoutGap.sm="20">
                              <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-brand">
                                    <mat-label >{{'select_Brand' | translate}}</mat-label>
                                    <mat-select formControlName="brand" (selectionChange)="changeBrand($event.value)">
                                       <mat-option *ngFor="let brand of brandList" [value]="brand">{{brand.brandName}}
                                       </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                              <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-model">
                                    <mat-label >{{'select_Model' | translate}}</mat-label>
                                    <mat-select formControlName="model" (selectionChange)="changeModel($event.value)">
                                       <mat-option *ngFor="let model of deviceList" [value]="model">{{model.modelName}}
                                       </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                              <div fxFlex="30" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-problem">
                                    <mat-label>{{'select_Problem' | translate}}</mat-label>
                                    <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                    [multiple]="true">
                                    <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                           </div>
                        </div>
                        <div *ngSwitchCase="'AIR_CONDITIONER'"[@openClose]>
                           <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap.lg="20" fxLayoutGap.md="20" fxLayoutGap.sm="20">
                              <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-brand">
                                    <mat-label >Model Type</mat-label>
                                    <mat-select formControlName="acTypes" (selectionChange)="changeAcType($event.value)">
                                       <mat-option *ngFor="let acType of acTypesList" [value]="acType">
                                       {{acType.acTypes | translate}}
                                       </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                              <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-model">
                                    <mat-label >{{'select_Problem' | translate}}</mat-label>
                                    <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                    [multiple]="true">
                                    <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                           </div>
                        </div>
                        <div *ngSwitchCase="'REFRIGERATOR'"[@openClose]>
                           <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap.lg="20" fxLayoutGap.md="20" fxLayoutGap.sm="20">
                              <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-brand">
                                    <mat-label >Model Type</mat-label>
                                    <mat-select formControlName="refrigeratorTypes" (selectionChange)="changeAcType($event.value)">
                                       <mat-option *ngFor="let refrigeratorType of refrigeratorTypesList" [value]="refrigeratorType">
                                       {{refrigeratorType.refrigeratorType | translate}}
                                       </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                              <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-model">
                                    <mat-label >{{'select_Problem' | translate}}</mat-label>
                                    <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                    [multiple]="true">
                                    <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                           </div>
                        </div>
                        <div *ngSwitchCase="'WASHING_MACHINE'"[@openClose]>
                           <div fxLayout="row" fxLayout.xs="column"  fxLayoutGap.lg="20" fxLayoutGap.md="20" fxLayoutGap.sm="20">
                              <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-brand">
                                    <mat-label >Model Type</mat-label>
                                    <mat-select formControlName="washingMachineTypes" (selectionChange)="changeAcType($event.value)">
                                       <mat-option *ngFor="let washingMachineType of washingMachineTypesList" [value]="washingMachineType">
                                       {{washingMachineType.washingMachineType | translate}}
                                       </mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                              <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                                 <mat-form-field appearance="fill" class="select-model">
                                    <mat-label >{{'select_Problem' | translate}}</mat-label>
                                    <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                    [multiple]="true">
                                    <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                                    </mat-select>
                                    <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                 </mat-form-field>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="margin-top20">
                        <div class="order-now-btn">
                           <button class="order-btn order-btn1" (click)="navigate()">{{'order_Now' | translate}}</button>
                        </div>
                        <div fxLayout="column" class="margin-top10">
                           <div class="display-star">
                              <div class="below-eclipse"><span><img src="../assets/images/icons/star.png" style="width: 28px;margin-right: 10px;"></span>100% {{'genuine_Parts' | translate}}</div>
                              <div class="below-eclipse"><span><img src="../assets/images/icons/star.png" style="width: 28px;margin-right: 10px;"></span>3 {{'months_Warranty' | translate}}</div>
                              <div class="below-eclipse"><span><img src="../assets/images/icons/star.png" style="width: 28px;margin-right: 10px;"></span>{{'twenty_Seven' | translate}}</div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>