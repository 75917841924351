<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">


<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 class="repair-heading-body uppercase">{{updateOrAdd | translate}}</h1>
    <div class="cursor-pointer padding0" (click)="onNoClick()"><mat-icon>close</mat-icon></div>
</div>

<form [formGroup]="addExpenseFormGroup">
    <mat-card >
        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="50">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'order_Id' | translate}}</mat-label>
                            <input matInput #input formControlName="orderId" placeholder="{{'order_Id' | translate}}">
                        </mat-form-field>
                    </div>
                    
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'vendor_name' | translate}}</mat-label>
                            <input matInput #input formControlName="vendorName"
                                placeholder="{{'vendor_name' | translate}}">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="fill">
                            <textarea matInput #input formControlName="expenseDetails" placeholder="{{'expense_Details' | translate}}"></textarea>
                        </mat-form-field>
                    </div>
            </div>
             <div fxLayout="column" fxFlex="50">
             <div>
                    <mat-form-field appearance="fill">
                        <mat-label >{{'expense_type' | translate}}</mat-label>
                        <mat-select formControlName="expenseType">
                            <mat-option  value={{expenseType.type}} *ngFor="let expenseType of expenseTypeList">{{expenseType.type | translate}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>
                </div> 

                   <div>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'expense_amount' | translate}}</mat-label>
                        <input matInput #input formControlName="expenseAmount"
                            placeholder="{{'expense_amount' | translate}}">
                    </mat-form-field>
                </div>
                    
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'expense_date' | translate}}</mat-label>
                            <input matInput  #input formControlName="expenseDate" [matDatepicker]="picker"  required>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>             
            </div> 

        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <div style="margin:10px;">
                <button class="button" type="submit" mat-raised-button (click)="addExpenseModel()">{{updateOrAdd | translate}}</button>
            </div>
        </div>
    </mat-card>
</form>
