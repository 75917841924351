import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Title } from "@angular/platform-browser";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppConstants } from "src/app/constants/app.constants";
import { Contact } from "src/app/data/contact-us-model/contact-us-model";
import { SupportService } from "src/app/services/support.service";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})

export class ContactComponent {
    saveContactDetailsUrl!: string;
    contactForm!: FormGroup;
    contactData: Contact
    constructor(private titleService: Title,
        private supportService: SupportService,
        private ngxService: NgxUiLoaderService,
        public snackBar: MatSnackBar) {
        this.titleService.setTitle("Contact Us");
        this.contactForm = new FormGroup({
            contactId: new FormControl(null, Validators.required),
            address: new FormControl(null, Validators.required),
            email: new FormControl(null, Validators.required),
            fullName: new FormControl(null, Validators.required),
            message: new FormControl(null, Validators.required),
            phoneNumber: new FormControl(null, Validators.required),
        });
    }

    saveContactDetails() {
        if (this.contactForm.status == 'INVALID') {
            this.snackBar.open('All fields are required!!', '', { duration: 5000, panelClass: ['blue-snackbar'] });
            return;
        }
        this.saveContactDetailsUrl = AppConstants.contactService + AppConstants.createContactIssue;
        this.contactData = {
            contactId: this.contactForm.get('contactId')?.value,
            address: this.contactForm.get('address')?.value,
            email: this.contactForm.get('email')?.value,
            fullName: this.contactForm.get('fullName')?.value,
            message: this.contactForm.get('message')?.value,
            phoneNumber: this.contactForm.get('phoneNumber')?.value,
        };
        this.ngxService.start();
        this.supportService.saveContactDetails(this.saveContactDetailsUrl, this.contactData).subscribe(
            {
                next: (data: any) => {
                    this.contactForm.reset();
                    this.ngxService.stop();
                    this.snackBar.open('Success!!', '', { duration: 5000, panelClass: ['green-snackbar'] });
                },
                error: (error: any) => {
                    console.log(error)
                    this.ngxService.stop();
                    this.snackBar.open('Something went wrong please try again later!!', '', { duration: 5000, panelClass: ['blue-snackbar'] });
                }
            }
        )
    }
    navigateToplayStore() {
        window.open('https://play.google.com/store/apps/details?id=com.mobi.repairing_company_app', '_blank')
    }

    navigateToAppStore() {
        window.open('https://apps.apple.com/in/app/repairing-company/id1586972099', '_blank')
    }

    navigateToyouTube() {
        window.open('https://www.youtube.com/@repairingcompany/', '_blank');
    }

    navigateTofacebook() {
        window.open('https://www.facebook.com/RepairingCompany', '_blank');
    }

    navigateToinstagram() {
        window.open('https://www.instagram.com/repairingcompany/', '_blank');
    }

    navigateTotwitter() {
        window.open('https://twitter.com/repairingcompny', '_blank');
    }

    navigateTolinkedin() {
        window.open('https://www.linkedin.com/company/repairing-company/', '_blank');
    }
}