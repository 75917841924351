<h5 class="heading">{{'my_Orders' | translate}}</h5>
<div class="row">
   <div class="col-md-12">
      <div *ngFor="let order of mobiOrderModel">
         <div class="accordion" >
            <div class="ordercard">
               <div class="line" >
                  <h2 class="mb-0">
                     <span class="btn btn-block text-left " style="width: 100%;">
                        <div class="row top">
                           <div class="col-md-6">
                              <h5>{{'Order_Placed' | translate}}</h5>
                              <b>{{(order.createdDate | date:'MMMM')! | translate}} {{order.createdDate | date:'dd, yyyy'}}</b>  
                           </div>
                           <div class="col-md-2">
                              <h5>{{'total' | translate}}</h5>
                              <b>{{order.orderAmount | currency:'INR'}}</b> 
                           </div>
                           <div class="col-md-4">
                              <div class="last">
                                 <h5>{{'mapperOrderId' | translate}} :  </h5>
                                 <b>{{order.orderId}}</b>
                              </div>
                           </div>
                        </div>
                     </span>
                  </h2>
               </div>
               <div  class="collapse show" aria-labelledby="headingOne">
                  <div class="pt-2">
                     <div class="row bot">
                        <div class="col-md-6">
                           <h4 *ngIf="order.brand!= null">{{order.brand.brandName}} {{order.model.modelName}}</h4>
                           <b>{{'order_type' | translate}} :</b> <span> {{order.orderType}} </span>
                           <br>
                           <b *ngIf="order!.repairIssues!.length != 0">{{'issues' | translate}} :</b>
                           <span  *ngFor="let e of order.repairIssues;  let last=last" class="mat-cell-def">
                           {{e.repairChargeDto?.issueDto?.issueName}}{{last?'':', '}}
                           </span>
                        </div>
                        <div class="col-md-6">
                           <div class="last-row">
                              <a class="cursor-a track" (click)="openOrderDetails(order.orderId)">{{'order_details' | translate}}</a> 
                              <!-- <a class="cursor-a track">{{'Write_Product_Review' | translate}}</a> -->
                              <a class="cursor-a track" (click)="viewOrAddSupport(order)">{{'Create_Support' | translate}}</a>  
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>