import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceAreaService {

  constructor(protected httpClient: HttpClient) { }

  serviceAreaListApiCall(serviceAreaListApiCallUrl:string):Observable<any>{
    return this.httpClient.get(serviceAreaListApiCallUrl);
  }

  updateserviceArea(updateserviceAreaUrl:string,support:any):Observable<any>{
    return this.httpClient.put(updateserviceAreaUrl,support);
  }

  addserviceArea(updateserviceAreaUrl:string,support:any):Observable<any>{
    return this.httpClient.post(updateserviceAreaUrl,support);
  }
  
}
