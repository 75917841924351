<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">


<!--   <div fxLayout="column" fxFlex="80" fxLayout.xs="column" fxFlex.xs="100">
        <div>
            <h1 class="repair-heading">{{'order_Confirmation' | translate}}</h1>
        </div>
        <div>
            <h2 class="repair-heading-body">{{'your_order_has_been_Successfull' | translate}} <span class="order-details-hyperlink" (click)="showOrderConfirmation()">{{'order_details' | translate}}</span></h2>
        </div>
        <div>
            <h2 class="repair-heading-body">{{'a_confirmation_email' | translate}}</h2>
        </div>
    </div>

    <div fxLayout="column" fxFlex="20">
        <div class="ellipse">
            <div fxLayout="column" fxLayout.xs="column">
                <div class="total-amount">
                   {{orderFailedOrSuccess | translate}}
                </div>
                <div class="total-rupees">
                    {{'orderId' | translate}} {{orderId}}
                </div>
            </div>
        </div>
    </div>

</div>

<div fxLayout="column" fxLayoutAlign="start start"  fxLayoutGap="20" class="every-repair-section margin-top5"  >
    <div fxLayout="row" class="padding-top40"  fxLayoutAlign="space-between center">
        <h1 class="repair-heading absoulte-position red-color" >{{'whats_next' | translate}}</h1>
    </div>
    <div>
        <h2 class="repair-heading-body margin-top40 margin-bottom5">{{'our_trained_technician_will_visit' | translate}}</h2>
    </div>
    <div>
        <h2 class="repair-heading-body margin-top0">{{'if_you_would_like_to_make' | translate}}<span class="order-details-hyperlink">{{'my_Orders' | translate}}</span>{{'on_Repairing_Company_mobile_App' | translate}}</h2>
    </div>
    <div class="order-now-btn buy-btn-below margin-left62 margin-bottom40" fxLayoutAlign="start start">
        <button class="order-btn" (click)="home()">{{'home' | translate}}</button>
    </div> -->



<div class="main">
    <section style="padding-bottom: 60px;">
        <div class="container">
            <div class="row">

                <div class="col-md-6">
                    <div class="order-confirmation">
                        <h1>{{'order_Confirmation' | translate}}</h1>
                        <h3>{{'orderId' | translate}} {{orderId}}</h3>
                        <p>{{'your_order_has_been_Successfull' | translate}}, <a class="order-details-hyperlink"
                                (click)="showOrderConfirmation()">{{'order_details' | translate}}</a></p>
                        <p>{{'a_confirmation_email' | translate}}</p>
                    </div>
                </div>

                <div class="col-md-1"></div>

                <div class="col-md-5">
                    <img src="../assets/images/order-confirmation.jpg" style="width: 100%;">
                </div>
            </div>
        </div>
    </section>

    <section class="whatnext">
        <div class="container">
            <div class="row">

                <div class="col-md-11">
                    <div class="">
                        <h1>{{'whats_next' | translate}}</h1>
                        <p>{{'our_trained_technician_will_visit' | translate}}</p>
                        <p>{{'if_you_would_like_to_make' | translate}}<span
                                class="order-details-hyperlink" (click)="navigateToMyAcc()">{{'my_Orders' |
                                translate}}</span>{{'on_Repairing_Company_mobile_App' | translate}}</p>
                        <button class="main-button" (click)="home()">{{'home' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <hr>
</div>



<div style="background: #fff;" fxLayout="column" fxLayoutAlign="space-between" fxLayoutGap="20"
    class="every-repair-section bg-color-annual-section ">
    <app-plans></app-plans>
</div>