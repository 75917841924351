<div class="del-popup">
    <div style="text-align: center;" class="img-delete">
        <img src="../../../../assets/images/delete-account.jpg" alt="delete-account.jpg">
    </div>
    <h4>{{'warning_msg' | translate}}</h4>
    <div style="text-align: center;">
        <button class="yes-btn" mat-button value="Yes" (click)="onClick('Yes')">{{'yes_btn' | translate}}</button>
        <button class="no-btn" mat-button value="No" (click)="onClick('No')">{{'no_btn' | translate}}</button>
    </div>
    <div style="text-align: center;">
    </div>
</div>