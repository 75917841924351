import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants, OrderStatus, RepairModeType } from 'src/app/constants/app.constants';
import { CaseModel } from 'src/app/data/case-model/case.model';
import { MobiOrderModel } from 'src/app/data/mobi-order-model/mobi-order.model';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-repair',
  templateUrl: './repair.component.html',
  styleUrls: ['./repair.component.scss']
})
export class RepairComponent extends BaseComponent implements OnInit {
  protected getOrderUrl: string;
  protected getClientCaseUrl: string;
  protected updateClientCaseUrl: string;
  protected updateRepairIssueUrl: string = AppConstants.orderService + AppConstants.UPDATE_REPAIR_ISSUE;
  caseData: any;
  orderData: any;
  mmCaseId: number;
  allIssueDone: number = 0;
  onSIteOrderPickUp: boolean = false;
  displayedColumns: string[] = ['issueName', 'issueDescription', 'action'];
  constructor(
    dialogRef: MatDialogRef<RepairComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private dashboardService: DashboardService,
    router: Router,
    snackBar: MatSnackBar,
    route: ActivatedRoute,
    dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef) {
    super();
    this.dialogRef = dialogRef;
    this.router = router;
    this.snackBar = snackBar;
    this.route = route;
    this.dialog = dialog;
    this.mmCaseId = data['mmCaseId'];
    this.getClientCaseUrl = AppConstants.supportService + AppConstants.GET_CASE + '?id=' + this.mmCaseId;
    this.getCaseAPI();

  }

  ngOnInit(): void {
  }
  repairIssueStatusChanged(repairIssue) {
    // let status = this.getStatusFroRepairIssue(isHold, repairIssue.status);
    let status = 'REPAIR_COMPLETED';
    console.log("status => ", status);
    console.log(repairIssue);
    let repairIssueRequestData = JSON.parse(JSON.stringify(repairIssue));
    repairIssueRequestData.status = status;
    repairIssueRequestData.orderId = this.orderData.orderId;
    console.log(repairIssueRequestData);
    this.updateRepairIssue(repairIssueRequestData);

  }

  getOrderAPI() {
    this.dashboardService.getRequest(this.getOrderUrl)
        .subscribe(
            res => {
                this.passOrderData(res);
            },
            (error) => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

getCaseAPI() {
    this.dashboardService.getRequest(this.getClientCaseUrl)
        .subscribe(
            res => {
                this.passClientCaseResponse(res);
            },
            (error) => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

updateRepairIssue(data) {
    this.dashboardService
        .putRequest(this.updateRepairIssueUrl, data)
        .subscribe(
            res => {
                this.passRepairIssueResponse(res);

            },
            (error) => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}

updateCase() {
    this.dashboardService.getRequest(this.updateClientCaseUrl)
        .subscribe(
            res => {
                this.dialogRef.close(res);
                this.passUpdateClientCaseResponse(res);
            },
            error => {
                if (this.errorHandler(error)) {
                    this.errorDisplayDialog(error.error.message);
                }
            }
        );
}





  orderHasOnlyOnSite(): boolean {
    let result = true;
    for (let repairIssue of this.orderData.repairIssues!) {
      if (repairIssue.repairModeDto!.repairModeType !== RepairModeType.ONSITE) {
        result = false;
      }
    }
    return result;
  }

  orderHasOnPickUpRepair(): boolean {
    let result = false;
    for (let repairIssue of this.orderData.repairIssues!) {
      if (repairIssue.repairModeDto!.repairModeType === RepairModeType.PICKUP_AND_DROP) {
        result = true;
      }
    }
    return result;
  }
  caseStatusChanged() {
    let caseStatus;
    console.log("current status", this.caseData.caseStatus)
    console.log("has pickup", this.orderHasOnPickUpRepair());
    console.log("has pickup", this.checkIfOnSIteWasPickedUp());
    if ((this.caseData.caseStatus === OrderStatus.SERVICE_CENTER_DROPPED
      || this.caseData.caseStatus === OrderStatus.SERVICE_STARTED)
      && (this.orderHasOnPickUpRepair() || !this.checkIfOnSIteWasPickedUp())) {
      caseStatus = this.caseData.caseStatus === OrderStatus.SERVICE_CENTER_DROPPED
        ? OrderStatus.SERVICE_STARTED : OrderStatus.SERVICE_COMPLETED;
      this.caseData.caseStatus = caseStatus;
    } else if ((this.caseData.caseStatus === OrderStatus.ARRIVED_AT_LOCATION
      || this.caseData.caseStatus === OrderStatus.SERVICE_STARTED)) {
      caseStatus = this.caseData.caseStatus === OrderStatus.ARRIVED_AT_LOCATION
        ? OrderStatus.SERVICE_STARTED : OrderStatus.PRODUCT_SERVICED;
      this.caseData.caseStatus = caseStatus;
    }
    console.log("status", caseStatus);
    console.log("status", this.caseData.caseStatus);
    this.updateClientCaseUrl = AppConstants.supportService + AppConstants.UPDATE_CASE_STATUS
      + '?id=' + this.caseData.mmCaseId + '&status=' + caseStatus;
    this.updateCase();
  }

  changeToServiceCenter() {
    let caseStatus = OrderStatus.PICKUP_COMPLETED;
    this.caseData.caseStatus = caseStatus;
    this.updateClientCaseUrl = AppConstants.supportService + AppConstants.UPDATE_CASE_STATUS
      + '?id=' + this.caseData.mmCaseId + '&status=' + caseStatus;
    this.updateCase();
  }
  // private getStatusFroRepairIssue(isHold, r_status) {
  //   if (isHold) {
  //     return 'REPAIR_ON_HOLD';
  //   } else if (r_status === 'PENDING') {
  //     return 'REPAIR_IN_PROGRESS';
  //   } else if (r_status === 'REPAIR_IN_PROGRESS') {
  //     return 'REPAIR_COMPLETED';
  //   }
  // }
  protected passOrderData(res: any) {
    this.orderData = res;
    this.onSIteOrderPickUp = this.checkIfOnSIteWasPickedUp() && this.orderHasOnlyOnSite();
    console.log("is pickup allowed", this.onSIteOrderPickUp, this.orderHasOnlyOnSite(), this.checkIfOnSIteWasPickedUp());
  }

  protected passClientCaseResponse(res: any) {
    this.caseData = res;
    console.log(res);
    this.getOrderUrl = AppConstants.orderService + AppConstants.getOrderRequest + '?id=' + this.caseData.orderId;
    this.getOrderAPI();
  }

  checkIfOnSIteWasPickedUp(): boolean {
    let result = true;
    for (let assignment of this.caseData.caseAssignmentHistoryDtos!) {
      if (assignment?.fromCaseStatus?.caseStatus === OrderStatus.ARRIVED_AT_LOCATION
        && assignment?.toCaseStatus?.caseStatus === OrderStatus.PICKUP_COMPLETED) {
    console.log("has onsite and was picked up")

          return false;
      }
      if (assignment?.fromCaseStatus?.caseStatus === OrderStatus.ARRIVED_AT_LOCATION
        && result === true) {
        result = true;
      }
    }
    return result;
  }

  clickedAction() {
    //this.showSnackBar("Action not available yet", "Status updare repair issue", 3000, AppConstants.SNACK_BAR_ERROR_CLASS);
    this.errorDisplayDialog("Action not available yet");
  }

  protected passRepairIssueResponse(res: any) {
    this.orderData.repairIssues!.splice(this.orderData.repairIssues!.findIndex(issue => issue.repairIssueId === res.repairIssueId), 1);
    this.orderData.repairIssues!.push(res);
    this.getOrderAPI();
  }

  protected passUpdateClientCaseResponse(res: any) {
    // this.routeTo("/dashboard", null);
  }

  closeSheet() {
    console.log("action allowed");
    if (this.checkIfAllReapiarsAreComplete()) {
      console.log("flag", this.checkIfAllReapiarsAreComplete());
      this.caseStatusChanged();
    } else {
      console.log("flag", this.checkIfAllReapiarsAreComplete());
      // this.showSnackBar("App Repairs have to be Completed.", "Not Allowed", 3000, AppConstants.SNACK_BAR_WARNING_CLASS);
      this.errorDisplayDialog("Mobile Repairs have to be Completed");
    }
  }

  checkIfAllReapiarsAreComplete() {
    let flag = true;
    console.log("this.orderData.repairIssues", this.orderData.repairIssues);
    for (let repairI of this.orderData.repairIssues!) {
      if (repairI.status !== 'REPAIR_COMPLETED' && flag === true) {
        flag = false;
      }
    }
    return flag;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
