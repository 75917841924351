import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { ACTypesModel, BrandModel, RefrigeratorTypesModel, WashingMachineTypesModel } from 'src/app/data/brand-model/brand.model';
import { DeviceModel } from 'src/app/data/device-model/device.model';
import { ProductCategory } from 'src/app/data/product-category-model/product-category-model';
import { RepairChargesModel } from 'src/app/data/repair-charges-model/repair-charges.model';
import { NewHomeService } from 'src/app/services/home.service';
import { Router } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';
import { LoginSendOTPDialogDialog } from '../dialogs/login-sendOTP-dialog/login-sendotp-dialog';
import { DOCUMENT } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import * as util from '../../utility-functions'
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-calculator-form',
  templateUrl: './calculator-form.component.html',
  styleUrls: ['./calculator-form.component.scss'],
  animations: [
    trigger("openClose", [
      transition("void => *", [
        style({
          height: 0,
          opacity: 0,
          transform: "scale(0.85)",
          "margin-bottom": 0,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
          paddingLeft: 0,
        }),
        animate(
          "50ms",
          style({
            height: "*",
            "margin-bottom": "*",
            paddingTop: "*",
            paddingBottom: "*",
            paddingRight: "*",
            paddingLeft: "*",
          })
        ),
        animate(70),
      ]),
    ]),
  ],
})
export class CalculatorFormComponent extends BaseComponent implements OnInit {
  productCategory:ProductCategory[] = [];
  brandList: BrandModel[] = [];
  deviceList: DeviceModel[] = [];
  repairChargeList: RepairChargesModel[] = [];
  selectedProblems: RepairChargesModel[] = [];
  calculatorForm!: FormGroup;
  totalAmount: number = 0;
  newHomeService: NewHomeService;
  getProductCategoryDataListUrl = AppConstants.orderService + AppConstants.getAllProducts;
  public acTypesList: ACTypesModel[] = [];
  public refrigeratorTypesList: RefrigeratorTypesModel[] = [];
  public washingMachineTypesList: WashingMachineTypesModel[] = [];
  selectedProductForSwitch:any;
  constructor(dialog: MatDialog,snackBar: MatSnackBar,newHomeService: NewHomeService,private userService:UserService,
    router:Router,@Inject(DOCUMENT) private document) {
    super();
    this.dialog = dialog;
    this.snackBar = snackBar;
    this.newHomeService = newHomeService;
    this.router = router;
  }

  redirectToOrderNowPage(){
    const temperdGlass = {
      temperedGlass:'temperedGlass',
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:temperdGlass});
  }

  ngOnInit(): void {
    this.calculatorForm = new FormGroup({
      product: new FormControl(),
      brand: new FormControl(),
      acTypes:new FormControl(),
      refrigeratorTypes:new FormControl(),
      washingMachineTypes:new FormControl(),
      model: new FormControl(),
      repair: new FormControl(),
    });
    this.getProductCategoryDataListUrl = AppConstants.orderService + AppConstants.getAllProducts;
    this.callGetProductCategoryDataListApi();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginSendOTPDialogDialog, {
      width: '350px',
      height:'590px',
      data: {},
      disableClose: true 
    });
    dialogRef.afterClosed().subscribe(result => {
      this.userService.getusernameonlogin(result);
      const orderDetails = {
      productCategory:this.productCategory,
      brandList:this.brandList,
      deviceList:this.deviceList,
      repairChargeList:this.repairChargeList,
      acTypesList:this.acTypesList,
      refrigeratorTypesList:this.refrigeratorTypesList,
      washingMachineTypesList:this.washingMachineTypesList,
      product:this.calculatorForm.controls['product'].value,
      brand:this.calculatorForm.controls['brand'].value,
      acTypes:this.calculatorForm.controls['acTypes'].value,
      refrigeratorTypes:this.calculatorForm.controls['refrigeratorTypes'].value,
      washingMachineTypes:this.calculatorForm.controls['washingMachineTypes'].value,
      model:this.calculatorForm.controls['model'].value,
      repair:this.calculatorForm.controls['repair'].value,
      totalAmount:this.totalAmount
    }
      if(result != undefined)
      {
        this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:orderDetails});
      }
      
    });
  }

  navigate(){
    if(this.calculatorForm.dirty){
      const orderDetails= {
        productCategory:this.productCategory,
        brandList:this.brandList,
        deviceList:this.deviceList,
        repairChargeList:this.repairChargeList,
        acTypesList:this.acTypesList,
        refrigeratorTypesList:this.refrigeratorTypesList,
        washingMachineTypesList:this.washingMachineTypesList, 
        product:this.calculatorForm.controls['product'].value,
        brand:this.calculatorForm.controls['brand'].value,
        acTypes:this.calculatorForm.controls['acTypes'].value,
        refrigeratorTypes:this.calculatorForm.controls['refrigeratorTypes'].value,
        washingMachineTypes:this.calculatorForm.controls['washingMachineTypes'].value,
        model:this.calculatorForm.controls['model'].value,
        repair:this.calculatorForm.controls['repair'].value,
        totalAmount:this.totalAmount
      }
      if(localStorage.getItem("access_token") == undefined){
        this.openDialog();
      }
       else{
        this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:orderDetails});
      }
      
       }else{
      this.router.navigateByUrl(AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute));
    }
  }
  NavigateToRecommended(){
    this.document.getElementById('recommended').scrollIntoView({
      behavior: 'smooth'
    });
  }
  NavigateToPlansPage(PlanName){
    const plan = {
      planName:PlanName
    }
    this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.plansRoute)],{state:plan});
  }

  callGetProductCategoryDataListApi(){
    this.newHomeService.getProductCategoryDataListApi(this.getProductCategoryDataListUrl).subscribe({
      next:(res)=>{
        this.productCategory = res;
        this.calculatorForm.controls['product'].setValue(res[0].productCategoryDescription);
       this.changeProduct(res[0].productCategoryDescription)
      },
      error:(error) =>{
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
      }
    }
  )}

  selectProblems() {
    this.totalAmount = 0;
    this.selectedProblems = this.calculatorForm.controls['repair'].value;
    console.log(this.selectedProblems);
    

    if (this.selectedProblems != null) {
      this.selectedProblems.forEach((e:any) => {
        this.totalAmount = this.totalAmount + e.repairAmount;
      });
    }
  } 
  

  
  changeProduct(selectedProduct:any){
     this.selectedProductForSwitch = selectedProduct
    let getBrandDataListUrl = AppConstants.orderService + AppConstants.getAllBrandsClientByCategory + "?category=" + selectedProduct;
    this.newHomeService.getBrandDataListApi(getBrandDataListUrl).subscribe({
      next:(res) =>{
        if(this.calculatorForm.controls['product'].getRawValue() == 'AIR_CONDITIONER' || this.calculatorForm.controls['product'].getRawValue() == 'REFRIGERATOR' || this.calculatorForm.controls['product'].getRawValue() == 'WASHING_MACHINE')
        {
            this.LoadProductTypes()
        }
        if(localStorage.getItem('access_token') != undefined)
        {
          if(util.token() != undefined && util.token().includes('APPLE'))
          {
            const result = res.filter((obj) => {
              return obj.brandName === 'Apple';
            });
  
            this.brandList = result
          }
          else
          {
            this.brandList = res
          }
        }
        else
          {
            this.brandList = res
          }
      },
      error:(error) =>{
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
      }
    })

    this.calculatorForm.controls['brand'].reset();
    this.calculatorForm.controls['model'].reset();
    this.calculatorForm.controls['repair'].reset();
    this.totalAmount = 0; 
    this.brandList = [];
    this.acTypesList = [];
    this.deviceList = [];
    this.repairChargeList = [];
    this.selectedProblems = [];
  }

  LoadProductTypes()
  {
    let getProductTypesUrl;
    if(this.calculatorForm.controls['product'].getRawValue() == 'AIR_CONDITIONER')
    {
      getProductTypesUrl = AppConstants.orderService + AppConstants.getACtypes;
    }
    else if(this.calculatorForm.controls['product'].getRawValue() == 'REFRIGERATOR')
    {
      getProductTypesUrl = AppConstants.orderService + AppConstants.getRefrigeratorTypes;
    }
    else if(this.calculatorForm.controls['product'].getRawValue() == 'WASHING_MACHINE')
    {
      getProductTypesUrl = AppConstants.orderService + AppConstants.getWashingMachineTypes;
    }
    
    this.newHomeService.getProductsTypesApi(getProductTypesUrl).subscribe({
      next:(res) =>{
       
        if(this.calculatorForm.controls['product'].getRawValue() == 'AIR_CONDITIONER')
        {
          this.acTypesList = res
        }
        else if(this.calculatorForm.controls['product'].getRawValue() == 'REFRIGERATOR')
        {
          this.refrigeratorTypesList = res
        }
        else if(this.calculatorForm.controls['product'].getRawValue() == 'WASHING_MACHINE')
        {
          this.washingMachineTypesList = res
        }
      },
      error:(error) =>{
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
      }
    })
  }
  changeBrand(brand: BrandModel) {
    let getModelDataListUrl = AppConstants.orderService + AppConstants.getModels + "?brandId=" + brand.brandId
    this.newHomeService.getModelDataListApi(getModelDataListUrl).subscribe({
      next : (res)=>{
        this.deviceList = res;
        console.log(res)
      },
      error : (error) =>{
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
      }
    })

    this.calculatorForm.controls['model'].reset();
    this.calculatorForm.controls['repair'].reset();
    this.totalAmount = 0;
    this.deviceList = [];
    this.repairChargeList = [];
    this.selectedProblems = [];
  }

  changeModel(model: DeviceModel) {
    let getRepairChargeDataListUrl = AppConstants.orderService + AppConstants.getRepairChargesFromMobiMech + "?modelId=" + model.modelId
    this.newHomeService.getRepairChargeDataListApi(getRepairChargeDataListUrl).subscribe(
      {
        next: (res) =>{
          this.repairChargeList = res;
          this.repairChargeList = this.repairChargeList.filter((e:any) => e.repairAmount > 0);
        },
        error: (error) =>{
          if (this.errorHandler(error)) {
            if(error?.error?.message == undefined){
             this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            }else{
             this.errorDisplayDialog(error.error.message);
            }
           }
        }
      }
    )



    this.calculatorForm.controls['repair'].reset();
    this.totalAmount = 0;
    this.repairChargeList = [];
    this.selectedProblems = [];
  }

  changeAcType(allProducts: any) {
    let getRepairChargeDataListUrl;
    if(this.calculatorForm.controls['product'].getRawValue() == 'AIR_CONDITIONER')
    {
     getRepairChargeDataListUrl = AppConstants.orderService + AppConstants.getRepairChargesFromMobiMech + "?acTypeId=" + allProducts.acTypeId
    }
    else if(this.calculatorForm.controls['product'].getRawValue() == 'REFRIGERATOR')
    {
     getRepairChargeDataListUrl = AppConstants.orderService + AppConstants.getRepairChargesFromMobiMech + "?refrigeratorTypeId=" + allProducts.refrigeratorTypeId
    }
    else if(this.calculatorForm.controls['product'].getRawValue() == 'WASHING_MACHINE')
    {
     getRepairChargeDataListUrl = AppConstants.orderService + AppConstants.getRepairChargesFromMobiMech + "?washingMachineTypeId=" + allProducts.washingMachineTypeId
    }
      this.newHomeService.getRepairChargeDataListApi(getRepairChargeDataListUrl).subscribe(
      {
        next: (res) =>{
          this.repairChargeList = res;
          this.repairChargeList = this.repairChargeList.filter((e:any) => e.repairAmount > 0);
        },
        error: (error) =>{
          if (this.errorHandler(error)) {
            if(error?.error?.message == undefined){
             this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
            }else{
             this.errorDisplayDialog(error.error.message);
            }
           }
        }
      }
    )
    this.calculatorForm.controls['repair'].reset();
    this.totalAmount = 0;
    this.repairChargeList = [];
    this.selectedProblems = [];
  }
}
