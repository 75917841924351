import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";




@Component({

selector: 'app-faq',
templateUrl: './faq.component.html',
styleUrls: ['./faq.component.scss'],

})

export class FaqComponent{
    constructor(private translation:TranslateService,private router:Router,private titleService: Title ) {
        this.translation.get('faq').subscribe((res: string) => {
          this.titleService.setTitle(res);
        });
    
        this.translation.onLangChange.subscribe((event: LangChangeEvent) => {
          this.translation.get('faq').subscribe((res: string) => {
            this.titleService.setTitle(res);
          });
        });
      }
    
}