import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';

@Component({
  selector: 'app-compareplans',
  templateUrl: './compareplans.component.html',
  styleUrls: ['./compareplans.component.scss']
})
export class CompareplansComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<CompareplansComponent>,public router:Router) { }

  ngOnInit(): void {
  }
  
  onNoClick(){
    this.dialogRef.close();
  }

  NavigateToPlansPage(PlanName){
    const plan = {
      planName:PlanName
    }
    this.dialogRef.close({data:PlanName});
    
    
  }
}
