import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { RepairIssueModel } from 'src/app/data/repair-issue-model/repair-issue.model';
import { DeviceIssueService } from 'src/app/services/device-issue.service';

@Component({
  selector: 'app-add-issue-dialog',
  templateUrl: './add-issue-dialog.component.html',
  styleUrls: ['./add-issue-dialog.component.scss']
})
export class AddIssueDialogComponent extends BaseComponent implements OnInit {

  newIssueModel: RepairIssueModel = new RepairIssueModel();
  protected createNewIssueUrl: string;
  issueNameControl = new FormControl('', [Validators.required]);
  issueDescriptionControl = new FormControl('', [Validators.required]);
  constructor(dialogRef: MatDialogRef<AddIssueDialogComponent>,
    private ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private deviceIssueService:DeviceIssueService,
    snackBar: MatSnackBar,
    dialog: MatDialog) {
    super();
    this.dialogRef = dialogRef;
    this.snackBar = snackBar;
    this.dialog = dialog;
   
    this.createNewIssueUrl = AppConstants.orderService + AppConstants.ADD_REPAIR_ISSUE;
  }

  ngOnInit(): void {}
 

submitNewIssue() {
  this.ngxService.start();
    const newRepairIssue = {
        "issueName": this.newIssueModel.issueName,
        "issueDescription": this.newIssueModel.issueDescription,
        "hsnCode": 99872
    };
    this.deviceIssueService.addRequest(this.createNewIssueUrl, newRepairIssue)
        .subscribe({
          next:(data)=>{
            this.dialogRef.close(data);
            this.ngxService.stop();
            this.snackBar.open('Issue Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
          },
          error:(error)=>{
         console.log(error)
         this.ngxService.stop();
         this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
          }
        });
  }
  

  onNoClick(): void {
    this.dialogRef.close();
  }
}
