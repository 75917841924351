import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRouteConstants } from 'src/app/constants/app-route.constants';
import { CompareplansComponent } from '../compareplans/compareplans.component';
import { UserService } from 'src/app/services/user.service';
import { LoginSendOTPDialogDialog } from '../dialogs/login-sendOTP-dialog/login-sendotp-dialog';

@Component({
  selector: 'app-protection-plans',
  templateUrl: './protection-plans.component.html',
  styleUrls: ['./protection-plans.component.scss']
})
export class ProtectionPlansComponent implements OnInit {
  planName:string;
  orderAmount:number=0;
  name:string = ''
  constructor(private dialog: MatDialog,private userService:UserService,private titleService:Title,private router:Router,private route:ActivatedRoute,public loginDialog: MatDialog) {
    
    this.planName = this.router.getCurrentNavigation()!.extras!.state ==undefined ? 'all_in_one':this.router.getCurrentNavigation()!.extras!.state!['planName']    
    this.titleService.setTitle('Plans');
  }

  assistancePlan=[
    'free_pickup_from_home','drops_to_Service_Centers','100_money_back_guarantee','scheudule_picks_and_drops','real_time_case_status','24x7_support','1_year_validity'
  ]
  all_in_one=[
   '100covers_physical_damage','100covers_Water_damage','doorstep_Service','loaner_phone_during_repair','100_money_back_guarantee','real_time_case_status','24x7_support','1_year_validity'
  ]
  extendedWarranty=[
    '100_wear_and_tear_repairs','100_money_back_guarantee','scheudule_picks_and_drops','real_time_case_status','24x7_support'
  ]
  assitancePlanNotCovered=[
    'cost_of_parts_during_phone_repairs','service_cost_for_phone_repairs','loaner_phone_during_repair'
  ]
  extendedWarrantyPlanNotCovered=[
    'screen_damage_repairs','water_damage_repair','loaner_phone_during_repair'
  ]
  NavigateToShield(){
    this.planName='shield_plan';
  }
  NavigateToOrderPage(){
    if(this.planName=='shield_plan'){
      this.orderAmount = 1199
    }else if(this.planName=='extended_Warranty'){
      this.orderAmount = 199
    }else if(this.planName=='assistance_Plan'){
      this.orderAmount = 999
    }else if(this.planName=='all_in_one'){
      this.orderAmount = 1999
    }
    const plan = {
      orderAmount:this.orderAmount,
      planName:this.planName
    }
    if(localStorage.getItem('access_token') != null)
    {
      this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:plan});
    }
    else{
      this.openDialog(plan)
    }
   
  }

  openDialog(plan): void {
    const dialogRef = this.dialog.open(LoginSendOTPDialogDialog, {
      width: '350px',
      height:'590px',
      data: {},
      disableClose: true 
    });
    dialogRef.afterClosed().subscribe(result => {
      this.userService.getusernameonlogin(result);
      if(result != undefined)
      {
        this.router.navigate([AppRouteConstants.getRoute(AppRouteConstants.orderDetailsRoute)],{state:plan});
      }
      
    });
  }

  ngOnInit(): void { 
  }

  showComparePlans(): void {
    const dialogRef = this.loginDialog.open(CompareplansComponent, {
      width: '84vw',
      maxWidth:'84vw',
      height:'100%',
      disableClose: true,
      panelClass: "mat-dialog-container-details" ,
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.planName = result.data;
      }
    });
  }

  navigate(){
    this.router.navigate(['/']);
  }

}
