import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'aap-lockappsoniphone',
    templateUrl: './lockappsoniphone.component.html'
})

export class LockappsoniphoneComponent {
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Keep your information secure by locking any app on your iPhone without coding knowledge! Follow our guide and protect yourself with a Password or Touch Id today."})
        this.metaService.updateTag({name:'keywords',content:'lock apps on iphone, how to lock apps in iphone'})
        this.canonicalServide.setCanonicalURL();
        
        this.translate.get('how_To_Lock_Apps_On_iPhone').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('how_To_Lock_Apps_On_iPhone').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        });
    }
}