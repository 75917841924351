<div class= fullscreen>
    <mat-card>
        <h1 mat-dialog-title>User Information</h1>
        <div mat-dialog-content>
            <label>First Name:<b> {{userDetail.firstName}}</b></label><br>
            <label>Last Name:<b> {{userDetail.lastName}}</b></label><br>
            <label>Email Address:<b> {{userDetail.email}}</b></label><br>
            <!-- <ng-template [ngIf]="userDetail.mobileNumber"> -->
            <label>Mobile Number:<b> {{userDetail.mobileNumber}}</b></label><br>
            <!-- </ng-template> -->
            <label>Role:<b> {{userDetail.role}}</b></label><br>

            <h2 mat-dialog-title>User Current Record</h2>
            <div mat-dialog-content>
                <ng-template [ngIf]="userDetail.role === 'DELIVERY_ENGINEER'">
                    <label>Delayed Pickup:<b> {{delayedPickup.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'DELIVERY_ENGINEER'">
                    <label>Delayed DropToCenter:<b> {{delayedDropToCenter.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'DELIVERY_ENGINEER'">
                    <label>On-Time Pickup:<b> {{onTimePickup.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'DELIVERY_ENGINEER'">
                    <label>On-Time DropToCenter:<b> {{onTimeDropToCenter.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'SERVICE_ENGINEER'">
                    <label>Delayed Service:<b> {{delayedService.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'SERVICE_ENGINEER'">
                    <label>On-Time Service:<b> {{onTimeService.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'DELIVERY_ENGINEER'">
                    <label>Delayed DropToCustomer:<b> {{delayedDropToCustomer.length}}</b></label><br>
                </ng-template>
                <ng-template [ngIf]="userDetail.role === 'DELIVERY_ENGINEER'">
                    <label>On-Time DropToCustomer:<b> {{onTimeDropToCustomer.length}}</b></label><br>
                </ng-template>
            </div>
            <h2 mat-dialog-title>Complete Record</h2>
            <ngx-charts-advanced-pie-chart [view]="view" [scheme]="colorScheme" [results]="single"
                [gradient]="gradient">
            </ngx-charts-advanced-pie-chart>    
            <button mat-button (click)="onNoClick()">Close</button>
        </div>
    </mat-card>
</div>
