import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-deal-with-water-damage',
    templateUrl: './deal-with-water-damage.html'
})

export class DealWithWaterDamageComponent{
    
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
      this.metaService.updateTag({name:'description',content:"Discover expert tips for repairing a water-damaged device. Get your mobile phone back to life with our reliable solutions. Learn more!"})
      this.metaService.updateTag({name:'keywords',content:'Water-Damaged Device Repair, repairing company, repair services in delhi/ncr, mobile phone repair, phone screen replacement, iphone repair'})
      this.canonicalServide.setCanonicalURL();
      
      this.translate.get('deal_with_water_damage').subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
      
      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translate.get('deal_with_water_damage').subscribe((res: string) => {
              this.titleService.setTitle(res);
        });
        });
    }   
}
