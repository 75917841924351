<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">


<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 class="repair-heading-body uppercase">{{'add_procurement_vendor_details' | translate}}</h1>
    <div class="cursor-pointer padding0" (click)="onNoClick()"><mat-icon>close</mat-icon></div>
</div>

<form [formGroup]="addProcurementVendorFormGroup">
    <mat-card >
        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="50">
                   <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'vendor_name' | translate}}</mat-label>
                            <input matInput #input formControlName="procurementVendorName"
                                placeholder="{{'vendor_name' | translate}}">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="fill">
                            <textarea matInput #input formControlName="procurementVendorAddress" placeholder="{{'address' | translate}}"></textarea>
                        </mat-form-field>
                    </div>

                   
            </div>
           
             <div fxLayout="column" fxFlex="50">

                <div>
                    <mat-form-field appearance="fill">
                        <mat-label>{{'mobile_number' | translate}}</mat-label>
                        <input matInput #input formControlName="procurementVendorPhoneNumber"
                            placeholder="{{'mobile_number' | translate}}">
                    </mat-form-field>
                </div>
             
             <div>
                    <mat-form-field appearance="fill">
                        <mat-label >{{'vendor_department' | translate}}</mat-label>
                        <mat-select formControlName="vendorDepartment">
                            <mat-option  value={{procurementVendorDepartment.productCategoryName}} *ngFor="let procurementVendorDepartment of productCategory">{{procurementVendorDepartment.productCategoryName | translate}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                    </mat-form-field>
                </div> 
          
            </div> 

        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <div style="margin:10px;">
                <button class="button" type="submit" mat-raised-button (click)="addProcurementVendor()">Submit</button>
            </div>
        </div>
    </mat-card>
</form>
