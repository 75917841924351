import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent} from './app.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TokenInterceptor } from './services/token.interceptor';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from './material/material.module';
import { MessageDisplayDailogComponent } from './ui/dialogs/message-display-dailog/message-display-dailog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GetStartedDialog } from './ui/dialogs/get-started-dialog/get-started-dialog';
import { LoginSendOTPDialogDialog } from './ui/dialogs/login-sendOTP-dialog/login-sendotp-dialog';
import { DatePipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { HomeComponent } from './ui/home/home.component';
import { FooterComponent } from './ui/footer/footer.component';
import { OrderDetailsComponent } from './ui/order-details/order-details.component';
import { PaymentStatusComponent } from './ui/payment-status/payment-status.component';
import { OrderConformationDetailsComponent } from './ui/order-conformation-details/order-conformation-details.component';
import { NgxUiLoaderModule,NgxUiLoaderConfig } from "ngx-ui-loader";
import { FormatTimePipe } from './ui/pipes/formattime.pipe';
import { ErrorDisplayDialogPaytmComponent } from './ui/dialogs/error-display-dialog-paytm/error-display-dialog-paytm.component';
import { ProtectionPlansComponent } from './ui/protection-plans/protection-plans.component';
import { CompareplansComponent } from './ui/compareplans/compareplans.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { SocialLoginModule, SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider } from "@abacritt/angularx-social-login";
import { AddExpensesComponent } from './ui/dialogs/add-expenses/add-expenses.component';
import { EditOrderDialog } from './ui/dialogs/edit-order-dialog/edit-order-dialog';
import { ProcurementVendorDialog } from './ui/dialogs/procurement-vendor-dialog/procurement-vendor-dialog';
import { SupportTicketDialogComponent } from './ui/dialogs/support-ticket-dialog/support-ticket-dialog.component';
import { VendorApprovalDialogComponent } from './ui/dialogs/vendor-approval-dialog/vendor-approval-dialog.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { AddEditServiceCenterDialogComponent } from './ui/dialogs/add-edit-service-center-dialog/add-edit-service-center-dialog.component';
import { AddEditServiceAreaComponent } from './ui/dialogs/add-edit-service-area/add-edit-service-area.component';
import { AddIssueChargeDialogComponent } from './ui/dialogs/add-issue-charge-dialog/add-issue-charge-dialog.component';
import { AddBrandDeviceDialogComponent } from './ui/dialogs/add-brand-device-dialog/add-brand-device-dialog.component';
import { AddDeviceContractDialogComponent } from './ui/dialogs/add-device-contract-dialog/add-device-contract-dialog.component';
import { AddEditDevicePlanDialogComponent } from './ui/dialogs/add-edit-device-plan-dialog/add-edit-device-plan-dialog.component';
import { RepairComponent } from './ui/dialogs/repair/repair.component';
import { TransitComponent } from './ui/dialogs/transit/transit.component';
import { UserRecordDialogComponent } from './ui/dialogs/user-record/user-record-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AddIssueDialogComponent } from './ui/dialogs/add-issue-dialog/add-issue-dialog.component';
import { MyAccountComponent } from './ui/my-account/my-account.component';
import { PrivacyComponent } from './ui/privacy/privacy.component';
import { RefundPolicyComponent } from './ui/refund-policy/refund-policy.component';
import { TermsConditionsComponent } from './ui/terms-conditions/terms-conditions.component';
import { WarrantyPolicyComponent } from './ui/warranty-policy/warranty-policy.component';
import { MydetailsComponent } from './ui/my-account/my-details/my-details.component';
import { MyOrdersComponent } from './ui/my-account/my-orders/my-orders.component';
import { MyaddressComponent } from './ui/my-account/my-addresses/my-addresses.component';
import { UpdateAddressDilog } from './ui/dialogs/update-address-dialog/update-address-dialog';
import { MysupportComponent } from './ui/my-account/my-support/my-support.component';
import { ViewOraddSupportComponent } from './ui/my-account/view-oradd-support/view-oradd-support.component';
import { OrderDetailsDialog } from './ui/dialogs/order-details-dialog/order-details-dialog';
import { environment } from 'src/environments/environment';
import { ContactComponent } from './ui/contact/contact.component';
import { PlansComponent } from './ui/plans/plans.component';
import { BlogsModule } from './ui/blogs/blogs.module';
import { AddOrUpdateRolesComponent } from './ui/dialogs/add-or-update-roles/add-or-update-roles.component';
import { AddOrUpdateUserComponent } from './ui/dialogs/add-or-update-user/add-or-update-user.component';
import { FaqComponent } from './ui/faqs/faq.component';
import { ScheduledpickupsComponent } from './ui/scheduledpickups/scheduledpickups.component';
import { CareersComponent } from './ui/careers/careers.component';
import { ViewDetailsPlansComponent } from './ui/view-details-plans/view-details-plans.component';
import { YesOrNoDialog } from './ui/dialogs/yes-or-no-dialog/yes-or-no-dialog';
import { AfterDeleteDialog } from './ui/dialogs/yes-or-no-dialog/after-delete-dialog';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
    "bgsColor": "#ffffff",
    "bgsOpacity": 0.5,
    "bgsPosition": "bottom-right",
    "bgsSize": 90,
    "bgsType": "ball-spin-clockwise",
    "blur": 6,
    "delay": 0,
    "fastFadeOut": true,
    "fgsColor": "#cd3851",
    "fgsPosition": "center-center",
    "fgsSize": 100,
    "fgsType": "ball-spin-clockwise",
    "gap": 24,
    "logoPosition": "center-center",
    "logoSize": 120,
    "logoUrl": "",
    "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40, 40, 40, 0.8)",
    "pbColor": "red",
    "pbDirection": "ltr",
    "pbThickness": 3,
    "hasProgressBar": false,
    "text": "",
    "textColor": "#FFFFFF",
    "textPosition": "center-center",
    "maxTime": -1,
    "minTime": 300
};
@NgModule({
  declarations: [
    AppComponent,
    MessageDisplayDailogComponent,
    LoginSendOTPDialogDialog,
    GetStartedDialog,
    FormatTimePipe,
    HomeComponent,
    FooterComponent,
    OrderDetailsComponent,
    PaymentStatusComponent,
    OrderConformationDetailsComponent,
    ErrorDisplayDialogPaytmComponent,
    ProtectionPlansComponent,
    CompareplansComponent,
    AddExpensesComponent,
    EditOrderDialog,
    ProcurementVendorDialog,
    AddOrUpdateUserComponent,
    AddOrUpdateRolesComponent,
    SupportTicketDialogComponent,
    VendorApprovalDialogComponent,
    AddEditServiceCenterDialogComponent,
    MultiSelectComponent,
    AddEditServiceAreaComponent,
    AddIssueChargeDialogComponent,
    AddIssueDialogComponent,
    AddBrandDeviceDialogComponent,
    AddEditDevicePlanDialogComponent,
    AddDeviceContractDialogComponent,
    RepairComponent,
    TransitComponent,
    UserRecordDialogComponent,
    MyAccountComponent,
    WarrantyPolicyComponent,
    TermsConditionsComponent,
    PrivacyComponent,
    RefundPolicyComponent,
    MydetailsComponent,
    MyOrdersComponent,
    MyaddressComponent,
    UpdateAddressDilog,
    MysupportComponent,
    ViewOraddSupportComponent,
    OrderDetailsDialog,
    ContactComponent,
    PlansComponent,
    FaqComponent,
    ScheduledpickupsComponent,
    CareersComponent,
    ViewDetailsPlansComponent,
    YesOrNoDialog,
    AfterDeleteDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MaterialModule,
    MatSortModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatPaginatorModule,
    SocialLoginModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgMultiSelectDropDownModule.forRoot(),
    BlogsModule
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  { provide: 'LOCALSTORAGE', useFactory: getLocalStorage },
  { provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: false,
      providers: [{
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            environment.googleProviderID
            )
        },],
      onError: (err) => {console.error(err);}
    } as SocialAuthServiceConfig,},
    HttpClient , DatePipe],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    bootstrap: [AppComponent],
    
})
export class AppModule { }

export function getLocalStorage() {
  return (typeof window !== 'undefined') ? window.localStorage: null;
}