import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { ACTypesModel, BrandModel, RefrigeratorTypesModel, WashingMachineTypesModel } from 'src/app/data/brand-model/brand.model';
import { DeviceContractModel } from 'src/app/data/device-contract-model/device-contract.model';
import { DeviceModel } from 'src/app/data/device-model/device.model';
import { DevicePlanModel } from 'src/app/data/device-plans-model/device-plans.model';
import { DeviceIssueService } from 'src/app/services/device-issue.service';

@Component({
  selector: 'app-add-edit-device-plan-dialog',
  templateUrl: './add-edit-device-plan-dialog.component.html',
  styleUrls: ['./add-edit-device-plan-dialog.component.scss']
})
export class AddEditDevicePlanDialogComponent extends BaseComponent implements OnInit {

  selectedBrand: BrandModel = new BrandModel();
  selectedDevice: DeviceModel = new DeviceModel();
  devicePlanModel: DevicePlanModel = new DevicePlanModel();
  planList: DeviceContractModel[] = [];
  deviceContractFormGroup: FormGroup;
  isEditFlow: boolean = false;
  planFormControl: FormControl;
  enabledFormControl: FormControl;
  protected selectedAcType: ACTypesModel = new ACTypesModel();
  protected selectedRefrigeratorType: RefrigeratorTypesModel = new RefrigeratorTypesModel();
  protected selectedWashingMachineType: WashingMachineTypesModel = new WashingMachineTypesModel();
  protected selectedpro:string;
  protected createDevicePlanByModelUrl: string;
  protected updateDevicePlanByModelUrl: string;
  constructor(dialogRef: MatDialogRef<AddEditDevicePlanDialogComponent>,
    private ngxService: NgxUiLoaderService,
    private deviceIssueService:DeviceIssueService,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private fb: FormBuilder,
    snackBar: MatSnackBar,
    dialog: MatDialog) {
    super();
    this.dialogRef = dialogRef
    this.dialog = dialog;
    this.snackBar = snackBar;
    this.selectedBrand = data['selectedBrand'];
    this.selectedDevice = data['selectedDevice'];
    this.selectedAcType = data['selectedAcType'];
    this.selectedRefrigeratorType = data['selectedRefrigeratorType'];
    this.selectedWashingMachineType = data['selectedWashingMachineType']
    this.selectedpro = data['selectedPro'];
    console.log(this.selectedAcType )
    this.planList = data['unmappedDeviceContracts'];
    if (data['devicePlan'] !== null && data['devicePlan'] !== undefined) {
      this.isEditFlow = true;
      this.devicePlanModel = data['devicePlan'];
      this.updateDevicePlanByModelUrl = AppConstants.orderService + AppConstants.UPDATE_MODEL_CONTRACT;
    } else {
     
      if(this.selectedpro == 'MOBILE')
      {
        this.devicePlanModel.model = this.selectedDevice;
      }
      else if(this.selectedpro == 'AIR_CONDITIONER')
      {
       this.devicePlanModel.acTypeDto = this.selectedAcType;
      }
      else if(this.selectedpro == 'REFRIGERATOR')
      {
       this.devicePlanModel.refrigeratorTypeDto = this.selectedRefrigeratorType;
      }
      else if(this.selectedpro == 'WASHING_MACHINE')
      {
        this.devicePlanModel.washingMachineTypeDto = this.selectedWashingMachineType 
      }
      this.createDevicePlanByModelUrl = AppConstants.orderService + AppConstants.CREATE_MODEL_CONTRACT;
    
    }
    this.planFormControl = new FormControl('', [Validators.required]);
    this.enabledFormControl = new FormControl('', [Validators.required]);
   }

  ngOnInit(): void {
    this.deviceContractFormGroup = this.fb.group({
      deviceContractFormControl: [this.devicePlanModel.deviceContract, [Validators.required]],
      enabledD: [this.devicePlanModel.enabled, [Validators.required]],
    });
  }

  createDevicePlanAPI(data) {
    this.ngxService.start();
  
    this.deviceIssueService.addRequest(this.createDevicePlanByModelUrl, data)
    .subscribe({
      next:(data)=>{
        this.passCreateResponse(data);
        this.ngxService.stop();
        this.snackBar.open('Plan Tenure Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
      },
      error:(error)=>
      {
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
          this.ngxService.stop();
          this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
        }
      }
    });
}

updateDevicePlan(data) {
  this.ngxService.start();
  
    this.deviceIssueService.updateRequest(this.updateDevicePlanByModelUrl, data)
    .subscribe({
      next:(data)=>
      {
        this.passUpdateResponse(data);
        this.ngxService.stop();
        this.snackBar.open('Plan Tenure Updated Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
       },
      error:(error)=>
      {
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
          this.ngxService.stop();
          this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
        }
      }
    });
}

  submit() {
   this.createDevicePlanAPI(this.devicePlanModel);
  }

  setDevicePlan(plan) {
    this.devicePlanModel.deviceContract = plan;
  }

  protected passCreateResponse(res: any) {
    this.dialogRef.close(res);
  }
  protected passUpdateResponse(res: any) {
    this.dialogRef.close(res);
  }

  close() {
    this.dialogRef.close(false);
  }
}
