import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { MobiOrderModel } from 'src/app/data/mobi-order-model/mobi-order.model';
import { SupportTicketModel } from 'src/app/data/support-ticket-model/support-ticket.model';
import { OrderDetailsService } from 'src/app/services/order-details.service';

@Component({
  selector: 'app-view-oradd-support',
  templateUrl: './view-oradd-support.component.html',
  styleUrls: ['./view-oradd-support.component.scss']
})
export class ViewOraddSupportComponent implements OnInit {

  supportTicket:SupportTicketModel = new SupportTicketModel();
  supportForm!: FormGroup;
  mobiorder:MobiOrderModel = new MobiOrderModel();
  mobiOrderModel:any;
  hide:boolean = false;
  UserId:any;
  getAllOrderByUserUrl:string;
  constructor(private changeDetector: ChangeDetectorRef,@Inject(MAT_DIALOG_DATA) public data: any,public dialogRef: MatDialogRef<ViewOraddSupportComponent>,public snackBar: MatSnackBar,private ngxService: NgxUiLoaderService,
    private orderDetailsService:OrderDetailsService) 
    { 
      this.UserId = localStorage.getItem('userId')
    }

  ngOnInit(): void {
    
    this.supportForm = new FormGroup({
      category: new FormControl('please_select',Validators.required),
      subject: new FormControl(null,Validators.required),
      details:new FormControl(null,Validators.required),
      orderId:new FormControl()
    });
    
    if(this.data?.orderDetail !=null)
    {
      console.log([this.data.orderDetail[0].orderId])
      this.mobiOrderModel = [this.data.orderDetail[0].orderId];
      this.supportForm.controls['orderId'].setValue(this.mobiOrderModel)
      this.supportForm.controls['orderId'].disable();
    }
    if(this.data?.orderDetail ==null)
    {
      this.getAllOrderData()
    }
  }
  
  getAllOrderData() {
    this.ngxService.start();
    this.getAllOrderByUserUrl = AppConstants.orderService + AppConstants.getOrdersIds +"?UserId=" + this.UserId 
    this.orderDetailsService.getAllOrderByUserId(this.getAllOrderByUserUrl)
      .subscribe({
        next: (data) => {
          this.ngxService.stop();
          console.log(data);
          this.mobiOrderModel = data;
        },
        error: (error: any) => {
         },
      });
  }
  submitIssue(){
    if(this.supportForm.status == 'INVALID' ){
      this.snackBar.open('All fields are required!!','',{duration:5000,panelClass: ['blue-snackbar']})
      return;
    }
    this.ngxService.start();
    this.supportTicket.category = this.supportForm.controls['category'].value
    this.supportTicket.subject = this.supportForm.controls['subject'].value 
    this.supportTicket.details = this.supportForm.controls['details'].value
    this.supportTicket.userId = Number.parseInt(localStorage.getItem('userId')?.toString()!);
    this.supportTicket.status = "Open"
    let createSupportIssue = AppConstants.supportService + AppConstants.createSupportIssue
    if(this.data?.orderDetail !=null)
    {
      this.supportTicket.orderId = this.supportForm.controls['orderId'].value[0].orderId;
    }
    else
    {
      this.supportTicket.orderId = this.supportForm.controls['orderId'].value;
    }
    this.orderDetailsService.createSupportIssue(createSupportIssue,this.supportTicket).subscribe({
      next:(res)=>{
        this.ngxService.stop();
        this.snackBar.open('Ticket Ceated!!','',{duration:5000,panelClass: ['green-snackbar']});
        this.onNoClick();
      },
      error:(error)=>{
        this.ngxService.stop();
      }
    })
  }

  onNoClick(){
    this.dialogRef.close();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
