<form [formGroup]="deviceContractFormGroup">
    <h1 mat-dialog-title>{{'create_Service_Plan' | translate}}</h1>

    <div mat-dialog-content>
        <mat-form-field appearance="fill">
            <mat-label>{{'plan_Description' | translate}}</mat-label>
            <textarea matInput [(ngModel)]="deviceContractModel.description" formControlName="description"
                placeholder="Description for plan..." required></textarea>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{'plan_Name' | translate}}</mat-label>
          <input matInput [(ngModel)]="deviceContractModel.name" formControlName="nameDD"
              placeholder="Name for plan..." required>
      </mat-form-field>
        <mat-form-field appearance="fill">
            <mat-label>{{'plan_Amount' | translate}}</mat-label>
            <input matInput type="number" [(ngModel)]="deviceContractModel.amount" formControlName="amount" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>{{'plan_Tenure' | translate}}</mat-label>
            <mat-select [(ngModel)]="deviceContractModel.tenure" formControlName="tenureDD" required>
              <mat-option *ngFor="let tenure of tenureList" [value]="tenure">
                {{tenure + (tenure === 1 ? ' day': ' days')}} 
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{'cover_Issues' | translate}}</mat-label>
            <mat-select [(ngModel)]="deviceContractModel.issues" formControlName="issuesControl" multiple>
              <!-- <mat-select-trigger>
                {{issuesControl.value ? issuesControl.value[0]!.issueName : ''}}
                <span *ngIf="issuesControl!.value?.length! > 1" class="example-additional-selection">
                  (+{{issuesControl!.value?.length! - 1}} {{issuesControl!.value?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger> -->
              <mat-option *ngFor="let issue of issuesList" [value]="issue">{{issue.issueName}}</mat-option>
            </mat-select>
            <mat-error *ngIf="issuesControl.invalid">{{'plan_Description' | translate}}</mat-error>
          </mat-form-field>
          <div mat-dialog-action>
            <button class="button" mat-raised-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
            <button class="button" mat-raised-button  (click)="submit()"
            [disabled]="deviceContractFormGroup.invalid"
              cdkFocusInitial>{{'add_Service_Plan' | translate}}</button>
          </div>
    </div>

    <!--  -->
</form>