
  <form>
    <h1 mat-dialog-title>{{'create_New_Issue' | translate}}</h1>
    <div mat-dialog-content>
      <mat-form-field appearance="fill" class="margin-top20">
        <mat-label>{{'issue_Name' | translate}}</mat-label>
        <input matInput [(ngModel)]="newIssueModel.issueName" [formControl]="issueNameControl" required>
        <mat-error *ngIf="issueNameControl.invalid">{{'you_must_enter_Name' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{'issue_Description' | translate}}</mat-label>
        <input matInput [(ngModel)]="newIssueModel.issueDescription" [formControl]="issueDescriptionControl" required>
        <mat-error *ngIf="issueDescriptionControl.invalid">{{'you_must_enter_Description' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button class="button" mat-raised-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
      <button class="button" mat-raised-button (click)="submitNewIssue()" [disabled]="!(!issueNameControl.invalid && !issueDescriptionControl.invalid)" cdkFocusInitial>
        {{'add_New_Issue' | translate}}</button>
    </div>
  </form>