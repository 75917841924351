import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'yes-or-no-dialog',
    templateUrl: './yes-or-no-dialog.html',
    styleUrls: ['./yes-or-no-dialog.css']
})
export class YesOrNoDialog implements OnInit {

    constructor(public dialogRef: MatDialogRef<YesOrNoDialog>) { }
    
    ngOnInit(): void {
    }

    onClick(event): void {
       this.dialogRef.close(event);
    }
}
