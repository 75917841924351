import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { DepartmentModel } from 'src/app/data/department-list-model/department.model';
import { ServiceAreaModel } from 'src/app/data/serive-area-model/service-area.model';
import { ServiceCenterAreaModel } from 'src/app/data/service-center-area-model/service-center-area.model';
import { ServiceCenterDepartmentModel } from 'src/app/data/service-center-department-model/service-center-department.model';
import { ServiceCenterModel } from 'src/app/data/service-center-model/service-center.model';
import { ServiceCenterService } from 'src/app/services/service-center.service';

@Component({
  selector: 'app-add-edit-service-center-dialog',
  templateUrl: './add-edit-service-center-dialog.component.html',
  styleUrls: ['./add-edit-service-center-dialog.component.scss']
})
export class AddEditServiceCenterDialogComponent extends BaseComponent implements OnInit {

  /* data models */
  serviceCenter: ServiceCenterModel;
  serviceAreaList: ServiceAreaModel[] = [];
  departmentList: DepartmentModel[] = [];
  centerName = new FormControl('', [Validators.required]);
  contactNumber = new FormControl('', [Validators.pattern('^[0-9]{10}$')]);
  selectarea = 0;
  selectDepartment = 0;
  protected serviceAreaListUrl: string = AppConstants.userService + AppConstants.GET_SERVICE_AREA_LIST;
  protected createServiceCenterUrl: string;
  protected updatServiceCenterUrl: string;
  protected departmentListUrl: string = AppConstants.userService + AppConstants.GET_ALL_DEPARTMENTS;
  dropdownSettings:IDropdownSettings = {};
  constructor( dialogRef: MatDialogRef<AddEditServiceCenterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    snackBar: MatSnackBar,
    private serviceCenterService: ServiceCenterService,
    dialog: MatDialog) {
    super();
    this.dialog = dialog;

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'departmentName',
      textField: 'departmentName',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
      };
   
    this.dialogRef = dialogRef;
    this.snackBar = snackBar;
    this.getDepartmentList();
    this.getServiceAreaList();
    if (this.data) {
      this.serviceCenter = this.cloneObject(this.data);
      this.setServiceCenterDeptArea();
      this.updatServiceCenterUrl = AppConstants.userService + AppConstants.UPDATE_SERVICE_CENTER;
      console.log(this.updatServiceCenterUrl);
    } else {
      this.serviceCenter = new ServiceCenterModel();
      this.createServiceCenterUrl = AppConstants.userService + AppConstants.CREATE_SERVICE_CENETER;
    }
    this.serviceCenter.departments!.filter((value, key) => {
      if (value.isSelected) {
        this.selectDepartment ++;
      }
    });
  }

  ngOnInit(): void {
  }


  /* ui logic functions*/

  setServiceCenterDeptArea() {
    this.serviceCenter.serviceCenterDepartments = [];
    this.serviceCenter.servieCenterAreas = [];
    for (let centerDept of this.serviceCenter.departments!) {
      centerDept.isSelected = true;
      this.serviceCenter.serviceCenterDepartments.push(centerDept.departmentDto!);
    }
    for (let centerArea of this.serviceCenter.serviceAreas!) {
      centerArea.isSelected = true;
      this.serviceCenter.servieCenterAreas.push(centerArea.serviceAreaDto!);
    }
  }

  assignDepartments(department, action) {
    console.log(this.serviceCenter.departments);
    if (action) {
      this.selectDepartment ++;
      if (this.serviceCenter.departments!.find(x => x.departmentDto!.departmentId === department.departmentId)) {
        this.serviceCenter.departments!.find(x => x.departmentDto!.departmentId === department.departmentId!)!.isSelected = true
      } else {
        let dept = new ServiceCenterDepartmentModel();
        dept.departmentDto = department;
        this.serviceCenter.departments!.push(dept);
      }
    } else {
      this.selectDepartment --;
      this.serviceCenter.departments!.find(x => x.departmentDto!.departmentId === department.departmentId)!.isSelected = false;
    }
  }

  assignServiceAreas(serviceArea, action) {
    if (action) {
      this.selectarea ++;
      console.log("servieCenterAreas length", this.serviceCenter.servieCenterAreas);
      
      // this.serviceCenter.servieCenterAreas.push(serviceArea);
      if (this.serviceCenter.serviceAreas!.find(x => x.serviceAreaDto!.serviceAreaId === serviceArea.serviceAreaId)) {
        this.serviceCenter.serviceAreas!.find(x => x.serviceAreaDto!.serviceAreaId === serviceArea.serviceAreaId)!.isSelected = true
      } else {
        let serviceCenterArea = new ServiceCenterAreaModel();
        serviceCenterArea.serviceAreaDto = serviceArea;
        this.serviceCenter.serviceAreas!.push(serviceCenterArea);
      }

    } else {
      this.selectarea --;
      console.log("servieCenterAreas length remove", this.serviceCenter.servieCenterAreas);
      // this.serviceCenter.servieCenterAreas.splice(this.serviceCenter.servieCenterAreas.findIndex(serviceArea), 1);
      this.serviceCenter.serviceAreas!.find(x => x.serviceAreaDto!.serviceAreaId === serviceArea.serviceAreaId)!.isSelected = false;
    }
  }

  submitForm() {
    console.log(this.serviceCenter);
    for (let centerDept of this.serviceCenter.departments!) {
      if (!centerDept.isSelected) {
        this.serviceCenter.departments!
          .splice(this.serviceCenter.departments!.findIndex(x => x.departmentDto!.departmentId === centerDept.departmentDto!.departmentId), 1);
      }
    }

    for (let serviceArea of this.serviceCenter.serviceAreas!) {
      if (!serviceArea.isSelected) {
        this.serviceCenter.serviceAreas!
          .splice(this.serviceCenter.serviceAreas!.findIndex(x => x.serviceAreaDto!.serviceAreaId === serviceArea.serviceAreaDto!.serviceAreaId), 1);
      }
    }
    console.log(this.serviceCenter);
    const data = {
      "serviceCenterId": this.serviceCenter?.serviceCenterId,
      "serviceCenterName": this.serviceCenter.serviceCenterName,
      "serviceCenterContactNumber": this.serviceCenter.serviceCenterContactNumber,
      "enabled": this.serviceCenter.enabled,
      "creationDate": this.serviceCenter?.creationDate,
      "departments": this.setDepartmentList(this.serviceCenter.departments!),
      "serviceAreas": this.setAreaList(this.serviceCenter.serviceAreas!)
    };
    console.log(data);
    this.serviceCenter?.serviceCenterId ? this.updateServiceCenter(data) : this.createServiceCenter(data);
  }

  setDepartmentList(departments: ServiceCenterDepartmentModel[]) {
    let list:any = [];
    for (let dept of departments) {
      list.push({
        "serviceCenterDeptId": dept?.serviceCenterDeptId,
          "departmentDto": dept?.departmentDto
      });
    }
    return list;
  }

  setAreaList(areas: ServiceCenterAreaModel[]) {
    let list:any = [];
    for (let area of areas) {
      list.push({
        "serviceCenterAreaId": area?.serviceCenterAreaId,
        "serviceAreaDto": area.serviceAreaDto,
        "creationDate": area?.creationDate
      });
    }
    return list;
  }
  /* resposne methods. */

  getServiceAreaList() {
    this.serviceCenterService.getServiceAreaList(this.serviceAreaListUrl)
        .subscribe({
          next:(data)=>{
            this.passServiceAreaListResponse(data)
          },
          error:(error)=>
          {
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
            }
          }
        });
}

getDepartmentList() {
    this.serviceCenterService.getDepartmentList(this.departmentListUrl)
        .subscribe({
          next:(data)=>{
            this.passDepartmentListReponse(data);
          },
          error:(error)=>
          {
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
            }
          }
        })
}

createServiceCenter(serviceCenter) {
    this.serviceCenterService.addServiceCenter(this.createServiceCenterUrl, serviceCenter)
        .subscribe({
          next:(data)=>{
            this.passCreateUpdateServiceCenterResponse(data)
          },
          error:(error)=>
          {
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
            }
          }
        });
}

updateServiceCenter(serviceCenter: ServiceCenterModel) {
    this.serviceCenterService.updateServiceCenter(this.updatServiceCenterUrl, serviceCenter)
        .subscribe({
          next:(data)=>{
            this.passCreateUpdateServiceCenterResponse(data)
          },
          error:(error)=>
          {
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
            }
          }
        });
}
  protected passServiceAreaListResponse(res: any) {
    this.serviceAreaList = res;
  }
  protected passDepartmentListReponse(res: any) {
    this.departmentList = res;
  }
  protected passCreateUpdateServiceCenterResponse(res: any) {
    console.log('created service center');
    this.dialogRef.close(true);
  }
}
