import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { CanonicalService } from 'src/app/canonical.service';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html'
})
export class RefundPolicyComponent implements OnInit {

  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {this.metaService.updateTag({name:'description',content:"Repairing Company Repair offers you a 6-month warranty along with a refund."})
    this.metaService.updateTag({name:'keywords',content:'Repairing Company Refund Policy'})
    this.canonicalServide.setCanonicalURL();       
    
    this.translate.get('refund_Policy').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('refund_Policy').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    }); 
   }

  ngOnInit(): void {
    
  }

}
