export class LanguageModel{
    languageCode:string;
    languageName:string;
    languageFlagClass:string;
}

export class BotModel{
    id:any
    name:string;
    message:string;
    question:boolean;
    date:Date;
    formattedDate:string;
    image:any
    problems:any
    message1:any
    address:any
    isDocData:any;
    docname:any;
    isImageData:any;
}
