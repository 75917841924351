import { OrderStatus, OrderType } from "src/app/constants/app.constants";
import { ACTypesModel, BrandModel, ModelDetailModel,  } from "../brand-model/brand.model";
import { ExpenseModel } from "../expense-model/expense.model";
import { OrderRepairIssueModel } from "../order-repair-issue-model/order-repair-issue.model";
import { PaymentModel } from "../payment-model/payment.model";
import { PurchaseModel } from "../purchase-model/purchase-model";
import { UserAddressModel } from "../user-address-model/user-address.model";
import { UserDetailsModel } from "../user-details/user-details.model";

export class MobiOrderModel {
    orderId?: number;
    orderStatus?: OrderStatus; // pick-up, delivery, in-service etc.
    createdDate?: Date;
    modifiedDate?: Date;
    orderType?: OrderType; // purchase, repair etc.
    userId?: number;
    userDto?: UserDetailsModel;
    paymentId?: number;
    addressId?: number;
    orderAmount?: number;
    deliveryDate?: Date;
    repairIssues?: OrderRepairIssueModel[];
    paymentDto?: PaymentModel;
    addressDto?: UserAddressModel;
    purchaseDtoList?: PurchaseModel[] = [];
    vendorOrder?: boolean;
    guarantee?: boolean = true;
    vendorId?: number;
    mobileNumber?: number;
    imeiNumber?: String;
    customerName?: String;
    vendorName?: String;
    amountBeforeTax?: number;
    tax?: number;
    discount?: number;
    totalAmount?: number;
    source?: String;
    phone?: String;
    orderDepartment?: String;
    actualCost?: number;
    textField?: boolean = false;
    notes?:String;
    discountReason?: String;
    quality_tested?: String;
    brand:BrandModel;
    model:ModelDetailModel;
    acType:ACTypesModel
    expenseDto:ExpenseModel;
    expenseDtoList:ExpenseModel[];
    promoCode?: String;
    promoCodeAmtOff?: number;
    addressLine1?: String;
    addressLine2?: String;
    pincode?: String;
    state?: String;
    city?: String;
    firstName?: String;
    middleName?: String;
    lastName?: String;
    email?: String;
    screenProtector!:any;
}