import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { ServiceAreaModel } from 'src/app/data/serive-area-model/service-area.model';
import { ServiceAreaService } from 'src/app/services/service-area.service';

@Component({
  selector: 'app-add-edit-service-area',
  templateUrl: './add-edit-service-area.component.html',
  styleUrls: ['./add-edit-service-area.component.scss']
})
export class AddEditServiceAreaComponent extends BaseComponent implements OnInit  {
  protected createServiceAreaUrl: string;
  protected updateServiceAreaUrl: string;
  serviceArea: ServiceAreaModel;
  areaName = new FormControl('', [Validators.required]);
  areaDescription = new FormControl('', [Validators.required]);
  pincodeField = new FormControl('', [Validators.pattern('^[0-9]{6}$')]);
  constructor(dialogRef: MatDialogRef<AddEditServiceAreaComponent>,
    private serviceAreaService:ServiceAreaService,
    @Inject(MAT_DIALOG_DATA) public data: {},
    snackBar: MatSnackBar,
     dialog: MatDialog) {
    super();
    this.dialogRef = dialogRef;
    this.dialog = dialog;
    this.snackBar = snackBar;
    if (this.data) {
      this.serviceArea = this.cloneObject(this.data);
      this.updateServiceAreaUrl = AppConstants.userService + AppConstants.UPDATE_SERVICE_AREA;
    } else {
      this.serviceArea = new ServiceAreaModel();
      this.createServiceAreaUrl = AppConstants.userService + AppConstants.CREATE_SERVICE_AREA;
    }
  }

  ngOnInit(): void {}


  createServiceAreaCall(servicArea: ServiceAreaModel) {
      this.serviceAreaService.addserviceArea(this.createServiceAreaUrl, servicArea)
          .subscribe({
            next:(data)=>{this.passServiceAresResponse(data);
            },
            error:(error)=>{
              if (this.errorHandler(error)) {
                this.errorDisplayDialog(error.error.message);
            }
            }
          });
  }

    updateServiceAreaCall(servicArea: ServiceAreaModel) {
        this.serviceAreaService.updateserviceArea(this.updateServiceAreaUrl, servicArea)
            .subscribe({
              next:(data)=>
              {
                this.passServiceAresResponse(data);
              },
              error:(error)=>{
                if (this.errorHandler(error)) {
                  this.errorDisplayDialog(error.error.message);
              }
              }
            });
    }
  submitForm() {
   
    if (this.serviceArea.serviceAreaId) {
    
        this.updateServiceAreaCall(this.serviceArea);
      
    } else {
      this.createServiceAreaCall(this.serviceArea);
    }
  }
  
  protected passServiceAresResponse(res: any) {
   this.dialogRef.close(true);
  }

}
