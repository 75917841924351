<form>
    <h1 mat-dialog-title *ngIf="serviceArea!.serviceAreaId != null">
        {{'edit_service_area' | translate}}
    </h1>
    <h1 mat-dialog-title *ngIf="serviceArea!.serviceAreaId == null">
        {{'create_service_area' | translate}}
    </h1>
   
    <div mat-dialog-content>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'area_name' | translate}}</mat-label>
                    <input matInput type="text" [(ngModel)]="serviceArea.serviceAreaName" [formControl]="areaName" required>
                    <mat-error *ngIf="areaName.invalid">{{'you_must_enter_a_name' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'area_description' | translate}}</mat-label>
                    <input matInput  type="text" [(ngModel)]="serviceArea.serviceAreaDescription"
                        [formControl]="areaDescription" required>
                    <mat-error *ngIf="areaDescription.invalid">{{'please_provide_area_description' | translate}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'area_pincode' | translate}}</mat-label>
                    <input matInput type="number" [(ngModel)]="serviceArea.pinCode" [formControl]="pincodeField"
                        required>
                    <mat-error *ngIf="numberOnlyField.invalid">{{'please_provide_area_pincode' | translate}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="spacing" appearance="fill">
                    <mat-label>{{'active_area' | translate}}</mat-label>
                    <mat-select [(ngModel)]="serviceArea.enable" [formControl]="requiredField" required>
                        <mat-option [value]="false">{{'services_Suspended' | translate}}</mat-option>
                        <mat-option [value]="true">{{'service_active' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-button mat-dialog-close>{{'Cancel' | translate}}</button>
        <button mat-button (click)="submitForm()" [disabled]="!(!requiredField.invalid && !pincodeField.invalid)"
            cdkFocusInitial>
            <span *ngIf="serviceArea!.serviceAreaId != null">  
                {{'edit_service_area' | translate}}
            </span>
            <span *ngIf="serviceArea!.serviceAreaId == null">  
                {{'create_service_area' | translate}}
            </span>
        </button>
    </div>
</form>