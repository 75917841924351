<section class="scheduled-banner">
<div class="topsquare"></div>     
<div class="topcircle"></div> 
<div class="third-triangle">
    <img src="../assets/images/banner-bgtop.jpg">    
</div>    
<h1><span>Scheduled</span> Pickups and Drops</h1> 

</section>

<section class="sec-sectin">
<div class="container-fluid">
<div class="row">
<div class="col-md-7">
<div class="text">    
<h5>Repairing Company</h5>
<h2>Make Your Gadget Better</h2> 
<p>At Repairing Company, our mission is to provide
    exceptional and professional repair services for all your 
    mobile and laptop needs. Our team of highly skilled 
    technicians is trained to handle a wide range of 
    hardware and software issues using only the 
    highest-quality genuine parts.</p> 
<p>Our services include repairs for broken screens, 
    charging problems, software malfunctions, and 
    other technical issues. We understand the critical 
    role that your devices play in your daily life, and 
    we are dedicated to getting them back to you 
    as quickly and efficiently as possible.
</p> 
<button class="button-main mt-4">Who We Are</button>     
</div> 
</div> 
<div class="col-md-5">
<img src="../assets/images/second-sec.png" style="width: 100%;">    
</div>    
</div>    
</div>    
</section>

<section class="third-sectin">
    <div class="third-triangle">
        <img src="../assets/images/third-triangle.jpg">    
    </div>     
    <div class="container-fluid">
    <div class="row">
    <div class="col-md-3">
    <div class="text1">
    <div class="text-width"> 
    <img src="../assets/images/icon-learnmore1.jpg">
    <p>At Repairing Company, our mission is to provide
        exceptional and professional repair services for all your 
        mobile and laptop needs.</p>    
    </div> 
</div> 
    </div> 
    <div class="col-md-5">
    <img src="../assets/images/third-secbg.png" style="width: 100%;">    
    </div> 
    <div class="col-md-3">
    <div class="text2">
    <div class="text-width">   
    <img src="../assets/images/icon-learnmore2.jpg">     
    <p>Our services include repairs for broken screens, charging problems, software malfunctions, and other technical issues. We understand the critical role that your devices play.</p>    
    </div>
    </div> 
    </div>   
    </div>    
    </div>    
    </section>

<section class="forth-sectin">
<div class="container-fluid" style="padding: 0;">
<div class="row">
<div class="col-md-6 p-0">
    <div class="service-box">
        <div class="service-icon orange">
            <div class="front-content">
                <img src="../assets/images/place-order1.png"> 
                <h3>Place Order</h3>
            </div>
        </div>
        <div class="service-content">
            <h3>Place Order</h3>
            <p>At Repairing Company, our mission is to provide exceptional and professional repair services for all your mobile and laptop needs. Our team of highly skilled technicians is trained to handle a wide range of hardware and software issues using only the highest-quality genuine parts.</p>
        </div>
    </div>       
</div> 
<div class="col-md-6 p-0">
    <div class="service-box">
        <div class="service-content" style="opacity: 1;transform: rotateX(0);position: relative;">
            <h3>Scheduled Date</h3>
            <p>At Repairing Company, our mission is to provide exceptional and professional repair services for all your mobile and laptop needs. Our team of highly skilled technicians is trained to handle a wide range of hardware and software issues using only the highest-quality genuine parts.</p>
        </div>
    </div>       
</div> 

<div class="col-md-6 p-0">
    <div class="service-box">
        <div class="service-content" style="opacity: 1;transform: rotateX(0);position: relative;">
            <h3>Pickup</h3>
            <p>At Repairing Company, our mission is to provide exceptional and professional repair services for all your mobile and laptop needs. Our team of highly skilled technicians is trained to handle a wide range of hardware and software issues using only the highest-quality genuine parts.</p>
        </div>
    </div>       
</div> 

<div class="col-md-6 p-0">
    <div class="service-box">
        <div class="service-icon orange">
            <div class="front-content">
                <img src="../assets/images/pickup1.png"> 
                <h3>Drops</h3>
            </div>
        </div>
        <div class="service-content">
            <h3>Drops</h3>
            <p>At Repairing Company, our mission is to provide exceptional and professional repair services for all your mobile and laptop needs. Our team of highly skilled technicians is trained to handle a wide range of hardware and software issues using only the highest-quality genuine parts.</p>
        </div>
    </div>       
</div> 
</div>    
</div>    
</section>
    
<!-- 
<section id="journey">
    <div class="container">
      <h2>Our Journey</h2>
      <div class="row timeline-holder">
        <div class="col-10 col-lg-9 text-end pe-lg-5">
          <div class="path-holder">
            
            <div id="yb-2021" class="year-box yb-2021">
              <div class="text">
                <h3>March 2021</h3>
                <h4>Acquisition of<br>Webselense</h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/webselence-logo.svg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2020" class="year-box yb-2020">
              <div class="logo">
                <div class="logo-holder">2.5<small>m</small></div>
              </div>
              <div class="text me-0">
                <h3>H1 2020</h3>
                <h4>
                  2.5m paying<br>
                  subscribers
                </h4>
              </div>
            </div>
            <div id="yb-2019" class="year-box yb-2019">
              <div class="text me-0">
                <h3>December 2019</h3>
                <h4>Acquisition of PIA</h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/access-ico.svg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2019-2" class="year-box yb-2019-2">
              <div class="logo">
                <div class="logo-holder">1<small>m</small></div>
              </div>
              <div class="text me-0">
                <h3>H1 2019</h3>
                <h4>
                  1m paying<br>
                  subscribers
                </h4>
              </div>
            </div>
            <div id="yb-2018" class="year-box yb-2018">
              <div class="text me-0">
                <h3>July 2018</h3>
                <h4>Acquisition of Intego</h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/intego-ico.jpg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2018-2" class="year-box yb-2018-2">
              <div class="logo">
                <div class="logo-holder">500<small>k</small></div>
              </div>
              <div class="text me-0">
                <h3>H1 2018</h3>
                <h4>
                  500k paying <br>
                  subscribers
                </h4>
              </div>
            </div>
            <div id="yb-2018-3" class="year-box yb-2018-3">
              <div class="text me-0">
                <h3>March 2018</h3>
                <h4>
                  Name change to <br>
                  kape technologies
                </h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/kape-logo-timeline.jpg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2017" class="year-box yb-2017">
              <div class="text me-0">
                <h3>March 2017</h3>
                <h4>
                  Acquisition of <br>
                  CyberGhost
                </h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/cg.svg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2016" class="year-box yb-2016">
              <div class="text me-0">
                <h3>H2 2016</h3>
                <h4>
                  Launch of a new <br>
                  strategic roadmap
                </h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/road-map-ico.svg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2016-2" class="year-box yb-2016-2">
              <div class="text me-0">
                <h3>May 2016</h3>
                <h4>
                  New management <br>
                  team appointed
                </h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/bag-ico.svg" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2014" class="year-box yb-2014">
              <div class="text me-0">
                <h3>September 2014</h3>
                <h4>IPO on LSE</h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/lse.png" alt="">
                </div>
              </div>
            </div>
            <div id="yb-2011" class="year-box yb-2011">
              <div class="text me-0">
                <h3>2011</h3>
                <h4>Company founded <br>as Crossrider</h4>
              </div>
              <div class="logo">
                <div class="logo-holder">
                  <img src="assets/images/shield-ico.svg" alt="">
                </div>
              </div>
            </div>
            

            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 749.83 3559.13" enable-background="new 0 0 749.83 3559.13" xml:space="preserve">
              <path id="Default" transform="translate(-329 -1402.078)" fill="none" stroke="#E6EAEB" stroke-width="8" stroke-miterlimit="10" d="
                  M860.26,3149.84l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04s83.01,209.04,185.42,209.04 M864.35,3185.88l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19s68.37,172.19,152.7,172.19 M868.5,3222.73l-320.93-0.35c-66.7,0-120.76,60.96-120.76,136.15
                  s54.07,136.15,120.76,136.15 M871.42,2804.65h-205 M861.42,2877.55h-195 M866.42,2841.55h-188 M547.57,2116.89
                  c-66.7,0-120.76-60.96-120.76-136.15c0-75.2,54.07-136.15,120.76-136.15l320.93,0.35c102.4,0,185.42-93.59,185.42-209.04
                  s-83.01-209.04-185.42-209.04 M543.48,2152.92c-84.33,0-152.7-77.09-152.7-172.19s68.37-172.19,152.7-172.19l320.88-0.48
                  c84.33,0,152.7-77.09,152.7-172.19s-68.37-172.19-152.7-172.19 M539.33,2189.78c-102.4,0-185.42-93.59-185.42-209.04
                  s83.01-209.04,185.42-209.04l320.93,0.35c66.7,0,120.76-60.96,120.76-136.15s-54.07-136.15-120.76-136.15 M860.26,2187.16
                  c66.7,0,120.76,60.96,120.76,136.15s-54.07,136.15-120.76,136.15l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04
                  s83.01,209.04,185.42,209.04 M864.35,2151.12c84.33,0,152.7,77.09,152.7,172.19s-68.37,172.19-152.7,172.19l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19s68.37,172.19,152.7,172.19 M868.5,2114.26c102.4,0,185.42,93.59,185.42,209.04
                  s-83.01,209.04-185.42,209.04L547.57,2532c-66.7,0-120.76,60.96-120.76,136.15s54.07,136.15,120.76,136.15 M871.42,2114.26h-193
                   M861.42,2187.16h-183 M866.42,2151.16h-178 M860.26,2877.54c66.7,0,120.76,60.96,120.76,136.15s-54.07,136.15-120.76,136.15
                   M864.35,2841.51c84.33,0,152.7,77.09,152.7,172.19s-68.37,172.19-152.7,172.19 M868.5,2804.65c102.4,0,185.42,93.59,185.42,209.04
                  s-83.01,209.04-185.42,209.04 M871.42,2804.65h-205 M861.42,2877.55h-195 M866.42,2841.55h-188 M860.26,3561.43
                  c66.7,0,120.76,60.96,120.76,136.15s-54.07,136.15-120.76,136.15l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04
                  c0,115.45,83.01,209.04,185.42,209.04 M864.35,3525.4c84.33,0,152.7,77.09,152.7,172.19s-68.37,172.19-152.7,172.19l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19c0,95.09,68.37,172.19,152.7,172.19 M868.5,3488.54c102.4,0,185.42,93.59,185.42,209.04
                  s-83.01,209.04-185.42,209.04l-320.93-0.35c-66.7,0-120.76,60.96-120.76,136.15s54.07,136.15,120.76,136.15 M871.42,3488.54H551.08
                   M861.42,3561.44H551.08 M866.42,3525.44H563.08 M860.26,4248.24c66.7,0,120.76,60.96,120.76,136.15
                  c0,75.2-54.07,136.15-120.76,136.15l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04c0,115.45,83.01,209.04,185.42,209.04
                   M864.35,4212.21c84.33,0,152.7,77.09,152.7,172.19c0,95.1-68.37,172.19-152.7,172.19l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19c0,95.1,68.37,172.19,152.7,172.19 M868.5,4175.35c102.4,0,185.42,93.59,185.42,209.04
                  c0,115.45-83.01,209.04-185.42,209.04l-320.93-0.35c-66.7,0-120.76,60.96-120.76,136.15c0,75.19,54.07,136.15,120.76,136.15
                   M871.42,4175.35H551.08 M861.42,4248.25H551.08 M866.42,4212.25H563.08"></path>
              <path mask="url('#mask')" id="Active" transform="translate(-329 -1402.078)" fill="none" stroke="#A381FB" stroke-width="8" stroke-miterlimit="10" d="
                  M860.26,3149.84l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04s83.01,209.04,185.42,209.04 M864.35,3185.88l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19s68.37,172.19,152.7,172.19 M868.5,3222.73l-320.93-0.35c-66.7,0-120.76,60.96-120.76,136.15
                  s54.07,136.15,120.76,136.15 M871.42,2804.65h-205 M861.42,2877.55h-195 M866.42,2841.55h-188 M547.57,2116.89
                  c-66.7,0-120.76-60.96-120.76-136.15c0-75.2,54.07-136.15,120.76-136.15l320.93,0.35c102.4,0,185.42-93.59,185.42-209.04
                  s-83.01-209.04-185.42-209.04 M543.48,2152.92c-84.33,0-152.7-77.09-152.7-172.19s68.37-172.19,152.7-172.19l320.88-0.48
                  c84.33,0,152.7-77.09,152.7-172.19s-68.37-172.19-152.7-172.19 M539.33,2189.78c-102.4,0-185.42-93.59-185.42-209.04
                  s83.01-209.04,185.42-209.04l320.93,0.35c66.7,0,120.76-60.96,120.76-136.15s-54.07-136.15-120.76-136.15 M860.26,2187.16
                  c66.7,0,120.76,60.96,120.76,136.15s-54.07,136.15-120.76,136.15l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04
                  s83.01,209.04,185.42,209.04 M864.35,2151.12c84.33,0,152.7,77.09,152.7,172.19s-68.37,172.19-152.7,172.19l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19s68.37,172.19,152.7,172.19 M868.5,2114.26c102.4,0,185.42,93.59,185.42,209.04
                  s-83.01,209.04-185.42,209.04L547.57,2532c-66.7,0-120.76,60.96-120.76,136.15s54.07,136.15,120.76,136.15 M871.42,2114.26h-193
                   M861.42,2187.16h-183 M866.42,2151.16h-178 M860.26,2877.54c66.7,0,120.76,60.96,120.76,136.15s-54.07,136.15-120.76,136.15
                   M864.35,2841.51c84.33,0,152.7,77.09,152.7,172.19s-68.37,172.19-152.7,172.19 M868.5,2804.65c102.4,0,185.42,93.59,185.42,209.04
                  s-83.01,209.04-185.42,209.04 M871.42,2804.65h-205 M861.42,2877.55h-195 M866.42,2841.55h-188 M860.26,3561.43
                  c66.7,0,120.76,60.96,120.76,136.15s-54.07,136.15-120.76,136.15l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04
                  c0,115.45,83.01,209.04,185.42,209.04 M864.35,3525.4c84.33,0,152.7,77.09,152.7,172.19s-68.37,172.19-152.7,172.19l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19c0,95.09,68.37,172.19,152.7,172.19 M868.5,3488.54c102.4,0,185.42,93.59,185.42,209.04
                  s-83.01,209.04-185.42,209.04l-320.93-0.35c-66.7,0-120.76,60.96-120.76,136.15s54.07,136.15,120.76,136.15 M871.42,3488.54H551.08
                   M861.42,3561.44H551.08 M866.42,3525.44H563.08 M860.26,4248.24c66.7,0,120.76,60.96,120.76,136.15
                  c0,75.2-54.07,136.15-120.76,136.15l-320.93-0.35c-102.4,0-185.42,93.59-185.42,209.04c0,115.45,83.01,209.04,185.42,209.04
                   M864.35,4212.21c84.33,0,152.7,77.09,152.7,172.19c0,95.1-68.37,172.19-152.7,172.19l-320.88,0.48
                  c-84.33,0-152.7,77.09-152.7,172.19c0,95.1,68.37,172.19,152.7,172.19 M868.5,4175.35c102.4,0,185.42,93.59,185.42,209.04
                  c0,115.45-83.01,209.04-185.42,209.04l-320.93-0.35c-66.7,0-120.76,60.96-120.76,136.15c0,75.19,54.07,136.15,120.76,136.15
                   M871.42,4175.35H551.08 M861.42,4248.25H551.08 M866.42,4212.25H563.08"></path>
              <defs>
                <mask id="mask">
                  <path class="mask-path" fill="none" stroke="#5EFF5C" stroke-width="83" stroke-miterlimit="10" d="M535.4,61.6c84.3,0,152.7,77,152.7,172.1
                   s-68.4,172.2-152.7,172.2l-320.9,0.5c-84.3,0-152.7,77.1-152.7,172.2s68.4,172.2,152.7,172.2l144.9-1.8h178l-2.1,0
                   C619.7,749,688,826,688,921.1s-68.4,172.2-152.7,172.2l-320.9,0.5c-84.3,0-152.7,77.1-152.7,172.2s68.4,172.2,152.7,172.2l134.9,1.2
                   h188h-188l185.9,0c84.3,0,152.7,77.1,152.7,172.2s-68.4,172.2-152.7,172.2l-320.9,0.5c-84.3,0-152.7,77.1-152.7,172.2
                   s68.4,172.2,152.7,172.2l19.6-5.3h303.3l-2.1,0c84.3,0,152.7,77.1,152.7,172.2s-68.4,172.2-152.7,172.2l-320.9,0.5
                   c-84.3,0-152.7,77.1-152.7,172.2s68.4,172.2,152.7,172.2l19.6-2.4h303.3l-2.1,0c84.3,0,152.7,77.1,152.7,172.2
                   s-68.4,172.2-152.7,172.2l-320.9,0.5c-84.3,0-152.7,77.1-152.7,172.2s68.4,172.2,152.7,172.2" style="stroke-dashoffset: 1552;"></path>
                </mask>
              </defs>
            </svg>
          </div>
        </div>
        <!-- <div class="col-2 col-lg-3 d-flex text-start">
          <div class="wrap-yearbook">
            <div class="yearbook">
              <ul>
                <li class="y-2021"><a href="#yb-2021">2021</a></li>
                <li class="y-2020">
                  <a href="#yb-2020">2020</a>
                </li>
                <li class="y-2019"><a href="#yb-2019">2019</a></li>
                <li class="y-2018"><a href="#yb-2018">2018</a></li>
                <li class="y-2017"><a href="#yb-2017">2017</a></li>
                <li class="y-2016"><a href="#yb-2016">2016</a></li>
                <li class="y-2015"><a href="#yb-2015">2015</a></li>
                <li class="y-2014"><a href="#yb-2014">2014</a></li>
                <li class="y-2013"><a href="#yb-2013">2013</a></li>
                <li class="y-2012"><a href="#yb-2012">2012</a></li>
                <li class="y-2011"><a href="#yb-2011">2011</a></li>
              </ul>
              <span class="indicator"></span>
            </div>
          </div>
        </div> 
        <div class="col-lg-12 d-none d-lg-block">
          <div class="bottom-text">
           
          </div>
        </div>
      </div>
    </div>
  </section> -->