export class AuthModel {
    access_token?: string;
    emailId: string;
    fullName:string;
    userId:string;
    token_type?: string;
    refresh_token?: string;
    expires_in?: number;
    scope?: string;
    role:string;
    name?: string;
    jti?: string;
    status:any

}