import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-tips-to-be-successful-in-repair-business',
    templateUrl: './tips-to-be-successful-in-repair-business.html'
})

export class TipsToBeSuccessfulInRepairBusinessComponent{
  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {
    this.metaService.updateTag({name:'description',content:"Establishing a mobile phone repair business? Read this post for 20 great tips to help you create and manage a thriving cell phone repairing company."})
    this.metaService.updateTag({name:'keywords',content:'cell phone repair busines, repairing company, repairing services,  mobile phone repair service, doorstep repair at low price'})
    this.canonicalServide.setCanonicalURL();
    
    this.translate.get('tips_To_Be_Successful').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('tips_To_Be_Successful').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    });
  }      
}