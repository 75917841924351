import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogsRoutingModule } from './blogs-routing.module';
import { CalculatorFormComponent } from '../calculator-form/calculator-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BlogsComponent } from './blogs.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BestlaptopComponent } from './best-laptops-for-students/bestlaptop.component';
import { BestcameraphonesCcomponent } from './bestcameraphones/bestcameraphones.component';
import { BestmobilephonesintheworldComponent } from './bestmobilephonesintheworld/bestmobilephonesintheworld.component';
import { LockappsoniphoneComponent } from './lockappsoniphone/lockappsoniphone.component';
import { takescreenshotskeyboardComponent } from './takescreenshotskeyboard/takescreenshotskeyboard.component';
import { trackyourlostmobileComponent } from './trackyourlostmobile/trackyourlostmobile.component';
import { AndroidRepairsComponent } from './android-repairs/android-repairs';
import { EnhancingCustomerExperienceComponent } from './enhancing-customer-experience/enhancing-customer-experience';
import { NeedLaptopRepairComponent } from './need-laptop-repair/need-laptop-repair';
import { TipsToBeSuccessfulInRepairBusinessComponent } from './tips-to-be-successful-in-repair-business/tips-to-be-successful-in-repair-business';
import { Iphone14vsIphone14PlusComponent } from './iphone14vsiphone14plus/iphone14vsiphone14plus';
import { Iphone14vsIphone14ProComponent } from './iphone14vsiphone14pro/iphone14vsiphone14pro';
import { Iphone14vsIphone14ProMaxComponent } from './iphone14vsiphone14ProMax/iphone14vsiphone14ProMax';
import { Iphonepro14vsIphonepro14maxProComponent } from './iphone14provsiphone14promax/iphone14provsiphone14promax';
import { DealWithWaterDamageComponent } from './deal-with-water-damage/deal-with-water-damage';
import { ImpactOf5gComponent } from './impactof5g/impactof5gnow';
import { CrackedScreenComponent } from './cracked-screen/cracked-screen';


@NgModule({
  declarations: [
    BlogsComponent,
    CalculatorFormComponent,
    trackyourlostmobileComponent,
    takescreenshotskeyboardComponent,
    LockappsoniphoneComponent,
    BestlaptopComponent,
    BestcameraphonesCcomponent,
    BestmobilephonesintheworldComponent,
    EnhancingCustomerExperienceComponent,
    NeedLaptopRepairComponent,
    TipsToBeSuccessfulInRepairBusinessComponent,
    AndroidRepairsComponent,
    Iphone14vsIphone14PlusComponent,
    Iphone14vsIphone14ProComponent,
    Iphone14vsIphone14ProMaxComponent,
    Iphonepro14vsIphonepro14maxProComponent,
    DealWithWaterDamageComponent,
    ImpactOf5gComponent,
    CrackedScreenComponent
  ],
  imports: [
    CommonModule,
    BlogsRoutingModule,
    TranslateModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule
  ],
  exports:[CalculatorFormComponent]
})
export class BlogsModule { }
