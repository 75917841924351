<head>
    <title>FAQ</title>
    <meta name="description" content="Mobile processors are an essential component of any mobile device. In this article, you will get the name of the best mobile phone processor list in 2023"/>
    <meta name="keywords" content="Best Processor for mobile, Best mobile processor, mobile phone processor, smartphone processor"/>
    <link rel="canonical" href="https://repairingcompany.com/ui/the-best-processor-for-mobile-phone-ranking-list" />
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap" rel="stylesheet">
    </head>
    
    <section class="header-banner">
    <div class="container">
    <div class="col-md-11">
    <h1>FAQ</h1>
    <div class="datetime">
     
    </div>
    </div>
    </div>
    </section>   
    
    <section class="middle-sec">
    <div class="container">
    <div class="row">
    <div class="col-md-12">

        <div class="faq">

            <div class="acc-kontainer">          
            <div>
            <input type="radio" name="acc" id="acc1">
            <label for="acc1">How is Repairing company different from others?</label>
            <div class="acc-body">
            Repairing company is different from others as Repairing offers doorstep service for your mobile repair. This means that:
            <ul>
            <li>100% genuine products: You do not have to worry about counterfeit parts appearing on your device. We believe in reliability and customer service satisfaction. </li> 
            <li>Our Guaranteed period of warranty: 3 Months of free replacement warranty for screens & 3 months warranty for other spare parts repaired.</li>
            <li>Unbeatable price: we provide the best rates for repair in the industry:  You can save upto 40 per cent on repair costs with us.</li> 
            <li>Scheduled pickups and drops: We come directly to your place of choice at the time you decide.</li> 
            <li>24X7 support: For any questions or queries, visit the Repairing company service centre or call us at 7290068900</li>    
            </ul>          
            </div>
            </div>
            
            <div>
            <input type="radio" name="acc" id="acc2">
            <label for="acc2">How do I Set up An Account for Repairing company?</label>
            <div class="acc-body">
            To set up your Repairing company account, visit the website, and at the very beginning of the page, you’ll be asked to sign in
            <ul>
            <li>Click on it and enter the mobile number you’d like to register with.</li> 
            <li>You will receive an otp. </li>  
            <li>After verifying the otp , Fill in your name and your email address.</li> 
            </ul>
            <p>After that, you are all set. Just begin selecting the device you want to repair.</p>
            <p>From the screen, display, mic, speaker, battery, receiver, and charging port to even software issues,  Repairing company promises its users 100% Genuine Parts.. To ensure quality and reliability, we test all our parts in-house before we use them in repair. All technicians are experts, so you don’t have to worry about faulty installations. We offer a warranty for our replaced parts.</p>
            </div>
            </div>
            <div>
            <input type="radio" name="acc" id="acc3">
            <label for="acc3">What are the payment modes available on Accessories?</label>
            <div class="acc-body">
            You can pay with Paytm or even have cash on delivery option.     
            </div>
            </div>
            
            <div>
            <input type="radio" name="acc" id="acc4" checked>
            <label for="acc4">What is the warranty offered on Repairing company repairs? How can I claim it?</label>
            <div class="acc-body">
            We offer 3 Months of free replacement warranty for screens & 3 months warranty for other spare parts repaired.
            To claim your warranty you can write to support@repairingcompany.in with the service details and the invoice delivered to you at the repair time. If you need further assistance, call the Cashify Repair customer service team on XXXXXXXXXX.     
            </div>
             </div>

            <div>
                <input type="radio" name="acc" id="acc5">
                <label for="acc5">Is there a delivery charge for the Accessories I order?</label>
                <div class="acc-body">
                We do not impose any charges on delivery. It is completely free for our users.     
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc6">
                <label for="acc6">How will I know if my order has been confirmed?</label>
                <div class="acc-body">
                    Once your order has been confirmed, you will receive a confirmation email on your registered email id. Once your order is dispatched, you’ll receive an email with your tracking ID to track your order.    
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc7">
                <label for="acc7">Whom should I contact to claim warranty for my product?                </label>
                <div class="acc-body">
                    In order to know write to us at support@repairingcompany.in    
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc8">
                <label for="acc8">My mobile phone is not listed on the website. What to do now?</label>
                <div class="acc-body">
                    In such cases, please contact us by email at support@repairingcompany.in <br>We shall respond to you within one business day and try to rectify the issue as early as possible.
  
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc9">
                <label for="acc9">Does Repairing Company provides data privacy protection.               </label>
                <div class="acc-body">
                    We recommend you to  delete all the personal data and also remove the SIM and memory card form your phone. But, just in case you haven’t, then it’s not a problem at all. Because we believe in customer’s privacy and before going ahead with any further process, we ensure that our technical expert has cleared out all the available data from the device.  
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc10">
                <label for="acc10">Is repair facility only available at home or I can visit to any service centers?              </label>
                <div class="acc-body">
                Repair facility is available to its users both at home and at service stores. If user wants then can visit the store and  repair their phone in front of themselves
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc11">
                <label for="acc11">Is there any subscription plan which repairing company can suggest me to buy?</label>
                <div class="acc-body">
                We currently have 3 subscription plans that you can see on our official website also we have provided a recommendation plan, its totally upto the user requirement. 
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc12">
                <label for="acc12"> How can I place an order?</label>
                <div class="acc-body">
                Once you have opened your account on our website just click on the order now button and fill some required details. Lastly choose for your payment option(currently its paytm). 
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc13">
                <label for="acc13"> How can I track my order?</label>
                <div class="acc-body">
                    You will receive emails during the phase period of repairing.  
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc14">
                <label for="acc14"> How long will it take to fix it?</label>
                <div class="acc-body">
                Turnaround time is 2-7 days after receiving the phone depending on job and availability of parts. Most jobs are completed within 2-3 days This does depend on the fault, make of phone and parts required.  
                </div>
            </div>

            <div>
                <input type="radio" name="acc" id="acc15">
                <label for="acc15">Why do the charges differ?</label>
                <div class="acc-body">
                Each repair is priced on time taken, type of damage, and any parts needed. Until we assess the damage we can not know the true cost of repair.
                </div>
            </div>
</div>    
</div>
        
    </div>    
    </div>
    </div>
    </section>
    