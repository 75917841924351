import { DepartmentModel } from "../department-list-model/department.model";
import { ServiceAreaModel } from "../serive-area-model/service-area.model";
import { ServiceCenterAreaModel } from "../service-center-area-model/service-center-area.model";
import { ServiceCenterDepartmentModel } from "../service-center-department-model/service-center-department.model";
import { UserModel } from "../user-model/user.model";

export class ServiceCenterModel {
    serviceCenterId?: number;
    branchManager?: UserModel;
    serviceCenterName?: string;
    serviceCenterContactNumber?: string;
    enabled?: boolean;
    creationDate?: Date;
    serviceCenterDepartments?: DepartmentModel[] = [];
    servieCenterAreas?: ServiceAreaModel[] = [];
    departments?: ServiceCenterDepartmentModel[] = [];
    serviceAreas?: ServiceCenterAreaModel [] = [];
}