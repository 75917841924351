<form [formGroup]="deviceContractFormGroup">
    <h1 mat-dialog-title>{{'device_Plan_Mapper' | translate}}</h1>

    <div mat-dialog-content>
        <div *ngIf="selectedpro == 'MOBILE'">
            <mat-label class="brtop">{{'brand_name' | translate}} : <b>{{selectedBrand.brandName}}</b> </mat-label>
            <br>
            <mat-label class="brtop">{{'device_Name' | translate}} : <b>{{selectedDevice.modelName}}</b> </mat-label>
            <br>
          </div>
          <div *ngIf="selectedpro == 'AIR_CONDITIONER'">
            <mat-label class="brtop">{{'AC_Type' | translate}} : <b>{{selectedAcType.acTypes | translate}}</b> </mat-label>
          </div>
          <div *ngIf="selectedpro == 'REFRIGERATOR'">
            <mat-label class="brtop">{{'RefrigeratorType' | translate}} : <b>{{selectedRefrigeratorType.refrigeratorType | translate}}</b> </mat-label>
          </div>
          <div *ngIf="selectedpro == 'WASHING_MACHINE'">
            <mat-label class="brtop">{{'WASHING_MACHINE_Type' | translate}} : <b>{{selectedWashingMachineType.washingMachineType | translate}}</b> </mat-label>
          </div>

               <!-- plans -->
        <mat-form-field appearance="fill">
            <mat-label>{{'select_Plan' | translate}}</mat-label>
            <mat-select [disabled]="planList.length <=0 || isEditFlow" [formControl]="planFormControl">
                <mat-option *ngFor="let plan of planList" [value]="plan.description"
                    (click)="setDevicePlan(plan)">
                    <span>{{plan.description}}</span>
                </mat-option>
            </mat-select>
            <mat-error *ngIf="planFormControl.invalid">{{'please_Select_Plan' | translate}}</mat-error>
        </mat-form-field>

        <!-- enabled/disabled -->
        <mat-form-field appearance="fill">
            <mat-label>{{'active' | translate}}</mat-label>
            <mat-select [(ngModel)]="devicePlanModel.enabled" [formControl]="enabledFormControl" required>
                <mat-option [value]="false">{{'in_Active' | translate}}</mat-option>
                <mat-option [value]="true">{{'active' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

    </div>
    <div mat-dialog-action>
        <button class="button" mat-raised-button (click)="close()">{{'close' | translate}}</button>
        <button class="button" mat-raised-button (click)="submit()">{{'save' | translate}}</button>
    </div>
</form>