import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationService {

  constructor(private http: HttpClient) {
  }
  
  getOrderDetailsByID(getOrderDetailsByIDUrl:string) : Observable<any>{
    return this.http.get(getOrderDetailsByIDUrl);
  }

}
