import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppConstants } from 'src/app/constants/app.constants';
import { SupportTicketModel } from 'src/app/data/support-ticket-model/support-ticket.model';
import { UserModel } from 'src/app/data/user-model/user.model';
import { SupportService } from 'src/app/services/support.service';

@Component({
  selector: 'app-support-ticket-dialog',
  templateUrl: './support-ticket-dialog.component.html',
  styleUrls: ['./support-ticket-dialog.component.css']
})
export class SupportTicketDialogComponent implements OnInit {
  supportTicket:SupportTicketModel;
  userDetail: UserModel;
  responseSupportTicket: SupportTicketModel;
  updateSupportTicketUrl:string;
  constructor(public dialogRef: MatDialogRef<SupportTicketDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {},
    public dialog: MatDialog,
    private ngZone: NgZone, public supportService:SupportService,  private ngxService: NgxUiLoaderService,public snackBar: MatSnackBar) {
    
    this.supportTicket = data['supportTicket'];
    this.userDetail = data['user'];
   }

  ngOnInit(): void {
  }

  updateSupportTicket(resolution:string){
    this.updateSupportTicketUrl = AppConstants.supportService + AppConstants.updateSupportIssue;
    this.supportTicket.status = 'Closed';
    this.supportTicket.resolution = resolution;

    this.supportService.updateSupportTicket(this.updateSupportTicketUrl,this.supportTicket).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.responseSupportTicket = data;
        this.snackBar.open('Support Issue Updated!!','',{duration:5000,panelClass: ['green-snackbar']});
        this.ngZone.run(() => {
          this.dialogRef.close(true);
        });
      },
      error:(error:any)=>{
        this.ngxService.stop();
        this.snackBar.open('Support Issue Not Updated!!','',{duration:5000,panelClass: ['blue-snackbar']}) 
      }
    });
  }

  onNoClick(): void {
    this.ngZone.run(() => {
      this.dialogRef.close(true);
    });
  }

}
