<link href="https://fonts.googleapis.com/css?family=Open+Sans&display=swap" rel="stylesheet" />
<link href="https://fonts.googleapis.com/css?family=Sniglet&display=swap" rel="stylesheet" />
<div>
  <form [formGroup]="getStartedForm">
    <span class="rc-float">  <a (click)="onNoClick()" class="rc-a cursor-a" >X &nbsp;</a> </span>
    <h2 mat-dialog-title class="rc-heading">
      {{'repairing_company' | translate}}
    </h2>
    <p class="rc-sub-heading">{{'last_step' | translate}}</p>
    <div mat-dialog-content  class="rc-margin">
      <span class="rc-input-heading">{{'first_name' | translate}}</span> <br>
      <mat-form-field appearance="outline">
        <input matInput formControlName="firstName"  name="phone" placeholder="{{'enter_first_name' | translate}}">  
      </mat-form-field>
    </div>
    <div mat-dialog-content  class="rc-margin1">
      <span class="rc-input-heading">{{'last_name' | translate}}</span> <br>
      <mat-form-field appearance="outline">
        <input matInput formControlName="lastName"  name="phone" placeholder="{{'enter_last_name' | translate}}">  
      </mat-form-field>
    </div>
    <div mat-dialog-content  class="rc-margin1" *ngIf="!isMobileNumberNull">
      <span class="rc-input-heading">{{'email' | translate}}</span> <br>
      <mat-form-field appearance="outline">
        <input matInput formControlName="email"  name="phone" placeholder="{{'enter_email' | translate}}">  
      </mat-form-field>
    </div>
    <div mat-dialog-content  class="rc-margin1" *ngIf="isMobileNumberNull">
      <span class="rc-input-heading">{{'mobile_number' | translate}}</span> <br>
      <mat-form-field appearance="outline">
        <input matInput formControlName="mobileNumber"  name="phone" placeholder="{{'mobile_number' | translate}}">  
      </mat-form-field>
    </div>
    <div mat-dialog-actions class="center rc-margin1"  >
      <button mat-button  cdkFocusInitial 
        class="rc-submit-btn" (click)="submit()">{{'get_started' | translate}}</button>
    </div>
  </form>
</div>