import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppConstants } from "src/app/constants/app.constants";
import { UserAddressModel } from "src/app/data/user-address-model/user-address.model";
import { UserService } from "src/app/services/user.service";
import { UpdateAddressDilog } from "../../dialogs/update-address-dialog/update-address-dialog";

@Component({
  selector: "my-addresses",
  templateUrl: "./my-addresses.component.html",
  styleUrls: ["./my-addresses.component.scss"],
})

export class MyaddressComponent implements OnInit {
  constructor(
    public editaddressDialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    private userservice: UserService
  ) { }
  UserId: any;
  addresses: any;
  userAdd: UserAddressModel;
  primaryAddress: UserAddressModel | null = null;
  updateadressurl: string;
  deleteAddressUrl: string;
  ngOnInit(): void {
    this.UserId = localStorage.getItem("userId")?.toString();
    this.getUserByUserID();
  }

  getUserByUserID() {
    this.ngxService.start();
    this.userservice.getUserDetailById(this.UserId).subscribe({
      next: (data: any) => {
        this.addresses = this.processAddresses(data.addresses);
        this.primaryAddress = this.addresses.find(address => address.primaryAddress === true);
        this.ngxService.stop();
      },
      error: (error) => {
        this.ngxService.stop();
      },
    });
  }

  private processAddresses(addresses: UserAddressModel[]): UserAddressModel[] {
    const primaryAddress = addresses.find(address => address.primaryAddress === true);
    if (primaryAddress) {
      const filteredAddresses = addresses.filter(address => address !== primaryAddress);
      return [primaryAddress, ...filteredAddresses];
    } else {
      return addresses;
    }
  }
  AddAddress() {
    const dialogRef = this.editaddressDialog.open(UpdateAddressDilog, {
      width: "80%",
      height: "75%",
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getUserByUserID();
    });
  }
  editAddressModal(address) {
    const dialogRef = this.editaddressDialog.open(UpdateAddressDilog, {
      width: "80%",
      height: "75%",
      data: {
        addressDetail: address,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  AddAddressModal() {
    const dialogRef = this.editaddressDialog.open(UpdateAddressDilog, {
      width: "80%",
      height: "75%",
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.getUserByUserID();
    });
  }

  deleteAddress(addressId) {
    this.ngxService.start();
    this.deleteAddressUrl =
      AppConstants.userService +
      AppConstants.deleteAddress +
      "?addressId=" +
      addressId;
    this.userservice.deleteAddress(this.deleteAddressUrl).subscribe({
      next: (data) => {
        if (data == true) {
          this.getUserByUserID();
        }
      },
      error: (error) => {
        console.log(error);
        this.ngxService.stop();
      },
    });
  }

  updateToPrimary(addressId) {
    this.ngxService.start();
    this.updateadressurl =
      AppConstants.userService +
      AppConstants.updateAddressToPrimary +
      "?userId=" +
      this.UserId +
      "&addressId=" +
      addressId +
      "&primaryAddress=" +
      true;
    this.userservice
      .UpdateAddress(this.updateadressurl, this.userAdd)
      .subscribe({
        next: (data) => {
          this.getUserByUserID();
        },
        error: (error) => {
          console.log(error);
          this.ngxService.stop();
        },
      });
  }
}
