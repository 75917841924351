<div>
  <form [formGroup]="repairChargeForm">
    <ng-template [ngIf]="!emptyUnmappedIssueView">

      <h1 mat-dialog-title *ngIf="selectedIssue.issueName == null">{{'create_New_Repair_Charge' | translate}}</h1>

      <h1 mat-dialog-title *ngIf="selectedIssue.issueName != null">{{'edit_Repair_Charge' | translate}}</h1>
      
      <div mat-dialog-content>
        <div *ngIf="selectedpro == 'MOBILE'">
          <mat-label class="brtop">{{'brand_name' | translate}} : <b>{{selectedBrand.brandName}}</b> </mat-label>
          <br>
          <mat-label class="brtop">{{'device_Name' | translate}} : <b>{{selectedDevice.modelName}}</b> </mat-label>
          <br>
        </div>
        <div *ngIf="selectedpro == 'AIR_CONDITIONER'">
          <mat-label class="brtop">{{'AC_Type' | translate}} : <b>{{selectedAcType.acTypes | translate}}</b> </mat-label>
        </div>
        <div *ngIf="selectedpro == 'REFRIGERATOR'">
          <mat-label class="brtop">{{'RefrigeratorType' | translate}} : <b>{{selectedRefrigeratorType.refrigeratorType | translate}}</b> </mat-label>
        </div>
        <div *ngIf="selectedpro == 'WASHING_MACHINE'">
          <mat-label class="brtop">{{'WASHING_MACHINE_Type' | translate}} : <b>{{selectedWashingMachineType.washingMachineType | translate}}</b> </mat-label>
        </div>

        <ng-template [ngIf]="!isEditRepairChargeView">
          <mat-form-field appearance="fill">
            <mat-label>{{'select_Issue' | translate}}</mat-label>
            <mat-select [(ngModel)]="selectedIssue"
              (selectionChange)="getAllRepairModes()" formControlName="issueDD" required>
              <mat-option *ngFor="let issue of unmappedIssues" [value]="issue">
                {{issue.issueName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </ng-template>
        <ng-template [ngIf]="isEditRepairChargeView">
          <mat-label class="brtop">{{'issue' | translate}}<b>{{selectedIssue.issueName}}</b></mat-label>
        </ng-template>

        <mat-form-field appearance="fill">
          <mat-label>{{'repair_Amount' | translate}}</mat-label>
          <input matInput [(ngModel)]="newRepairCharge.repairAmount" formControlName="amount" required>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{'repair_Mode' | translate}}</mat-label>
          <mat-select [(ngModel)]="selectedMode" formControlName="repairModeDD" required>
            <mat-option *ngFor="let mode of repairModes" [value]="mode">
              {{mode?.repairMode}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="selectedMode?.repairMode=='PICKUP_AND_DROP'">
          <mat-label>{{'resolution_Period' | translate}}({{'Hrs' | translate}})</mat-label>
          <input matInput [(ngModel)]="newRepairCharge.resolutionPeriod" formControlName="resolutionPeriod" required>
        </mat-form-field>
      </div>

      <div mat-dialog-content>
        <button class="button" mat-raised-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
        <button  class="button" mat-raised-button (click)="submitNewRepairCharge()" [disabled]="repairChargeForm.invalid"
          cdkFocusInitial *ngIf="newRepairCharge.repairChargeId == null">{{'add_Issue_Charge' | translate}}</button>
          
        <button class="button" mat-raised-button (click)="submitNewRepairCharge()" [disabled]="repairChargeForm.invalid"
          cdkFocusInitial *ngIf="newRepairCharge.repairChargeId != null">{{'submit' | translate}}</button>
     </div>
    </ng-template>
  </form>
</div>
