import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRouteConstants } from '../constants/app-route.constants';
import { AuthService } from '../services/auth.service';
import { LoginSendOTPDialogDialog } from '../ui/dialogs/login-sendOTP-dialog/login-sendotp-dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  protected dialog!: MatDialog;
  constructor(private router:Router,private authService:AuthService,dialog: MatDialog){
    this.dialog=dialog;
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // checking if user logged in navigated to dashboard
      if(this.authService.isLoggedIn()){
        return true;
      }else{
        this.openDialog();
        return false;
      }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(LoginSendOTPDialogDialog, {
      width: '350px',
      height:'590px',
      data: {},
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {});
  }
}
