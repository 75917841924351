import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-android-repairs',
    templateUrl: './android-repairs.html'
})

export class AndroidRepairsComponent{
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Don't worry about your broken or damaged Android phones - let our repairing company help with a variety of repair services such as screen replacements, & more."})
        this.metaService.updateTag({name:'keywords',content:'Android Phone Repair Services, Repairing Company, android repairing services, screen replacement, battery replacement, mobile repair service'})
        this.canonicalServide.setCanonicalURL();       
        
        this.translate.get('android_Phone_Repairs').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('android_Phone_Repairs').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        }); 
    }      
}