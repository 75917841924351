import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss'],
})

export class AboutComponent{
    constructor(private titleService:Title,private translation:TranslateService,private metaService:Meta,private canonicalServide:CanonicalService )
    {
        this.metaService.updateTag({name:'description',content:"At Repairing Company, our mission is to provide exceptional and professional repair services for all your mobile and laptop needs. Our team of highly skilled technicians is trained to handle a wide range of hardware and software issues using only the highest-quality genuine parts."});
        this.metaService.updateTag({name:'keywords',content:'About Us, Repairing Company About us'})
        this.canonicalServide.setCanonicalURL();
        this.translation.get('about_us').subscribe((res: string) => {
            this.titleService.setTitle(res);
          });
      
          this.translation.onLangChange.subscribe((event: LangChangeEvent) => {
            this.translation.get('about_us').subscribe((res: string) => {
              this.titleService.setTitle(res);
            });
          });
    }
}