export class LedgerModel{
    transactionType? : string ;
    transactionDate? : Date;
    transactionAmount? : number;
    mapperOrderId? : number;
    debitOrCredit? : string;
    paymentId? : number;
    expenseId? : number;
    vendorName? : string;
    procurementAmount? : number;
    totalAmount? : number;
    expenseType?: string;
    expenseDetails?:string;
}