import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { BrandModel } from 'src/app/data/brand-model/brand.model';
import { DeviceContractModel } from 'src/app/data/device-contract-model/device-contract.model';
import { DeviceModel } from 'src/app/data/device-model/device.model';
import { RepairIssueModel } from 'src/app/data/repair-issue-model/repair-issue.model';
import { DeviceIssueService } from 'src/app/services/device-issue.service';

@Component({
  selector: 'app-add-device-contract-dialog',
  templateUrl: './add-device-contract-dialog.component.html',
  styleUrls: ['./add-device-contract-dialog.component.scss']
})
export class AddDeviceContractDialogComponent extends BaseComponent implements OnInit {
  protected addNewDevicePlanUrl: string = AppConstants.orderService + AppConstants.CREATE_DEVICE_CONTRACT;
  protected getAllIssuesUrl: string = AppConstants.orderService + AppConstants.GET_REPAIR_ISSUES;
  selectedBrand: BrandModel = new BrandModel();
  selectedDevice: DeviceModel = new DeviceModel();
  deviceContractModel: DeviceContractModel = new DeviceContractModel();
  deviceContractFormGroup: FormGroup;
  tenureList: number[] = [];
  issuesList: RepairIssueModel[] = [];
  issuesControl = new FormControl(this.deviceContractModel.issues, [Validators.required]);
  constructor(
    private ngxService: NgxUiLoaderService,
    private deviceIssueService:DeviceIssueService,
    dialogRef: MatDialogRef<AddDeviceContractDialogComponent>,
    snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private fb: FormBuilder,
    dialog: MatDialog) {
    super();
    this.dialog = dialog;
    this.snackBar = snackBar;
    this.dialogRef = dialogRef;
    this.createTenureList();
    this.fetchAllIssues();
  }

  ngOnInit(): void {
   console.log( this.issuesControl);
    this.deviceContractFormGroup = this.fb.group({
      description: ['this.deviceContractModel.description', [Validators.required]],
      nameDD: ['this.deviceContractModel.name', [Validators.required]],
      amount: ['this.deviceContractModel.amount', [Validators.required]],
      tenureDD: ['this.deviceContractModel.tenure', [Validators.max(30), Validators.min(1)]],
      issuesControl: this.issuesControl,
    });
  }

  createTenureList() {
    for (let i = 1; i <= 30; i++) {
      this.tenureList.push(i);
    }
  }

  onNoClick() {
    this.dialogRef.close(false);
  }

  submit() {
    this.createDevicePlan(this.deviceContractModel);
  }
  createDevicePlan(data) {
    this.ngxService.start();
    this.deviceIssueService.addRequest(this.addNewDevicePlanUrl, data)
    .subscribe({
      next:(data)=>{
        this.passDeviceCreationResponse(data);
        this.ngxService.stop();
        this.snackBar.open('Device Plan Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
      },
      error:(error)=>{
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
           this.ngxService.stop();
              this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
        }
      }
    });
}

fetchAllIssues(){
    this.deviceIssueService.getRequest(this.getAllIssuesUrl)
    .subscribe({
      next:(data)=>{
        this.passAllIssuesResponse(data);
        console.log(data)
      },
      error:(error)=>{
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      }
    });
}
  protected passDeviceCreationResponse(res: any) {
    console.log(res);
    this.dialogRef.close(res);
  }

  protected passAllIssuesResponse(res: RepairIssueModel[]){
    this.issuesList = res;
  }
}
