import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppComponent } from "src/app/app.component";
import { AppConstants } from "src/app/constants/app.constants";
import { UserModel } from "src/app/data/user-model/user.model";
import { UserService } from "src/app/services/user.service";
import { YesOrNoDialog } from "../dialogs/yes-or-no-dialog/yes-or-no-dialog";
import { AfterDeleteDialog } from "../dialogs/yes-or-no-dialog/after-delete-dialog";

@Component({
  selector: "my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})

export class MyAccountComponent implements OnInit {
  UserId: any;
  userIdTodelete: any;
  deleteAccountUrl: string;
  userDetails: UserModel;
  address: string;
  isDisabled1 = true;
  isDisabled2 = true;
  isDisabled3 = true;
  isDisabled4 = true;
  tab: any = "tab1";
  tab1: any;
  tab2: any;
  tab3: any;
  tab4: any;
  Clicked: boolean;
  myacc: boolean = true;
  url: string = "../../../assets/images/silver.png";
  myorder: boolean = false;
  myaddress: boolean = false;
  mysupport: boolean = false;
  firstname?: string;
  lastname?: string;
  dialogRef: any;
  constructor(private titleService: Title,
    public loginDialog: MatDialog,
    private router: Router,
    private appcomponent: AppComponent,
    private ngxService: NgxUiLoaderService,
    private userservice: UserService
  ) {
    this.titleService.setTitle('My Account');
  }

  onClick(check) {
    if (check == 1) {
      this.tab = "tab1";
      this.myacc = true;
      this.myorder = false;
      this.myaddress = false;
      this.mysupport = false;
    } else if (check == 2) {
      this.tab = "tab2";
      this.myacc = false;
      this.myorder = true;
      this.myaddress = false;
      this.mysupport = false;
    } else if (check == 3) {
      this.tab = "tab3";
      this.myacc = false;
      this.myorder = false;
      this.myaddress = true;
      this.mysupport = false;
    } else {
      this.tab = "tab4";

      this.myacc = false;
      this.myorder = false;
      this.myaddress = false;
      this.mysupport = true;
    }
  }

  onSwitch(check): any {
    switch (check) {
      case 1:
        return "tab1";
      case 2:
        return "tab2";
      case 3:
        return "tab3";
      case 4:
        return "tab4";
    }
  }
  ngOnInit(): void {
    this.UserId = localStorage.getItem("userId")?.toString();
    console.log(this.UserId)
    this.goldOrSilverUser();
    this.getUserByUserID(this.UserId);
  }

  goldOrSilverUser() {
    this.ngxService.start();
    this.userservice.goldOrSilverUser().subscribe({
      next: (res) => {
        this.ngxService.stop();
        if (res == true) {
          this.url = "../../../assets/images/gold.png";
        } else {
          this.url = "../../../assets/images/silver.png";
        }
      },
      error: (error) => {
        this.ngxService.stop();
      },
    });
  }

  getUserByUserID(UserId) {
    this.ngxService.start();
    this.userservice.getUserDetailById(UserId).subscribe({
      next: (data: any) => {
        this.userDetails = data;
        this.firstname = this.userDetails!.firstName;
        this.lastname = this.userDetails!.lastName;
        this.ngxService.stop();
      },
      error: (error) => { },
    });
  }

  logout() {
    this.appcomponent.logout();
  }

  openDialog(): void {
    this.dialogRef = this.loginDialog.open(YesOrNoDialog, {
      width: '490px',
      height: '380px',
      panelClass: 'custom-container',
      data: {},
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
      if (result == 'Yes') {
        this.deleteAccount();
      }
    });

  }

  deleteAccount() {
    this.ngxService.start();
    this.userIdTodelete = localStorage.getItem('userId');
    this.deleteAccountUrl = AppConstants.userService + AppConstants.deleteAccount + "?userId=" + this.userIdTodelete

    this.userservice.updateAccount(this.deleteAccountUrl).subscribe(
      {
        next: (data) => {
          this.ngxService.stop();
          this.openDialogAfterDelete();
        },
        error: (error) => {
          console.log(error);
          this.ngxService.stop();
        }
      }
    )
  }

  openDialogAfterDelete(): void {
    this.dialogRef = this.loginDialog.open(AfterDeleteDialog, {
      width: '490px',
      height: '380px',
      panelClass: 'custom-container',
      data: {},
      disableClose: true
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.router.navigate([''])
      localStorage.clear();
    });
  }

}
