import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConstants } from '../constants/app.constants';
import { AuthModel } from '../data/auth-model/auth-model';
import { UserDetailsModel } from '../data/user-details/user-details.model';
import { LocalStorageService } from './local-storage.service';
@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  

    httpHeaders: HttpHeaders;
    private eventBus: Subject<any>;
    isSocialUser: boolean = false;
    lastAppStateSaved: {} = {};
    private userDetails: UserDetailsModel = new UserDetailsModel();
    userEmail: String;
  
    /**appStateCreateUrl
     * const for dependecy injection
     * @param httpClient
     */
    constructor(protected httpClient: HttpClient,
      private localStorageService: LocalStorageService,
      private authService: SocialAuthService) {
      this.httpHeaders = new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Accept', 'application/json')
        .append('X-Requested-With', 'XMLHttpRequest');
      this.eventBus = new Subject<any>();
    }
  
    subscribeEventBus(): Observable<any> {
      return this.eventBus.asObservable();
    }
  
   
    /**
     * func to set access token in
     * http api calls
     * @param accessToken
     */
    setBearerToken(token_type: string, accessToken: string) {
      this.httpHeaders = new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('Accept', 'application/json')
        .append('X-Requested-With', 'XMLHttpRequest')
        .append('Authorization', token_type + " " + accessToken);
    }
  
    removeBearertoken() {
  
    }
  
    setSocialLoginFLag() {
      this.localStorageService.setItemInLocalStorage(AppConstants.socialLoginFlag, "true");
    }
    getSocialLoginFLag() {
      return this.localStorageService.getItemInLocalStorage(AppConstants.socialLoginFlag) != null ?
        true : false;
    }
  
    getRequestWithoutAuth(url) {
      return this.httpClient.get(url, { headers: new HttpHeaders().append("Content-Type", "application/json")
      .append('X-Requested-With', 'XMLHttpRequest') });
    }
    /**
     * Functions for API Gateway Connection
     */
    /**
     * func to make login, register and social login calls
     * this function will not have acess token in headers.
     * @param endPoint ['manual register', 'manual login', 'social login']
     * @param userData
     */
    userAuthentication(url, userData): Observable<any> {
      return this.httpClient.post(url, userData, {
        headers: new HttpHeaders()
          .append('Content-Type', 'application/json')
          .append('Accept', 'application/json')
          .append('X-Requested-With', 'XMLHttpRequest')
      });
    }
  
    userAuthenticationWithFiles(url, userData): Observable<any> {
      return this.httpClient.post(url, userData, {
        headers: new HttpHeaders()
          .append('Content-Type', 'multipart/form-data')
      });
    }
  
    uploadFileAuth(url, formData): Observable<any> {
  
      const req = new HttpRequest('POST', url, formData, {
        reportProgress: true,
        responseType: 'json'
      });
      return this.httpClient.request(req);
    }
    /**
     * function to set login call
     * @param url
     * @param userData
     */
    userLoginWithPwd(url, userData): Observable<any> {
      return this.httpClient.post(url, null, {
        headers: new HttpHeaders({
          'Authorization': 'Basic ' + btoa(userData['email'] + ':' + userData['pwd']),
        'X-Requested-With': 'XMLHttpRequest'
        })
      });
    }
    /**
     * func to make post api calls,
     * used for creating new objects
     * @param url
     * @param postBody
     */
    postRequest(url: string, postBody: any): Observable<any> {
      return this.httpClient.post(url, postBody, { headers: this.httpHeaders });
    }
  
    putRequest(url: string, putBody: any): Observable<any> {
      return this.httpClient.put(url, putBody, { headers: this.httpHeaders });
    }
    /**
     * func to make get api calls
     * @param url
     */
    getRequest(url: string): Observable<any> {
      return this.httpClient.get(url, { headers: this.httpHeaders });
    }
  
     /**
     * func to make get geo Coding api calls
     * @param url
     */
    getGeoCoderRequest(url: string): Observable<any> {
      return this.httpClient.get(url, { headers: this.httpHeaders });
    }
  
    /**
     * function to make delete api requests.
     * @param url
     */
    deleteRequest(url: string): Observable<any> {
      return this.httpClient.delete(url, { headers: this.httpHeaders });
    }
  
  
   
    /**
     * function to check if user details
     * are available on localstorage
     */
    isUserAuthAvailable() {
      return this.getUserAuthData()?.name;
    }
  
  
    getUserAuthData(): AuthModel {
      return this.localStorageService.getJsonObjectFromLocalStorage(AppConstants.userAuthData);
    }
  
    getUserDetailsModel() {
      return this.userDetails;
    }
  
    getUserMobileNumber() {
      return this.userDetails.mobileNumber;
    }
  
  
    getHeaders(): HttpHeaders {
      return this.httpHeaders;
    }
    
    isUserDataPresent() {
      return this.localStorageService.getJsonObjectFromLocalStorage(AppConstants.userDataKey) !== null && 
      this.localStorageService.getJsonObjectFromLocalStorage(AppConstants.userDataKey) !== undefined ;
    }
  

}
