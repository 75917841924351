import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-bestmobilephonesintheworld',
    templateUrl: './bestmobilephonesintheworld.component.html'
})

export class BestmobilephonesintheworldComponent{
  constructor(private translate:TranslateService,private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService)
  {
    this.metaService.updateTag({name:'description',content:"Stop your search for fantastic mobile phones! Our experts have selected 10 incredible phones as the best offerings in January 2023 – find out more here."})
    this.metaService.updateTag({name:'keywords',content:'best phone in the world, fantastic mobile phones, top mobile phones'})
    this.canonicalServide.setCanonicalURL();
    
    this.translate.get('best_Mobile_Phones').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('best_Mobile_Phones').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    });
  }
}