import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

export interface Element {
    specification:string;
    iPhone_14_Pro: string;
    iPhone_4_Pro_Max: string;
  }
  
  const ELEMENT_DATA: Element[] = [
    {specification:'Starting price', iPhone_14_Pro: '$999', iPhone_4_Pro_Max: '$1,099'},
    {specification:'Screen size', iPhone_14_Pro: '6.1-inch OLED (2556 x 1179)', iPhone_4_Pro_Max: '6.7-inch OLED (2796 x 1290)'},
    {specification:'Refresh rate', iPhone_14_Pro: '1-120Hz', iPhone_4_Pro_Max: '1-120Hz'},
    {specification:'CPU', iPhone_14_Pro: 'A16 Bionic', iPhone_4_Pro_Max: 'A16 Bionic'},
    {specification:'RAM (based on teardowns)', iPhone_14_Pro: '6GB', iPhone_4_Pro_Max: '6GB'},
    {specification:'Storage', iPhone_14_Pro: '128GB, 256GB, 512GB, 1TB	', iPhone_4_Pro_Max: '128GB, 256GB, 512GB, 1TB'},
    {specification:'Rear cameras', iPhone_14_Pro: '48MP (f/1.8) main with 2x optical zoom, 12MP (f/2.2) ultrawide, 12MP (f/2.8) telephoto with 3x optical zoom', iPhone_4_Pro_Max: '48MP (f/1.8) main with 2x optical zoom, 12MP (f/2.2) ultrawide, 12MP (f/2.8) telephoto with 3x optical zoom'},
    {specification:'Front cameras', iPhone_14_Pro: '12MP (f/1.9)', iPhone_4_Pro_Max: '12MP (f/1.9)'},
    {specification:'Battery size (based on teardowns)', iPhone_14_Pro: '3,200 mAh', iPhone_4_Pro_Max: '4,323 mAh'},
    {specification:'Battery life (Hrs:MIns)', iPhone_14_Pro: '10:13', iPhone_4_Pro_Max: '13:39'},
    {specification:'Size', iPhone_14_Pro: '5.8 x 2.8 x 0.31 inches', iPhone_4_Pro_Max: '6.3 x 3.1 x 0.31 inches'},
    {specification:'Weight', iPhone_14_Pro: '7.3 ounces', iPhone_4_Pro_Max: '8.5 ounces'}
];
  
@Component({
    selector: 'iphone14provsiphone14promax',
    templateUrl: './iphone14provsiphone14promax.html'
})

export class Iphonepro14vsIphonepro14maxProComponent{
    displayedColumns: string[] = ['specification', 'iPhone_14_Pro', 'iPhone_4_Pro_Max'];
    dataSource = ELEMENT_DATA;
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Looking to upgrade to the latest iPhone Pro model? Check out our comparison of the iPhone 14 Pro and iPhone 14 Pro Max to find out which one suits your needs."})
        this.metaService.updateTag({name:'keywords',content:'iPhone 14 Pro, iPhone 14 Pro Max, Repairing Company, iPhone 14 Pro Features, Specifications,  iPhone 14 Pro Max Difference'})
        this.canonicalServide.setCanonicalURL();
       
        this.translate.get('iPhone_14_Plus_pro_vs_iPhone_14_Pro_Max').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('iPhone_14_Plus_pro_vs_iPhone_14_Pro_Max').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        });  
        
    
    }   
}
