<section class="header-banner">
    <div class="container">
       <div class="col-md-11">
          <h1>Repairing Company Warranty Policy</h1>
       </div>
    </div>
 </section>
<section class="middle-sec">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
            <p>Every smartphone screen repair or replacement performed by the Repairing Company comes with a six-month breakage warranty starting from the date of invoicing. Moreover, we offer a 6-month warranty on all the additional replacement parts.</p>
      
           <b>The 3 Months of breakage coverage include :</b>
            <ul class="margin-top20">
               <li class="margin-top10">The device malfunctions or does not work as planned or is designed by a technician from a repairing company.</li>
               <li class="margin-top10">Any display problems( Mobile & Laptop) that might develop automatically, are connected to screen quality, notably touch problems, and are fixed by repair firm professionals.</li>
            </ul>
            <p>If the screen we changed causes any of the concerns listed above, you can get a brand-new screen with a 6-month warranty!</p>
        <b>Note that only the specific gadget repaired and the original customer are eligible for the warranty.
            We only believe in reliability and follow our policies.
            </b>
        </div>
      </div>
   </div>
</section>
<section class="middle-sec1">
   <div class="container">
      <div class="row">
        <div class="col-md-12">
            <b>To process a claim request, Your Gadget must be turned on and operating normally.</b>
            <p>The guarantee is only valid for three months if the technician is not given your device's old screen.</p>
            <p>To make a warranty claim, send a video of the device with the current issue. Also send us your phone number, order number, and IMEI on our official mail <a href = "mailto: support@repairingcompany.com">support@repairingcompany.com</a></p>
            
            <b>How do I make a warranty claim?</b>
            <p>Write to us at support@repairingcompany.com with the service details and the invoice delivered to you at the time of repair.</p>
            
            <b>How long will it take to answer my question if it is covered by warranty?</b>
            <p>From the time you send us an inquiry, our team typically responds with a solution within 48 to 72 hours. Your inquiry will be acknowledged via email, and your problem will be resolved as quickly as possible.
                The warranty is only valid for the purchased components and/or services.</p>
            
            <b>The following situations preclude the use of the warranty:</b>
            <ul>
                <li class="margin-top10">Any accidental damage is not covered by us, and in all circumstances where this is the case, our warranty is void. The 6-month warranty does not apply to direct damage or harm resulting from accidents.</li>
                <li class="margin-top10">All display( Mobile & Laptop) problems that might develop without any manual intervention are connected to the screen quality, in particular, visible lines and an empty screen.</li>
                <li class="margin-top10">Excessive damage to the device means that there is a chance that internal components may have been impacted as a result of severe or excessive damage to the device. If such damage is discovered after the order has been completed, the warranty will not apply to any replacement parts. Repairing Company Repair or any of its representatives will not be held responsible or liable for such issues. Such malfunctions may even become apparent after immediately opening the device and reassembling any component damage.</li>
                <li class="margin-top10">There will be no consideration given to subsequent handling errors that cause the frame to bend, twist, or crack.</li>
                <li class="margin-top10">Following rough handling, such as a hard press that could cause damage or dislocation of any part, followed by unintentional or intentional drops.</li>
                <li class="margin-top10">Water stains</li>
                <li class="margin-top10">For Mobile and laptops, Customers are advised to back up all data before the repair attempt to prevent any data loss as a result of the repair.</li>
                <li class="margin-top10">Damage brought on by failed customer DIY repairs.</li>
                <li class="margin-top10">Unrelated software problems to the repair.</li>

            </ul>

        </div>
      </div>
   </div>
</section>
<section class="middle-sec">
   <div class="container">
      <div class="row">
         <div class="col-md-12">
           <b>Additionally, our warranty does not apply to the results of repairs under certain pre-repair circumstances, such as:</b>
            <ul>
                <li class="margin-top10">Existence of recognized manufacturing and/or performance issues with the device independent of repair, as noted before the repair.</li>
            </ul>
            <p>Once your specific device has been diagnosed by our specialist, they will be able to explain it in detail. We advise against trying to repair the device yourself if you have any doubts because any damage could affect the reparability of your device.</p>
            <b>Note: The spare part shown on the screen does not qualify for a refund. For more details, please refer to your order email.</b>
        </div>
      </div>
   </div>
</section>