<ng-template [ngIf]="isBrandDialog">
  <form>
    <h1 mat-dialog-title>{{'create_New_Brand' | translate}}</h1>
    <div mat-dialog-content>
      <mat-form-field appearance="fill">
        <mat-label>{{'brand_Name' | translate}}</mat-label>
        <input matInput [(ngModel)]="newBrandModel.brandName" [formControl]="requiredField" required>
        <mat-error *ngIf="requiredField.invalid">{{'you_must_enter_Name' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{'category' | translate}}</mat-label>
        <mat-select [(ngModel)]="newBrandModel.category" [formControl]="brandCategoryControl" required>
          <mat-option *ngFor="let mode of categories" [value]="mode">
            {{mode}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button class="button" mat-raised-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
      <button class="button" mat-raised-button (click)="submitNewBrand()" [disabled]="requiredField.invalid" cdkFocusInitial>{{'add_New_Brand' | translate}}</button>
    </div>
  </form>
</ng-template>

<ng-template [ngIf]="(!isIssueDialog && !isBrandDialog)">
  <form>
    <h1 mat-dialog-title>{{'create_New_Device' | translate}}</h1>
    <div mat-dialog-content>
      <label>{{'brand_name' | translate}}<b> {{selectedBrand.brandName}}</b></label>
      <mat-form-field appearance="fill" class="margin-top10">
        <mat-label>{{'device_name' | translate}}</mat-label>
        <input matInput [(ngModel)]="newDeviceModel.modelName" [formControl]="requiredField" required>
        <mat-error *ngIf="requiredField.invalid">{{'you_must_enter_Name' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>{{'select_Year' | translate}}</mat-label>
        <input matInput type="number" [(ngModel)]="newDeviceModel.modelYear" [formControl]="numberOnlyField" required>
        <mat-error *ngIf="numberOnlyField.invalid">{{'please_fill_a_year' | translate}}</mat-error>
      </mat-form-field>
    </div>
    <div mat-dialog-actions>
      <button class="button" mat-raised-button (click)="onNoClick()">{{'Cancel' | translate}}</button>
      <button class="button" mat-raised-button (click)="submitNewDevice()" [disabled]="!(!requiredField.invalid && !numberOnlyField.invalid)"
        cdkFocusInitial>{{'add_New_Model' | translate}}</button>
    </div>
  </form>
</ng-template>
