import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { AppConstants } from 'src/app/constants/app.constants';
import { ProcurementVendorModel } from 'src/app/data/procurement-vendor-model/procurement-vendor-model';
import { ProductCategory } from 'src/app/data/product-category-model/product-category-model';
import { NewHomeService } from 'src/app/services/home.service';
import { ProcurementVendorService } from 'src/app/services/procurement-vendor.service';

@Component({
  selector: 'procurement-vendor-dialog',
  templateUrl: './procurement-vendor-dialog.html',
  styleUrls: ['./procurement-vendor-dialog.scss']
})
export class ProcurementVendorDialog extends BaseComponent implements OnInit {
  addProcurementVendorFormGroup: FormGroup;
  ProcurementVendorModel: ProcurementVendorModel = new ProcurementVendorModel();
  newHomeService: NewHomeService;
  getProductCategoryDataListUrl = AppConstants.orderService + AppConstants.getAllProducts;
  productCategory:ProductCategory[] = [];
  saveProcurementVendor:string = AppConstants.orderService + AppConstants.addProcurementVendor;
 
  constructor(newHomeService: NewHomeService,dialogRef: MatDialogRef<ProcurementVendorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {},public procurementVendorService : ProcurementVendorService,
    private ngxService: NgxUiLoaderService,snackBar: MatSnackBar) { 
      super();
      this.newHomeService = newHomeService;
      this.dialogRef = dialogRef;
      this.snackBar = snackBar;
      this.callGetProductCategoryDataListApi();
  }

  

  callGetProductCategoryDataListApi(){
    this.newHomeService.getProductCategoryDataListApi(this.getProductCategoryDataListUrl).subscribe({
      next:(res)=>{
        this.productCategory = res;
      },
      error:(error) =>{
        if (this.errorHandler(error)) {
          if(error?.error?.message == undefined){
           this.errorDisplayDialog(AppConstants.somethingWentWrongErrorMsg);
          }else{
           this.errorDisplayDialog(error.error.message);
          }
         }
      }
    }
  )}

  ngOnInit(): void {
    this.addProcurementVendorFormGroup = new FormGroup({
      procurementVendorId: new FormControl(null),
      procurementVendorName: new FormControl(null,Validators.required),
      procurementVendorPhoneNumber: new FormControl(null,Validators.required),
      procurementVendorAddress: new FormControl(null,Validators.required),
      vendorDepartment: new FormControl(null,Validators.required)
    });
  }

  addProcurementVendor(){
    if(this.addProcurementVendorFormGroup.status == 'INVALID' && localStorage.getItem("access_token") != undefined){
      this.snackBar.open('All fields are required!!','',{duration:5000,panelClass: ['blue-snackbar']})
      return;
    }
    this.ProcurementVendorModel.procurementVendorId =  this.addProcurementVendorFormGroup.controls['procurementVendorId'].value;
    this.ProcurementVendorModel.procurementVendorName =  this.addProcurementVendorFormGroup.controls['procurementVendorName'].value;
    this.ProcurementVendorModel.procurementVendorCreatedDate = new Date();
    this.ProcurementVendorModel.procurementVendorPhoneNumber =  this.addProcurementVendorFormGroup.controls['procurementVendorPhoneNumber'].value;
    this.ProcurementVendorModel.procurementVendorAddress =  this.addProcurementVendorFormGroup.controls['procurementVendorAddress'].value;
    this.ProcurementVendorModel.vendorDepartment =  this.addProcurementVendorFormGroup.controls['vendorDepartment'].value;
    this.save();
  }

  save(){
    this.ngxService.start();
    this.procurementVendorService.saveProcurementVendor(this.saveProcurementVendor,this.ProcurementVendorModel).subscribe({
      next:(data:any)=>{
        this.ngxService.stop();    
        this.addProcurementVendorFormGroup.reset();
        this.onNoClick();
        this.snackBar.open('Vendor Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
      },
      error:(error:any)=>{
        console.log(error);    
        this.ngxService.stop();
      }
    });
  }

  onNoClick(){
    this.dialogRef.close();
  }
}
