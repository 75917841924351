import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject('LOCALSTORAGE') private localStorage: any) { }

  /**
   * function to set item
   * @param itemKey key in local storage
   * @param item data saved with key
   */
  setItemInLocalStorage(itemKey: string, item: string) {
    this.localStorage.setItem(itemKey, item);
  }

  getItemInLocalStorage(itemKey: any) {
    return this.localStorage.getItem(itemKey);
  }

  getJsonObjectFromLocalStorage(objKey: any) {
    return JSON.parse(this.getItemInLocalStorage(objKey));
  }

  setJsonObjectInLocalStorage(storeKey: string, obj: any) {
    this.setItemInLocalStorage(storeKey, JSON.stringify(obj));
  }

  removeDataFromLocalStorageKey(key: any) {
    this.localStorage.removeItem(key);
  }

  clearLocalStorage() {
    this.localStorage.clear();
  }
}
