<div class="row">
    <div class="col-md-12">
     <div class="heading">    
    <h3> {{'support'|translate}}</h3> 
    <p>{{'please_report'|translate}}</p>
    </div>
      
    </div>
    <div class="col-md-12">
    <div class="cardd support">
    <form [formGroup]="supportForm">    
        <div class="row">
          <div class="col-md-12">
            <label>{{'Select_your_Order_Id'|translate}}</label> 
            
            <select name="orderId" id="orderId"  formControlName="orderId">
              <option value="please_select" class="color-black" *ngIf="data?.orderDetail ==null">{{'Please_select'|translate}}</option>
              <option  *ngFor="let o of mobiOrderModel"  class="color-black">{{o}}</option>
              </select>
            </div>  
        <div class="col-md-12">
        <label>{{'support_needed_on'|translate}}</label> 
        
        <select name="intrested" id="intrested" fdprocessedid="mso7i" formControlName="category">
            <option value="please_select" class="color-black">{{'please_select'|translate}}</option>
            <option value="Order Pickup" class="color-black">{{'order_pickup'|translate}}</option>
            <option value="Order Delivery Drop" class="color-black">{{'order_delivery_drop'|translate}}</option>
            <option value="Service Quality" class="color-black">{{'service_quality'|translate}}</option>
            <option value="payments" class="color-black">{{'payments'|translate}}</option>
            <option value="others" class="color-black">{{'others'|translate}}</option>
          </select>
        </div>   
          
        
        <div class="col-md-12">
          <label>{{'subject_line'|translate}}</label>    
          <div class="displayform">   
            <input type="text" name="" placeholder="Please type subject here.." formControlName="subject" matInput>
          </div>    
        </div> 
        
        <div class="col-md-12">
        <label>{{'details'|translate}}</label>  
        <div class="displayform">    
        <textarea id="message" name="message" required="" placeholder="Please share details here.." formControlName="details" matInput></textarea>  
          
        </div> 
        </div> 
        
        <div class="col-md-12">
        <button class="main-button margin-right10" (click)="onNoClick()">Close</button>
        <button class="main-button" (click)="submitIssue()">{{'submit_issue'|translate}}</button>
        </div>
        </div>
        
    </form>
    </div>    
    </div> 