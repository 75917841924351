<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>

<link href="https://fonts.googleapis.com/css2?family=Calligraffitti&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/lipis/flag-icons@6.6.6/css/flag-icons.min.css"/>
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
    <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap" rel="stylesheet">

    <nav class="main-nav">
      <div class="nav-content">
        <ul class="left-content">
          <li class="nav-item">
            <a  (click)="Home()" class="rc-heading-a cursor-a" >
              <div fxLayout="row" fxFlex="20" class="rc-custom-margin" style="margin-right: 8px;">
                <span class="repairing-company-heading-rc">Rc</span>
              </div>
              <div fxFlex="80" class="heading-class rc-custom-margin" >
                <span class="repairing-company-heading">{{'repairing_Company' | translate}}</span>
                <span class="repairing-company-description">{{'single_Stop_for_all_DoorStep_Repairs' | translate}}</span>
              </div>
            </a>
          </li>
    
          <li class="nav-item tuggle-btn">
            <div class="tuggle-btn-content">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </li>
        </ul>
    
       
      </div>
    
      <div class="tuggle-content">
        <div class="app-bar-login right bar-menu">
          <div class="rc-btn-icon-margin">
            <button mat-button class="login-btn-class" (click)="Home()">
              {{'home' | translate}}
            </button>  
            <button mat-button class="login-btn-class"(click)="navigateToAbout()">
              {{'about_us' | translate}} 
            </button>
            <!-- <button mat-button class="login-btn-class"(click)="navigateToContact()">
              <span class="material-symbols-outlined">
              deskphone
              </span> {{'contact_us' | translate}} 
            </button> -->
            <button mat-button class="login-btn-class" (click)="navigateToBlogs()">
              {{'Blogs' | translate}}
            </button>
            <button [@openClose] *ngIf="!hideloginbtn" mat-button (click)="openDialog()" class="login-btn-class">
              {{'login' | translate}}
            </button>
            <button disabled [@openClose] *ngIf="hideloginbtn" mat-button class="login-btn-class rc-welcome-title1">
            <span class="rc-welcome-title2"  *ngIf="emailId != null" >
            {{'Welcome' | translate}}
            </span> <br >
            <span class="rc-welcome-title3"  *ngIf="emailId != null" >
            {{name1}}
            </span>
            </button>
            <button mat-button   *ngIf="hideloginbtn" [matMenuTriggerFor]="menu"   class="rc-welcome-title1">
            <span class="material-icons md-36">menu</span>
            </button>
            <mat-menu #menu="matMenu"class="mat-menu1 margin">
              <div>
                <button disabled mat-menu-item class="menu-bar-name" *ngIf="showlogoutbtn">
                  <span class="menu-bar-font1"> {{name1}}</span> <br>
                  <span class="menu-bar-font2"> {{emailId}}</span>
                </button> 
              </div>
             
              <div>
                <button mat-menu-item  type="submit"  *ngIf="showlogoutbtn" (click)="navigateToMyAccount()">
                    <span class="material-symbols-outlined bar-icons icon-color" >
                    person
                    </span>{{'my_account' | translate}}
                </button>
              </div>
              
              <!-- <div>
                <button mat-menu-item  type="submit"  *ngIf="showlogoutbtn">
                  <span class="material-symbols-outlined bar-icons icon-color">
                  shopping_cart
                  </span>{{'my_cart' | translate}}
                </button>
              </div> -->
              
              <!-- <div>
                <button mat-menu-item  type="submit"   *ngIf="showlogoutbtn">
                  <span class="material-symbols-outlined bar-icons icon-color">
                  shopping_bag
                  </span>{{'my_orders' | translate}}
                </button>
              </div> -->

             <div *ngIf="utility.hasPermission('LANGUAGE')">
              <button class="rc-logout-dropdwn" mat-menu-item  type="submit" (click)="changeTheme()" >
                <span *ngIf="!hidedarkicon" class="material-symbols-outlined bar-icons" > 
                dark_mode
                </span>
                <span *ngIf="hidedarkicon" class="material-symbols-outlined bar-icons" > 
                sunny
                </span>{{selectedIcon | translate}}
              </button>
             </div>
             <!-- <div>
              <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_ORDER','CREATE_ORDER'])" (click)="navigateToDashboard()">
                <span class="material-symbols-outlined bar-icons icon-color">
                  dashboard
                </span>{{'dashboard' | translate}}
              </button>
            </div> -->
            <div>
              <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['CREATE_USER','READ_USER','UPDATE_USER'])" (click)="navigateToVendorsDashboard()">
                <span class="material-symbols-outlined bar-icons icon-color">
                  team_dashboard
                </span>{{'vendors_dashboard' | translate}}
              </button>
            </div>
            <div>
              <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_ORDER','CREATE_ORDER'])" (click)="navigateToOpenCases()">
                <span class="material-symbols-outlined bar-icons icon-color">
                  cases
                </span>{{'open_case' | translate}}
              </button>
            </div>
          
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['CREATE_USER','READ_USER','UPDATE_USER'])" (click)="navigateToManageUsers()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    manage_accounts
                    </span>{{'manage_users' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_ROLE','CREATE_ROLE'])" (click)="navigateToManageRoles()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    engineering
                    </span>{{'manage_roles' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_SUPPORT_ISSUE','CREATE_SUPPORT_ISSUE'])" (click)="navigateToManageSupportTicket()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    support_agent
                    </span>{{'support_tickets' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['UPDATE_USER','READ_USER'])" (click)="navigateToManageApproveVendors()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    verified_user
                    </span>{{'approve_vendors' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit" *ngIf="utility.hasAnyPermission(['UPDATE_PRODUCT_COMMISSION'])" (click)="navigateToManageProductCommission()"> 
                  <span class="material-symbols-outlined bar-icons icon-color">
                    currency_rupee
                    </span>{{'manage_vendor_commission' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_SERVICE_CENTER','UPDATE_SERVICE_CENTER','CREATE_SERVICE_CENTER'])" (click)="navigateToServiceCenter()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    business_center
                    </span>{{'service_center' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_SERVICE_AREA','UPDATE_SERVICE_AREA','CREATE_SERVICE_AREA'])" (click)="navigateToServiceArea()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    work
                    </span>{{'service_area' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['CREATE_ORDER','READ_ORDER'])" (click)="navigateToCreateOrder()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    draft_orders
                  </span>{{'create_order' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_BRAND','CREATE_BRAND','UPDATE_BRAND'])" (click)="navigateToDeviceIssue()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    devices
                  </span>{{'device' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="utility.hasAnyPermission(['READ_ORDER'])" (click)="navigateToActionTracker()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                    analytics
                  </span>{{'action_tracker' | translate}}
                </button>
              </div>
              <div>
                <button mat-menu-item type="submit"  *ngIf="showlogoutbtn" (click)="logout()">
                  <span class="material-symbols-outlined bar-icons icon-color">
                  logout
                  </span>{{'log_out' | translate}}
                  </button>
              </div>
              <button *ngIf="utility.hasPermission('LANGUAGE')" mat-menu-item type="submit" (click)="changeLang('hi')">
              Hindi
              </button>
              <button *ngIf="utility.hasPermission('LANGUAGE')" mat-menu-item type="submit" (click)="changeLang('en')">
              English
              </button>
            </mat-menu>
            <!-- <button class="menu-btn-flag" mat-icon-button [matMenuTriggerFor]="menu1">
              {{selectedLang}}
              </button>
              <mat-menu #menu1="matMenu">
              <button mat-menu-item *ngFor="let lang of languageList" (click)="changeLang(lang)">
                {{lang.languageName}}
              </button>
              </mat-menu> -->
          </div>
        </div>
      </div>
    </nav>

    <div class="chatbot-container" *ngIf="chatBotEnabled">
      <iframe [src]="safeImageUrl" style="width: 500px;
      height: 680px;
      position: fixed;
      right: 0;
      bottom: 0;
      z-index: 9999;" frameborder="0"></iframe>
    </div>

    <mat-toolbar class="rc-toolbar">
  <mat-toolbar-row>
    <div >
      <div class="rc-custom-padding" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="20"
        fxLayout.xs="column">
        
        
        <div></div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="custom-height">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-sidenav-container>
  <mat-sidenav-content>
  </mat-sidenav-content>
</mat-sidenav-container>
<app-footer></app-footer>
<ngx-ui-loader></ngx-ui-loader>