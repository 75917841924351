<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
<link
    href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap"
    rel="stylesheet">

<app-calculator-form></app-calculator-form>


<section class="middle-sec">
    <div class="container">
        <div class="col-md-9">
            <h1>Blogs</h1>
        </div>
    </div>

    <div class="row">

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/Designer.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Dec 6, 2023</span>
                    <h3 class="post-title"><a>The Impact of 5G on Mobile Phone Repairs: What You Need to Know
                        </a></h3>
                    <p class="post-description">
                        Dive into the realm where 5G transforms mobile repairs, unraveling unprecedented challenges and solutions. Stay ahead in tech evolution.
                    </p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToImpactOf5g()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/cracked-screen.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Nov 5, 2023</span>
                    <h3 class="post-title"><a>Cracked Screen Chronicles: A Comprehensive Guide to Screen Replacement
                        </a></h3>
                    <p class="post-description">
                        A cracked screen is one of the most common and frustrating smartphone accidents
                    </p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToCrackedScreen()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/water-damage.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Nov 4, 2023</span>
                    <h3 class="post-title"><a>HOW TO DEAL WITH A WATER DAMAGED DEVICE</a></h3>
                    <p class="post-description">
                        Water damage is one of the most common causes of electronic device failure. Whether your mobile
                        phone.....
                    </p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToCrackedScreen()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/blog_4.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Jan 5, 2023</span>
                    <h3 class="post-title"><a>How To Track Your Lost Mobile Phone With IMEI Number</a></h3>
                    <p class="post-description">
                        Losing your mobile phone can be a stressful and frustrating experience. Not only is it an
                        inconvenience, but....
                    </p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToTrackyourlostmobile()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/blog_5.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Jan 5, 2023</span>
                    <h3 class="post-title"><a>How To Take Screenshots Using Keyboard Shortcuts On PC/Laptop?</a></h3>
                    <p class="post-description">
                        Taking a screenshot on a PC or laptop can be done in a variety of ways, but using keyboard
                        shortcuts is one of the.....
                    </p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToTakescreenshotskeyboard()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/lock-screenapp.jpg" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Jan 12, 2023</span>
                    <h3 class="post-title"><a>How To Lock Apps On iPhone With A Password Or TouchID? </a></h3>
                    <p class="post-description">
                        Have you ever wondered how to lock apps on your iPhone? This is the right place for you if your
                        answer is yes. </p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToLockappsoniphoneComponent()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/bestlaptopforstudent.jpg" height="50%"
                            alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Jan 12, 2023</span>
                    <h3 class="post-title"><a>Best Laptops For Students In India 2023</a></h3>
                    <p class="post-description">
                        We didn't have any idea that COVID-19 would become a hybrid online-offline environment in 2020
                        when it hit us.....</p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToBestlaptopComponent()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/phone-camera.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Jan 18, 2023</span>
                    <h3 class="post-title"><a>Best Camera Phones Under 20000 In India (2023)</a></h3>
                    <p class="post-description">
                        When it comes to camera phones under 20,000 rupees in India, there are plenty of great options
                        to choose from.</p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToBestcameraphonesCcomponent()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>


        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/mobile-phones.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Jan 23, 2023</span>
                    <h3 class="post-title"><a>10 Best Mobile Phones In The World January 2023 </a></h3>
                    <p class="post-description">
                        Hopefully, you're here because you're curious about the coolest phone in the world that you can
                        buy in 2023.</p>
                    <ul class="post-bar">
                        <li><a (click)="navigateToBestmobilephonesintheworldComponent()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/mobile-repair.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Feb 23, 2023</span>
                    <h3 class="post-title"><a>Best Practices for Enhancing Customer Experience With Repair Services</a>
                    </h3>
                    <p class="post-description">
                        The customer experience is a critical aspect of any business, and the repair services industry
                        is no exception.
                    <ul class="post-bar">
                        <li><a (click)="navigateToenhancingcustomerexperience()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/laptop_repair.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Feb 23, 2023</span>
                    <h3 class="post-title"><a>10 Ways to Know Whether You Need Laptop Repair</a></h3>
                    <p class="post-description">
                        Laptops are essential devices for work, study, and entertainment. However, like all electronics,
                        they can encounter issues and require repair.
                    <ul class="post-bar">
                        <li><a (click)="navigateToneedLaptopRepair()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/buisness.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Feb 24, 2023</span>
                    <h3 class="post-title"><a>20 Killer Tips To Be Successful In Cell Phone Repair Business</a></h3>
                    <p class="post-description">
                        In today's world, mobile phones have become a ubiquitous part of our daily lives. They serve as
                        a means of communication, entertainment...
                    <ul class="post-bar">
                        <li><a (click)="navigateTipsToBeSuccessfulInRepairBusiness()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/android.jpg" height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">Feb 24, 2023</span>
                    <h3 class="post-title"><a>ANDROID PHONE REPAIRS HANDLED</a></h3>
                    <p class="post-description">
                        Android smartphones are popular and reliable devices, but like all technology, they are not
                        immune to issues and damage.When an Android phone is not working as it should...
                    <ul class="post-bar">
                        <li><a (click)="navigateToAndroidRepairs()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>



        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/iphone_14_iphone_14_pro.png" height="50%"
                            alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">March 25, 2023</span>
                    <h3 class="post-title"><a>iPhone 14 vs iPhone 14 Pro</a></h3>
                    <p class="post-description">
                        Are you wondering what makes the new iPhone 14 and iPhone 14 Pro different? Both are 6.1-inch
                        ‌iPhone‌ models that follow their predecessors, the iPhone 13 and iPhone 13 Pro.
                    <ul class="post-bar">
                        <li><a (click)="navigateToiphone14vsiphone14pro()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/iphone_14_iphone_14_pro_max.png" height="50%"
                            alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">March 25, 2023</span>
                    <h3 class="post-title"><a>iPhone 14 Plus vs iPhone 14 Pro Max</a></h3>
                    <p class="post-description">
                        Are you wondering what makes the new iPhone 14 and iPhone 14 Pro different? Both are 6.1-inch
                        ‌iPhone‌ models that follow their predecessors, the iPhone 13 and iPhone 13 Pro.
                    <ul class="post-bar">
                        <li><a (click)="navigateToiphone14vsiphone14proMax()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/iphone_14_pro_iphone_14_pro_max.png"
                            height="50%" alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">March 25, 2023</span>
                    <h3 class="post-title"><a>iPhone 14 Pro vs. iPhone 14 Pro Max: Which Pro model should you get?</a>
                    </h3>
                    <p class="post-description">
                        The iPhone 14 Pro and iPhone 14 Pro Max offer a range of features that cater to both casual and
                        professional users alike. If money is not an issue
                    <ul class="post-bar">
                        <li><a (click)="navigateToiphone14provsiphone14proMax()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class="col-md-4">
            <div class="post-slide">
                <div class="post-img">
                    <a class="over-layer"><img src="../assets/images/blogs/iphone_14_iphone_14_plus.png" height="50%"
                            alt=""></a>
                </div>
                <div class="post-content">
                    <span class="post-date">March 24, 2023</span>
                    <h3 class="post-title"><a>iPhone 14 vs iPhone 14 Plus</a></h3>
                    <p class="post-description">
                        The new iPhone 14 has been a highly anticipated device, and while it features some great
                        upgrades like an enhanced chipset and improved photo/video capabilities....
                    <ul class="post-bar">
                        <li><a (click)="navigateToiphone14vsiphone14plus()">Read More</a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

</section>