<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans&display=swap" rel="stylesheet">
<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">
<link href="https://fonts.googleapis.com/css2?family=Sniglet&display=swap" rel="stylesheet">


<div fxLayout="row" fxLayoutAlign="space-between center" >
    <h1 class="repair-heading-body uppercase">{{updateOrAdd | translate}}</h1>
    <div class="cursor-pointer padding0" (click)="onNoClick()"><mat-icon>close</mat-icon></div>
</div>

<form [formGroup]="addRolesFormGroup">
    <mat-card >
        <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="10" fxLayout.xs="column">
            <div fxLayout="column" fxFlex="50">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'Role_Name' | translate}}</mat-label>
                            <input matInput #input formControlName="roleName"
                                placeholder="{{'Role_Name' | translate}}" [readonly]="updateOrAdd=='view_role'">
                        </mat-form-field>
                    </div>   
                </div>
                <div fxLayout="column" fxFlex="50">
                    <div>
                        <mat-form-field appearance="fill">
                            <mat-label>{{'Role_NameHi' | translate}}</mat-label>
                            <input matInput #input formControlName="roleNameHi"
                                placeholder="{{'Role_NameHi' | translate}}" [readonly]="updateOrAdd=='view_role'">
                        </mat-form-field>
                    </div>      
                </div>  
             
        </div>
        <div fxLayout="row" fxLayoutAlign="center" >
         <div fxLayout="column" fxFlex="50">
            <mat-label class="margin-left10">{{'select_permission' | translate}}</mat-label>
            <div class="multiselect" >
               <ng-multiselect-dropdown 
                   [settings]="dropdownSettings"
                   [data]="permission"
                   [disabled]="updateOrAdd=='view_role'"
                   formControlName="permission">
               </ng-multiselect-dropdown>
           </div>
         </div>
                
               
           
        </div>
      
        <div fxLayout="row" fxLayoutAlign="center" *ngIf="updateOrAdd!='view_role'">
            <div style="margin:20px;">
                <button class="button" type="submit" mat-raised-button (click)="addOrUpdateRoleModel()">{{updateOrAdd | translate}}</button>
            </div>
        </div>
    </mat-card>
</form>
