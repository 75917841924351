import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";



@Component({

    selector: 'app-scheduledpickups',
    templateUrl: './scheduledpickups.component.html',
    styleUrls: ['./scheduledpickups.component.scss']
   
})

export class ScheduledpickupsComponent implements OnInit{
 
  ngOnInit(): void {
    // Indicator
const yearbookMenu = document.querySelector(".yearbook ul");
const marker:any = document.querySelector(".indicator");
const menuLink = document.querySelectorAll(
  "#journey .timeline-holder .yearbook ul li"
);

if (typeof yearbookMenu != "undefined" && yearbookMenu != null) {
  for (let i = 0; i < menuLink.length; i++) {
    menuLink[i].addEventListener("click", function () {
      let current = document.getElementsByClassName("active");
      let menuItemPoition:any
      // If there's no active class
      if (current.length > 0) {
        current[0].className = current[0].className.replace(" active", "");
      }
      // Add the active class to the current/clicked button
     
      marker.style.transform = `translateY(${menuItemPoition}px)`;
    });
  }
}

const journey:any = document.querySelector("#journey");
document.addEventListener("scroll", function (e) {
  let windowPosition = window.scrollY;
  let positionJourney = journey.offsetTop;
  let positionPath = windowPosition - positionJourney;
  // console.log(journey.scrollTop + position);
  let svgMask:any = document.querySelector(".mask-path");
  svgMask.style["stroke-dashoffset"] = 10000 - positionPath * 3;
});
  }

    constructor(private translation:TranslateService,private router:Router,private titleService: Title ) {
        this.translation.get('Schedule_Pickup').subscribe((res: string) => {
          this.titleService.setTitle(res);
        });
    
        this.translation.onLangChange.subscribe((event: LangChangeEvent) => {
          this.translation.get('Schedule_Pickup').subscribe((res: string) => {
            this.titleService.setTitle(res);
          });
        });
      }
}