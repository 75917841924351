import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppConstants, OrderStatus, OrderType } from "src/app/constants/app.constants";
import { BrandModel } from "src/app/data/brand-model/brand.model";
import { City } from "src/app/data/city-model/city-model";
import { DeviceModel } from "src/app/data/device-model/device.model";
import { GstCategoryModel } from "src/app/data/gst-category-model/gst-category.model";
import { MobiOrderModel } from "src/app/data/mobi-order-model/mobi-order.model";
import { OrderRepairIssueModel } from "src/app/data/order-repair-issue-model/order-repair-issue.model";
import { PaymentModel } from "src/app/data/payment-model/payment.model";
import { RepairChargesModel } from "src/app/data/repair-charges-model/repair-charges.model";
import { State } from "src/app/data/state-model/state-model";
import { TimeSlotModel, UserAddressModel } from "src/app/data/user-address-model/user-address.model";
import { UserDetailsModel } from "src/app/data/user-details/user-details.model";
import { OrderDetailsService } from "src/app/services/order-details.service";
import { UserModel } from "src/app/data/user-model/user.model";
import { BaseComponent } from "src/app/abstract-components/base.component";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ProcurementVendorDialog } from "../procurement-vendor-dialog/procurement-vendor-dialog";
import { ProcurementVendorService } from "src/app/services/procurement-vendor.service";
import { ProcurementVendorModel } from "src/app/data/procurement-vendor-model/procurement-vendor-model";
import { ExpenseModel } from "src/app/data/expense-model/expense.model";
import { AddExpensesService } from "src/app/services/add-expenses.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "login-sendotp-dialog",
  templateUrl: "./edit-order-dialog.html",
  styleUrls: ["./edit-order-dialog.scss"]
})

export class EditOrderDialog extends BaseComponent implements OnInit {
  public brandList: BrandModel[] = [];
  public deviceList: DeviceModel[] = [];
  public repairChargeList: RepairChargesModel[] = [];
  public selectedProblems: RepairChargesModel[] = [];
  public states: string[] = [];
  public cities: string[] = [];
  protected getBrandDataListUrl: string;
  protected getModelDataListUrl: string;
  protected getRepairChargeDataListUrl: string;
  protected getUserDetailsDataListUrl: string;
  protected getCustomerUserDetailsDataListUrl: string;
  protected getGstDataUrl: string;
  protected getRegisterUserUrl: string;
  protected getAllStatesUrl: string;
  protected getAllCityUrl: string;
  protected getPaymentDTOUrl: string;
  protected createMMOrderUrl: string;
  protected codPaymentOrderUrl: string;
  status: any;
  expenseAmount;
  vendor;
  expenseId;
  mobiOrder: MobiOrderModel = new MobiOrderModel();
  createdMobiOrder: MobiOrderModel = new MobiOrderModel();
  paymentDTO: PaymentModel;
  paymentDTOAfterCOD: PaymentModel;
  addressOrderDto: UserAddressModel;
  registerUserYn: boolean = false;
  registeredUserId: number = 0;
  createOrder: FormGroup;
  isRequired: boolean = false;
  userOptions: UserDetailsModel[] = [];
  customerOptions: UserDetailsModel[] = [];
  totalAmount: number = 0;
  totalAmountPlusTax: number = 0;
  amountBeforeGst: number = 0;
  discountAmount: number = 0;
  gstCgstPercentage: number = 9;
  gstSgstPercentage: number = 9;
  amountAfterDiscount: number = 0;
  sgstAmount: number = 0;
  cgstAmount: number = 0;
  selectedVendor: UserDetailsModel;
  selectedCustomer: UserDetailsModel;
  gstObject: GstCategoryModel;
  addressDto: UserAddressModel = new UserAddressModel();
  stateList: State[] = [];
  cityList: City[] = [];
  orderTypeValue: string;
  callValidate: boolean = false;
  updateOrderUrl: any;
  expenseDto: ExpenseModel = new ExpenseModel();
  procurementdetails: FormGroup;
  getAllProcurementVendorListUrl: string = AppConstants.orderService + AppConstants.getAllProcurementVendor;
  ProcurementVendorList: ProcurementVendorModel[] = [];
  totalExpense: any;
  displayedColumns: string[] = ["Problem", "Vendor", "Amount", "Action"];
  getOrderByIdUrl: string;
  saveExpense: string = AppConstants.orderService + AppConstants.addExpense;
  updateOrAddedSuccessMsg: string = "Expenses Added Successfully!!";
  orderstatus = OrderStatus;
  OrderStatuskeys: any;
  deleteExpenseUrl: string;
  showTable: boolean = false;
  customerMobile: string;
  getCustomerAddressUrl: string;
  getMMCaseByOrderIdUrl: string;
  updateCasestatusUrl: string;
  statusType: any = 'ORDER_CANCELLED';
  deliverStatus: any = 'PRODUCT_DELIVERED';
  mmCaseId: number;
  paymentMode: string;
  paymentStatus: string;
  getpaymentstatus: any;
  getpaymentMode: any;
  orderDepartmentstring: any;
  orderDepartment: any
  actypestring: any;
  actype: any;
  custfirstname
  custlastname
  custemail

  constructor(
    public addExpService: AddExpensesService,
    public procurementVendorService: ProcurementVendorService,
    public dialogRef1: MatDialog,
    public dialogRefupdate: MatDialogRef<EditOrderDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    dataManager: OrderDetailsService,
    private translate: TranslateService,
    snackBar: MatSnackBar,
    private ngxService: NgxUiLoaderService
  ) {
    super();
    this.dataManager = dataManager;
    this.snackBar = snackBar;
    this.getAllProcurementVendorList();
  }

  ngOnInit(): void {
    this.OrderStatuskeys = Object.values(this.orderstatus).filter((k) =>
      isNaN(Number(k))
    );

    this.procurementdetails = new FormGroup({
      expenseAmount: new FormControl(null),
      vendorName: new FormControl(null),
    });

    this.createOrder = new FormGroup({
      orderType: new FormControl("retail"),
      brand: new FormControl(null, Validators.required),
      model: new FormControl(null, Validators.required),
      orderStatus: new FormControl(null, Validators.required),
      repair: new FormControl(null, Validators.required),
      imeiNumber: new FormControl(),
      deliveryDate: new FormControl(null, Validators.required),
      notes: new FormControl(),
      customerFirstName: new FormControl({
        disabled: this.isRequired,
        value: null,
      }),
      customerLastName: new FormControl({
        disabled: this.isRequired,
        value: null,
      }),
      customerMobile: new FormControl({
        disabled: this.isRequired,
        value: null,
      }),
      customerEmail: new FormControl({
        disabled: this.isRequired,
        value: null,
      }),
      customerAddress1: new FormControl({
        disabled: this.isRequired,
        value: null,
      }),
      customerAddress2: new FormControl({
        disabled: this.isRequired,
        value: null,
      }),

      city: new FormControl({ disabled: this.isRequired, value: null }),
      state: new FormControl({ disabled: this.isRequired, value: null }),
      pinCode: new FormControl({ disabled: this.isRequired, value: null }),
      selectedAddressID: new FormControl(null),
      discountPercentage: new FormControl(0),
      discountReasons: new FormControl(),
      modeOfPayment: new FormControl({ disabled: true, value: "COD" }),
      vendorSearch: new FormControl({
        disabled: !this.isRequired,
        value: null,
      }),
      vendorIdSearch: new FormControl({ disabled: true, value: null }),
      vendorNameSearch: new FormControl({ disabled: true, value: null }),
      vendorMobileSearch: new FormControl({ disabled: true, value: null }),
    });
    this.getBrandDataListApi();

    if (this.data.order_details != null) {
      this.mobiOrder = this.data.order_details;
      this.getMMCaseByOrderId(this.mobiOrder.orderId)
      this.getAddressByAddressId(this.mobiOrder.addressId)
      if (this.mobiOrder.brand != null) {
        this.getModelDataListApi(this.mobiOrder.brand!.brandId);
      }
      this.getOrderById(this.data.order_details.orderId);

//debugger
      if (this.mobiOrder.userDto != null) 
      {
        this.custfirstname = this.mobiOrder.userDto!.firstName;
        this.custlastname = this.mobiOrder.userDto!.lastName;
        this.custemail = this.mobiOrder.userDto!.email;

        var sliced = this.mobiOrder.userDto?.mobileNumber?.toString().slice(0, 2);
        var sliced1 = this.mobiOrder.userDto?.mobileNumber?.toString().slice(2, 12);
        this.customerMobile = sliced + '-' + sliced1
      }
      else 
      {
        this.custfirstname = this.mobiOrder.firstName;
        this.custlastname = this.mobiOrder.lastName;
        this.custemail = this.mobiOrder.email;

        var customerMobile1 = this.mobiOrder.mobileNumber?.toString().slice(0, 2);
        var customerMobile2 = this.mobiOrder.mobileNumber?.toString().slice(2, 12);
        this.customerMobile = customerMobile1 + '-' + customerMobile2
      }

      this.status = this.data.order_details.orderStatus
    }

    this.orderDepartmentstring = this.mobiOrder.orderDepartment
    this.translate.get(this.orderDepartmentstring).subscribe(
      res => {
        this.orderDepartment = res;
      })

    if (this.mobiOrder.acType != null) {
      this.actypestring = this.mobiOrder.acType.acTypes
      this.translate.get(this.actypestring).subscribe(
        res => {
          this.actype = res;
        })
    }
  }
  custaddress1
  custaddress2
  custcity
  custpin
  custstate
  getAddressByAddressId(addressId) {
    this.getCustomerAddressUrl = AppConstants.userService +
      AppConstants.getAddressByAddressId + "?addressId=" + addressId;
    this.ngxService.start();
    this.dataManager
      .getRequest(this.getCustomerAddressUrl)
      .subscribe({
        next: (res: any) => {
          if (res != null) {
            this.custaddress1 = res.addressLine1;
            this.custaddress2 = res.addressLine2;
            this.custcity = res.city;
            this.custpin = res.pincode;
            this.custstate = res.state;
          }
          else {
            this.custaddress1 = this.mobiOrder.addressLine1;
            this.custaddress2 = this.mobiOrder.addressLine2;
            this.custcity = this.mobiOrder.city;
            this.custpin = this.mobiOrder.pincode;
            this.custstate = this.mobiOrder.state;
          }
          this.ngxService.stop();

        },
        error: (error: any) => {
          this.ngxService.stop();
          if (this.errorHandler(error)) {
            this.errorDisplayDialog(error.error.message);
          }
        },
      });
  }

  updateOrder() {

    if (this.getpaymentstatus == 'PAYMENT_AWAITED' && this.getpaymentMode == 'COD' && this.mobiOrder.orderStatus == this.deliverStatus) {
      this.updateOrderUrl = AppConstants.orderService + AppConstants.updateOrderStatus + "?id=" + this.mobiOrder.orderId + "&status=" + this.getpaymentstatus + "&quality_tested=" + this.mobiOrder.quality_tested;
    }
    else {
      this.updateOrderUrl = AppConstants.orderService + AppConstants.updateOrderStatus + "?id=" + this.mobiOrder.orderId + "&status=" + this.mobiOrder.orderStatus + "&quality_tested=" + this.mobiOrder.quality_tested;
    }
    this.ngxService.start();
    this.dataManager.updateOrderStatus(this.updateOrderUrl)
      .subscribe({
        next: (res: any) => {
          this.ngxService.stop();

          this.dialogRefupdate.close();
          this.snackBar.open("Updated Successfully", "", {
            duration: 5000,
            panelClass: ["green-snackbar"],
          });
          if (this.mobiOrder.orderStatus == this.statusType) {
            this.cancelCase(this.mobiOrder.orderId);
          }
          else if (this.mobiOrder.orderStatus != this.status) {
            this.updateCaseStatus(this.mmCaseId)
          }


        },
        error: (error: any) => {
          console.log(error);
        },
      });
  }
  updateCaseStatus(mmCaseId) {
    this.updateCasestatusUrl = AppConstants.supportService + AppConstants.UPDATE_CASE_STATUS + "?id=" + mmCaseId;
    this.dataManager.getRequest(this.updateCasestatusUrl).subscribe(
      {
        next: (res: any) => { },
        error: (error: any) => {
          console.log(error);
        }
      }
    )
  }
  cancelCase(OrderId) {
    this.updateCasestatusUrl = AppConstants.supportService + AppConstants.cancelCase + "?id=" + OrderId
    this.dataManager.getRequest(this.updateCasestatusUrl).subscribe(
      {
        next: (res: any) => {

        },
        error: (error: any) => {
          console.log(error);
        }
      }
    )
  }
  oncloseSnackbar() {
    this.dialogRefupdate.close();
  }

  getMMCaseByOrderId(orderId) {
    this.getMMCaseByOrderIdUrl =
      AppConstants.supportService + AppConstants.getMMCaseByOrderId + "?orderId=" + orderId;
    this.dataManager.getRequest(this.getMMCaseByOrderIdUrl).subscribe({
      next: (res: any) => {
        this.mmCaseId = res.mmCaseId
      },
      error: (error: any) => {

      },
    });
  }

  getBrandDataListApi() {
    //this.ngxService.start();
    this.getBrandDataListUrl =
      AppConstants.orderService + AppConstants.GET_ALL_BRANDS;
    this.dataManager.getRequest(this.getBrandDataListUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.brandList = res;
        this.getGstDataUrl =
          AppConstants.orderService + AppConstants.getGstCategory;
        this.getGstDataApi();
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  getModelDataListApi(brand: any) {
    //this.ngxService.start();
    this.getModelDataListUrl =
      AppConstants.orderService + AppConstants.getModels + "?brandId=" + brand;
    this.dataManager.getRequest(this.getModelDataListUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.deviceList = res;
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  getRepairChargeDataListApi() {
    //this.ngxService.start();
    this.dataManager.getRequest(this.getRepairChargeDataListUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.passDataRepairchargeListResponse(res);
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  getUserDetailsDataListApi() {
    //this.ngxService.start();
    this.dataManager.getRequest(this.getUserDetailsDataListUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.passUserDetailsDataListResponse(res);
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  getCustomerUserDetailsDataListApi() {
    //this.ngxService.start();
    this.dataManager
      .getRequest(this.getCustomerUserDetailsDataListUrl)
      .subscribe({
        next: (res: any) => {
          //this.ngxService.stop();
          this.getCustomerUserDetailsDataListRepsonse(res);
        },
        error: (error: any) => {
          //this.ngxService.stop();
          if (this.errorHandler(error)) {
            this.errorDisplayDialog(error.error.message);
          }
        },
      });
  }

  getGstDataApi() {
    //this.ngxService.start();
    this.dataManager.getRequest(this.getGstDataUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.getGstDataRepsonse(res);
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  registerUserApi(regsiterDto: UserModel) {
    //this.ngxService.start();
    this.dataManager
      .postRequest(this.getRegisterUserUrl, regsiterDto)
      .subscribe({
        next: (res: any) => {
          //this.ngxService.stop();
          this.getRegisterUserData(res);
        },
        error: (error: any) => {
          //this.ngxService.stop();
          if (this.errorHandler(error)) {
            this.errorDisplayDialog(error.error.message);
          }
        },
      });
  }

  allStatesApi() {
    //this.ngxService.start();
    this.dataManager.getRequest(this.getAllStatesUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.getAllStatesData(res);
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  cityApi() {
    //this.ngxService.start();
    this.dataManager.getRequest(this.getAllCityUrl).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        this.getAllCityData(res);
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  getPaymentDTOApi(MobiOrderModel: MobiOrderModel) {
    //this.ngxService.start();
    this.dataManager
      .postRequest(this.getPaymentDTOUrl, MobiOrderModel)
      .subscribe({
        next: (res: any) => {
          //this.ngxService.stop();
          this.getPaymentDTOData(res);
        },
        error: (error: any) => {
          //this.ngxService.stop();
          if (this.errorHandler(error)) {
            this.errorDisplayDialog(error.error.message);
          }
        },
      });
  }

  createMMOrderApi(MobiOrderModel: MobiOrderModel) {
    //this.ngxService.start();
    this.dataManager
      .postRequest(this.createMMOrderUrl, MobiOrderModel)
      .subscribe({
        next: (res: any) => {
          //this.ngxService.stop();
          this.getMMOrderData(res);
        },
        error: (error: any) => {
          //this.ngxService.stop();
          if (this.errorHandler(error)) {
            this.errorDisplayDialog(error.error.message);
          }
        },
      });
  }

  makeCODPaymentAgainstOrderDataApi() {
    //this.ngxService.start();
    this.dataManager.postRequest(this.codPaymentOrderUrl, null).subscribe({
      next: (res: any) => {
        //this.ngxService.stop();
        //  this.getMakeCODPaymentAgainstOrderData(res);
      },
      error: (error: any) => {
        //this.ngxService.stop();
        if (this.errorHandler(error)) {
          this.errorDisplayDialog(error.error.message);
        }
      },
    });
  }

  changeState(state: string) {
    this.getAllCityUrl =
      AppConstants.userService +
      AppConstants.getAllCitiesByStateName +
      "?stateName=" +
      state;
    this.cityApi();
  }

  changeOrderType(orderTypeValue: string) {
    this.orderTypeValue = orderTypeValue;

    if (orderTypeValue == "business") {
      this.createOrder.get("vendorSearch")!.enable(); //enable venor and disable customer fields.

      this.createOrder.get("customerFirstName")!.disable();
      this.createOrder.get("customerLastName")!.disable();
      this.createOrder.get("customerMobile")!.disable();
      this.createOrder.get("customerEmail")!.disable();
      this.createOrder.get("customerAddress1")!.disable();
      this.createOrder.get("customerAddress2")!.disable();
      this.createOrder.get("city")!.disable();
      this.createOrder.get("state")!.disable();
      this.createOrder.get("pinCode")!.disable();

      this.createOrder.controls["customerFirstName"].setValue(null);
      this.createOrder.controls["customerLastName"].setValue(null);
      this.createOrder.controls["customerMobile"].setValue(null);
      this.createOrder.controls["customerEmail"].setValue(null);
      this.createOrder.controls["customerAddress1"].setValue(null);
      this.createOrder.controls["customerAddress2"].setValue(null);
      this.createOrder.controls["city"].setValue(null);
      this.createOrder.controls["state"].setValue(null);
      this.createOrder.controls["pinCode"].setValue(null);
      this.createOrder.controls["selectedAddressID"].setValue(null);

      this.isRequired = true;
    } else {
      this.isRequired = false;
      this.createOrder.get("vendorSearch")!.disable(); //disable vendor and enable customer fields.
      this.createOrder.controls["vendorSearch"].setValue(null);
      this.createOrder.controls["vendorIdSearch"].setValue(null);
      this.createOrder.controls["vendorNameSearch"].setValue(null);
      this.createOrder.controls["vendorMobileSearch"].setValue(null);

      this.createOrder.get("customerFirstName")!.enable();
      this.createOrder.get("customerLastName")!.enable();
      this.createOrder.get("customerMobile")!.enable();
      this.createOrder.get("customerEmail")!.enable();
      this.createOrder.get("customerAddress1")!.enable();
      this.createOrder.get("customerAddress2")!.enable();
      this.createOrder.get("city")!.enable();
      this.createOrder.get("state")!.enable();
      this.createOrder.get("pinCode")!.enable();
    }
  }

  changeBrand(brand: any) {
    this.getModelDataListApi(brand);
    this.createOrder.controls["model"].reset();
    this.createOrder.controls["repair"].reset();
    //this.selectProblems();
  }

  changeModel(model: any) {
    this.getRepairChargeDataListUrl =
      AppConstants.orderService +
      AppConstants.getRepairChargesFromMobiMech +
      "?modelId=" +
      model;
    this.getRepairChargeDataListApi();
    this.createOrder.controls["repair"].reset();
    //this.selectProblems();
  }

  searchVendorDetails() {
    let vendorSearchVal: string =
      this.createOrder.controls["vendorSearch"].value;
    if (vendorSearchVal != "" && vendorSearchVal != null) {
      if (
        vendorSearchVal.length == 6 &&
        !AppConstants.containsAlphabet.test(vendorSearchVal)
      ) {
        this.getUserDetailsDataListUrl =
          AppConstants.userService +
          AppConstants.getUserById +
          "?userId=" +
          vendorSearchVal;
        this.getUserDetailsDataListApi();
      } else if (
        AppConstants.mobileRegex.test(vendorSearchVal) &&
        !AppConstants.containsAlphabet.test(vendorSearchVal)
      ) {
        this.getUserDetailsDataListUrl =
          AppConstants.userService +
          AppConstants.getUserDetailsByMobileNumber +
          "?mobileNumber=" +
          vendorSearchVal;
        this.getUserDetailsDataListApi();
      } else if (AppConstants.emailRegex.test(vendorSearchVal)) {
        this.getUserDetailsDataListUrl =
          AppConstants.userService +
          AppConstants.GET_USER_DATA +
          "?username=" +
          vendorSearchVal;
        this.getUserDetailsDataListApi();
      }
    }
  }

  setSelectedVendor() {
    let userId = this.createOrder.controls["vendorSearch"].value;
    this.selectedVendor = this.userOptions.filter(
      (e) => (e.userId = userId)
    )[0];
    this.createOrder.controls["vendorIdSearch"].setValue(
      this.selectedVendor.userId
    );
    this.createOrder.controls["vendorNameSearch"].setValue(
      this.selectedVendor.firstName
    );
    this.createOrder.controls["vendorMobileSearch"].setValue(
      this.selectedVendor.mobileNumber
    );
  }

  searchCustomerDetails() {
    let customerSearch: string =
      this.createOrder.controls["customerMobile"].value;
    if (customerSearch != "" && customerSearch != null) {
      if (
        AppConstants.mobileRegex.test(customerSearch) &&
        !AppConstants.containsAlphabet.test(customerSearch)
      ) {
        this.getCustomerUserDetailsDataListUrl =
          AppConstants.userService +
          AppConstants.getUserDetailsByMobileNumber +
          "?mobileNumber=" +
          customerSearch;
        this.getCustomerUserDetailsDataListApi();
      }
    }
  }

  setSelectedCustomer() {
    let mobileNumber = this.createOrder.controls["customerMobile"].value;
    this.selectedCustomer = this.customerOptions.filter(
      (e) => (e.mobileNumber = mobileNumber)
    )[0];

    this.createOrder.controls["customerFirstName"].setValue(
      this.selectedCustomer.firstName
    );
    this.createOrder.controls["customerLastName"].setValue(
      this.selectedCustomer.lastName
    );
    this.createOrder.controls["customerEmail"].setValue(
      this.selectedCustomer.email
    );
  }

  setAddress() {
    let selectedAddressId = this.createOrder.controls["customerAddress1"].value;
    if (selectedAddressId != null) {
      if (this.orderTypeValue == "business") {
        this.addressOrderDto = this.selectedVendor.addresses!.filter(
          (e) => e.addressId == selectedAddressId
        )[0];
      } else {
        this.addressOrderDto = this.selectedCustomer.addresses!.filter(
          (e) => e.addressId == selectedAddressId
        )[0];
      }

      this.createOrder.controls["selectedAddressID"].setValue(
        this.addressOrderDto.addressId
      );
      this.createOrder.controls["customerAddress1"].setValue(
        this.addressOrderDto.addressLine1
      );
      this.createOrder.controls["customerAddress2"].setValue(
        this.addressOrderDto.addressLine2
      );
      this.createOrder.controls["pinCode"].setValue(
        this.addressOrderDto.pincode
      );
      this.createOrder.controls["state"].setValue(this.addressOrderDto.state);
      this.createOrder.controls["city"].setValue(this.addressOrderDto.city);

      this.changeState(this.addressOrderDto.state!);
    }
  }

  selectProblems() {
    this.totalAmount = 0;
    this.selectedProblems = this.createOrder.controls["repair"].value;

    if (this.selectedProblems != null) {
      this.selectedProblems.forEach((e: any) => {
        this.totalAmount = this.totalAmount + e.repairAmount;
      });

      let discountPercent: number =
        this.createOrder.controls["discountPercentage"].value;
      this.discountAmount = (discountPercent / 100) * this.totalAmount;
      this.discountAmount = Number.parseFloat(this.discountAmount.toFixed(2));

      this.amountAfterDiscount = this.totalAmount - this.discountAmount;

      this.cgstAmount = 0;
      this.sgstAmount = 0;

      this.amountBeforeGst =
        this.amountAfterDiscount - (this.cgstAmount + this.sgstAmount);

      this.totalAmountPlusTax = this.amountAfterDiscount;
    }
  }

  protected passDataModelListResponse(res: any) {
    this.deviceList = res;
    //this.closeLoaderDialog();
  }

  protected passDataRepairchargeListResponse(res: any) {
    this.repairChargeList = res;
    this.repairChargeList = this.repairChargeList.filter(
      (e: any) => e.repairAmount > 0
    );
    //this.ngxService.stop();
  }

  protected passUserDetailsDataListResponse(res: any) {
    if (res != null) {
      this.userOptions = [];
      if (Array.isArray(res)) {
        this.userOptions = res;
      } else {
        this.userOptions.push(res);
      }
      this.setSelectedVendor();
      this.createOrder.controls["customerAddress1"].setValue(
        this.selectedVendor.addresses![0].addressId
      );
      this.setAddress();
    }
    //this.ngxService.stop();
  }

  protected getCustomerUserDetailsDataListRepsonse(res: any) {
    if (res.userId != null) {
      this.customerOptions = [];
      if (Array.isArray(res)) {
        this.customerOptions = res;
      } else {
        this.customerOptions.push(res);
      }
      // set user details
      this.setSelectedCustomer();
      this.createOrder.controls["customerAddress1"].setValue(
        this.selectedCustomer.addresses![0].addressId
      );
      this.setAddress();
      this.registerUserYn = false;

      if (this.callValidate) {
        this.callValidate = false;
        this.validate();
      }
    } else {
      this.registerUserYn = true;
    }
    //this.ngxService.stop();
  }

  searchCustomerDetailsByUserId() {
    if (this.registeredUserId != 0) {
      this.getCustomerUserDetailsDataListUrl =
        AppConstants.userService +
        AppConstants.getUserById +
        "?userId=" +
        this.registeredUserId;
      this.getCustomerUserDetailsDataListApi();
    }
  }

  protected getRegisterUserData(res: any) {
    this.registeredUserId = res;
    //this.ngxService.stop();

    this.searchCustomerDetailsByUserId();
  }

  registerUser() {
    let custFirstName = this.createOrder.controls["customerFirstName"].value;
    let custLastName = this.createOrder.controls["customerLastName"].value;
    let custEmail = this.createOrder.controls["customerEmail"].value;
    let custMobile = this.createOrder.controls["customerMobile"].value;
    let custAddress1 = this.createOrder.controls["customerAddress1"].value;
    let custAddress2 = this.createOrder.controls["customerAddress2"].value;
    let custCity = this.createOrder.controls["city"].value;
    let custState = this.createOrder.controls["state"].value;
    let custPinCode = this.createOrder.controls["pinCode"].value;
    let vendorId = this.createOrder.controls["vendorIdSearch"].value;

    if (
      custFirstName != null &&
      custLastName != null &&
      custEmail != null &&
      custMobile != null &&
      this.addressDto != null
    ) {
      this.addressDto.addressLine1 = custAddress1;
      this.addressDto.addressLine2 = custAddress2;
      this.addressDto.city = custCity;
      this.addressDto.pincode = custPinCode;
      this.addressDto.state = custState;

      let addressList: any = [];
      addressList.push(this.addressDto);

      const data = {
        firstName: custFirstName,
        lastName: custLastName,
        emailId: custEmail,
        mobileNumber: custMobile,
        vendorClient: true,
        role: "USER",
        addresses: addressList,
      };

      this.getRegisterUserUrl = AppConstants.userService + AppConstants.addUser;
      this.registerUserApi(data);
    }
  }

  protected getGstDataRepsonse(res: any) {
    if (res != null) {
      this.gstObject = res;
      this.gstCgstPercentage = this.gstObject.cgst;
      this.gstSgstPercentage = this.gstObject.sgst;
    }
    //this.ngxService.stop();

    this.getAllStatesUrl = AppConstants.userService + AppConstants.getAllStates;
    this.allStatesApi();
  }

  protected getAllStatesData(res: any) {
    this.stateList = res;
    //this.ngxService.stop();
  }

  protected getAllCityData(res: any) {
    this.cityList = res;
    //this.ngxService.stop();
  }

  makeOrder() {
    let repairIssues: OrderRepairIssueModel[] = [];

    this.selectedProblems.forEach((e) => {
      let repairIssue: OrderRepairIssueModel = new OrderRepairIssueModel();
      repairIssue.brandDto = this.createOrder.controls["brand"].value;
      repairIssue.repairChargeDto = e;
      repairIssue.modelDto = this.createOrder.controls["model"].value;
      repairIssue.repairedAmount = e.repairAmount;
      repairIssue.createdDate = new Date();
      repairIssue.updatedDate = new Date();
      repairIssue.repairRequestDate = new TimeSlotModel().date =
        this.createOrder.controls["deliveryDate"].value;
      repairIssue.repairModeDto = e.repairModeDto;
      repairIssues.push(repairIssue);
    });

    this.mobiOrder.repairIssues = repairIssues;

    if (this.orderTypeValue == "business") {
      this.mobiOrder.userId = Number.parseInt(this.selectedVendor.userId!);
      this.mobiOrder.userDto = this.selectedVendor;
      this.mobiOrder.vendorOrder = true;
    } else {
      this.mobiOrder.userId = Number.parseInt(this.selectedCustomer.userId!);
      this.mobiOrder.userDto = this.selectedCustomer;
      this.mobiOrder.vendorOrder = false;
    }

    this.mobiOrder.orderType = OrderType.REPAIR;
    this.mobiOrder.orderAmount = this.amountAfterDiscount;
    this.mobiOrder.addressId =
      this.createOrder.controls["selectedAddressID"].value;
    this.addressOrderDto.addressId =
      this.createOrder.controls["selectedAddressID"].value;
    this.addressOrderDto.addressLine1 =
      this.createOrder.controls["customerAddress1"].value;
    this.addressOrderDto.addressLine2 =
      this.createOrder.controls["customerAddress2"].value;
    this.addressOrderDto.pincode = this.createOrder.controls["pinCode"].value;
    this.addressOrderDto.state = this.createOrder.controls["state"].value;
    this.addressOrderDto.city = this.createOrder.controls["city"].value;

    this.mobiOrder.addressDto = this.addressOrderDto;
    this.mobiOrder.orderDepartment = "Mobile";
    this.mobiOrder.imeiNumber = this.createOrder.controls["imeiNumber"].value;

    let notes = this.createOrder.controls["notes"].value;
    let discountPercentage =
      this.createOrder.controls["discountPercentage"].value;
    let discountReasons = this.createOrder.controls["discountReasons"].value;

    if (notes != null) {
      this.mobiOrder.notes = notes;
    }
    if (discountPercentage != null) {
      this.mobiOrder.discount = discountPercentage;
    }
    if (discountReasons != null) {
      this.mobiOrder.discountReason = discountReasons;
    }

    this.mobiOrder.vendorId = this.createOrder.controls["vendorIdSearch"].value;

    this.getPaymentDTOUrl =
      AppConstants.orderService +
      AppConstants.calculatePaymentBasedOnAllConditions +
      "?userId=" +
      this.mobiOrder.userId;
    this.getPaymentDTOApi(this.mobiOrder);
  }

  validate() {
    if (this.createOrder.status == "INVALID") {
      this.showSnackBar(
        " All * Marked Fields Are Required!",
        "Warning",
        5000,
        AppConstants.SNACK_BAR_WARNING_CLASS
      );
      return;
    }

    if (this.registerUserYn) {
      this.callValidate = true;
      this.registerUser();
    } else {
      this.makeOrder();
    }
  }

  protected getPaymentDTOData(res: any) {
    this.paymentDTO = res;

    this.cgstAmount = this.paymentDTO.cgst!;
    this.sgstAmount = this.paymentDTO.sgst!;

    //this.ngxService.stop();

    this.mobiOrder.paymentDto = this.paymentDTO;

    this.createMMOrderUrl =
      AppConstants.orderService + AppConstants.createMMOrder;
    this.createMMOrderApi(this.mobiOrder);
  }

  protected getMMOrderData(res: MobiOrderModel) {
    this.createdMobiOrder = res;
    //this.ngxService.stop();
    this.codPaymentOrderUrl =
      AppConstants.paymentService +
      AppConstants.createPayment +
      "?orderId=" +
      this.createdMobiOrder.orderId +
      "&paymentModeType=COD";
    this.makeCODPaymentAgainstOrderDataApi();
  }

  // protected getMakeCODPaymentAgainstOrderData(res: any) {
  //   this.paymentDTOAfterCOD = res;
  //   //this.ngxService.stop();

  //   this.showSnackBar(
  //     "Order Placed Successfully, OrderId: " + this.createdMobiOrder.orderId,
  //     "Success",
  //     5000,
  //     AppConstants.SNACK_BAR_SUCESS_CLASS
  //   );
  //   this.clearForm();
  // }

  clearForm() {
    this.procurementdetails.reset();
    this.expenseDto.expenseId = null;
    this.expenseDto.orderId = this.data.order_details.orderId;
    this.procurementdetails.controls["vendorName"].setValue(null);
    this.procurementdetails.controls["expenseAmount"].setValue(null);
  }

  openProcurementVendors() {
    let dialogRef = this.dialogRef1.open(ProcurementVendorDialog, {
      height: "500px",
      width: "1000px",
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((info) => {
      this.getAllProcurementVendorList();
    });
  }

  getAllProcurementVendorList() {
    this.ngxService.start();
    this.procurementVendorService
      .getAllProcurementVendor(this.getAllProcurementVendorListUrl)
      .subscribe({
        next: (data: any) => {
          this.ProcurementVendorList = data;
          this.ngxService.stop();
        },
        error: (error: any) => {
          console.log(error);
          this.ngxService.stop();
        },
      });
  }

  AddExpense(Problem: any) {
    this.expenseDto.expenseId = null;
    this.expenseDto.orderId = this.data.order_details.orderId;
    this.expenseDto.expenseAmount = this.procurementdetails.controls["expenseAmount"].value;
    this.expenseDto.vendorName = this.procurementdetails.controls["vendorName"].value;
    this.expenseDto.expenseDate = new Date();
    this.expenseDto.expenseType = "PROCUREMENT";
    this.expenseDto.expenseDetails = "PROCUREMENT DETAILS";
    this.expenseDto.repairIssueDto = Problem;
    this.ngxService.start();
    this.addExpService.saveExpense(this.saveExpense, this.expenseDto)
      .subscribe({
        next: (data: any) => {
          this.getOrderById(this.data.order_details.orderId);
          this.snackBar.open(this.updateOrAddedSuccessMsg, "", {
            duration: 5000,
            panelClass: ["green-snackbar"],
          });
          this.clearForm()
        },
        error: (error: any) => {
          console.log(error);
          this.snackBar.open("Order Id Not found!!", "", {
            duration: 5000,
            panelClass: ["blue-snackbar"]
          });
          this.ngxService.stop();
        },
      });
  }

  getOrderById(OrderId) {
    this.getOrderByIdUrl = AppConstants.orderService + AppConstants.getOrderRequest + "?id=" + OrderId;
    this.dataManager.getOrderById(this.getOrderByIdUrl).subscribe({
      next: (data: any) => {
        this.totalExpense = data.repairIssues;
        this, this.ngxService.stop();
        this.getpaymentstatus = data.paymentDto!.status;
        this.getpaymentMode = data.paymentDto!.paymentTypeDto!.paymentModeType;
        this.translate.get(data.paymentDto!.status).subscribe((res: string) => {
          this.paymentStatus = res
        });
        this.translate.get(data.paymentDto!.paymentTypeDto!.paymentModeType).subscribe((res: string) => {
          this.paymentMode = res
        });
        this.status = data.orderStatus
      },
      error: (error: any) => {
        console.log(error);
        this.ngxService.stop();
      },
    });
  }

  DeleteExpense(Problem) {
    this.ngxService.start();
    this.deleteExpenseUrl = AppConstants.orderService + AppConstants.deleteExpense
    this.expenseDto.orderId = Problem.expense.orderId;
    this.expenseDto.expenseId = Problem.expense.expenseId
    this.expenseDto.repairIssueDto = Problem;

    this.addExpService.deleteExpense(this.deleteExpenseUrl, this.expenseDto).subscribe
      ({
        next: (data) => {
          if (data == true) {
            this.getOrderById(this.data.order_details.orderId);
            this.ngxService.stop();
          }
        },
        error: (error) => {
          console.warn(error);
        }
      })
  }

}
