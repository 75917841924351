import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(protected httpClient: HttpClient) { }

  getInReviewVendorList(getInReviewVendorListUrl:string):Observable<any>{
    return this.httpClient.get(getInReviewVendorListUrl);
  }

  approveVendor(approveVendorUrl:string,vendor:any):Observable<any>{
    return this.httpClient.put(approveVendorUrl,vendor);
  }

  getAllVendors(getAllVendorsDataUrl:string):Observable<any>{
    return this.httpClient.get(getAllVendorsDataUrl);
  }
  getAllVendorsCount(getAllVendorsCountUrl:string):Observable<any>{
    return this.httpClient.get(getAllVendorsCountUrl);
  }
}
