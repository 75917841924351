<h5 class="heading">{{'My_Details' | translate}}</h5>
<div class="cardd">
   <form [formGroup]="editForm">
      <div class="row">
         <div class="col-md-6">
            <label>{{'First_Name' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'First_Name' | translate}}" name="firstName" matInput
                  formControlName="firstName">
               <div class="editicon cursor-a" (click)="editfName()" *ngIf="isDisabled1"><span
                     class="material-symbols-outlined">edit</span>
               </div>
               <div class="editicon cursor-a" (click)="submit()" *ngIf="!isDisabled1"> <span
                     class="material-symbols-outlined">save</span>
               </div>
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'Last_Name' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'Last_Name' | translate}}" name="lastName" matInput
                  formControlName="lastName">
               <div class="editicon cursor-a" (click)="editlName()" *ngIf="isDisabled2"><span
                     class="material-symbols-outlined">edit</span></div>
               <div class="editicon cursor-a" (click)="submit()" *ngIf="!isDisabled2"> <span
                     class="material-symbols-outlined">save</span></div>
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'Mobile_Number' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'Mobile_Number' | translate}}" name="mobileNumber" matInput
                  formControlName="mobileNumber">
               <div class="editicon cursor-a" (click)="editmobile()" *ngIf="isDisabled3"><span
                     class="material-symbols-outlined">edit</span></div>
               <div class="editicon cursor-a" (click)="submit()" *ngIf="!isDisabled3"> <span
                     class="material-symbols-outlined">save</span></div>
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'email' | translate}}</label>
            <div class="displayform">
               <input type="email" placeholder="{{'Mobile_Number' | translate}}" name="email" matInput
                  formControlName="email">
               <div class="editicon cursor-a" (click)="editemail()" *ngIf="isDisabled4"><span
                     class="material-symbols-outlined">edit</span></div>
               <div class="editicon cursor-a" (click)="submit()" *ngIf="!isDisabled4"> <span
                     class="material-symbols-outlined">save</span></div>
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'address_first_line' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'address_first_line' | translate}}" name="AddressLine1" matInput
                  formControlName="addressLine1">
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'address_first_line2' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'address_first_line2' | translate}}" name="AddressLine2" matInput
                  formControlName="addressLine2">
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'city' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'city' | translate}}" name="City" matInput formControlName="city">
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'state' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'state' | translate}}" name="State" matInput formControlName="state">
            </div>
         </div>
         <div class="col-md-6">
            <label>{{'pincode' | translate}}</label>
            <div class="displayform">
               <input type="text" placeholder="{{'pincode' | translate}}" name="Pincode" matInput
                  formControlName="pincode">
            </div>
         </div>
      </div>
   </form>
</div>

<!-- <h5 class="heading" _ngcontent-nfi-c106="">Membership Details</h5>
<div class="row justify-content-center">
   <div class="col-md-5" *ngIf="goldMember == false">
      <div class="pricingTable">
         <div class="pricingTable-header">
            <div class="pricing-icon">
               <img src="../../../assets/images/silver.png" style="width: 75px;">
            </div>
            <h3 class="title">Silver</h3>
         </div>
         <ul class="pricing-content">
            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  100% wear and tear repairs
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  100% money back guarantee check
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  Free pickup from home
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  Scheduled pickups and drops check
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  Real time case status
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  24x7 support
               </div>
            </li>

         </ul>
         <div (click)="showComparePlans()">view details</div>
         <button class="upgrade-btn" (click)="NavigateToOrderPage()">Upgrade</button>
      </div>
   </div>
   <div class="col-md-5" *ngIf="goldMember == true">
      <div class="pricingTable orange">
         <div class="pricingTable-header">
            <div class="pricing-icon">
               <img src="../../../assets/images/gold.png" style="width: 75px;">
            </div>
            <h3 class="title">Gold</h3>
         </div>
         <ul class="pricing-content">
            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  100% wear and tear repairs
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  100% money back guarantee check
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  Free pickup from home
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  Scheduled pickups and drops check
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  Real time case status
               </div>
            </li>

            <li>
               <div class="icon">
                  <mat-icon role="img"
                     class="mat-icon notranslate padding-top0imp material-icons mat-ligature-font mat-icon-no-color"
                     aria-hidden="true" data-mat-icon-type="font">check</mat-icon>
               </div>
               <div class="text">
                  24x7 support
               </div>
            </li>
         </ul>
         <div class="price-value">
            Rs 1999.00
         </div>

      </div>
   </div>

</div> -->