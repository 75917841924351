import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-impactof5gnow',
    templateUrl: './impactof5gnow.html'
})

export class ImpactOf5gComponent{
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Dive into the realm where 5G transforms mobile repairs, unraveling unprecedented challenges and solutions. Stay ahead in tech evolution."})
        this.metaService.updateTag({name:'keywords',content:'mobile phone repair, repairing company, 5GRevolution, MobileRepairInsights, 5Gphones'})
        this.canonicalServide.setCanonicalURL();       
        
        this.translate.get('impact_of_5g_now').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('impact_of_5g_now').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        }); 
    }      
}