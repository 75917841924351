import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

export interface Element {
    Specifications:string;
    iPhone14Plus: string;
    iPhone_14ProMax: string;
  }
  
  const ELEMENT_DATA: Element[] = [
    { Specifications:'Price (starting)',iPhone14Plus: '$899.00', iPhone_14ProMax: '$1,099.00'},
    { Specifications:'Dimensions (inches)',iPhone14Plus: '6.33 x 3.07 x 0.31', iPhone_14ProMax: '6.33 x 3.05 x 0.31'},
    { Specifications:'Weight (ounces)',iPhone14Plus: '7.16', iPhone_14ProMax: '8.47'},
    { Specifications:'Processor',iPhone14Plus: 'A15 Bionic, 5-core GPU', iPhone_14ProMax: 'A16 Bionic, 5-core GPU'},
    { Specifications:'Storage',iPhone14Plus: '128GB, 256GB, 512GB', iPhone_14ProMax: '128GB, 256GB, 512GB, 1TB'},
    { Specifications:'Display type',iPhone14Plus: '	6.7-inch Super Retina XDR', iPhone_14ProMax: '6.7-inch Super Retina XDR'},
    { Specifications:'Resolution',iPhone14Plus: '2,778 x 1,284 at 458ppi', iPhone_14ProMax: '2,796 x 1,290 at 460 ppi'},
    { Specifications:'Biometrics',iPhone14Plus: 'Face ID', iPhone_14ProMax: 'Face ID'},
    { Specifications:'Connectivity',iPhone14Plus: '5G (Sub-6GHz and mmWave), Gigabit-class LTE, Wi-Fi 6, Bluetooth 5.3, Ultra Wideband, Emergency SOS via Satellite', iPhone_14ProMax: '5G (Sub-6GHz and mmWave), Gigabit-class LTE, Wi-Fi 6, Bluetooth 5.3, Ultra Wideband, Emergency SOS via Satellite'},
    { Specifications:'Rear Cameras',iPhone14Plus: '12MP Wide, 12MP Ultra Wide', iPhone_14ProMax: '48MP Wide, 12MP Ultra Wide, 12MP Telephoto'},
    { Specifications:'Video',iPhone14Plus: '4K up to 60 fps, 1080p up to 60 fps, 720p HD at 30 fps, 1080p Slo-Mo up to 240 fps, Cinematic mode, Action mode, Sensor-shift optical image, stabilization for video', iPhone_14ProMax: '4K up to 60 fps, 1080p up to 60 fps, 720p HD at 30 fps, 1080p Slo-Mo up to 240 fps, Cinematic mode, Action mode, Sensor-shift optical image, stabilization for video, Dual optical image stabilization for video'},
    { Specifications:'Front Camera',iPhone14Plus: '12MP TrueDepth, Autofocus', iPhone_14ProMax: '12MP TrueDepth, Autofocus'},
    { Specifications:'Battery Size (video playback time)',iPhone14Plus: 'Up to 26 hours', iPhone_14ProMax: 'Up to 29 hours'},
    { Specifications:'Colors',iPhone14Plus: 'Midnight, Purple, Starlight, Blue, (PRODUCT)RED', iPhone_14ProMax: 'Space Black, Silver, Gold, Deep Purple'}
];
  
@Component({
    selector: 'iphone14vsiphone14ProMax',
    templateUrl: './iphone14vsiphone14ProMax.html'
})

export class Iphone14vsIphone14ProMaxComponent{
    displayedColumns: string[] = ['Specifications', 'iPhone14Plus', 'iPhone_14ProMax'];
    dataSource = ELEMENT_DATA;
    constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
    {
        this.metaService.updateTag({name:'description',content:"Which iPhone 14 model is right for you? Compare the features and specs of the iPhone 14 Plus and iPhone 14 Pro Max in this comprehensive guide."})
        this.metaService.updateTag({name:'keywords',content:'iPhone 14 Plus, iPhone 14 Pro Max, Repairing Company, iPhone 14 Plus Features, Specifications,  iPhone 14 Pro Max Difference'})
        this.canonicalServide.setCanonicalURL();
       
        this.translate.get('iPhone_14_Plus_vs_iPhone_14_Pro_Max').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.get('iPhone_14_Plus_vs_iPhone_14_Pro_Max').subscribe((res: string) => {
            this.titleService.setTitle(res);
        });
        });  
        
    
    }   
}
