import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BrandModel } from 'src/app/data/brand-model/brand.model';
import { FormControl, Validators } from '@angular/forms';
import { AppConstants } from 'src/app/constants/app.constants';
import { BaseComponent } from 'src/app/abstract-components/base.component';
import { DeviceModel } from 'src/app/data/device-model/device.model';
import { RepairIssueModel } from 'src/app/data/repair-issue-model/repair-issue.model';
import { DeviceIssueService } from 'src/app/services/device-issue.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-add-brand-device-dialog',
  templateUrl: './add-brand-device-dialog.component.html',
  styleUrls: ['./add-brand-device-dialog.component.scss']
})
export class AddBrandDeviceDialogComponent extends BaseComponent implements OnInit {

  newBrandModel: BrandModel = new BrandModel();
  newDeviceModel: DeviceModel = new DeviceModel();
  newIssueModel: RepairIssueModel = new RepairIssueModel();
  protected createNewDeviceUrl: string;
  protected createNewBrandUrl: string;
  selectedBrand: BrandModel = new BrandModel();
  isBrandDialog: boolean;
  isIssueDialog: boolean;
  categories: String[] = ['Mobile','Air Conditioner','Laptop','Washing Machine'];
  brandCategoryControl = new FormControl('', [Validators.required]);

  constructor(dialogRef: MatDialogRef<AddBrandDeviceDialogComponent>,
    private ngxService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: {},
    private deviceIssueService:DeviceIssueService,
    snackBar: MatSnackBar,
    dialog: MatDialog) {
    super();
    this.dialogRef = dialogRef;
    this.snackBar = snackBar;
    this.dialog = dialog;
    this.selectedBrand = data['selectedBrand'];
    console.log('my selected brand'+ this.selectedBrand.category);
    this.isBrandDialog = data['isBrandDialog'];
    this.isIssueDialog = (this.selectedBrand == undefined) ? true : false;
    this.createNewBrandUrl = AppConstants.orderService + AppConstants.ADD_BRNAD;
    this.createNewDeviceUrl = AppConstants.orderService + AppConstants.ADD_DEVICE;
  }

  ngOnInit(): void {
  }
  submitNewBrand() {
    this.ngxService.start();
    const newBrandDevice = {
        "brandName": this.newBrandModel.brandName,
        "category": this.newBrandModel.category,
        "enabled": true
    };
    this.deviceIssueService.addRequest(this.createNewBrandUrl, newBrandDevice)
        .subscribe({
          next:(data)=>{
            this.dialogRef.close(data)
            this.ngxService.stop();
            this.snackBar.open('Brand Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
          },
          error:(error)=>{
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
              this.ngxService.stop();
              this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
            }
          }
        });
}

saveNewDevice() {
    const newBrandDevice = {
        "modelName": this.newDeviceModel.modelName,
        "modelYear": this.newDeviceModel.modelYear,
        "enabled": true,
        "brandDto": this.newDeviceModel.brandDto
    };
    this.deviceIssueService.addRequest(this.createNewDeviceUrl, newBrandDevice)
        .subscribe({
          next:(data)=>{
            this.dialogRef.close(data)
            this.ngxService.stop();
            this.snackBar.open('Device Added Successfully!!','',{duration:5000,panelClass: ['green-snackbar']})
          },
          error:(error)=>{
            if (this.errorHandler(error)) {
              this.errorDisplayDialog(error.error.message);
              this.ngxService.stop();
              this.snackBar.open("Something Went Wrong", "", { duration: 5000, panelClass: ["blue-snackbar"]});
          }
          }
        });
}


  submitNewDevice() {
    this.ngxService.start();
    this.newDeviceModel.brandDto!.brandId = this.selectedBrand.brandId;
    this.newDeviceModel.brandDto!.brandName = this.selectedBrand.brandName;
    this.newDeviceModel.brandDto!.category = this.selectedBrand.category;
    this.newDeviceModel.brandDto!.enabled = this.selectedBrand.enabled;
    this.saveNewDevice();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
