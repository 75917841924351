import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-takescreenshotskeyboard',
    templateUrl: './takescreenshotskeyboard.component.html'
})

export class takescreenshotskeyboardComponent{
  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {
    this.metaService.updateTag({name:'description',content:"If you want to take a screenshot on a PC or laptop can be done in a variety of ways. In this blog, we will guide you on how to take a screenshot using keyboard shortcuts."})
    this.metaService.updateTag({name:'keywords',content:'how to take screenshot in laptop, Screenshot Using Keyboard Shortcuts'})
    this.canonicalServide.setCanonicalURL();

    this.translate.get('how_To_Take_Screenshots').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('how_To_Take_Screenshots').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    });
  }   
}