import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { AppConstants } from "src/app/constants/app.constants";
import { MobiOrderModel } from "src/app/data/mobi-order-model/mobi-order.model";
import { UserDetailsModel } from "src/app/data/user-details/user-details.model";
import { OrderDetailsService } from "src/app/services/order-details.service";
import { OrderDetailsDialog } from "../../dialogs/order-details-dialog/order-details-dialog";
import { ViewOraddSupportComponent } from "../view-oradd-support/view-oradd-support.component";

@Component({
  selector: "my-orders",
  templateUrl: "./my-orders.component.html",
  styleUrls: ["./my-orders.component.scss"],
})

export class MyOrdersComponent implements OnInit {
  constructor(
    public openDialog: MatDialog,
    private ngxService: NgxUiLoaderService,
    private orderDetailsService: OrderDetailsService
  ) {}
  UserId: any;
  getAllOrderByUserUrl: string;
  sortValue: any;
  mobiOrderModel: MobiOrderModel[];
  user_data: UserDetailsModel = new UserDetailsModel();
  pageNo: any;
  DataLength: any;
  pageSize: any;
  getOrderByIdUrl: string;
  ngOnInit(): void {
    this.pageNo = 0;
    this.sortValue = "DESC";
    this.UserId = localStorage.getItem("userId")?.toString();
    this.getAllOrderData(this.pageNo);
    this.getAllOrderCount();
  }

  sort(event) {
    this.pageNo = 0;
    if (event.direction == "asc") {
      this.sortValue = "ASC";
    } else if (event.direction == "desc") {
      this.sortValue = "DESC";
    } else {
      this.sortValue = event.direction;
    }
    this.getAllOrderData(this.pageNo);
  }
  pageEvents(event: any) {
    this.pageSize = event.pageSize;
    if (event.pageIndex > this.pageNo) {
      this.getAllOrderData(event.pageIndex);
      this.pageNo = event.pageIndex;
    } else {
      this.getAllOrderData(event.pageIndex);
      this.pageNo = event.pageIndex;
    }
  }

  getAllOrderData(pageNo: any) {
    this.ngxService.start();
    this.getAllOrderByUserUrl =
      AppConstants.orderService +
      AppConstants.getOrders +
      "?searchValue=" +
      this.UserId +
      "&sortOrder=" +
      this.sortValue +
      "&offSet=" +
      pageNo;
    this.orderDetailsService
      .getAllOrderByUserId(this.getAllOrderByUserUrl)
      .subscribe({
        next: (data: MobiOrderModel[]) => {
          this.mobiOrderModel = data;
          this.ngxService.stop();
        },
        error: (error: any) => {
          this.ngxService.stop();
        },
      });
  }

  getAllOrderCount() {
    this.getAllOrderByUserUrl =
      AppConstants.orderService +
      AppConstants.getOrdersCount +
      "?searchValue=" +
      this.UserId;
    //this.ngxService.start();
    this.orderDetailsService
      .getAllOrderByUserId(this.getAllOrderByUserUrl)
      .subscribe({
        next: (data: any) => {
          this.DataLength = data;
        },
        error: (error: any) => {
          console.log(error);
          this.ngxService.stop();
        },
      });
  }

  openOrderDetails(OrderId: any) {
    this.ngxService.start();
    this.getOrderByIdUrl =
      AppConstants.orderService +
      AppConstants.getOrderRequest +
      "?id=" +
      OrderId;
    this.orderDetailsService.getOrderById(this.getOrderByIdUrl).subscribe({
      next: (data: any) => {
        console.log(data);
        const dialogRef = this.openDialog.open(OrderDetailsDialog, {
          width: "55%",
          height: "100%",
          data: { orderDetail: data },
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result) => {});
        this.ngxService.stop();
      },
      error: (error: any) => {
        console.log(error);
        this.ngxService.stop();
      },
    });
  }

  viewOrAddSupport(Order: any) {
    let dialogRef = this.openDialog.open(ViewOraddSupportComponent, {
      height: "100%",
      width: "100%",
      disableClose: true,
      data: { orderDetail: [Order] },
    });
    dialogRef.afterClosed().subscribe((info) => {});
  }
}
