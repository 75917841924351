import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ServiceCenterService {

  constructor(protected httpClient: HttpClient) { }

  serviceCenterListApiCall(serviceCenterListApiCallUrl:string):Observable<any>{
    return this.httpClient.get(serviceCenterListApiCallUrl);
  }

  updateServiceCenter(updateServiceCenterUrl:string,support:any):Observable<any>{
    return this.httpClient.put(updateServiceCenterUrl,support);
  }

  addServiceCenter(updateServiceCenterUrl:string,support:any):Observable<any>{
    return this.httpClient.post(updateServiceCenterUrl,support);
  }

  getDepartmentList(getDepartmentListUrl:string):Observable<any>{
    return this.httpClient.get(getDepartmentListUrl);
  }
  getServiceAreaList(getServiceAreaListtUrl:string):Observable<any>{
    return this.httpClient.get(getServiceAreaListtUrl);
  }
  
}
