import { Component } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { CanonicalService } from "src/app/canonical.service";

@Component({
    selector: 'app-enhancing-customer-experience',
    templateUrl: './enhancing-customer-experience.html'
})

export class EnhancingCustomerExperienceComponent{  
  constructor(private titleService:Title,private metaService:Meta,private canonicalServide:CanonicalService,private translate:TranslateService)
  {
    this.metaService.updateTag({name:'description',content:"Make sure your customers are satisfied with every service you provide! Follow our top 3 best practices for enhancing their repair experience."})
    this.metaService.updateTag({name:'keywords',content:'Repair services, Repairing Company, Enhancing the customer experience'})
    this.canonicalServide.setCanonicalURL();

    this.translate.get('best_Practices').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    this.translate.get('best_Practices').subscribe((res: string) => {
        this.titleService.setTitle(res);
    });
    });
  }      
}