<div class="row">
   <div class="col-md-6" >
      <h5 class="heading">{{'My_Addresses' | translate}}</h5>
   </div>
   <div class="col-md-6" >
      <button (click)="AddAddressModal()"  mat-raised-button class="button">{{'Add_Address' | translate}} +</button>
   </div>
</div>
<div class="main">
   <div class="container-fluid">
      <div class="row">
         <div class="col-md-6" *ngFor="let address of addresses">
            <div class="rc-margin">
               <div [class]="{'primary': address.primaryAddress === true}" *ngIf="address.primaryAddress == true">{{'Primary_Address' | translate}}
            </div>
            <div class="cardd"[class]="{'primary-address': address.primaryAddress === true}">
               <p>{{address.addressLine1}}, {{address.addressLine2}}</p>
               <p>{{address.city}}, {{address.state}} - {{address.pincode}}</p>
               <div class="text-display">
                  <h5><a class="cursor-a"(click)="editAddressModal(address)">{{'Edit' | translate}}</a></h5>
                  <span> <div class="rc-padding">|</div></span> 
                  <h5><a class="cursor-a rc-a-color" (click)="deleteAddress(address.addressId)">{{'remove' | translate}}</a></h5>
                  <span  *ngIf="address.primaryAddress != true"><div class="rc-padding">|</div></span>
                  <h5 *ngIf="address.primaryAddress != true"><a class="cursor-a" (click)="updateToPrimary(address.addressId)">{{'Set_as_Primary' | translate}}</a></h5>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>