<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css">
<link
   href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,100&display=swap"
   rel="stylesheet">
<div class="container">
   <form [formGroup]="calculatorForm">
      <div fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutAlign.sm="center center"
         fxLayoutAlign.xs="center stretch" class="genuine-class-color" fxLayoutGap="20" fxLayout.sm="column"
         fxLayout.xs="column">
         <div fxLayout="column" fxFlex="60" fxLayout.xs="column" fxFlex.xs="400">
            <div>
               <!-- <ul class="breadcrumb">
                  <li (click)="navigate()"><span>{{'home' | translate}}</span></li>
                  <li><span class="color-red">{{'repair' | translate}}</span></li>
                  </ul> -->
               <h1 class="repair-heading">{{'order_details' | translate}}</h1>
            </div>
            <div class="form-bg">
               <div class="margin-top10" [@openClose]>
                  <mat-form-field appearance="fill" class="select-product">
                     <mat-label>{{'select_Product' | translate}}</mat-label>
                     <mat-select formControlName="product" (selectionChange)="changeProduct($event.value)">
                        <mat-option *ngFor="let product of productCategory"
                           value={{product.productCategoryName}}>{{product.productCategoryDescription |
                           translate}}</mat-option>
                     </mat-select>
                     <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                  </mat-form-field>
               </div>
               <div [ngSwitch]="product">


                  <div *ngSwitchCase="'MOBILE'" [@openClose]>
                     <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.lg="20" fxLayoutGap.md="20"
                        fxLayoutGap.sm="20">
                        <div fxFlex="30" class="margin-top10">
                           <mat-form-field appearance="fill" class="select-brand">
                              <mat-label>{{'select_Brand' | translate}}</mat-label>
                              <mat-select formControlName="brand" (selectionChange)="changeBrand($event.value)">
                                 <mat-option *ngFor="let brand of brandList" [value]="brand">{{brand.brandName}}
                                 </mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                        <div fxFlex="30" class="margin-top10">
                           <mat-form-field appearance="fill" class="select-model">
                              <mat-label>{{'select_Model' | translate}}</mat-label>
                              <mat-select formControlName="model" (selectionChange)="changeModel($event.value)">
                                 <mat-option *ngFor="let model of deviceList" [value]="model">{{model.modelName}}
                                 </mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                        <div *ngIf="planName==''" fxFlex="34" class="margin-top10">
                           <mat-form-field appearance="fill" class="select-problem">
                              <mat-label>{{'select_Problem' | translate}}</mat-label>
                              <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                 [multiple]="true">
                                 <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                     </div>
                  </div>

                  <div *ngSwitchCase="'AIR_CONDITIONER'" [@openClose]>
                     <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.lg="20" fxLayoutGap.md="20"
                        fxLayoutGap.sm="20">
                        <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                           <mat-form-field appearance="fill" class="select-brand">
                              <mat-label>Model Type</mat-label>
                              <mat-select formControlName="acTypes" (selectionChange)="changeAcType($event.value)">
                                 <mat-option *ngFor="let acType of acTypesList" [value]="acType">
                                    {{acType.acTypes | translate}}
                                 </mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                        <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                           <mat-form-field appearance="fill" class="select-model">
                              <mat-label>{{'select_Problem' | translate}}</mat-label>
                              <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                 [multiple]="true">
                                 <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                     </div>
                  </div>

                  <div *ngSwitchCase="'REFRIGERATOR'" [@openClose]>
                     <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.lg="20" fxLayoutGap.md="20"
                        fxLayoutGap.sm="20">
                        <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                           <mat-form-field appearance="fill" class="select-brand">
                              <mat-label>Model Type</mat-label>
                              <mat-select formControlName="refrigeratorTypes"
                                 (selectionChange)="changeAcType($event.value)">
                                 <mat-option *ngFor="let refrigeratorTypes of refrigeratorTypesList"
                                    [value]="refrigeratorTypes">
                                    {{refrigeratorTypes.refrigeratorType| translate}}
                                 </mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                        <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                           <mat-form-field appearance="fill" class="select-model">
                              <mat-label>{{'select_Problem' | translate}}</mat-label>
                              <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                 [multiple]="true">
                                 <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                     </div>
                  </div>
                  <div *ngSwitchCase="'WASHING_MACHINE'" [@openClose]>
                     <div fxLayout="row" fxLayout.xs="column" fxLayoutGap.lg="20" fxLayoutGap.md="20"
                        fxLayoutGap.sm="20">
                        <div fxFlex="35" fxFlex.md="35" fxFlex.sm="40">
                           <mat-form-field appearance="fill" class="select-brand">
                              <mat-label>Model Type</mat-label>
                              <mat-select formControlName="washingMachineTypes"
                                 (selectionChange)="changeAcType($event.value)">
                                 <mat-option *ngFor="let washingMachineType of washingMachineTypesList"
                                    [value]="washingMachineType">
                                    {{washingMachineType.washingMachineType| translate}}
                                 </mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                        <div fxFlex="32" fxFlex.md="35" fxFlex.sm="40">
                           <mat-form-field appearance="fill" class="select-model">
                              <mat-label>{{'select_Problem' | translate}}</mat-label>
                              <mat-select formControlName="repair" (selectionChange)="selectProblems()"
                                 [multiple]="true">
                                 <mat-option *ngFor="let repair of repairChargeList" [value]="repair">
                                    {{repair.issueDto?.issueName}}</mat-option>
                              </mat-select>
                              <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                           </mat-form-field>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div fxLayout="column" fxLayoutAlign="center center" fxFlex="40">
            <div class="ellipse">
               <div fxLayout="column" fxLayout.xs="column">
                  <div class="circle pulse orange">
                     <div class="total-rupees" *ngIf="promoCodeTotalAmount==0">
                        <span class="total-rupees">₹</span> {{totalAmount | number : '1.2-2'}}
                     </div>
                     <div class="total-rupees-silver" *ngIf="promoCodeTotalAmount>0">
                        <span class="total-rupees">₹</span> {{promoCodeTotalAmount | number : '1.2-2'}}
                     </div>
                     <div class="total-rupees total-rupees1"
                        *ngIf=" paymentDTO1!= null && totalAmount != paymentDTO1!.paymentTotalAmount">
                        <span class="total-rupees1">₹</span> {{paymentDTO1!.paymentTotalAmount | number : '1.2-2'}}
                     </div>

                  </div>
                  <div class="row">
                     <div class="col-md-12">
                        <h2>{{'total_Amount' | translate}}</h2>
                     </div>
                     <div class="col-md-6">
                        <div class="below-eclipse"><span><img src="../assets/images/icons/star.png"
                                 style="width: 28px;margin-right: 10px;"></span>100% {{'genuine_Parts' | translate}}
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="below-eclipse"><span><img src="../assets/images/icons/star.png"
                                 style="width: 28px;margin-right: 10px;"></span>{{'3_Months_Warranty' | translate}}
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="below-eclipse"><span><img src="../assets/images/icons/star.png"
                                 style="width: 28px;margin-right: 10px;"></span>{{'doorstep_Delivery' | translate}}
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="below-eclipse"><span><img src="../assets/images/icons/star.png"
                                 style="width: 28px;margin-right: 10px;"></span>{{'twenty_Seven' | translate}}</div>
                     </div>
                     <div class="col-md-12">
                        <div class="details-cs">
                           <ng-container *ngIf="paymentDTO1!=null">
                              <div fxLayout="row" *ngFor="let problem of paymentDTO1.repairIssueDto">
                                 <div fxLayout="column" fxFlex="50">
                                    <b>{{problem.repairChargeDto!.issueDto!.issueName}}</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{problem!.beforeTax | number : '1.2-2'}}</div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="paymentDTO1!=null">
                              <div fxLayout="row" *ngIf="paymentDTO1!.cgst! > 0">
                                 <div fxLayout="column" fxFlex="50"><b>CGST</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{paymentDTO1!.cgst | number : '1.2-2'}}</div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="paymentDTO1!=null">
                              <div fxLayout="row" *ngIf="paymentDTO1!.sgst! > 0">
                                 <div fxLayout="column" fxFlex="50"><b>SGST</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{paymentDTO1!.sgst | number : '1.2-2'}}</div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="paymentDTO1!=null">
                              <div fxLayout="row" *ngIf="paymentDTO1!.igst! > 0">
                                 <div fxLayout="column" fxFlex="50"><b>IGST</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{paymentDTO1!.igst | number : '1.2-2'}}</div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="paymentDTO1!=null">
                              <div fxLayout="row">
                                 <div fxLayout="column" fxFlex="50"><b>Promocode Discount</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{paymentDTO1!.promoCodeAmtOff == null ? 0:
                                    paymentDTO1!.promoCodeAmtOff| number : '1.2-2'}}</div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="paymentDTO1!=null && paymentDTO1.goldMember">
                              <div fxLayout="row">
                                 <div fxLayout="column" fxFlex="50"><b>Gold membership Discount</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{goldMembershipDiscount | number : '1.2-2'}}
                                 </div>
                              </div>
                           </ng-container>
                           <ng-container *ngIf="paymentDTO1!=null">
                              <div fxLayout="row">
                                 <div fxLayout="column" fxFlex="50"><b>Total Amount</b></div>
                                 <div fxLayout="column" fxFlex="50">RS. {{paymentDTO1!.paymentTotalAmount| number :
                                    '1.2-2'}}</div>
                              </div>
                           </ng-container>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <ng-container *ngIf="paymentDTO1!=null &&promoCodeTotalAmount>0">
               <div fxLayout="row" *ngFor="let problem of paymentDTO1.repairIssueDto">
                   <div fxLayout="column" fxFlex="50"> <b>{{problem.issueName}}</b></div>
               </div>
               </ng-container> -->
         </div>
      </div>
      <div fxLayout="column" fxLayout.xs="column" class="genuine-class-color width-60">
         <div class="form-bg">
            <div class="margin-top-60-1400px" *ngIf="planName!=''">
               <h5 class="repair-heading-h5">{{'phone_purchase_date' | translate}}<span class="required">*</span></h5>
               <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                  <mat-label>{{'select_phone_purchase_date' | translate}}</mat-label>
                  <input matInput formControlName="phonePurchaseDate" [matDatepicker]="picker" [max]="minDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
               </mat-form-field>
            </div>
            <div class="margin-top-60-1400px" *ngIf="planName==''">
               <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                  <mat-label>{{'select_repair_date' | translate}}</mat-label>
                  <input matInput formControlName="repairDate" [matDatepicker]="picker" [min]="minDate">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
               </mat-form-field>
            </div>
            <div class="margin-top10">
               <h5 class="repair-heading-h5">{{'address' | translate}} <span class="required">*</span></h5>
               <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                  <mat-label>{{'address_first_line' | translate}}</mat-label>
                  <input matInput formControlName="address1">
               </mat-form-field>
            </div>
            <div class="margin-top10">
               <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                  <mat-label>{{'address_first_line2' | translate}}</mat-label>
                  <input matInput formControlName="address2">
               </mat-form-field>
            </div>
            <div class="row">
               <div class="col-md-4">
                  <div class="margin-top10">
                     <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                        <mat-label>{{'state' | translate}}</mat-label>
                        <mat-select formControlName="state" (selectionChange)="allCitiesApi($event.value)">
                           <mat-option *ngFor="let state of stateList" value={{state.name}}>{{state.name}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                     </mat-form-field>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="margin-top10">
                     <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                        <mat-label>{{'city' | translate}}</mat-label>
                        <mat-select formControlName="city">
                           <mat-option *ngFor="let city of cityList" value={{city.name}}>{{city.name}}</mat-option>
                        </mat-select>
                        <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                     </mat-form-field>
                  </div>
               </div>
               <div class="col-md-4">
                  <div class="margin-top10">
                     <mat-form-field appearance="fill" class="select-brand mat-form-fileld">
                        <mat-label>{{'pincode' | translate}}</mat-label>
                        <input matInput formControlName="pincode">
                     </mat-form-field>
                  </div>
               </div>
            </div>
            <div class="row" *ngIf="paymentDTO1!=null && paymentDTO1!.paymentTotalAmount!=0">
               <div class="col-md-12">
                  <div class="margin-top10">
                     <mat-label>Payment Mode</mat-label>
                     <mat-radio-group formControlName="PaymentMode" class="radio-cs" (change)="radioChange($event)">
                        <mat-radio-button class="margin-left20" value="Razor">{{'online' |
                           translate}}</mat-radio-button>
                        <mat-radio-button class="margin-left20" value="COD">{{'cash_on_delivery' |
                           translate}}</mat-radio-button>
                     </mat-radio-group>
                  </div>
               </div>
            </div>
            <!-- <div class="row" *ngIf="paymentDTO1!=null && paymentDTO1!.paymentTotalAmount!=0 && this.paymentOnlineModeType == 'Online'">
               <div class="col-md-12">
                  <div class="margin-top10">
                     <mat-label>Online Mode</mat-label>
                     <mat-radio-group formControlName="PaymentOnlineMode" class="radio-cs">
                        <mat-radio-button value="Paytm">{{'paytm' | translate}}</mat-radio-button>
                        <mat-radio-button class="margin-left20" value="Razor">{{'razor_pay' | translate}}</mat-radio-button>
                     </mat-radio-group>
                  </div>
               </div>
            </div> -->
            <div class="row">
               <div class="col-md-6">
                  <div class="margin-top10">
                     <mat-form-field appearance="fill" class="select-brand mat-form-fileld" style="position: relative;">
                        <mat-label>{{'promo_code' | translate}}</mat-label>
                        <input matInput formControlName="promocode">
                        <button class="promoc">Apply</button>
                        <div *ngIf="disablePromocodeFieldAndBtn == false">
                           <button class="promoc" [disabled]="disablePromocodeFieldAndBtn"
                              (click)="validatePromoCode()">{{'apply' | translate}}</button>
                        </div>
                        <div *ngIf="disablePromocodeFieldAndBtn == true">
                           <button class="promoc" (click)="removePromoCode()">{{'remove' | translate}}</button>
                        </div>
                     </mat-form-field>
                  </div>
               </div>
               <div class="col-md-6">
                  <mat-slide-toggle class="toggle-class" (click)="toggle()" [ngModelOptions]="{ standalone: true }" >
                    <p class="pfont">{{ 'screen_protector' | translate }}</p>
                  </mat-slide-toggle>
                </div>

            </div>
            <div class="row" *ngIf="temperedGlass!=''">
               <div class="col-md-6">
                  <div class="margin-top10">
                     <mat-form-field appearance="fill" class="select-brand mat-form-fileld" style="position: relative;">
                        <mat-label>{{'tempered_glass' | translate}}</mat-label>
                        <input matInput [readonly]="true" formControlName="temperedGlass">
                        <button class="promoc">Apply</button>
                        <div>
                           <button class="promoc" (click)="removeTemperedGlass()">{{'remove' | translate}}</button>
                        </div>
                     </mat-form-field>
                  </div>
               </div>
            </div>
            <div class="order-now-btn btn-style " fxLayoutAlign="start start">
               <button class="order-btn " type="submit" (click)="makeOrder()">{{'order_Now' | translate}}</button>
            </div>
         </div>
      </div>
   </form>
</div>